import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USERS_WITH_TIME_SHEET_ENTRIES } from '@atom/graph/user';
import {
  TimeEntriesConnectionInput,
  TimeEntriesTotalDurationInput,
} from '@atom/types/timeEntry';
import { UsersConnection, UsersConnectionInput } from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import TeamContext from '../TeamContext';
import ListBulkControls from '../timeHeaders/ListBulkControls';
import PayPeriodControls from '../timeHeaders/PayPeriodControls';
import TitleHeader from '../timeHeaders/TitleHeader';

import TimeSheetListTable from './TimeSheetListTable';

const TimeSheetList = () => {
  const { activeTimeSheetGroup, selectedPayPeriod } = useContext(TeamContext);

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [selectedUserIds, setSelectedUserIds] = useState<Set<string>>(
    new Set(),
  );

  const { data, loading, refetch: refetchUsersWithTimeSheetEntries } = useQuery<
    { users: UsersConnection },
    {
      input: UsersConnectionInput;
      timeSheetEntriesInput: TimeEntriesConnectionInput;
      timeEntriesTotalDurationInput: TimeEntriesTotalDurationInput;
    }
  >(GET_USERS_WITH_TIME_SHEET_ENTRIES, {
    variables: {
      input: {
        page,
        limit,
        userGroupId: activeTimeSheetGroup?.id,
        showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
        userGroupStartDate: selectedPayPeriod?.startDate,
        userGroupEndDate: selectedPayPeriod?.endDate,
      },
      timeSheetEntriesInput: {
        userGroupId: activeTimeSheetGroup?.id,
        dateStart: selectedPayPeriod?.startDate,
        dateEnd: selectedPayPeriod?.endDate,
      },
      timeEntriesTotalDurationInput: {
        dateStart: selectedPayPeriod?.startDate,
        dateEnd: selectedPayPeriod?.endDate,
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSelectedUserIds(new Set()),
  });

  useEffect(() => {
    if (selectedPayPeriod) {
      setPage(1);
      setLimit(25);
      setSelectedUserIds(new Set());
    }
  }, [selectedPayPeriod]);

  const showBulkControls = selectedUserIds.size > 0;

  return (
    <>
      <TitleHeader title={activeTimeSheetGroup?.groupPath?.join(' / ')} />
      {showBulkControls ? (
        <ListBulkControls
          users={data?.users?.users}
          loading={loading}
          selectedUserIds={selectedUserIds}
          setSelectedUserIds={setSelectedUserIds}
          refetchUsersWithTimeSheetEntries={refetchUsersWithTimeSheetEntries}
        />
      ) : (
        <PayPeriodControls loading={loading} />
      )}
      <TimeSheetListTable
        users={data?.users?.users}
        totalCount={data?.users?.totalCount}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        setSelectedUserIds={setSelectedUserIds}
        selectedUserIds={selectedUserIds}
        loading={loading}
      />
    </>
  );
};

export default TimeSheetList;

import React from 'react';
import * as R from 'ramda';

import { LocationDataTitle, TaskLocation } from '@atom/types/taskLocation';

import './workOrderReport.css';

interface Props {
  rowLocations: TaskLocation[];
}

const LocationsRow = ({ rowLocations }: Props) => {
  const convertNumberToLocaleString = (input: string | number): string => {
    return R.isNil(input) ? '' : Number(input).toLocaleString('en-US');
  };

  return (
    <div styleName="location-row-container">
      {rowLocations.map(location => {
        const route =
          location?.data.find(item => item.title === LocationDataTitle.ROUTE)
            ?.value || '';

        const direction =
          location?.data.find(
            item => item.title === LocationDataTitle.DIRECTION,
          )?.value || '';

        const startMilepost = location?.data.find(
          item => item.title === LocationDataTitle.START_MILEPOST,
        )?.value;

        const endMilepost = location?.data.find(
          item => item.title === LocationDataTitle.END_MILEPOST,
        )?.value;

        return (
          <div styleName="location-row">
            <div>{route}</div>
            <div styleName="location-row-sub">
              <div styleName="location-cell">{direction}</div>
              <div styleName="location-cell">
                {convertNumberToLocaleString(startMilepost)}
              </div>
              <div styleName="location-cell">
                {convertNumberToLocaleString(endMilepost)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LocationsRow;

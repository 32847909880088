import React, { useContext } from 'react';

import TeamContext, {
  baseUserGroup,
  Section,
  View,
} from '@atom/components/teamPortal/TeamContext';
import TeamCreateControls from '@atom/components/teamPortal/teamControls/TeamCreateControls';
import { Icon, IconButton } from '@atom/mui';
import { UserGroupTreeType } from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import UserGroupRow from './UserGroupRow';

import './userGroupTree.css';

const UserGroupTree = () => {
  const {
    activeView,
    activeGroup,
    handleGroup,
    userGroupTree,
    expanded,
    toggleExpanded,
    navigateToView,
  } = useContext(TeamContext);

  const onClick = (
    selectedUserGroup: UserGroupTreeType,
    path: UserGroupTreeType[],
  ) => {
    navigateToView(View.DIRECTORY);
    handleGroup(selectedUserGroup, path);
  };

  const rowStyle =
    activeView === View.DIRECTORY && activeGroup.id === baseUserGroup.id
      ? 'row selected root'
      : 'row root';

  const icon = expanded.has(Section.DIRECTORY) ? 'expand_less' : 'expand_more';

  const handleToggleExpandDirectory = event => {
    event.stopPropagation();
    toggleExpanded(Section.DIRECTORY);
  };

  return (
    <div styleName="tree-container">
      <div
        styleName={rowStyle}
        onClick={() =>
          onClick(baseUserGroup, [
            { id: userGroupTree.id, name: userGroupTree.name },
          ])
        }
      >
        <div styleName="row-content">
          <div styleName="left-content">
            <div styleName="name-container">
              <div styleName="title root">{baseUserGroup.name}</div>
            </div>
          </div>
          <div styleName="button-container">
            {hasRolePermissions(ROLE_SETS.ORG_ADMIN) && (
              <TeamCreateControls userGroup={baseUserGroup} />
            )}
            <IconButton onClick={handleToggleExpandDirectory}>
              <Icon>{icon}</Icon>
            </IconButton>
          </div>
        </div>
      </div>
      {expanded.has(Section.DIRECTORY) && (
        <>
          {userGroupTree.userGroups.map((userGroup: UserGroupTreeType) => (
            <UserGroupRow
              key={userGroup.id}
              userGroup={userGroup}
              indentation={0}
              path={[userGroupTree, userGroup]}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default UserGroupTree;

import React, { useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import SchemaDetailContext, {
  PendingCreations,
  PendingGroupUpdates,
  PendingUpdates,
  PreviewTab,
  SchemaDetailView,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { GET_SCHEMA_TREE } from '@atom/graph/schema';
import { Progress } from '@atom/mui';
import {
  SchemaTree,
  SchemaTreeAttribute,
  SchemaTreeAttributeGroup,
  SelectedAttributeRoute,
} from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import SchemaBuilder from './schemaBuilder/SchemaBuilder';
import SchemaDetailHeader from './schemaDetailHeader/SchemaDetailHeader';
import SchemaPreview from './schemaPreview/SchemaPreview';
import { getAttributePathFromRoute } from './schemaDetailUtilities';

import './schemaDetail.css';

interface Props {
  match: Match<{ id: string }>;
}

const SchemaDetail = ({ match }: Props) => {
  const [view, setView] = useState<SchemaDetailView>(SchemaDetailView.PREVIEW);
  const [previewTab, setPreviewTab] = useState<PreviewTab>(PreviewTab.INFO);
  const [collapsedSubItems, setCollapsedSubItems] = useState<Set<string>>(
    new Set([]),
  );

  const [schemaTree, setSchemaTree] = useState<Partial<SchemaTree>>();
  const [selectedSubItem, setSelectedSubItem] = useState<Partial<SchemaTree>>();
  const [selectedSubItemPath, setSelectedSubItemPath] = useState<any[]>(null);
  const [selectedAttribute, setSelectedAttribute] = useState<
    Partial<SchemaTreeAttribute>
  >();
  const [selectedAttributeRoute, setSelectedAttributeRoute] = useState<
    SelectedAttributeRoute
  >(null);
  const [selectedAttributeGroup, setSelectedAttributeGroup] = useState<
    Partial<SchemaTreeAttributeGroup>
  >();

  // Cart for pending changes to attribute groups on published schema
  const [pendingGroupUpdates, setPendingGroupUpdates] = useState<
    PendingGroupUpdates
  >();
  // Cart for pending changes to attributes on published schema
  const [pendingUpdates, setPendingUpdates] = useState<PendingUpdates>();
  // Cart for net new attribute creations on published schema
  const [pendingCreations, setPendingCreations] = useState<PendingCreations>();

  const onCompleted = data => {
    // On initial load, collapse all top level items in the tree
    if (isNilOrEmpty(schemaTree)) {
      const elementGroupIds = data?.schemaTree?.elementGroups.map(
        group => group.id,
      );

      const elementIds = data?.schemaTree?.elements.map(element => element.id);

      setCollapsedSubItems(new Set([...elementGroupIds, ...elementIds]));
    }

    setSchemaTree(data?.schemaTree);

    // If a sub item is selected, update the sub item's value
    if (selectedSubItem) {
      setSelectedSubItem(
        R.view(R.lensPath(selectedSubItemPath), data?.schemaTree) || {},
      );
    }

    // If a sub item and attribute are selected, update the attribute's value
    if (selectedSubItem && selectedAttribute) {
      const path = [
        ...selectedSubItemPath,
        ...getAttributePathFromRoute(selectedSubItem, selectedAttributeRoute),
      ];

      setSelectedAttribute(R.view(R.lensPath(path), data?.schemaTree) || {});
    }
  };

  const { refetch: refetchSchemaTree, loading: schemaTreeLoading } = useQuery(
    GET_SCHEMA_TREE,
    {
      variables: {
        id: match.params.id,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted,
    },
  );

  const schemaDetailLoading = isNilOrEmpty(schemaTree);

  const getActiveView = () => {
    const views = {
      [SchemaDetailView.PREVIEW]: <SchemaPreview />,
      [SchemaDetailView.BUILDER]: <SchemaBuilder />,
    };

    return views[view];
  };

  const viewStyle = view === SchemaDetailView.PREVIEW ? 'view preview' : 'view';
  const loading = schemaTreeLoading;

  return (
    <SchemaDetailContext.Provider
      value={{
        schemaTree,
        refetchSchemaTree,
        loading,
        view,
        setView,
        previewTab,
        setPreviewTab,
        collapsedSubItems,
        setCollapsedSubItems,
        selectedSubItem,
        setSelectedSubItem,
        selectedSubItemPath,
        setSelectedSubItemPath,
        selectedAttributeRoute,
        setSelectedAttributeRoute,
        selectedAttribute,
        setSelectedAttribute,
        selectedAttributeGroup,
        setSelectedAttributeGroup,
        pendingGroupUpdates,
        setPendingGroupUpdates,
        pendingUpdates,
        setPendingUpdates,
        pendingCreations,
        setPendingCreations,
      }}
    >
      {schemaDetailLoading ? (
        <Progress />
      ) : (
        <>
          <SchemaDetailHeader />
          <div styleName={viewStyle}>{getActiveView()}</div>
        </>
      )}
    </SchemaDetailContext.Provider>
  );
};

export default SchemaDetail;

import React, { useContext } from 'react';

import CreateRequest from './createRequest/CreateRequest';
import RequestsContext from './RequestsContext';

import './requests.css';

const RequestsHeader = () => {
  const { totalCount } = useContext(RequestsContext);

  return (
    <>
      <div styleName="requests-header">
        <div>{`${totalCount} Requests`}</div>
        <CreateRequest />
      </div>
    </>
  );
};

export default RequestsHeader;

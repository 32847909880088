import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import {
  ELEMENT_CREATE,
  ELEMENT_DELETE,
  ELEMENT_UPDATE,
} from '@atom/graph/schema';
import { Icon, IconButton, List, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  ElementCreateInput,
  ElementUpdateInput,
  SchemaTree,
} from '@atom/types/schema';
import { getDataManagementElementIcon } from '@atom/utilities/iconUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AddItemModal from '../AddItemModal';
import EditItemModal from '../EditItemModal';

import ListItemWrapper from './ListItemWrapper';

import './subItemTree.css';

const { ListItemText } = List;

interface Props {
  element: SchemaTree;
  level: number;
  path: any[];
}

const ElementRow = ({ element, level, path }: Props) => {
  const container = useRef<HTMLDivElement>();

  const {
    schemaTree,
    selectedSubItem,
    collapsedSubItems,
    setCollapsedSubItems,
    refetchSchemaTree,
    setSelectedSubItemPath,
    setSelectedSubItem,
    setSelectedAttribute,
    setSelectedAttributeGroup,
    setSelectedAttributeRoute,
  } = useContext(SchemaDetailContext);

  const [hovering, setHovering] = useState<boolean>(false);
  const [openSubItemCreate, setOpenSubItemCreate] = useState<boolean>(false);
  const [openSubItemEdit, setOpenSubItemEdit] = useState<boolean>(false);
  const [openSubItemDelete, setOpenSubItemDelete] = useState<boolean>(false);
  const [overflowing, setOverflowing] = useState<boolean>(false);

  const [createElement] = useMutation<
    { elementCreate: SchemaTree },
    { input: ElementCreateInput }
  >(ELEMENT_CREATE);

  const [updateElement] = useMutation<
    { elementUpdate: SchemaTree },
    { input: ElementUpdateInput }
  >(ELEMENT_UPDATE);

  const [deleteElement] = useMutation<
    { elementDelete: boolean },
    { id: string }
  >(ELEMENT_DELETE);

  const attributeSubtext = useMemo(() => {
    if (isNilOrEmpty(element?.attributeGroups)) {
      return '';
    }

    const flatAttributes = element.attributeGroups.reduce((acc, group) => {
      return [...acc, ...group.attributes];
    }, []);

    const attributeNames = flatAttributes.reduce((acc, attribute) => {
      return attribute.isVisibleAsSubtext ? [...acc, attribute.name] : acc;
    }, []);

    return attributeNames.join(', ');
  }, [element]);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [element.name, attributeSubtext]);

  const handleListItemClick = () => {
    setSelectedSubItemPath(path);
    setSelectedSubItem(element);
    setSelectedAttribute(null);
    setSelectedAttributeGroup(null);
    setSelectedAttributeRoute(null);
  };

  const toggleListItem = event => {
    event?.stopPropagation();
    setCollapsedSubItems(toggleFromSet(collapsedSubItems, element?.id));
  };

  const handleSubItemCreate = async (name: string) => {
    await createElement({
      variables: {
        input: {
          rootSchemaId: schemaTree?.id,
          parentSchemaId: element?.id,
          assetType: name,
          name,
        },
      },
    });

    await refetchSchemaTree();
    setOpenSubItemCreate(false);
  };

  const handleSubItemEdit = async (name: string) => {
    await updateElement({
      variables: {
        input: {
          schemaId: element?.id,
          assetType: name,
          name,
        },
      },
    });

    await refetchSchemaTree();
    setOpenSubItemEdit(false);
  };

  const handleSubItemDelete = async () => {
    await deleteElement({
      variables: {
        id: element.id,
      },
    });

    setSelectedSubItemPath(null);
    setSelectedSubItem(null);
    setOpenSubItemDelete(false);

    await refetchSchemaTree();
  };

  const hasChildren = !isNilOrEmpty(element?.elements);
  const selected = selectedSubItem?.id === element?.id;
  const showActionButtons = (selected || hovering) && !schemaTree?.isPublished;

  const expandedIcon = !collapsedSubItems.has(element?.id)
    ? 'arrow_drop_down'
    : 'arrow_right';
  const textStyles = {
    color: selected ? colors.brand.blue : colors.neutral.dark,
  };

  const icon = getDataManagementElementIcon(element.markerId, selected, {
    paddingRight: '0.5rem',
  });

  const tooltipContent = (
    <div>
      <div>{element.name}</div>
      <div>{attributeSubtext}</div>
    </div>
  );

  const content = (
    <div styleName="sub-item-content">
      <div styleName="sub-item-name" ref={container}>
        {!overflowing ? (
          <div>
            <div style={textStyles}>{element.name}</div>
            <div styleName="sub-item-subtext">{attributeSubtext}</div>
          </div>
        ) : (
          <Tooltip title={tooltipContent} placement="bottom-end">
            <div>
              <div style={textStyles} styleName="sub-item-overflow-container">
                {element.name}
              </div>
              <div styleName="sub-item-overflow-container subtext">
                {attributeSubtext}
              </div>
            </div>
          </Tooltip>
        )}
      </div>
      {showActionButtons && (
        <div styleName="sub-item-action-buttons">
          <IconButton
            tooltip="Add Sub Item"
            onClick={() => setOpenSubItemCreate(true)}
            size="small"
          >
            <img src={addItemIcon} />
          </IconButton>
          <IconButton
            tooltip="Rename"
            onClick={() => setOpenSubItemEdit(true)}
            size="small"
          >
            <Icon>edit</Icon>
          </IconButton>
          <IconButton
            tooltip="Delete"
            onClick={() => setOpenSubItemDelete(true)}
            size="small"
          >
            <Icon>delete</Icon>
          </IconButton>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <ListItemWrapper
          onClick={handleListItemClick}
          selected={selected}
          level={level}
          hasChildren={hasChildren}
        >
          <>
            {hasChildren && (
              <IconButton size="small" onClick={toggleListItem}>
                <Icon>{expandedIcon}</Icon>
              </IconButton>
            )}
            {icon}
            <ListItemText primary={content} />
          </>
        </ListItemWrapper>
      </div>
      <AddItemModal
        open={openSubItemCreate}
        closeModal={() => setOpenSubItemCreate(false)}
        type="Sub Item"
        handleSave={handleSubItemCreate}
      />
      <EditItemModal
        name={element.name}
        type="Sub Item"
        handleSave={handleSubItemEdit}
        open={openSubItemEdit}
        closeModal={() => setOpenSubItemEdit(false)}
      />
      <DeleteModal
        open={openSubItemDelete}
        onConfirm={handleSubItemDelete}
        onCancel={() => setOpenSubItemDelete(false)}
        title="Delete Sub Item?"
        content={`Deleting ${element.name} will also delete all items under it.`}
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  );
};

export default ElementRow;

import React, { useContext } from 'react';
import * as R from 'ramda';

import DataTypeInput, {
  BooleanOrientation,
} from '@atom/components/common/dataTypeInput/DataTypeInput';
import colors from '@atom/styles/colors';
import { SchemaAttribute } from '@atom/types/schema';
import { removeFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import CreateAssetModalContext from '../CreateAssetModalContext';

import './attributesTab.css';

interface Props {
  attribute: SchemaAttribute;
}

const AttributeRow = ({ attribute }: Props) => {
  const {
    attributes,
    setAttributes,
    errorAttributeIds,
    setErrorAttributeIds,
  } = useContext(CreateAssetModalContext);

  const onChange = value => {
    setAttributes({
      ...attributes,
      [attribute.id]: { value },
    });

    if (errorAttributeIds.has(attribute.id)) {
      setErrorAttributeIds(removeFromSet(errorAttributeIds, attribute.id));
    }
  };

  const name = attribute.isRequired ? `* ${attribute.name}` : attribute.name;
  const value = isNilOrEmpty(attribute.defaultValue)
    ? R.pathOr(null, [attribute.id, 'value'], attributes)
    : attribute.defaultValue;
  const isDisabled =
    !attribute.isEditable || !isNilOrEmpty(attribute.defaultValue);
  const error = errorAttributeIds.has(attribute.id);

  const nameStyle = {
    color: error ? colors.brand.red : colors.neutral.gray,
  };

  return (
    <div styleName="attribute-row">
      <div styleName="attribute-name" style={nameStyle}>
        {name}
      </div>
      <div styleName="attribute-input">
        <DataTypeInput
          id={attribute.id}
          dataType={attribute.dataType}
          options={attribute.enumeration}
          thousandsSeparator={attribute.thousandsSeparator}
          onChange={onChange}
          value={value}
          disabled={isDisabled}
          error={error}
          booleanOrientation={BooleanOrientation.HORIZONTAL}
          dataCyPrefix="globalCreateAssetModal"
        />
      </div>
    </div>
  );
};

export default AttributeRow;

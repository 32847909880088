import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateFormTemplate } from '@atom/actions/formTemplateActions';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Icon, Modal, Select, TextField } from '@atom/mui';
import {
  formTemplateContainsAnyModule,
  formTemplateContainsModule,
} from '@atom/selectors/formModuleSelectors';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import FormBuilderContext from './FormBuilderContext';

import './formBuilder.css';

const { MenuItem } = Select;

const styles = {
  input: {
    marginLeft: '1.5rem',
  },
  menu: {
    maxHeight: '30rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const EditFormModal = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const { formTemplate, schemas = [] } = useContext(FormBuilderContext);

  const [schemaId, setSchemaId] = useState<string>(formTemplate.schemaId || '');
  const [name, setName] = useState<string>(formTemplate?.name);

  useEffect(() => {
    setSchemaId(formTemplate.schemaId || '');
    setName(formTemplate.name);
  }, [formTemplate]);

  const resetState = () => {
    setSchemaId(formTemplate.schemaId);
    setName(formTemplate.name);
  };

  const handleClose = () => {
    onClose();
    resetState();
  };

  const confirm = () => {
    dispatch(
      updateFormTemplate({
        ...formTemplate,
        name,
        schemaId,
      }),
    );

    handleClose();
  };

  const formHasLinkedAsset = formTemplateContainsModule('asset', formTemplate);
  const formHasAnyModule = formTemplateContainsAnyModule(formTemplate);

  const isDisabled = formHasAnyModule || formHasLinkedAsset;

  const schemaOptions = [{ id: '', name: '', markerId: '' }, ...schemas];

  return (
    <Modal
      title="Edit Form"
      onConfirm={confirm}
      onCancel={handleClose}
      confirmButtonText="Save"
      open={open}
      disabled={isNilOrEmpty(name)}
      loading={isNilOrEmpty(schemas)}
    >
      <div styleName="edit-modal">
        <div styleName="edit-row">
          <img src={renameIcon} />
          <TextField
            required
            id="formTemplateName"
            label="Name"
            value={name}
            onChange={event => setName(event.target.value)}
            error={isNilOrEmpty(name)}
            helperText={
              isNilOrEmpty(name) ? 'The form title must be provided' : ''
            }
            style={styles.input}
          />
        </div>
        <div styleName="edit-row">
          <Icon>category</Icon>
          <Select
            fullWidth
            label="Inventory Type"
            value={schemaId}
            onChange={event => setSchemaId(event.target.value)}
            disabled={isDisabled}
            style={styles.input}
            MenuProps={{ style: styles.menu }}
          >
            {schemaOptions.map(schema => {
              return (
                <MenuItem key={schema.id} value={schema.id}>
                  <div styleName="schema-option">
                    {schema.markerId !== '' &&
                      schemaUtilities.getSchemaIconFromSchemaOrAsset(schema, {
                        imgOffset: '0',
                        iconSize: '34px',
                        marginTop: '0',
                      })}
                    <span>{schema.name}</span>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default EditFormModal;

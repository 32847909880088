import React, { useContext } from 'react';

import { Replenishment } from '@atom/types/assetRequest';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { getDisplayDateTime } from '@atom/utilities/timeUtilities';

import ViewRequestContext from '../ViewRequestContext';

import './sddotView.css';

interface Props {
  replenishment: Replenishment;
}

const ReplenishmentView = ({ replenishment }: Props) => {
  const { activeRequest } = useContext(ViewRequestContext);

  const createdByText = `Created by ${replenishment?.createdBy?.firstName} ${
    replenishment?.createdBy?.lastName
  } on ${getDisplayDateTime(replenishment?.createdDate)}`;

  return (
    <div styleName="replenishment-form">
      <div styleName="replenishment-title">{replenishment?.name}</div>
      <div styleName="replenishment-row">
        <div styleName="replenishment-cell long">
          <div styleName="row-title">Invoice Number</div>
          <div>{replenishment?.invoiceNumber}</div>
        </div>
        <div styleName="replenishment-cell long">
          <div styleName="row-title">{`Quantity Received (${
            activeRequest?.assetUnit || ''
          })`}</div>
          <div>{replenishment?.quantityReceived}</div>
        </div>
        <div styleName="replenishment-cell">
          <div styleName="row-title">Unit Cost</div>
          <div>{numberToLocaleString(replenishment?.unitCost)}</div>
        </div>
        <div styleName="replenishment-cell">
          <div styleName="row-title">Total Cost</div>
          <div>{numberToLocaleString(replenishment?.totalCost)}</div>
        </div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">Comments</div>
        <div>{replenishment?.comments}</div>
      </div>
      <div styleName="created-subtext">{createdByText}</div>
    </div>
  );
};

export default ReplenishmentView;

import * as React from 'react';
import { format } from 'date-fns';
import * as R from 'ramda';

import { numberToLocaleString } from './currencyUtility';
import { isNilOrEmpty } from './validationUtilities';

import './attributeDisplayUtility.css';

const getAttributeContent = (oldAttributeValue, newAttributeValue) => (
  <div styleName="attribute-change-container">
    <span styleName="attribute-strike-through">
      <span>{oldAttributeValue}</span>
    </span>
    <span
      styleName={`attribute-new-value ${
        !isNilOrEmpty(oldAttributeValue) ? 'attribute-new-value-offset' : ''
      }`}
    >
      {newAttributeValue}
    </span>
  </div>
);

const dateDisplay = (
  oldValue: number,
  hasPendingChanges?: boolean,
  newValue?: number,
) => {
  const oldAttributeValue =
    !oldValue || R.isEmpty(oldValue)
      ? ''
      : Number(oldValue)
      ? format(new Date(oldValue), 'MM/dd/yyyy')
      : '-';

  const newAttributeValue =
    !newValue || R.isEmpty(newValue)
      ? ''
      : Number(newValue)
      ? format(new Date(newValue), 'MM/dd/yyyy')
      : '-';

  if (!hasPendingChanges) {
    return oldAttributeValue;
  }

  return getAttributeContent(oldAttributeValue, newAttributeValue);
};

const currencyDisplay = (
  oldValue: number,
  hasPendingChanges?: boolean,
  newValue?: number,
) => {
  const oldAttributeValue = R.isNil(oldValue)
    ? ''
    : numberToLocaleString(oldValue);

  const newAttributeValue = R.isNil(newValue)
    ? ''
    : numberToLocaleString(newValue);

  if (!hasPendingChanges) {
    return oldAttributeValue;
  }

  return getAttributeContent(oldAttributeValue, newAttributeValue);
};

const numberDisplay = (
  oldValue: number,
  unit?: string,
  hasPendingChanges?: boolean,
  newValue?: number,
  thousandsSeparator?: boolean,
) => {
  const oldAttributeValue = R.isNil(oldValue)
    ? ''
    : unit
    ? `${
        thousandsSeparator ? Number(oldValue).toLocaleString('en-US') : oldValue
      } ${unit}`
    : thousandsSeparator
    ? Number(oldValue).toLocaleString('en-US')
    : oldValue;

  const newAttributeValue = R.isNil(newValue)
    ? ''
    : unit
    ? `${
        thousandsSeparator ? Number(newValue).toLocaleString('en-US') : newValue
      } ${unit}`
    : thousandsSeparator
    ? Number(newValue).toLocaleString('en-US')
    : newValue;

  if (!hasPendingChanges) {
    return oldAttributeValue;
  }

  return getAttributeContent(oldAttributeValue, newAttributeValue);
};

const booleanDisplay = (
  oldValue?: boolean,
  hasPendingChanges?: boolean,
  newValue?: boolean,
) => {
  const oldAttributeValue = R.isNil(oldValue)
    ? ''
    : oldValue
    ? 'TRUE'
    : 'FALSE';
  const newAttributeValue = R.isNil(newValue)
    ? ''
    : newValue
    ? 'TRUE'
    : 'FALSE';

  if (!hasPendingChanges) {
    return oldAttributeValue;
  }

  return getAttributeContent(oldAttributeValue, newAttributeValue);
};

const enummultipleDisplay = (
  oldValue?: string[],
  hasPendingChanges?: boolean,
  newValue?: string[],
) => {
  let oldAttributeValue = '';
  let newAttributeValue = '';

  if (Array.isArray(oldValue)) {
    oldAttributeValue = oldValue ? oldValue.join(', ') : '';
  }

  if (!hasPendingChanges) {
    return oldAttributeValue;
  }

  if (Array.isArray(newValue)) {
    newAttributeValue = newValue ? newValue.join(', ') : '';
  }

  return getAttributeContent(oldAttributeValue, newAttributeValue);
};

const enumSingleDisplay = (
  oldValue?: string,
  hasPendingChanges?: boolean,
  newValue?: string,
) => {
  const oldAttributeValue = !R.isNil(oldValue) ? oldValue : '';
  const newAttributeValue = !R.isNil(newValue) ? newValue : '';

  if (!hasPendingChanges) {
    return oldAttributeValue;
  }

  return getAttributeContent(oldAttributeValue, newAttributeValue);
};

const displayText = (
  oldValue?: string,
  hasPendingChanges?: boolean,
  newValue?: string,
) => {
  const oldAttributeValue = !R.isNil(oldValue) ? oldValue : '';
  const newAttributeValue = !R.isNil(newValue) ? newValue : '';

  if (!hasPendingChanges) {
    return oldAttributeValue;
  }

  return getAttributeContent(oldAttributeValue, newAttributeValue);
};

const displayCost = (value: any) => {
  return (
    <div styleName="cost-container">
      ${value.rate}/<span styleName="cost-unit">{value.unit}</span>
    </div>
  );
};

export function display(
  value: any,
  dataType: string,
  unit?: string,
  hasPendingChanges?: boolean,
  newValue?: any,
  thousandsSeparator?: boolean,
) {
  switch (dataType) {
    case 'date': {
      return dateDisplay(value, hasPendingChanges, newValue);
    }
    case 'enummultiple': {
      return enummultipleDisplay(value, hasPendingChanges, newValue);
    }
    case 'boolean': {
      return booleanDisplay(value, hasPendingChanges, newValue);
    }
    case 'number': {
      return numberDisplay(
        value,
        unit,
        hasPendingChanges,
        newValue,
        thousandsSeparator,
      );
    }
    case 'currency': {
      return currencyDisplay(value, hasPendingChanges, newValue);
    }
    case 'shorttext':
    case 'longtext': {
      return displayText(value, hasPendingChanges, newValue);
    }
    case 'cost': {
      return displayCost(value);
    }
    case 'enumsingle':
    default: {
      return enumSingleDisplay(value, hasPendingChanges, newValue);
    }
  }
}

export function displayElementNameOnChange(name: string, changeType: string) {
  switch (changeType) {
    case 'added': {
      return (
        <span styleName="attribute-new-value">
          <span>{name}</span>
        </span>
      );
    }
    case 'deleted': {
      return <span styleName="attribute-strike-through">{name}</span>;
    }
    default: {
      return <span>{name}</span>;
    }
  }
}

const attributeDisplayUtility = {
  display,
  displayElementNameOnChange,
};

export default attributeDisplayUtility;

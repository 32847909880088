import React from 'react';
import { actions, LocalForm } from 'react-redux-form';
import * as R from 'ramda';

import { Snackbar } from '@atom/mui';
import {
  AttributesType,
  InventoryAssetDetailType,
} from '@atom/types/inventory';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import {
  getErrorAttributeIds,
  getPreviousErrorAttributeIds,
} from './attributeDetailResponsiveUtilities';
import AttributeSectionResponsive from './AttributeSectionResponsive';

const MODEL_NAME = 'instance';

export const mapAttributeValue = (value: any, dataType: string): any => {
  switch (dataType) {
    case 'currency':
    case 'number': {
      return R.isEmpty(value) ? null : Number(value);
    }
    case 'date': {
      return R.isNil(value) ? value : value.valueOf();
    }
    case 'boolean': {
      return value === 'true';
    }
    default:
      return value;
  }
};

interface ReduxStateProps {
  inventoryAssetDetail: InventoryAssetDetailType;
  loading: any;
}

interface PassedProps {
  onAttributeUpdate: (any) => void;
  onPendingApproval?: (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  canManageChanges: boolean;
  canUpdate: boolean;
  setUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
  // Skips triggering manual attribute validation and instead relies on passed
  // in errorAttributes and resolveInvalidAssetsError props
  skipManualAttributeValidation?: boolean;
  // errorAttributes can be passed if error state is handle outside this component
  errorAttributes?: { [attributeGroupId: string]: string[] };
  resolveInvalidAssetsError?: (
    assetId: string,
    attributeGroupId: string,
  ) => void;
}

type Props = ReduxStateProps & PassedProps;

type State = {
  inventoryAssetDetail: InventoryAssetDetailType;
  attributeGroups: any[];
  dispatch?: any;
  form?: any;
  errorAttributes?: { [attributeGroupId: string]: string[] };
};

class AttributeDetailResponsive extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inventoryAssetDetail: props.inventoryAssetDetail,
      attributeGroups:
        props.inventoryAssetDetail?.attributeGroups?.map((group: any): any => ({
          name: group.name,
          isEdit: false,
          collapsed: !group.expandByDefault,
        })) || [],
      form: {},
      errorAttributes: props.errorAttributes || {},
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { attributeGroups } = this.state;

    const newAttributeGroups = attributeGroups.map((group: any): any => {
      if (
        this.props.loading.includes(group.name) &&
        !nextProps.loading.includes(group.name)
      ) {
        return {
          name: group.name,
          isEdit: !group.isEdit,
          collapsed: group.collapsed,
        };
      }

      return R.omit(['isDisabled'], group);
    });

    if (this.props.loading.length && !nextProps.loading.length) {
      if (this.props.setUnsavedChanges) {
        this.props.setUnsavedChanges(false);
      }
      this.setState({ inventoryAssetDetail: nextProps.inventoryAssetDetail });
    }

    this.setState({
      inventoryAssetDetail: nextProps.inventoryAssetDetail,
      attributeGroups: newAttributeGroups,
      errorAttributes: nextProps.errorAttributes,
    });
  }

  attachDispatch = (dispatch: any) => {
    this.setState({ dispatch });
  };

  updateAttributeGroupEditState = (
    attributeGroupName: string,
    isEdit: boolean,
  ): any => {
    const { attributeGroups } = this.state;

    const updatedAttributes = attributeGroups.map(
      (attributeGroup: any): any => {
        if (attributeGroup.name !== attributeGroupName) {
          return { ...attributeGroup, isDisabled: isEdit };
        }

        return {
          ...attributeGroup,
          isEdit,
        };
      },
    );

    this.setState({ attributeGroups: updatedAttributes });
  };

  updateAttributeGroupCollapsedState = (attributeGroupName: string): any => {
    const { attributeGroups } = this.state;

    const updatedAttributes = attributeGroups.map(
      (attributeGroup: any): any => {
        if (attributeGroup.name !== attributeGroupName) {
          return attributeGroup;
        }

        return {
          ...attributeGroup,
          collapsed: !attributeGroup.collapsed,
        };
      },
    );

    this.setState({ attributeGroups: updatedAttributes });
  };

  revertAttributeChanges = (attributeGroupName: string) => {
    const { inventoryAssetDetail, form, dispatch } = this.state;

    const attributeIdsToRemove = inventoryAssetDetail.attributeGroups
      .find(
        (attributeGroup: any): any =>
          attributeGroup.name === attributeGroupName,
      )
      .attributes.map((attribute: any): string => attribute.id);

    // Removes the attributes off of the local form state
    const updatedForm = R.omit(attributeIdsToRemove, form);

    /* 
      Removes the attribute off of the <LocalForm /> instance
      
      Further reading on LocalForm, dispatch and action creators:
      https://davidkpiano.github.io/react-redux-form/docs/guides/local.html
    */
    attributeIdsToRemove.forEach((attribute: string): any =>
      dispatch(actions.omit(MODEL_NAME, attribute)),
    );

    this.setState((): any => ({
      form: updatedForm,
    }));

    if (this.props.setUnsavedChanges) {
      // Use timeout of 0ms to run this on next render loop
      // - prevents sticky collapsed boolean
      setTimeout(() => this.props.setUnsavedChanges(false), 0);
    }
  };

  onChange = (form: any) => {
    const { inventoryAssetDetail, errorAttributes } = this.state;

    if (!isNilOrEmpty(errorAttributes)) {
      const currentAttributeGroup = inventoryAssetDetail.attributeGroups.find(
        (attributeGroup: any): any =>
          R.any(R.propEq('id', R.keys(form)[0]))(attributeGroup.attributes),
      );

      if (currentAttributeGroup) {
        const errorIds = getErrorAttributeIds(form, currentAttributeGroup);
        this.setState({
          errorAttributes: {
            ...errorAttributes,
            [currentAttributeGroup?.id]: errorIds,
          },
        });
      }
    }

    this.setState((): any => ({
      form,
    }));
  };

  onCollapse = (attributeGroupName: string) => {
    this.updateAttributeGroupCollapsedState(attributeGroupName);
  };

  onEditClick = (attributeGroupName: string) => {
    const isEdit = true;
    this.updateAttributeGroupEditState(attributeGroupName, isEdit);
  };

  onCancel = (attributeGroupName: string) => {
    const { inventoryAssetDetail, errorAttributes } = this.state;
    const { skipManualAttributeValidation } = this.props;
    const isEdit = false;

    if (!skipManualAttributeValidation) {
      const currentAttributeGroup = inventoryAssetDetail.attributeGroups.find(
        (attributeGroup: any): any =>
          attributeGroup.name === attributeGroupName,
      );

      const errorIds = getPreviousErrorAttributeIds(currentAttributeGroup);

      this.setState({
        errorAttributes: {
          ...errorAttributes,
          [currentAttributeGroup?.id]: errorIds,
        },
      });
    }

    this.revertAttributeChanges(attributeGroupName);
    this.updateAttributeGroupEditState(attributeGroupName, isEdit);
  };

  removeSavedAttributes = (attributeIdsToRemove: string[]) => {
    const { form, dispatch } = this.state;
    const updatedForm = R.omit(attributeIdsToRemove, form);

    attributeIdsToRemove.forEach((attribute: string): any =>
      dispatch(actions.omit(MODEL_NAME, attribute)),
    );

    this.setState((): any => ({
      form: updatedForm,
    }));
  };

  onSubmit = (attributeGroupIdsToSave: any[], attributeGroupName: string) => {
    const { form, inventoryAssetDetail } = this.state;
    const { onAttributeUpdate } = this.props;
    const { id, attributes } = inventoryAssetDetail;

    const updatedAttributes = attributeGroupIdsToSave.reduce(
      (previous: any, next: any): any => {
        return {
          ...previous,
          [next]: {
            ...attributes[next],
            value: mapAttributeValue(form[next], attributes[next].dataType),
          },
        };
      },
      {},
    );

    onAttributeUpdate({
      id,
      attributeGroupName,
      attributes: updatedAttributes,
    });
  };

  onSave = (attributeGroupName: string) => {
    const { form, inventoryAssetDetail, errorAttributes } = this.state;
    const {
      resolveInvalidAssetsError,
      skipManualAttributeValidation,
    } = this.props;

    const currentAttributeGroup = inventoryAssetDetail.attributeGroups.find(
      (attributeGroup: any): any => attributeGroup.name === attributeGroupName,
    );

    const attributeGroupIds = currentAttributeGroup.attributes.map(
      (attribute: any): string => attribute.id,
    );

    if (skipManualAttributeValidation) {
      resolveInvalidAssetsError(
        inventoryAssetDetail.id,
        currentAttributeGroup.id,
      );
    }

    if (!skipManualAttributeValidation) {
      const errorIds = getErrorAttributeIds(form, currentAttributeGroup);

      if (errorIds.length > 0) {
        this.setState({
          ...errorAttributes,
          errorAttributes: { [currentAttributeGroup.id]: errorIds },
        });

        Snackbar.error({
          message: 'Please fill out the required attributes.',
        });

        return;
      }
    }

    const attributeGroupIdsToSave = Object.keys(form).filter(
      (updatedId: string): any =>
        attributeGroupIds.filter(
          (groupId: string): boolean => groupId === updatedId,
        ).length,
    );

    this.onSubmit(attributeGroupIdsToSave, attributeGroupName);
    this.removeSavedAttributes(attributeGroupIdsToSave);
  };

  render() {
    const {
      inventoryAssetDetail,
      attributeGroups,
      errorAttributes,
    } = this.state;
    const {
      loading,
      onPendingApproval,
      canManageChanges,
      canUpdate,
    } = this.props;

    return (
      <LocalForm
        model={MODEL_NAME}
        initialState={{}}
        getDispatch={(dispatch: any): void => this.attachDispatch(dispatch)}
        onChange={this.onChange}
      >
        {inventoryAssetDetail.attributeGroups.map(
          (attributeGroup: any, key: any) => {
            const isEdit = attributeGroups[key]
              ? attributeGroups[key].isEdit
              : false;

            const collapsed = attributeGroups[key]
              ? attributeGroups[key].collapsed
              : false;

            const isDisabled = attributeGroups[key]
              ? attributeGroups[key].isDisabled
              : false;

            return (
              <AttributeSectionResponsive
                key={key}
                onPendingApproval={onPendingApproval}
                attributeGroup={attributeGroup}
                onClick={this.onEditClick}
                onCollapse={this.onCollapse}
                onCancel={this.onCancel}
                onSave={this.onSave}
                collapsed={collapsed}
                isEdit={isEdit}
                loading={loading.includes(attributeGroup.name)}
                canManageChanges={canManageChanges}
                canUpdate={canUpdate}
                isDisabled={isDisabled}
                errorAttributes={errorAttributes}
              />
            );
          },
        )}
      </LocalForm>
    );
  }
}

export default AttributeDetailResponsive;

import * as React from 'react';

import { Button, Icon, IconButton, Progress } from '@atom/mui';

import './attributeDetailResponsive.css';

interface Props {
  loading: boolean;
  name: string;
  onClick: (string) => void;
  onCollapse: (string) => void;
  collapsed: boolean;
  onCancel: (string) => void;
  onSave: (string) => void;
  isEdit?: boolean;
  hasPendingChanges?: boolean;
  canUpdate?: boolean;
  isDisabled?: boolean;
  typePreview?: boolean;
  hasAttributeError?: boolean;
}

const AttributeGroupHeaderResponsive = ({
  name,
  onClick,
  onCollapse,
  collapsed,
  isEdit,
  onCancel,
  onSave,
  loading,
  hasPendingChanges,
  canUpdate = false,
  isDisabled = false,
  typePreview = false,
  hasAttributeError = false,
}: Props) => {
  const buildRightNavContainer = () => {
    if (isEdit) {
      return (
        <span styleName="attribute-group-header-container">
          <Button onClick={(): void => onCancel(name)} disabled={loading}>
            CANCEL
          </Button>
          {!typePreview && (
            <Button
              color="primary"
              disabled={loading || hasAttributeError}
              onClick={(): void => onSave(name)}
            >
              SAVE
            </Button>
          )}
          {loading && (
            <div styleName="spinner-container">
              <Progress size={25} thickness={2} />
            </div>
          )}
        </span>
      );
    }

    if (canUpdate) {
      return (
        <div>
          <IconButton disabled={isDisabled} onClick={(): void => onClick(name)}>
            <Icon color={isDisabled ? '#ccc' : 'inherit'}>edit</Icon>
          </IconButton>
          <IconButton onClick={(): void => onCollapse(name)}>
            <Icon>
              {collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
            </Icon>
          </IconButton>
        </div>
      );
    }

    return (
      <div>
        <IconButton onClick={(): void => onCollapse(name)}>
          <Icon>{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
        </IconButton>
      </div>
    );
  };

  const nameStyle = hasAttributeError
    ? 'asset-group-header-label error'
    : 'asset-group-header-label';

  return (
    <div styleName="asset-group-header-container">
      <div styleName="change-indicator-container">
        <div styleName={nameStyle}>{name}</div>
        {hasPendingChanges && <div styleName="change-indicator" />}
      </div>
      <div styleName="asset-group-header-edit-icon">
        {buildRightNavContainer()}
      </div>
    </div>
  );
};

export default AttributeGroupHeaderResponsive;

import React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import { List } from '@atom/mui';
import colors from '@atom/styles/colors';

import './subItemTree.css';

const { ListItemButton } = List;

const listItemButtonStyles: SxProps<Theme> = {
  '&.Mui-selected': {
    backgroundColor: colors.neutral.typhoon,
    borderLeftColor: colors.brand.blue,
    borderLeftStyle: 'solid',
    borderLeftWidth: '4px',
  },
  '&.Mui-selected:hover': {
    backgroundColor: colors.neutral.typhoon,
  },
  '&.MuiListItemButton-root:hover': {
    backgroundColor: colors.neutral.typhoon,
  },
};

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  selected?: boolean;
  level?: number;
  hasChildren?: boolean;
  attributeVersion?: boolean;
  attributeGroupVersion?: boolean;
}

const ListItemWrapper = ({
  onClick = () => {},
  selected = false,
  children,
  level = 0,
  hasChildren = false,
  attributeVersion = false,
  attributeGroupVersion = false,
}: Props) => {
  const getPaddingLeft = () => {
    // rem values as numbers for calculation
    const baseOffset = 1;
    const selectedOffset = 0.75;
    const levelOffset = 2;

    if (attributeVersion || attributeGroupVersion) {
      const totalRem = selected ? selectedOffset : baseOffset;
      return `${totalRem}rem`;
    }

    const totalRem =
      levelOffset * level +
      (selected ? selectedOffset : baseOffset) +
      (hasChildren ? 0 : levelOffset);
    return `${totalRem}rem`;
  };

  const listItemStyles = {
    height: '3.125rem',
    paddingTop: '0',
    paddingRight: '1rem',
    paddingBottom: '0',
    paddingLeft: getPaddingLeft(),
    ...(attributeGroupVersion && {
      borderBottom: `1px solid ${colors.neutral.ash}`,
      borderTop: `1px solid ${colors.neutral.ash}`,
    }),
  };

  return (
    <ListItemButton
      disableGutters
      onClick={onClick}
      sx={listItemButtonStyles}
      selected={selected}
      style={listItemStyles}
    >
      {children}
    </ListItemButton>
  );
};

export default ListItemWrapper;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { usePreferences } from '@atom/hooks/usePreferences';
// @ts-ignore
import collapseAll from '@atom/images/collapseAll.svg';
// @ts-ignore
import expandAll from '@atom/images/expandAll.svg';
import { Checkbox, Icon, IconButton, ListTable } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from '../BudgetDetailContext';
import {
  BUDGET_COL_WIDTH,
  CATEGORY_EXPAND_LIMIT,
  COMMENT_COL_WIDTH,
  COST_TYPE_COL_WIDTH,
  DEBOUNCE_CHECKBOX,
  ICON_COL_WIDTH,
} from '../budgetDetailUtils';

import BudgetDetailCategoryRow from './BudgetDetailCategoryRow';
import BudgetDetailItemTable from './BudgetDetailItemTable';

import '../budgetDetail.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const styles = {
  collapse: { width: '100%' },
  expandableTableCell: {
    padding: 0,
    border: 'none',
  },
  categoryCell: {
    borderBottom: `1px solid ${colors.neutral.gray}`,
  },
  controlsContainer: {
    margin: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  controlsDivider: {
    borderRight: `1px solid ${colors.neutral.gray}`,
  },
  allControl: {
    width: '6.5rem',
    cursor: 'pointer',
  },
  itemCount: {
    color: colors.neutral.gray,
    paddingLeft: '0.25rem',
    fontSize: fonts.lg,
    fontWeight: '500',
  },
  headingText: {
    fontSize: fonts.lg,
    fontWeight: '500',
  },
  fixedHeadingLabel: {
    paddingRight: '0.5rem',
  },
  fixedHeadingTooltip: { margin: 0, padding: 0, cursor: 'auto', width: '1rem' },
};

interface Props {
  getBudgetCategories: (input: any) => void;
}

const BudgetDetailCategories = ({ getBudgetCategories }: Props) => {
  const {
    budgetCategories,
    expandedCategories,
    setExpandedCategories,
    excludeZeroBudgetItems,
    setExcludeZeroBudgetItems,
    showTracking,
  } = useContext(BudgetDetailContext);

  const preferences = usePreferences();
  const hideCostType = R.pathOr(
    false,
    ['budgeting', 'hideCostType'],
    preferences,
  );

  const [showCollapse, setShowCollapse] = useState<boolean>(
    !(budgetCategories?.length < CATEGORY_EXPAND_LIMIT),
  );

  const allCategoryIds = useMemo(
    () => budgetCategories?.map(category => category.id) || [],
    [budgetCategories],
  );

  const itemCount = useMemo(() => {
    return budgetCategories.reduce((total, category) => {
      return total + category?.budgetItems?.length || 0;
    }, 0);
  }, [budgetCategories]);

  useEffect(() => {
    if (
      expandedCategories.size === 0 &&
      budgetCategories?.length < CATEGORY_EXPAND_LIMIT
    ) {
      setShowCollapse(false);
    } else {
      setShowCollapse(true);
    }
  }, [expandedCategories, budgetCategories]);

  const toggleRowOpen = (categoryId: string) => {
    setExpandedCategories(toggleFromSet(expandedCategories, categoryId));
  };

  const handleExpandAll = () => {
    if (budgetCategories?.length < CATEGORY_EXPAND_LIMIT) {
      setExpandedCategories(new Set(allCategoryIds));
    }
  };

  const handleCollapseAll = () => {
    setExpandedCategories(new Set([]));
  };

  const handleToggleHideZeroItems = debounce(() => {
    setExcludeZeroBudgetItems(prev => !prev);
  }, DEBOUNCE_CHECKBOX);

  return (
    <>
      <div>
        <span>
          <span style={styles.headingText}>Budget Items</span>
          <span style={styles.itemCount}>{itemCount || 0}</span>
        </span>
      </div>
      <div style={styles.controlsContainer}>
        {showCollapse ? (
          <span style={styles.allControl} onClick={() => handleCollapseAll()}>
            <img src={collapseAll} /> Collapse All
          </span>
        ) : (
          <span style={styles.allControl} onClick={() => handleExpandAll()}>
            <img src={expandAll} />
            Expand All
          </span>
        )}
        <span style={styles.controlsDivider}>&nbsp;</span>
        <Checkbox
          onChange={handleToggleHideZeroItems}
          checked={excludeZeroBudgetItems}
          label="Hide budget items without a budget and actual cost"
        />
      </div>
      {!isNilOrEmpty(budgetCategories) && (
        <ListTable fullHeight={false}>
          <TableHead>
            <TableRow>
              <TableCell variant="head">NAME</TableCell>
              {!hideCostType && (
                <TableCell width={COST_TYPE_COL_WIDTH} variant="head">
                  COST TYPE
                </TableCell>
              )}
              <TableCell width={BUDGET_COL_WIDTH} align="right" variant="head">
                BUDGET
              </TableCell>
              {showTracking && (
                <>
                  <TableCell
                    width={BUDGET_COL_WIDTH}
                    align="right"
                    variant="head"
                  >
                    ACTUAL
                  </TableCell>
                  <TableCell
                    width={BUDGET_COL_WIDTH}
                    align="right"
                    variant="head"
                  >
                    <span style={styles.fixedHeadingLabel}>FUTURE FIXED</span>
                    <IconButton
                      style={styles.fixedHeadingTooltip}
                      tooltip="Future fixed cost only applies to budget items that are fixed costs."
                      TooltipProps={{ placement: 'right' }}
                      disableFocusRipple
                    >
                      <Icon style={{ fontSize: '1rem' }}>help_outline</Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell
                    width={BUDGET_COL_WIDTH}
                    align="right"
                    variant="head"
                  >
                    REMAINING
                  </TableCell>
                </>
              )}
              <TableCell width={COMMENT_COL_WIDTH} variant="head">
                COMMENT
              </TableCell>
              <TableCell width={ICON_COL_WIDTH} variant="head" />
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetCategories.map(budgetCategory => (
              <React.Fragment key={budgetCategory.id}>
                <BudgetDetailCategoryRow
                  toggleRowOpen={toggleRowOpen}
                  budgetCategory={budgetCategory}
                  key={budgetCategory.id}
                />
                <TableRow hover={false}>
                  <TableCell
                    style={styles.expandableTableCell}
                    colSpan={hideCostType ? 7 : 8}
                  >
                    {expandedCategories.has(budgetCategory.id) && (
                      <BudgetDetailItemTable
                        budgetCategory={budgetCategory}
                        getBudgetCategories={getBudgetCategories}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </ListTable>
      )}
    </>
  );
};

export default BudgetDetailCategories;

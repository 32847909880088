import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  GET_BUDGET_CATEGORIES,
  GET_BUDGET_CHARTS,
  GET_BUDGET_UNITS,
} from '@atom/graph/budget';
import { Progress } from '@atom/mui';
import {
  BudgetCategoriesConnection,
  BudgetCategoriesConnectionInput,
  BudgetChart,
  BudgetChartsConnection,
  BudgetChartsConnectionInput,
  BudgetUnitsConnection,
  BudgetUnitsConnectionInput,
} from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailCategories from './categoryTable/BudgetDetailCategories';
import ExpendituresChart from './expendituresChart/ExpendituresChart';
import BudgetDetailChildUnits from './unitsTable/BudgetDetailChildUnits';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailOverview from './BudgetDetailOverview';

import './budgetDetail.css';

const styles = {
  container: {
    marginTop: '90px',
    marginBottom: '10rem',
  },
  floatingSpinner: {
    position: 'fixed',
    width: '100%',
    top: '65%',
    zIndex: 10,
  },
};

const BudgetDetailUnit = () => {
  const {
    budget,
    parentBudgetUnit,
    childBudgetUnits,
    setChildBudgetUnits,
    budgetCategories,
    setBudgetCategories,
    categoryIds,
    comparisonBudgets,
    budgetTypes,
    budgetItemTemplateNames,
    excludeZeroBudgetItems,
    showChart,
    showTracking,
    showExpenditures,
  } = useContext(BudgetDetailContext);

  // Budget Chart API Calls
  const [budgetsRaw, setBudgetsRaw] = useState<BudgetChart[]>(null);
  const [fetchCharts, { loading: loadingChartData }] = useLazyQuery<
    { budgetCharts: BudgetChartsConnection },
    { input: BudgetChartsConnectionInput }
  >(GET_BUDGET_CHARTS, {
    fetchPolicy: 'cache-first',
    onCompleted: dataResponse => {
      const budgetData: BudgetChart[] = R.pathOr(
        [],
        ['budgetCharts', 'budgetCharts'],
        dataResponse,
      );
      setBudgetsRaw(budgetData);
    },
    onError: () => setBudgetsRaw(null),
  });

  useEffect(() => {
    if (showExpenditures && budget && parentBudgetUnit && showChart) {
      fetchCharts({
        variables: {
          input: {
            budgetIds: [budget?.id, ...comparisonBudgets.map(({ id }) => id)],
            budgetUnitId: parentBudgetUnit?.id,
            categoryIds,
            itemNames: budgetItemTemplateNames,
            budgetTypes,
          },
        },
      });
    }
  }, [parentBudgetUnit, comparisonBudgets, showChart]);

  const [getBudgetUnits, { loading: loadingBudgetUnits }] = useLazyQuery<
    { budgetUnits: BudgetUnitsConnection },
    { input: BudgetUnitsConnectionInput }
  >(GET_BUDGET_UNITS, {
    fetchPolicy: 'no-cache',
    onCompleted: response =>
      setChildBudgetUnits(response?.budgetUnits.budgetUnits),
  });

  const [
    getBudgetCategories,
    { loading: loadingBudgetCategories },
  ] = useLazyQuery<
    { budgetCategories: BudgetCategoriesConnection },
    { input: BudgetCategoriesConnectionInput }
  >(GET_BUDGET_CATEGORIES, {
    fetchPolicy: 'no-cache',
    onCompleted: response => {
      const categoriesData = response?.budgetCategories?.budgetCategories;
      setBudgetCategories(categoriesData);
    },
  });

  // Get display categories or units
  // - update with parentBudgetUnit and filter changes
  const callUpdateChildren = () => {
    if (!isNilOrEmpty(parentBudgetUnit)) {
      if (parentBudgetUnit.hasBudgetItems === false) {
        setBudgetCategories(null);
        setChildBudgetUnits(null);
        getBudgetUnits({
          variables: {
            input: {
              budgetId: budget.id,
              parentBudgetUnitId: parentBudgetUnit.id,
              categoryIds,
              budgetItemTemplateNames,
              budgetTypes,
            },
          },
        });
      } else {
        setChildBudgetUnits(null);
        getBudgetCategories({
          variables: {
            input: {
              budgetId: budget.id,
              budgetUnitId: parentBudgetUnit.id,
              categoryIds,
              budgetItemTemplateNames,
              excludeZeroBudgetItems,
              budgetTypes,
            },
          },
        });
      }
    }
  };
  useEffect(callUpdateChildren, [parentBudgetUnit, excludeZeroBudgetItems]);

  const budgetCategoriesView = !R.isNil(budgetCategories) ? (
    <BudgetDetailCategories getBudgetCategories={getBudgetCategories} />
  ) : (
    <></>
  );

  const childTableView = R.isNil(childBudgetUnits) ? (
    budgetCategoriesView
  ) : (
    <BudgetDetailChildUnits />
  );

  const showFloatingSpinner =
    isNilOrEmpty(parentBudgetUnit) || loadingBudgetCategories;

  return (
    <div style={styles.container}>
      <BudgetDetailOverview />
      {showChart && showTracking && (
        <ExpendituresChart budgetsRaw={budgetsRaw} loading={loadingChartData} />
      )}
      {showFloatingSpinner && <Progress style={styles.floatingSpinner} />}
      {loadingBudgetUnits && <Progress size={75} />}
      {childTableView}
    </div>
  );
};

export default BudgetDetailUnit;

import React from 'react';
import * as R from 'ramda';

import { NumericFormat } from '@atom/mui';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

interface Props {
  id: string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean;
  error: boolean;
  dataCyPrefix: string;
  thousandsSeparator: boolean;
}

const NumberInput = ({
  id,
  value,
  onChange,
  disabled,
  error,
  dataCyPrefix,
  thousandsSeparator,
}: Props) => {
  const handleChange = (number: number | string) => {
    onChange(
      !isNilOrEmpty(number)
        ? typeof number === 'number'
          ? number
          : parseFloat(number)
        : '',
    );
  };

  const data = !R.isNil(value) ? value : '';

  return (
    <NumericFormat
      thousandSeparator={thousandsSeparator}
      key={id}
      value={data}
      onValueChange={values => {
        if (values.floatValue !== value) {
          handleChange(values.floatValue);
        }
      }}
      disabled={disabled}
      error={error}
      data-cy={`${dataCyPrefix}NumberInput`}
    />
  );
};

export default NumberInput;

import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import { TYPE_LABELS } from '@atom/components/common/requests/assetRequestConstants';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import ViewRequestContext from '../ViewRequestContext';

import AddReplenishmentView from './AddReplenishmentView';
import ReplenishmentView from './ReplenishmentView';

import './sddotView.css';

const SDDOTReplenishmentView = () => {
  const { activeRequest } = useContext(ViewRequestContext);

  const [createView, setCreateView] = useState<boolean>(false);

  const totalQuantityReceived = activeRequest?.replenishments?.reduce(
    (acc, replenishment) => {
      return acc + Number(replenishment.quantityReceived || 0);
    },
    0,
  );

  const totalQuantityRemaining =
    R.pathOr(0, ['quantityOrdered'], activeRequest) - totalQuantityReceived;

  const showCreateButton = hasRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <div>
      <div styleName="view-row">
        <div styleName="row-title">Request Type</div>
        <div>{TYPE_LABELS[activeRequest?.type]}</div>
      </div>
      <div styleName="replenishment-row">
        <div>
          <div styleName="row-title">{`Total Quantity Ordered (${
            activeRequest?.assetUnit || ''
          })`}</div>
          <div>{activeRequest?.quantityOrdered}</div>
        </div>
        <div>
          <div styleName="row-title">{`Total Quantity Received (${
            activeRequest?.assetUnit || ''
          })`}</div>
          <div>{totalQuantityReceived}</div>
        </div>
        <div>
          <div styleName="row-title">{`Total Remaining Quantity on Order (${
            activeRequest?.assetUnit || ''
          })`}</div>
          <div>{totalQuantityRemaining}</div>
        </div>
      </div>
      <div styleName="view-row">
        {activeRequest?.replenishments.map(replenishment => (
          <ReplenishmentView
            key={replenishment.id}
            replenishment={replenishment}
          />
        ))}
      </div>
      {createView ? (
        <AddReplenishmentView onCancel={() => setCreateView(false)} />
      ) : showCreateButton ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Icon color={colors.neutral.white}>add</Icon>}
          onClick={() => setCreateView(true)}
        >
          Create Replenishment
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
};

export default SDDOTReplenishmentView;

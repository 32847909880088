import { Location } from '@atom/hooks/useCurrentLocation';

import { GeoJSONPoint } from './geojson';
import { PolicyAction, PolicyGrant } from './policy';
import { SchemaBudget, SchemaBudgetType } from './schema';
import { UpdatedByUserType, UserCollectionItem } from './user';

export enum AssetDetailView {
  ASSET_INFO = 'assetInfo',
  SUB_ITEMS = 'subItems',
  WORK = 'work',
  FILES = 'files',
  PHOTOS = 'photos',
  COST = 'cost',
  ANALYTICS = 'analytics',
  REQUESTS = 'requests',
}

export interface InventoryFilterCategoryNodeType {
  id: string;
  name: string;
  hasCategories: boolean;
  expanded?: boolean;
  subCategories?: InventoryFilterCategoryNodeType[];
}

export interface InventoryCategoryTree {
  id: string;
  name: string;
  schemaId?: string;
  colorId?: string;
  hasAssets?: boolean;
  hasAssetChanges?: string;
  assetType?: string;
  path?: string;
  categories?: InventoryCategoryTree[];
}

export interface ElementsItem {
  id: string;
  name: string;
  hasChildren?: boolean;
}

export interface AttributeGroupsItem {
  id?: string;
  name: string;
  attributes: string[];
}

export interface ElementGroupsItem {
  name: string;
  isGroup: boolean;
  elements: ElementsItem[];
}

export interface AttributesType {
  id: string;
  name: string;
  dataType: string;
  isFilterable?: boolean;
  isVisibleAsSubtext?: boolean;
  defaultValue?: string;
  enumeration: string[];
  createdBy?: string;
  isEditable: boolean;
  isRequired?: boolean;
  createdDate?: number;
  unit: string;
  value: any;
  changeId?: string;
  newValue?: any;
  thousandsSeparator?: boolean;
  // these properties are used by the FE to track pending attributes
  isPending?: boolean;
  isPendingUpdate?: boolean;
  publishedAttribute?: AttributesType;
  attributeGroupId?: string;
}

export interface HydratedAttributeGroupsItem {
  id: string;
  name: string;
  attributes: AttributesType[];
}

export interface DetailAttributeGroupsItem {
  id: string;
  name: string;
  attributes: AttributesType[];
}

export interface AttributesCollection {
  [key: string]: AttributesType;
}

export interface LastWorkOrderType {
  id?: string;
  name?: string;
  updatedDate?: number;
}

export interface AssetBudget {
  isFixed?: boolean;
  isTimeBased?: boolean;
  isStockBased?: boolean;
  quantityOnHand?: number;
  type?: SchemaBudgetType;
  rate?: number;
  unit?: string;
  isStartEndReading?: boolean;
  isStartEndCalculated?: boolean;
}

export interface HydratedInventorySchemaItem {
  id: string;
  name?: string;
  assetType?: string;
  attributes?: AttributesCollection;
  attributeGroups: HydratedAttributeGroupsItem[];
  elementGroups?: ElementGroupsItem[];
  assetCount?: number;
  elementCount?: number;
  createdBy?: string;
  isMaterial: boolean;
  budget: SchemaBudget;
  createdDate?: number;
  updatedBy?: UpdatedByUserType;
  updatedDate?: number;
  parentSchemaId?: string;
  colorId?: number;
  markerId?: number;
  locationType: string;
}

export interface InventorySchemaItem {
  id: string;
  name: string;
  assetType: string;
  attributes: AttributesCollection;
  attributeGroups: AttributeGroupsItem[];
  elementGroups: ElementGroupsItem[];
  assetCount: number;
  elementCount: number;
  createdBy: UserCollectionItem;
  createdDate: number;
  isMaterial: boolean;
  budget: SchemaBudget;
  updatedBy: UserCollectionItem;
  updatedDate: number;
  parentSchemaId: string;
  colorId: number;
  markerId: number;
  isPublished: boolean;
  locationType: string;
  ciEnabled: boolean;
  pciEnabled: boolean;
}

export interface InventorySchemaDetail {
  id: string;
  name: string;
  assetType: string;
  attributeGroups: DetailAttributeGroupsItem[];
  elements: InventorySchemaDetail[];
  elementGroups: DetailElementGroupsItem[];
  isPublished: boolean;
  createdBy: UserCollectionItem;
  isMaterial: boolean;
  budget: SchemaBudget;
  createdDate: number;
  updatedBy: UserCollectionItem;
  updatedDate: number;
  colorId: number;
  markerId: number;
  expanded?: boolean;
  locationType: string;
}

export interface DetailElementGroupsItem {
  id: string;
  name: string;
  isGroup?: boolean;
  expanded?: boolean;
  elements: InventorySchemaDetail[];
}

export interface HydratedElementGroupsItem {
  name: string;
  isGroup: boolean;
  elements: InventorySchemaDetail[];
}

export interface InventoryAssetDetailType {
  id?: string;
  name?: string;
  externalId?: string;
  parentCategoryId?: string;
  categoryId?: string;
  parentAssetId?: string;
  createdDate?: number;
  updatedDate?: number;
  changeType?: string;
  attributes?: AttributesCollection;
  schemaId?: string;
  assetType?: string;
  markerId?: number;
  colorId?: number;
  elementDetail?: any;
  isMaterial?: boolean;
  budget?: AssetBudget;
  location?: GeoJSONPoint;
  attributeGroups?: AttributeGroupsItem[];
  elementGroups?: ElementGroupsItem[];
  lastWorkOrder?: LastWorkOrderType;
  address?: string;
  cursor?: {
    total: number;
    index: number;
    next: string;
    previous: string;
  };
  mainPhotoFileId?: string;
  grants?: PolicyGrant[];
  actions?: PolicyAction[];
  inactive?: boolean;
  includeChangesBefore?: number;
}

export interface InventorySearchItem {
  ancestors?: string[];
  assetType?: string;
  externalId?: string;
  id?: string;
  markerId?: number;
  name?: string;
  schemaId?: string;
  updatedDate?: number;
  inactive?: boolean;
}

export interface ColumnItem {
  name?: string;
  fieldName?: string;
  dateType?: string;
}

export interface AssetsType {
  parentCategoryId?: string;
  total?: number;
}

export interface InventoryPortalPathType {
  id?: string;
  path?: string;
}

export interface InventoryCategoryTreeType {
  id?: string;
  name?: string;
  assetType?: string;
  expanded?: boolean;
  children?: InventoryCategoryTreeType[];
  hasChildren?: boolean;
}

export interface CategoriesType {
  inventoryCategory?: string;
  inventoryCategoryTree?: InventoryCategoryTreeType;
  inventoryPortalPath?: InventoryPortalPathType;
}

export interface InventoryDataItem {
  id?: string;
  externalId?: string;
  assetType?: string;
  updatedDate?: number;
  [key: string]: string | number;
}

export interface InventoryType {
  categories: CategoriesType;
  assets: AssetsType;
  data: InventoryDataItem[];
  columns: ColumnItem[];
  filters: Object;
  sort: Object;
}

export interface ElementsType {
  id: string;
  name: string;
  markerId?: string;
  elements?: ElementsType[];
}

export interface ElementGroupsType {
  name: string;
  isGroup: boolean;
  elements?: ElementsType[];
}

export interface InventorySchemaTreeType {
  id?: string;
  name?: string;
  expanded?: boolean;
  isMaterial?: boolean;
  budget?: SchemaBudget;
  elementGroups?: ElementGroupsType[];
}

export interface InventoryDetailRenamePayload {
  id: string;
  name: string;
  attributeGroupId: string;
  attributeId: string;
  isOnlyGroup: boolean;
}

export interface InventoryFullCategoryTreeType {
  id: string;
  assetType: string;
  name: string;
  path?: string;
  schemaId?: string;
  children?: InventoryFullCategoryTreeType[];
}

export interface ElementGroupsTreeItem {
  id?: string;
  name?: string;
  elements?: InventoryAssetTreeStateType[];
}

export interface InventoryAssetTreeStateType {
  id?: string;
  name?: string;
  assetType?: string;
  markerId?: number;
  elementGroups?: ElementGroupsTreeItem[];
  elements?: InventoryAssetTreeStateType[];
  changeType?: string;
  attributeGroups?: AttributeGroupsItem[];
  attributes?: AttributesCollection;
}

export enum InventoryPortalView {
  LIST = 'LIST',
  MAP = 'MAP',
}

export enum CreationType {
  upload = 'upload',
  import = 'import',
  inventory = 'inventory',
  work = 'work',
}

export enum ChangeType {
  ADDED = 'added',
  DELETED = 'deleted',
  EDITED = 'edited',
  ELEMENTS_CHANGED = 'elements-changed',
  PARENT_ADDED = 'parentAdded',
}

export enum LocationType {
  Point = 'Point',
  LineString = 'LineString',
}

export interface AssetAncestor {
  id: string;
  name: string;
}

export interface LastWorkOrder {
  id: string;
  name: string;
  updatedDate?: number;
}

export interface AssetDetail {
  id: string;
  name: string;
  address?: string;
  externalId?: string;
  categoryId?: string;
  assetType: string;
  creationType: CreationType;
  schemaId: string;
  parentAssetId: string;
  colorId: number;
  markerId: number;
  isMaterial: boolean;
  ancestors: AssetAncestor[];
  changeType?: ChangeType;
  includeChangesBefore: number;
  lastAppliedWorkOrderName?: string;
  lastAppliedDate?: number;
  lastWorkOrder: LastWorkOrder;
  location?: GeoJSONPoint;
  locationType?: LocationType;
  attributeGroups: HydratedAttributeGroupsItem[];
  budget: AssetBudget;
  createdBy: UserCollectionItem;
  createdDate: number;
  updatedBy: UserCollectionItem;
  updatedDate: number;
  mainPhotoFileId?: string;
  grants?: PolicyGrant[];
  actions?: PolicyAction[];
}

export interface SearchAssetsConnectionInput {
  query: string;
  schemaId?: string;
  schemaIds?: string[];
  parentCategoryId?: string;
  includeMaterialAssets?: boolean;
  includeLocationBasedAssets?: boolean;
  includeInactive?: boolean;
  page?: number;
  limit?: number;
  omitIds?: string[];
  budget?: {
    unit?: string;
  };
  attributes?: {
    id: string;
    value: any;
  }[];
}

export interface SearchAsset {
  id: string;
  schemaId: string;
  externalId: string;
  name: string;
  assetType: string;
  markerId: number;
  categoryPath: string[];
  isMaterial?: boolean;
  inactive?: boolean;
  budget?: {
    isStockBased?: boolean;
    quantityOnHand?: number;
    rate?: number;
    unit?: string;
  };
}

export interface SearchAssetsConnection {
  totalCount: number;
  assets: SearchAsset[];
}

export interface SearchCategory {
  id: string;
  name: string;
  schemaId: string;
  categoryPath: SearchCategory[];
}

export interface SearchCategoriesConnection {
  totalCount: number;
  categories: SearchCategory[];
}

export interface SearchCategoriesConnectionInput {
  query: string;
  schemaId?: string;
  parentCategoryId?: string;
  includeMaterialCategories?: boolean;
  includeLocationBasedCategories?: boolean;
  page?: number;
  limit?: number;
}

export interface MutcdCategory {
  name: string;
  types: MutcdType[];
}

export interface MutcdType {
  name: string;
  fileId: string;
}

export interface AssetConnectionItem {
  id: string;
  name: string;
  assetType: string;
  attributes?: any;
  colorId: number;
  markerId: number;
  isMaterial: boolean;
  mainPhotoFileId?: string;
  createdBy: UserCollectionItem;
  createdDate?: number;
  updatedBy?: UserCollectionItem;
  updatedDate?: number;
  location?: GeoJSONPoint;
  schemaName?: string;
  budget?: AssetBudget;
}

export interface AssetsConnection {
  totalCount: number;
  assets: AssetConnectionItem[];
}

export interface AssetsConnectionInput {
  ids?: string[];
  omitIds?: string[];
  rootAssets?: boolean;
  schemaId?: string;
  categoryIds?: string[];
  hasPendingChangesOnly?: boolean;
  includeInactive?: boolean;
  budget?: { unit?: string };
  // dictionary of attributes to value to search by
  // i.e. {attributes.5b47d25e0220e700010dd107: ["A"]}
  attributes?: { [key: string]: any };
  limit?: number;
  page?: number;
  sortBy?: string;
  isAscending?: boolean;
}

export interface AssetCreateBudgetInput {
  rate: number;
  unit: string;
  quantityOnHand?: number;
  quantityRemaining?: number;
  isStockBased: boolean;
  isStartEndReading: boolean;
  isStartEndCalculated: boolean;
}

export interface AssetCreateAttributesInput {
  [map: string]: {
    value: any;
  };
}

export interface AssetCreateInput {
  name: string;
  externalId?: string;
  schemaId: string;
  categoryId: string;
  attributes?: AssetCreateAttributesInput;
  location?: Location;
  budget?: Partial<AssetCreateBudgetInput>;
}

import React from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import { DashboardCard } from '@atom/types/dashboard';

import '../dashboardCard.css';

interface Props {
  card: DashboardCard;
  cardData: { users: { totalCount: string } };
}

const CardPendingUserRequests = ({ card, cardData }: Props) => {
  const display = R.pathOr(0, ['users', 'totalCount'], cardData);
  const style = {
    height: `${card.emptyHeight}px`,
    fontSize: '2.5rem',
  };
  return (
    <div style={style} styleName="card-content">
      <Link target="_blank" to="/team?view=PENDING">
        {display}
      </Link>
    </div>
  );
};

export default CardPendingUserRequests;

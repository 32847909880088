import * as React from 'react';
import * as R from 'ramda';

// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Collapse, Icon, IconButton, List, Menu, Modal } from '@atom/mui';
import config from '@atom/selectors/config';
import colors from '@atom/styles/colors';

import CreateMediaFolderModal from './CreateMediaFolderModal';
import EditMediaFolderModal from './EditMediaFolderModal';
import MoveMediaFolderModal from './MoveMediaFolderModal';

import './folders.css';

const { ListItemButton, ListItemText } = List;
const { MenuItem } = Menu;

const styles = {
  selectedNode: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    height: '2.5rem',
    paddingTop: '0.25rem',
    paddingRight: '0.5rem',
  },
  folderNode: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    height: '2.5rem',
    paddingTop: '0.25rem',
    paddingRight: '0.5rem',
  },
  itemLeftFolderIcon: {
    marginLeft: '3rem',
    marginRight: '0.625rem',
    bottom: '0rem',
  },
  itemLeftFolderDropDownIconContainer: {
    marginLeft: '0rem',
    marginRight: '0.625rem',
    display: 'flex',
    alignItems: 'center',
  },
};

interface Props {
  mediaFolderTree?: any;
  onToggle: (
    id: string,
    folderPath: any[],
    expanded: boolean,
    name: string,
  ) => void;
  selectedFolder: string;
  onEdit: (folderId: string, path: any[], folderName: string) => void;
  onCreate: (folderId: string, path: any[], folderName: string) => void;
  onDelete: (id: string, childPath: any[]) => void;
  onMoveComplete: (id: string, destId: string, destPath: any[]) => void;
  canCreateMedia: boolean;
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
}

enum ModalType {
  EDIT = 'EDIT',
  CREATE_SUB_FOLDER = 'CREATE_SUB_FOLDER',
  MOVE_TO_FOLDER = 'MOVE_TO_FOLDER',
  DELETE = 'DELETE',
  NONE = 'NONE',
}

class FolderTree extends React.Component<Props> {
  state: {
    openModal: { type: ModalType; folderId: any };
  } = { openModal: { type: ModalType.NONE, folderId: null } };

  toggleModal = (modalToOpen: ModalType, id: any) => {
    this.setState({ openModal: { type: modalToOpen, folderId: id } });
  };

  isModalOpen = (modalType: ModalType, id: any) => {
    const { type, folderId } = this.state.openModal;
    return type === modalType && folderId === id;
  };

  getListItemRootStyle = (listItemId, selectedFolder) => {
    return selectedFolder === listItemId
      ? styles.selectedNode
      : styles.folderNode;
  };

  renderSideNode(
    data,
    onToggle,
    folderPath,
    selectedFolder,
    level: number = 0,
  ) {
    const {
      onEdit,
      onCreate,
      onDelete,
      mediaFolderTree,
      onMoveComplete,
      canUpdateMedia,
      canDeleteMedia,
      canCreateMedia,
    } = this.props;

    if (!data) {
      return [];
    }

    return data.map((child, pathIndex) => {
      const { name, id, children, expanded } = child;
      const childPath = [...folderPath, 'children', pathIndex];
      const rootStyles = this.getListItemRootStyle(id, selectedFolder);
      const isExpanded = expanded ? !expanded : true;

      const leftIcon = R.isNil(children) ? (
        <Icon style={styles.itemLeftFolderIcon}>folder</Icon>
      ) : (
        <div style={styles.itemLeftFolderDropDownIconContainer}>
          <IconButton
            onClick={event => {
              event.stopPropagation();
              onToggle(id, childPath, isExpanded, name);
            }}
          >
            <Icon color={colors.neutral.dim}>
              {expanded ? 'arrow_drop_down' : 'arrow_right'}
            </Icon>
          </IconButton>
          <Icon>folder</Icon>
        </div>
      );

      const showFolderCreate =
        canCreateMedia && childPath.length < config.MAX_FOLDER_DEPTH * 2;
      const showAdditionalOptions =
        canUpdateMedia || canDeleteMedia || showFolderCreate;

      const onConfirmDeleteModal = () => {
        onDelete(id, childPath);
        this.toggleModal(ModalType.NONE, null);
      };

      const rightIconButton = showAdditionalOptions ? (
        <>
          <Menu>
            {canUpdateMedia && (
              <MenuItem
                startAdornment={
                  <Icon>
                    <img src={renameIcon} />
                  </Icon>
                }
                onClick={() => this.toggleModal(ModalType.EDIT, id)}
              >
                Rename
              </MenuItem>
            )}
            {showFolderCreate && (
              <MenuItem
                startAdornment={<Icon>create_new_folder</Icon>}
                onClick={() =>
                  this.toggleModal(ModalType.CREATE_SUB_FOLDER, id)
                }
              >
                Create Folder
              </MenuItem>
            )}
            {canUpdateMedia && (
              <MenuItem
                startAdornment={
                  <Icon>
                    <img src={folderMoveIcon} />
                  </Icon>
                }
                onClick={() => this.toggleModal(ModalType.MOVE_TO_FOLDER, id)}
              >
                Move To Folder
              </MenuItem>
            )}
            {canDeleteMedia && (
              <MenuItem
                startAdornment={<Icon>delete</Icon>}
                onClick={() => this.toggleModal(ModalType.DELETE, id)}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
          {canUpdateMedia && (
            <EditMediaFolderModal
              open={this.isModalOpen(ModalType.EDIT, id)}
              closeModal={() => this.toggleModal(ModalType.NONE, null)}
              folderId={id}
              folderPath={childPath}
              name={name}
              type="folder"
              editAction={(folderId, path, folderName) =>
                onEdit(folderId, path, folderName)
              }
            />
          )}
          {showFolderCreate && (
            <CreateMediaFolderModal
              open={this.isModalOpen(ModalType.CREATE_SUB_FOLDER, id)}
              closeModal={() => this.toggleModal(ModalType.NONE, null)}
              folderId={id}
              folderPath={childPath}
              type="folder"
              createAction={(folderId, path, folderName) =>
                onCreate(folderId, path, folderName)
              }
            />
          )}
          {canUpdateMedia && (
            <MoveMediaFolderModal
              open={this.isModalOpen(ModalType.MOVE_TO_FOLDER, id)}
              closeModal={() => this.toggleModal(ModalType.NONE, null)}
              mediaFolderTree={mediaFolderTree}
              type="folder"
              ids={[child.id]}
              loading={false}
              onEdit={onEdit}
              onCreate={onCreate}
              onComplete={onMoveComplete}
              disabled={[
                child.id,
                ...(child.ancestors && child.ancestors.length
                  ? [R.last(child.ancestors)]
                  : ['root']),
              ]}
            />
          )}
          {canDeleteMedia && (
            <Modal
              open={this.isModalOpen(ModalType.DELETE, id)}
              title={`Delete ${name} folder`}
              onCancel={() => this.toggleModal(ModalType.NONE, null)}
              confirmButtonText="Delete"
              ConfirmButtonProps={{ style: { background: colors.brand.red } }}
              // Stops the ListItemButton wrapper from running it's onClick
              onConfirm={event => {
                event.stopPropagation();
                onConfirmDeleteModal();
              }}
            >
              Are you sure you want to delete this folder? If the folder has any
              subfolders or files saved below this folder, everything will be
              deleted.
            </Modal>
          )}
        </>
      ) : (
        <div style={{ position: 'relative' }} />
      );

      return (
        <>
          <ListItemButton
            disableGutters
            key={id}
            style={{ ...rootStyles, paddingLeft: `${level * 1}rem` }}
            onClick={() => onToggle(id, childPath, true, name)}
          >
            {leftIcon}
            <ListItemText primaryTextStyle={{ fontWeight: 0 }} primary={name} />
            {rightIconButton}
          </ListItemButton>
          <Collapse in={child.expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {!R.isNil(children)
                ? this.renderSideNode(
                    children,
                    onToggle,
                    childPath,
                    selectedFolder,
                    level + 1,
                  )
                : []}
            </List>
          </Collapse>
        </>
      );
    });
  }

  render() {
    const {
      mediaFolderTree,
      onToggle,
      selectedFolder,
      onCreate,
      canCreateMedia,
    } = this.props;
    const { id, name, children, expanded } = mediaFolderTree;

    const rootStyles = this.getListItemRootStyle(id, selectedFolder);
    const folderPath = [];

    const isExpanded = expanded ? !expanded : true;

    const leftIcon = R.isNil(children) ? (
      <Icon style={styles.itemLeftFolderIcon}>folder</Icon>
    ) : (
      <div style={styles.itemLeftFolderDropDownIconContainer}>
        <IconButton
          onClick={event => {
            event.stopPropagation();
            onToggle(id, folderPath, isExpanded, name);
          }}
        >
          <Icon color={colors.neutral.dim}>
            {expanded ? 'arrow_drop_down' : 'arrow_right'}
          </Icon>
        </IconButton>
        <Icon>folder</Icon>
      </div>
    );

    const rightIconButton = canCreateMedia ? (
      <>
        <Menu>
          <MenuItem
            startAdornment={<Icon>create_new_folder</Icon>}
            onClick={() => this.toggleModal(ModalType.CREATE_SUB_FOLDER, id)}
          >
            Create Folder
          </MenuItem>
        </Menu>
        <CreateMediaFolderModal
          open={this.isModalOpen(ModalType.CREATE_SUB_FOLDER, id)}
          closeModal={() => this.toggleModal(ModalType.NONE, null)}
          folderId={id}
          folderPath={folderPath}
          type="folder"
          createAction={(folderId, path, folderName) =>
            onCreate(folderId, path, folderName)
          }
        />
      </>
    ) : (
      <div style={{ position: 'relative' }} />
    );

    return (
      <div styleName="folder-tree-container">
        <List disablePadding>
          <>
            <ListItemButton
              disableGutters
              key={id}
              style={rootStyles}
              onClick={() => onToggle(id, folderPath, true, name)}
            >
              {leftIcon}
              <ListItemText
                primaryTextStyle={{ fontWeight: 0 }}
                primary={name}
              />
              {rightIconButton}
            </ListItemButton>
            <Collapse
              in={mediaFolderTree.expanded}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding>
                {this.renderSideNode(
                  children,
                  onToggle,
                  folderPath,
                  selectedFolder,
                  1,
                )}
              </List>
            </Collapse>
          </>
        </List>
      </div>
    );
  }
}

export default FolderTree;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import {
  TASK_LOCATION_CREATE,
  TASK_LOCATION_DATA_AUTOCOMPLETE,
  TASK_LOCATION_FIND,
  TASK_LOCATION_UPDATE,
} from '@atom/graph/taskLocation';
import { Modal, Snackbar } from '@atom/mui';
import { GeoJSONGeometry } from '@atom/types/geojson';
import {
  LocationDataState,
  LocationDataTitle,
  LocationOptionsState,
  TaskLocation,
  TaskLocationAutocomplete,
  TaskLocationCreateInput,
  TaskLocationFind,
  TaskLocationSimpleInput,
  TaskLocationUpdateInput,
} from '@atom/types/taskLocation';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import {
  getInitialOptions,
  isMilepostsValid,
  mapAutocompleteToOptionsState,
  mapDataToSimpleInput,
  mapLocationDataToState,
  mapStateToData,
} from '../taskLocationUtilities';

import TaskLocationMap from './TaskLocationMap';
import TaskLocationSidebar from './TaskLocationSidebar';

import './taskLocationModal.css';

const styles = {
  modalContent: { padding: '0' },
};

export interface Props {
  open: boolean;
  onClose: () => void;
  /**
   * Denotes if modal is for editing or creating a location
   */
  editMode?: boolean;
  /**
   * Location will be empty when modal is in create mode
   */
  location?: TaskLocation;
}

const TaskLocationModal = ({
  open,
  onClose,
  editMode = false,
  location,
}: Props) => {
  const { workOrderDetail, task, locations, assets, refetch } = useContext(
    LocationsAndAssetsContext,
  );

  const [name, setName] = useState<string>(location?.name || 'New Location');
  const [geometry, setGeometry] = useState<GeoJSONGeometry>(
    location?.geometry || null,
  );
  const [rangeGapError, setRangeGapError] = useState<boolean>(false);
  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const [data, setData] = useState<LocationDataState>(
    mapLocationDataToState(location, locations, assets),
  );
  const [options, setOptions] = useState<LocationOptionsState>(
    getInitialOptions(),
  );

  const [autocompleteData, { loading: loadingAutocompleteData }] = useMutation<
    { taskLocationDataAutocomplete: TaskLocationAutocomplete },
    { input: TaskLocationSimpleInput }
  >(TASK_LOCATION_DATA_AUTOCOMPLETE);

  const [findLocation, { loading: loadingFindLocation }] = useMutation<
    { taskLocationFind: TaskLocationFind },
    { input: TaskLocationSimpleInput }
  >(TASK_LOCATION_FIND);

  const [createLocation, { loading: loadingCreateLocation }] = useMutation<
    { taskLocationCreate: TaskLocation },
    { input: TaskLocationCreateInput }
  >(TASK_LOCATION_CREATE);

  const [updateLocation, { loading: loadingUpdateLocation }] = useMutation<
    { taskLocationUpdate: TaskLocation },
    { input: TaskLocationUpdateInput }
  >(TASK_LOCATION_UPDATE);

  useEffect(() => {
    if (open) {
      setName(location?.name || 'New Location');
      setGeometry(location?.geometry || null);
      setData(mapLocationDataToState(location, locations, assets));
      setOptions(getInitialOptions());
      setRangeGapError(false);
    }
  }, [open]);

  const hasAllValidValues = useMemo(() => {
    const validMilepost = isMilepostsValid(options, data);
    const allValuesEntered = R.none(isNilOrEmpty)(
      R.keys(data).map(title => data[title]),
    );

    return allValuesEntered && validMilepost && !rangeGapError;
  }, [data, rangeGapError, options]);

  useEffect(() => {
    // If user does not have all valid inputs, clear name and geometry
    if (!hasAllValidValues) {
      setName('New Location');
      setGeometry(null);
    }
  }, [hasAllValidValues]);

  useEffect(() => {
    const getSuggestedLocation = async () => {
      try {
        setRangeGapError(false);

        const res = await findLocation({
          variables: { input: mapDataToSimpleInput(data, false) },
        });

        const findLocationData = res?.data?.taskLocationFind;
        setName(findLocationData?.name || 'New Location');
        setGeometry(findLocationData?.geometry || null);
      } catch (error) {
        if (error?.networkError?.statusCode === 422) {
          setRangeGapError(true);
        } else {
          Snackbar.error({ message: 'An unknown error occurred' });
        }
      }
    };

    if (open && hasAllValidValues && !workOrderDetail?.isClosed) {
      // Whenever a user has filled out all inputs with valid values
      // get the suggested name and geometry
      getSuggestedLocation();
    }
  }, [open, data, hasAllValidValues, workOrderDetail]);

  useEffect(() => {
    const getAutocompleteData = async () => {
      const res = await autocompleteData({
        variables: {
          input: mapDataToSimpleInput(data, true),
        },
      });

      const updatedOptions = mapAutocompleteToOptionsState(
        res?.data?.taskLocationDataAutocomplete,
      );

      setOptions(updatedOptions);
    };

    // Whenever a user makes a selection, get new autocomplete data
    getAutocompleteData();
  }, [data]);

  const updateValue = (property: LocationDataTitle, value: any) => {
    setData({ ...data, [property]: value });
  };

  const clearValues = () => {
    setData(
      mapLocationDataToState({ ...location, data: [] }, locations, assets),
    );
    setOptions(getInitialOptions());
    setName('New Location');
    setGeometry(null);
    setRangeGapError(false);
  };

  const handleClose = () => {
    clearValues();
    onClose();
  };

  const handleCreate = async () => {
    try {
      const newData = mapStateToData(options, data);

      await createLocation({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task?.id,
            name,
            geometry,
            data: newData,
          },
        },
      });

      refetch();
      handleClose();
    } catch (error) {
      Snackbar.error({ message: 'An unknown error occurred' });
    }
  };

  const handleUpdate = async () => {
    try {
      const newData = mapStateToData(options, data);

      await updateLocation({
        variables: {
          input: { id: location.id, name, geometry, data: newData },
        },
      });

      refetch();
      handleClose();
    } catch (error) {
      Snackbar.error({ message: 'An unknown error occurred' });
    }
  };

  const onConfirm = () => {
    if (task.isCompleted) {
      return setOpenFieldsConfirm(true);
    }

    return editMode ? handleUpdate() : handleCreate();
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
    return editMode ? handleUpdate() : handleCreate();
  };

  const handleCloseModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  const isOnlyLocationEdit =
    locations.length === 1 && locations[0]?.id === location?.id;
  // For ALDOT only...
  // If locations already exist on the task that are different than the current location, or assets,
  // the first three inputs are locked for the user.
  const inputsLocked =
    isCurrentClient([Client.ALDOT]) &&
    ((!isNilOrEmpty(locations) && !isOnlyLocationEdit) ||
      !isNilOrEmpty(assets));

  const modalTitle = editMode ? 'Edit Location' : 'Create Location';
  const confirmButtonText = editMode ? 'Save' : 'Create';

  const isSaveDisabled = useMemo(() => {
    return (
      !hasAllValidValues ||
      name === 'New Location' ||
      isNilOrEmpty(geometry) ||
      workOrderDetail?.isClosed ||
      (task.isCompleted && !task?.requireAtLeastOneLocation)
    );
  }, [workOrderDetail, name, geometry, hasAllValidValues]);

  const loading = loadingCreateLocation || loadingUpdateLocation;

  const mapLocation = { ...location, geometry };
  const mapLocations =
    locations.filter(item => item?.id !== location?.id) || [];

  return (
    <>
      <Modal
        open={open}
        title={modalTitle}
        contentStyle={styles.modalContent}
        width="xxxl"
        confirmButtonText={confirmButtonText}
        onCancel={(event, reason) => handleCloseModal(event, reason)}
        onConfirm={onConfirm}
        disabled={isSaveDisabled || loading}
      >
        <div styleName="modal-content">
          <TaskLocationSidebar
            name={name}
            values={data}
            options={options}
            updateValue={updateValue}
            loadingAutocompleteData={loadingAutocompleteData}
            loadingFindLocation={loadingFindLocation}
            clearValues={clearValues}
            inputsLocked={inputsLocked}
            rangeGapError={rangeGapError}
            setRangeGapError={setRangeGapError}
          />
          <div styleName="map-container">
            <TaskLocationMap
              editMode={editMode}
              location={mapLocation}
              locations={mapLocations}
            />
          </div>
        </div>
      </Modal>
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default TaskLocationModal;

import React from 'react';
import * as R from 'ramda';

import { AttributesType } from '@atom/types/inventory';
import { SchemaTreeAttribute } from '@atom/types/schema';

import AttributeRowResponsive from './AttributeRowResponsive';

import './attributeDetailResponsive.css';

interface Props {
  attributes: AttributesType[] | SchemaTreeAttribute[];
  attributeGroupName?: string;
  onPendingApproval?: (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  canManageChanges?: boolean;
  schemaPreview?: boolean;
  errorAttributeIds?: string[];
}

const AttributesListResponsive = ({
  attributes,
  attributeGroupName,
  onPendingApproval,
  canManageChanges,
  schemaPreview,
  errorAttributeIds = [],
}: Props) => {
  return (
    <div styleName="attribute-list-container">
      {attributes.map(attribute => (
        <AttributeRowResponsive
          key={attribute.id}
          attribute={attribute}
          attributeGroupName={attributeGroupName}
          onPendingApproval={onPendingApproval}
          canManageChanges={canManageChanges}
          schemaPreview={schemaPreview}
          hasAttributeError={R.includes(attribute.id, errorAttributeIds)}
        />
      ))}
    </div>
  );
};

export default AttributesListResponsive;

import React, { useContext } from 'react';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AttributeDetail from './attributeDetail/AttributeDetail';
import AttributeGroupDetail from './attributeGroupDetail/AttributeGroupDetail';
import SubItemDetail from './subItemDetail/SubItemDetail';
import SubItemTree from './subItemTree/SubItemTree';

import './schemaBuilder.css';

const SchemaBuilder = () => {
  const {
    selectedSubItem,
    selectedAttribute,
    selectedAttributeGroup,
  } = useContext(SchemaDetailContext);

  const showSubItemDetail = !isNilOrEmpty(selectedSubItem);
  const showAttributeDetail =
    !isNilOrEmpty(selectedSubItem) && !isNilOrEmpty(selectedAttribute);
  const showAttributeGroupDetail =
    !isNilOrEmpty(selectedSubItem) && !isNilOrEmpty(selectedAttributeGroup);

  return (
    <>
      <div styleName="builder-column">
        <SubItemTree />
      </div>
      {showSubItemDetail && (
        <div styleName="builder-column">
          <SubItemDetail />
        </div>
      )}
      {showAttributeDetail && (
        <div styleName="builder-column">
          <AttributeDetail />
        </div>
      )}
      {showAttributeGroupDetail && (
        <div styleName="builder-column">
          <AttributeGroupDetail />
        </div>
      )}
    </>
  );
};

export default SchemaBuilder;

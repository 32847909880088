import * as React from 'react';

import { TextField } from '@atom/mui';
import { EventType } from '@atom/types/event';

import '../../formInstance.css';

interface PassedProps {
  type: string;
  value: any;
  unit: string;
  isEditable: boolean;
  onChange: (event: EventType) => void;
}

type Props = PassedProps;

const NumericAttribute = ({
  type,
  value,
  onChange,
  unit,
  isEditable,
}: Props) => {
  return (
    <div styleName={`attribute-input${type === 'currency' ? ' currency' : ''}`}>
      <TextField
        variant="standard"
        name={type}
        onChange={onChange}
        value={value}
        type="number"
        disabled={!isEditable}
      />
      {type === 'number' && <div styleName="unit-text">{unit}</div>}
    </div>
  );
};

export default NumericAttribute;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import WorkOrdersContext, {
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import client from '@atom/graph/client';
import { GET_USERS } from '@atom/graph/user';
import { GET_USER_GROUPS } from '@atom/graph/userGroup';
import { Autocomplete, Avatar, Chip, Icon, List, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import {
  UserGroup,
  UserGroupsConnection,
  UserGroupsConnectionInput,
} from '@atom/types/userGroups';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getColorFromColorId } from '@atom/utilities/colorUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const { ListItemText } = List;

type DebouncedSearch = (name: string, ids?: string[]) => void;
type UserOrGroup = UserDetail | UserGroup;

const DEBOUNCE_TIME = 300;
const MIN_CHAR = 3;
const SEARCH_LIMIT = 250;
const SEARCH_PAGE = 1;

const styles = {
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
  avatar: {
    marginRight: '0.5rem',
  },
  chip: {
    marginTop: '5px',
    marginRight: '5px',
    backgroundColor: colors.neutral.ash,
    borderRadius: '20px',
  },
};

const isUser = (userOrGroup: UserOrGroup): userOrGroup is UserDetail => {
  return userOrGroup.hasOwnProperty('firstName');
};

const getUserAvatar = (user: UserDetail): React.ReactNode => (
  <Avatar style={styles.avatar} src={user.photoUrl} alt={user.firstName} />
);

const getUserGroupAvatar = (group: UserGroup): React.ReactNode => (
  <Avatar
    style={{
      ...styles.avatar,
      background: getColorFromColorId(group.colorId || 0),
    }}
    alt={group.name}
  >
    <Icon style={{ fontSize: 20 }} color={colors.neutral.white}>
      people
    </Icon>
  </Avatar>
);

const WorkOrdersAssigneeFilter = () => {
  const { workOrdersInputCart, dispatch } = useContext(WorkOrdersContext);

  const [query, setQuery] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPreselected, setLoadingPreselected] = useState<boolean>(false);
  const [results, setResults] = useState<UserOrGroup[]>([]);
  const [selected, setSelected] = useState<UserOrGroup[]>([]);
  const { filtersDisabled } = useContext(WorkOrdersContext);

  const getUsersAndGroups = async (
    name: string = '',
    userIds: string[] = [],
    userGroupIds: string[] = [],
  ) => {
    const hasIds = userIds?.length || userGroupIds?.length;

    if (hasIds) {
      setLoadingPreselected(true);
    } else {
      setLoadingSearch(true);
    }

    const [users, userGroups] = await Promise.all([
      ...(name || userIds?.length
        ? [
            client
              .query<
                { users: UsersConnection },
                { input: UsersConnectionInput }
              >({
                query: GET_USERS,
                variables: {
                  input: R.reject(isNilOrEmpty, {
                    limit: SEARCH_LIMIT,
                    page: SEARCH_PAGE,
                    name,
                    showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
                    ...(userIds.length && { ids: userIds }),
                  }),
                },
              })
              .then(({ data }) => data.users.users),
          ]
        : [Promise.resolve([])]),
      ...(name || userGroupIds?.length
        ? [
            client
              .query<
                { userGroups: UserGroupsConnection },
                { input: UserGroupsConnectionInput }
              >({
                query: GET_USER_GROUPS,
                variables: {
                  input: R.reject(isNilOrEmpty, {
                    limit: SEARCH_LIMIT,
                    page: SEARCH_PAGE,
                    name,
                    ...(userGroupIds.length && { ids: userGroupIds }),
                  }),
                },
              })
              .then(({ data }) => data.userGroups.userGroups),
          ]
        : [Promise.resolve([])]),
    ]);

    if (hasIds) {
      setSelected([...users, ...userGroups]);
      setLoadingPreselected(false);
    } else {
      setResults([...users, ...userGroups]);
      setLoadingSearch(false);
    }
  };

  const getUsersAndGroupsDebounced = useCallback<DebouncedSearch>(
    debounce(getUsersAndGroups, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (query.length >= MIN_CHAR) {
      getUsersAndGroupsDebounced(query);
    } else {
      setResults([]);
    }
  }, [query]);

  useEffect(() => {
    const hasIds =
      workOrdersInputCart.userIds?.length ||
      workOrdersInputCart.groupIds?.length;

    if (hasIds) {
      getUsersAndGroups(
        null,
        workOrdersInputCart.userIds,
        workOrdersInputCart.groupIds,
      );
    }

    if (!hasIds && selected.length) {
      setSelected([]);
    }
  }, [workOrdersInputCart.userIds, workOrdersInputCart.groupIds]);

  const handleChange = (values: UserOrGroup[]) => {
    setSelected(values);

    const { userIds, groupIds } = values.reduce(
      (acc, userOrGroup) => {
        return isUser(userOrGroup)
          ? {
              ...acc,
              userIds: [...acc.userIds, userOrGroup.id],
            }
          : {
              ...acc,
              groupIds: [...acc.groupIds, userOrGroup.id],
            };
      },
      { userIds: [], groupIds: [] },
    );

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'userIds',
        value: userIds,
      },
    });

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'groupIds',
        value: groupIds,
      },
    });
  };

  const handleDeselect = (id: string) => {
    setSelected(state => state.filter(user => user.id !== id));

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'userIds',
        value:
          workOrdersInputCart?.userIds?.filter(userId => userId !== id) ?? [],
      },
    });

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'groupIds',
        value:
          workOrdersInputCart?.groupIds?.filter(groupId => groupId !== id) ??
          [],
      },
    });
  };

  return (
    <Autocomplete<UserOrGroup, true, false>
      multiple
      label="Assigned To"
      options={results}
      loading={loadingSearch}
      disabled={loadingPreselected || filtersDisabled}
      inputValue={query}
      onInputChange={(event, val) => setQuery(val || '')}
      getOptionLabel={userOrGroup =>
        isUser(userOrGroup) ? getUserFullName(userOrGroup) : userOrGroup.name
      }
      placeholder={!selected.length ? 'Search for users or groups' : ''}
      value={selected}
      onChange={(event, values: UserGroup[]) => handleChange(values)}
      getOptionDisabled={userOrGroup =>
        selected.some(({ id }) => id === userOrGroup.id)
      }
      renderOption={(optionProps, userOrGroup) => (
        <li key={userOrGroup.id} {...optionProps}>
          {isUser(userOrGroup)
            ? getUserAvatar(userOrGroup)
            : getUserGroupAvatar(userOrGroup)}
          <ListItemText
            primary={
              isUser(userOrGroup)
                ? getUserFullName(userOrGroup)
                : userOrGroup.name
            }
            secondary={
              isUser(userOrGroup)
                ? userOrGroup.email
                : userOrGroup.groupPath.join(' / ')
            }
          />
        </li>
      )}
      renderTags={usersOrGroups => {
        return usersOrGroups.map(userOrGroup => (
          <Chip
            key={userOrGroup.id}
            style={styles.chip}
            // @ts-ignore
            avatar={
              isUser(userOrGroup)
                ? getUserAvatar(userOrGroup)
                : getUserGroupAvatar(userOrGroup)
            }
            label={
              isUser(userOrGroup)
                ? getUserFullName(userOrGroup)
                : userOrGroup.name
            }
            onDelete={() => handleDeselect(userOrGroup.id)}
          />
        ));
      }}
      groupBy={userOrGroup => (isUser(userOrGroup) ? 'Users' : 'Groups')}
      endAdornment={loadingPreselected && <Progress size={20} />}
      labelStyle={styles.label}
    />
  );
};

export default WorkOrdersAssigneeFilter;

import React, { Dispatch, SetStateAction } from 'react';

import {
  AttributeGroupUpdateBulkInputItem,
  AttributeUpdateBulkInputItem,
  SchemaTree,
  SchemaTreeAttribute,
  SchemaTreeAttributeGroup,
  SelectedAttributeRoute,
} from '@atom/types/schema';

export enum SchemaDetailView {
  PREVIEW = 'PREVIEW',
  BUILDER = 'BUILDER',
}

export enum PreviewTab {
  INFO = 'INFO',
  SUB_ITEMS = 'SUB_ITEMS',
  COST = 'COST',
}

export enum DragDropType {
  ATTRIBUTE = 'attribute',
  ATTRIBUTE_GROUP = 'attributeGroup',
  ENUM_OPTION = 'enumOption',
}

// Dictionary of schemaId (element) to dictionary of attributeGroupId and pending changes
export interface PendingGroupUpdates {
  [schemaId: string]: {
    [attributeGroupId: string]: AttributeGroupUpdateBulkInputItem;
  };
}

// Dictionary of schemaId (element) to dictionary of attributeId and pending changes
export interface PendingUpdates {
  [schemaId: string]: {
    [attributeId: string]: AttributeUpdateBulkInputItem;
  };
}

// Dictionary of schemaId (element) to dictionary of attributeGroupId to dictionary of temp attributes with attributeId
export interface PendingCreations {
  [schemaId: string]: {
    [attributeGroupId: string]: {
      [attributeId: string]: SchemaTreeAttribute;
    };
  };
}

interface Context {
  schemaTree: Partial<SchemaTree>;
  refetchSchemaTree: () => Promise<any>;
  loading: boolean;
  view: SchemaDetailView;
  setView: Dispatch<SetStateAction<SchemaDetailView>>;
  previewTab: PreviewTab;
  setPreviewTab: Dispatch<SetStateAction<PreviewTab>>;
  collapsedSubItems: Set<string>;
  setCollapsedSubItems: Dispatch<SetStateAction<Set<string>>>;
  selectedSubItem: Partial<SchemaTree>;
  setSelectedSubItem: Dispatch<SetStateAction<Partial<SchemaTree>>>;
  selectedSubItemPath: any[];
  setSelectedSubItemPath: Dispatch<SetStateAction<any[]>>;
  selectedAttributeRoute: SelectedAttributeRoute;
  setSelectedAttributeRoute: Dispatch<SetStateAction<SelectedAttributeRoute>>;
  selectedAttribute: Partial<SchemaTreeAttribute>;
  setSelectedAttribute: Dispatch<SetStateAction<Partial<SchemaTreeAttribute>>>;
  selectedAttributeGroup: Partial<SchemaTreeAttributeGroup>;
  setSelectedAttributeGroup: Dispatch<
    SetStateAction<Partial<SchemaTreeAttributeGroup>>
  >;
  pendingGroupUpdates: PendingGroupUpdates;
  setPendingGroupUpdates: Dispatch<SetStateAction<PendingGroupUpdates>>;
  pendingUpdates: PendingUpdates;
  setPendingUpdates: Dispatch<SetStateAction<PendingUpdates>>;
  pendingCreations: PendingCreations;
  setPendingCreations: Dispatch<SetStateAction<PendingCreations>>;
}

export const initialState: Context = {
  schemaTree: {},
  refetchSchemaTree: () => Promise.resolve({}),
  loading: false,
  view: SchemaDetailView.PREVIEW,
  setView: () => {},
  previewTab: PreviewTab.INFO,
  setPreviewTab: () => {},
  collapsedSubItems: new Set([]),
  setCollapsedSubItems: () => {},
  selectedSubItem: {},
  setSelectedSubItem: () => {},
  selectedSubItemPath: null,
  setSelectedSubItemPath: () => {},
  selectedAttributeRoute: null,
  setSelectedAttributeRoute: () => {},
  selectedAttribute: {},
  setSelectedAttribute: () => {},
  selectedAttributeGroup: {},
  setSelectedAttributeGroup: () => {},
  pendingGroupUpdates: {},
  setPendingGroupUpdates: () => {},
  pendingUpdates: {},
  setPendingUpdates: () => {},
  pendingCreations: {},
  setPendingCreations: () => {},
};

const SchemaDetailContext = React.createContext<Context>(initialState);

export default SchemaDetailContext;

/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { WORK_ORDER_CREATE } from '@atom/graph/work';
import { Button, Icon, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderCreateInput,
  WorkOrderDetailType,
  WorkOrderType,
} from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
import history from '@atom/utilities/history';
import {
  convertDateToMillisGMTMidday,
  generateYYYYMM,
  generateYYYYMMName,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import NewWorkOrderIcon from '../svgIcons/NewWorkOrderIcon';

import CreateSingleWork from './CreateSingleWork';
import CreateWorkTemplateSelection from './CreateWorkTemplateSelection';

import './createWorkModals.css';

type WorkTemplate = WorkTemplateListItem | SearchWorkTemplate;

enum View {
  WORK_TEMPLATE,
  SINGLE,
}

interface Props {
  open: boolean;
  onClose: () => void;
  asset: InventoryAssetDetailType;
  redirectOnCreate?: boolean;
  workTemplateRequired?: boolean;
}

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
  button: {
    color: colors.brand.blue,
    marginBottom: '1.25rem',
  },
  icon: {
    marginRight: '0.25rem',
  },
};

const CreateWorkForAssetModal = ({
  open,
  onClose,
  asset,
  redirectOnCreate,
  workTemplateRequired,
}: Props) => {
  const [view, setView] = useState<View>(View.WORK_TEMPLATE);
  const [workTemplate, setWorkTemplate] = useState<WorkTemplate>(null);
  const [name, setName] = useState<string>('');
  const [dueDate, setDueDate] = useState<Date>(new Date());

  const [createWork, { loading: loadingCreate }] = useMutation<
    { workOrderCreate: WorkOrderDetailType },
    { input: WorkOrderCreateInput }
  >(WORK_ORDER_CREATE);

  const handleWorkTemplateClick = (template: WorkTemplate) => {
    setName(generateYYYYMMName(template));
    setWorkTemplate(template);
    setView(View.SINGLE);
  };

  const handleCreateWorkClick = () => {
    setName(generateYYYYMM());
    setView(View.SINGLE);
  };

  const resetState = () => {
    setView(View.WORK_TEMPLATE);
    setWorkTemplate(null);
    setName('');
    setDueDate(new Date());
  };

  const isValid = () => {
    return name;
  };

  const handleSubmit = async () => {
    try {
      const res = await createWork({
        variables: {
          // @ts-ignore
          input: R.reject(isNilOrEmpty, {
            name,
            dueDate: convertDateToMillisGMTMidday(dueDate),
            workTemplateId: workTemplate?.id,
            inventoryAssetId: asset?.id,
          }),
        },
      });

      const workOrderData = res?.data?.workOrderCreate;

      if (redirectOnCreate) {
        history.push(`/workOrders/${workOrderData.id}`);
      } else {
        Snackbar.info({
          message: `Created work ${workOrderData.name}.`,
          action: 'View',
          onActionClick: () => history.push(`/workOrders/${workOrderData.id}`),
        });
      }

      onClose();
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const createWorkTemplateSelectionLabel = workTemplateRequired
    ? null
    : 'Or start with a work template:';

  const getFooter = () => {
    return view === View.WORK_TEMPLATE ? (
      <Button onClick={onClose}>Cancel</Button>
    ) : (
      <div styleName="footer">
        <div>
          <Button onClick={resetState}>Back</Button>
        </div>
        <div>
          <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid() || loadingCreate}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Create Work"
      open={open}
      onCancel={onClose}
      footer={getFooter()}
      onExited={resetState}
      contentStyle={
        view === View.WORK_TEMPLATE
          ? { ...styles.modal, height: '50vh', padding: '2rem 3.875rem' }
          : styles.modal
      }
    >
      {view === View.WORK_TEMPLATE ? (
        <>
          {!workTemplateRequired && (
            <div>
              <Button
                variant="outlined"
                style={styles.button}
                onClick={handleCreateWorkClick}
              >
                <Icon style={styles.icon}>
                  <NewWorkOrderIcon color={colors.brand.blue} />
                </Icon>
                Create New Work
              </Button>
            </div>
          )}
          <CreateWorkTemplateSelection
            onClick={template => handleWorkTemplateClick(template)}
            label={createWorkTemplateSelectionLabel}
            emptyContentLabel="There are 0 matching work templates."
            schemaId={asset.schemaId}
            hasSchema={false}
            action={PolicyAction.CREATE}
            type={WorkOrderType.DEFAULT}
          />
        </>
      ) : (
        <CreateSingleWork
          name={name}
          onNameChange={name => setName(name)}
          dueDate={dueDate}
          onDueDateChange={setDueDate}
          asset={asset}
          onAssetChange={() => {}}
          onAssetViewClick={() => {}}
          isLocationBased={!asset?.isMaterial}
          onLocationBasedChange={() => {}}
          workTemplate={workTemplate}
          disableAssetChange
        />
      )}
    </Modal>
  );
};

export default CreateWorkForAssetModal;

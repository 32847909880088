import { createContext } from 'react';

import {
  LocationDataState,
  LocationDataTitle,
  LocationOptionsState,
} from '@atom/types/taskLocation';

export interface Context {
  values: LocationDataState;
  options: LocationOptionsState;
  updateValue: (property: LocationDataTitle, value: any) => void;
  loading: boolean;
  inputsLocked: boolean;
  rangeGapError: boolean;
  setRangeGapError: (rangeGapError: boolean) => void;
  autoFillMilepost: (currentInput: LocationDataTitle) => void;
  showRanges: boolean;
  isRangeError: boolean;
  rangeSubtext: string;
  rangeSubtextStyles: any;
  handleMilepostChange: (title: LocationDataTitle, value: any) => void;
}

const TaskLocationSidebarContext = createContext<Context>({
  values: null,
  options: null,
  updateValue: () => {},
  loading: null,
  inputsLocked: null,
  rangeGapError: null,
  setRangeGapError: () => {},
  autoFillMilepost: () => {},
  showRanges: null,
  isRangeError: null,
  rangeSubtext: null,
  rangeSubtextStyles: null,
  handleMilepostChange: () => {},
});

export default TaskLocationSidebarContext;

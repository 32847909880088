import React, { Dispatch, SetStateAction } from 'react';

import {
  INITIAL_IS_ASCENDING,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT_BY,
} from '@atom/components/common/requests/assetRequestConstants';
import {
  AssetRequest,
  AssetRequestsFilters,
  AssetRequestType,
} from '@atom/types/assetRequest';

interface Context {
  requests: AssetRequest[];
  totalCount: number;
  filters: AssetRequestsFilters;
  filtersCart: AssetRequestsFilters;
  setFiltersCart: Dispatch<SetStateAction<AssetRequestsFilters>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  isAscending: boolean;
  setIsAscending: Dispatch<SetStateAction<boolean>>;
  changeType: (type: AssetRequestType) => void;
  setActiveRequestId: (id: string) => void;
}

export const initialState: Context = {
  requests: [],
  totalCount: 0,
  filters: {},
  filtersCart: {},
  setFiltersCart: () => {},
  page: INITIAL_PAGE,
  setPage: () => {},
  limit: INITIAL_LIMIT,
  setLimit: () => {},
  sortBy: INITIAL_SORT_BY,
  setSortBy: () => {},
  isAscending: INITIAL_IS_ASCENDING,
  setIsAscending: () => {},
  changeType: () => {},
  setActiveRequestId: () => {},
};

const RequestsContext = React.createContext<Context>(initialState);

export default RequestsContext;

import React, { useEffect, useState } from 'react';

import { Modal, TextField } from '@atom/mui';

const styles = {
  iconLayoutDiv: { display: 'flex', alignItems: 'center', gap: '1rem' },
};

export interface Props {
  open: boolean;
  loading?: boolean;
  title?: string;
  fieldName?: string;
  initialValue?: string;
  cancelText?: string;
  confirmText?: string;
  icon?: string;
  onCancel: () => void;
  onConfirm: (value: string) => void;
}

const CreateModal = ({
  open,
  loading,
  title = 'Create',
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Create',
  fieldName,
  initialValue,
  icon,
}: Props) => {
  const [value, setValue] = useState<string>(initialValue || '');

  const setErrorText = () => {
    return value.length === 0 ? `The ${fieldName} cannot be blank` : '';
  };

  const disabled = loading || value.length === 0;

  useEffect(() => {
    if (!open) {
      setValue(initialValue || '');
    }
  }, [open, initialValue]);

  return (
    <Modal
      title={title}
      open={open}
      loading={loading}
      disabled={disabled}
      cancelButtonText={cancelText}
      onCancel={onCancel}
      confirmButtonText={confirmText}
      onConfirm={() => onConfirm(value)}
    >
      <div style={styles.iconLayoutDiv}>
        {icon && (
          <div>
            <img src={icon} />
          </div>
        )}
        <TextField
          label={fieldName || ''}
          value={value}
          name="value"
          error={setErrorText().length > 0}
          helperText={setErrorText()}
          onChange={event => setValue(event.target.value)}
        />
      </div>
    </Modal>
  );
};

export default CreateModal;

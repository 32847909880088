import React, { useContext, useMemo } from 'react';

import { getApprovalKeyword } from '@atom/components/common/requests/customTenantUtilities';
import { Button, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetRequestStatus } from '@atom/types/assetRequest';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ViewRequestContext from '../ViewRequestContext';

import './sddotView.css';

const styles = {
  label: {
    color: colors.neutral.dim,
  },
  cancelButton: {
    marginRight: '1rem',
  },
};

const ApprovalForm = () => {
  const {
    fromAsset,
    activeRequest,
    quantityApproved,
    setQuantityApproved,
    comments,
    setComments,
    status,
    setStatus,
    setConfirmOpen,
  } = useContext(ViewRequestContext);

  const resetForm = () => {
    setQuantityApproved(null);
    setComments(null);
    setStatus(null);
  };

  const quantityUpdate = (value: any) => {
    const updatedValue = value === '' ? null : Math.abs(Number(value));

    if (updatedValue >= activeRequest?.quantityOrdered) {
      setStatus(AssetRequestStatus.FULL_APPROVAL);
    } else {
      setStatus(AssetRequestStatus.PARTIAL_APPROVAL);
    }

    setQuantityApproved(updatedValue);
  };

  const isApproval =
    status === AssetRequestStatus.FULL_APPROVAL ||
    status === AssetRequestStatus.PARTIAL_APPROVAL;

  const availableStockError =
    Number(fromAsset?.budget?.quantityOnHand) - Number(quantityApproved) < 0;
  const requestedQuantityError =
    Number(activeRequest?.quantityOrdered) - Number(quantityApproved) < 0;

  const quantityHelperText = useMemo(() => {
    switch (true) {
      case availableStockError:
        return `${fromAsset?.budget?.quantityOnHand} ${fromAsset?.budget?.unit} in stock. Exceeds available stock levels.`;
      case requestedQuantityError:
        return 'Cannot approve more than the quantity requested';
      default:
        return `${fromAsset?.budget?.quantityOnHand} ${fromAsset?.budget?.unit} in stock`;
    }
  }, [quantityApproved]);

  const approvalKeyword = getApprovalKeyword(status);
  const cancelText =
    status === AssetRequestStatus.CANCELED ? 'Do Not Cancel' : 'Cancel';
  const confirmText =
    status === AssetRequestStatus.CANCELED ? 'Cancel Request' : approvalKeyword;
  const buttonColor = isApproval ? 'primary' : 'error';
  const disableConfirm =
    isApproval &&
    (isNilOrEmpty(quantityApproved) ||
      availableStockError ||
      requestedQuantityError);

  return (
    <div styleName="approval-form">
      <div styleName="form-title">{`${approvalKeyword} Request`}</div>
      {isApproval && (
        <div styleName="input-row quantity">
          <TextField
            InputLabelProps={{ style: styles.label }}
            type="number"
            value={quantityApproved}
            label={`Approved Quantity (${activeRequest?.assetUnit || ''})`}
            onChange={event => quantityUpdate(event.target.value)}
            helperText={quantityHelperText}
            error={requestedQuantityError || availableStockError}
          />
        </div>
      )}
      <div styleName="input-row">
        <TextField
          InputLabelProps={{ style: styles.label }}
          fullWidth
          value={comments}
          label="Comments (optional)"
          onChange={event => setComments(event.target.value)}
        />
      </div>
      <div styleName="approve-buttons">
        <Button style={styles.cancelButton} onClick={resetForm}>
          {cancelText}
        </Button>
        <Button
          color={buttonColor}
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          disabled={disableConfirm}
        >
          {confirmText}
        </Button>
      </div>
    </div>
  );
};

export default ApprovalForm;

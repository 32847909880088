import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SortDirection } from '@mui/material';
import * as R from 'ramda';

import { Icon, ListTable } from '@atom/components/common/mui';
import PercentBar from '@atom/components/common/percentBar/PercentBar';
import { usePreferences } from '@atom/hooks/usePreferences';
import colors from '@atom/styles/colors';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import {
  Client,
  Environment,
  isCurrentClient,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import userUtilities from '@atom/utilities/userUtilities';

import { getStatusLabel } from '../budgetDetail/budgetDetailUtils';

import BudgetsContext from './BudgetsContext';
import BudgetsNoneAvailable from './BudgetsNoneAvailable';
import BudgetsOptions from './BudgetsOptions';

import './budgets.css';

const { getUserFullName } = userUtilities;

const styles = {
  link: {
    fontWeight: 500,
  },
  row: {
    backgroundColor: colors.neutral.white,
    height: '3rem',
  },
};

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const BudgetsTable = () => {
  const preferences = usePreferences();
  const showExpenditures = R.pathOr(
    false,
    ['budgeting', 'showExpenditures'],
    preferences,
  );
  const showApprovalFlow: boolean = R.pathOr(
    false,
    ['budgeting', 'showApprovalFlow'],
    preferences,
  );
  const {
    budgets,
    totalCount,
    page,
    setPage,
    limit,
    setLimit,
    sortDirection,
    setSortDirection,
    sortBy,
    setSortBy,
  } = useContext(BudgetsContext);
  /*
    TODO: Replace temporary permissions here with RBAC
    NOTE: This is shared behavior with the BudgetDetailHeader component
    
    Current Rules:
    QA and Production:
    - Edit/Delete are hidden for everyone except Admin for all budgets
    - Duplicate is visible to Org Admin and above ONLY in UDOT (Admin and above 
      for everyone else)
    Dev and UAT:
    - Options are hidden for everyone below Org Admin (show for Admin and Org Admin)
  
    source: https://atomai.atlassian.net/browse/AM-15294
  */
  const roleNeededToEditOrDelete: string[] = isCurrentEnvironment([
    Environment.QA,
    Environment.PRODUCTION,
  ])
    ? ROLE_SETS.ADMIN
    : ROLE_SETS.ORG_ADMIN;

  const roleNeededToDuplicate: string[] = isCurrentEnvironment([
    Environment.QA,
    Environment.PRODUCTION,
  ])
    ? isCurrentClient([Client.UDOT])
      ? ROLE_SETS.ORG_ADMIN
      : ROLE_SETS.ADMIN
    : ROLE_SETS.ORG_ADMIN;

  const canEdit: boolean = hasRolePermissions(roleNeededToEditOrDelete);
  const canDelete: boolean = hasRolePermissions(roleNeededToEditOrDelete);
  const canDuplicate: boolean = hasRolePermissions(roleNeededToDuplicate);

  const getSortDirection = (field: string) => {
    return field === sortBy ? sortDirection : 'desc';
  };

  const handleSortDirectionChange = (field: string) => (
    newSortDirection: SortDirection,
  ) => {
    setSortBy(field);
    setSortDirection(newSortDirection);
  };

  return totalCount === 0 ? (
    <BudgetsNoneAvailable />
  ) : (
    <ListTable offsetTop="165px">
      <TableHead>
        <TableRow header>
          <TableCell variant="head" />
          <TableCell
            variant="head"
            sortDirection={getSortDirection('name')}
            onSortChange={handleSortDirectionChange('name')}
          >
            Budget Name
          </TableCell>
          <TableCell variant="head">Budget Template</TableCell>
          {showApprovalFlow && <TableCell variant="head">Status</TableCell>}
          <TableCell
            variant="head"
            sortDirection={getSortDirection('startDate')}
            onSortChange={handleSortDirectionChange('startDate')}
          >
            Start Date
          </TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('endDate')}
            onSortChange={handleSortDirectionChange('endDate')}
          >
            End Date
          </TableCell>
          <TableCell variant="head">Total Budget</TableCell>
          {showExpenditures && (
            <>
              <TableCell variant="head">Total Actual</TableCell>
              <TableCell variant="head">Total Future Fixed</TableCell>
              <TableCell variant="head">Total Remaining</TableCell>
            </>
          )}
          <TableCell variant="head">Created By</TableCell>
          <TableCell variant="head">Created On</TableCell>
          <TableCell variant="head" />
        </TableRow>
      </TableHead>
      <TableBody>
        {budgets.map(budget => (
          <TableRow key={budget.id} style={styles.row}>
            <TableCell padding="checkbox">
              <Icon color={colors.brand.blue}>insert_chart_outlined</Icon>
            </TableCell>
            <TableCell style={styles.link}>
              <Link to={`/budget/${budget.id}`}>{budget.name}</Link>
            </TableCell>
            <TableCell>{budget.templateName}</TableCell>
            {showApprovalFlow && (
              <TableCell>{getStatusLabel(budget?.status)}</TableCell>
            )}
            <TableCell>
              {setDisplayDate(budget?.startDate?.valueOf())}
            </TableCell>
            <TableCell>{setDisplayDate(budget?.endDate?.valueOf())}</TableCell>
            <TableCell align="right">
              {numberToLocaleString(budget?.totalBudget)}
            </TableCell>
            {showExpenditures && (
              <>
                <TableCell align="right">
                  {numberToLocaleString(budget?.actualBudget)}
                </TableCell>
                <TableCell align="right">
                  {numberToLocaleString(budget?.fixedCosts)}
                </TableCell>
                <TableCell align="right">
                  <PercentBar
                    value={budget?.remaining}
                    limit={budget?.totalBudget}
                  />
                </TableCell>
              </>
            )}
            <TableCell>{getUserFullName(budget?.createdBy)}</TableCell>
            <TableCell>{setDisplayDate(budget?.createdDate)}</TableCell>
            <TableCell>
              {(canDelete || canEdit || canDuplicate) && (
                <BudgetsOptions
                  budget={budget}
                  canDelete={canDelete}
                  canEdit={canEdit}
                  canDuplicate={canDuplicate}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter
        style={{ border: `1px solid ${colors.neutral.ash}`, width: '100%' }}
      >
        <TableRow hover={false}>
          <TablePagination
            style={{ border: 'none' }}
            rowsPerPageOptions={[25, 50, 100, 250]}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={pageData => setPage(pageData)}
            onRowsPerPageChange={event => {
              setLimit(+event.target.value);
              setPage(1);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default BudgetsTable;

import React, { useContext } from 'react';
import * as R from 'ramda';

import { usePreferences } from '@atom/hooks/usePreferences';
import { Select } from '@atom/mui';
import { GrantType } from '@atom/types/policy';
import { POLICY_RESOURCE_OPTIONS } from '@atom/utilities/policyUtilities';

import PolicyModalContext from './PolicyModalContext';

const { MenuItem } = Select;

const PolicyGrantTypeSelection = () => {
  const { state, updateState } = useContext(PolicyModalContext);
  const { resource, grantType, loadingExistingPolicies } = state;

  const preferences = usePreferences();
  const showApprovalFlow: boolean = R.pathOr(
    false,
    ['budgeting', 'showApprovalFlow'],
    preferences,
  );

  const option = POLICY_RESOURCE_OPTIONS[resource];
  const menuItems = showApprovalFlow
    ? option.grantTypes
    : option.grantTypes.filter(type => type !== GrantType.BUDGETING_APPROVAL);

  return (
    <Select
      label="Sub Source"
      value={grantType || ''}
      onChange={event =>
        updateState({ grantType: event.target.value, grants: [] })
      }
      loading={loadingExistingPolicies}
      displayEmpty
    >
      {menuItems.map(type => (
        <MenuItem value={type} key={type}>
          {POLICY_RESOURCE_OPTIONS[type]?.label || ''}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PolicyGrantTypeSelection;

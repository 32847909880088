import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import AddEditSchemaModal from '@atom/components/common/addEditSchemaModal/AddEditSchemaModal';
import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import editSchemaIcon from '@atom/components/common/svgIcons/editSchemaIcon.svg';
// @ts-ignore
import lineTypeIconWhite from '@atom/components/common/svgIcons/lineTypeIconWhite.svg';
// @ts-ignore
import tabIcon from '@atom/components/common/svgIcons/tabIcon.svg';
import SchemaDetailContext, {
  PreviewTab,
  SchemaDetailView,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import textConstants from '@atom/constants/textConstants';
import { SCHEMA_DELETE, SCHEMA_UPDATE } from '@atom/graph/schema';
import { Icon, IconButton, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  LocationType,
  SchemaSavePayload,
  SchemaTree,
  SchemaUpdateInput,
} from '@atom/types/schema';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import HeaderNavigationButton from './HeaderNavigationButton';
import SchemaStatus from './SchemaStatus';

import './schemaDetailHeader.css';

const getTabs = isMaterial => {
  const locationTabs = [
    {
      value: PreviewTab.INFO,
      label: 'info',
      icon: 'info',
    },
    {
      value: PreviewTab.SUB_ITEMS,
      label: 'sub items',
      icon: 'list',
    },
  ];

  const materialTabs = [
    {
      value: PreviewTab.INFO,
      label: 'info',
      icon: 'info',
    },
    {
      value: PreviewTab.COST,
      label: 'cost',
      icon: 'monetization_on',
    },
  ];

  return isMaterial ? materialTabs : locationTabs;
};

const getLocationTypeIcon = (type: LocationType, isMaterial: boolean) => {
  if (isMaterial) {
    return <Icon color={colors.neutral.white}>category</Icon>;
  }

  return type === LocationType.POINT ? (
    <Icon color={colors.neutral.white}>location_on</Icon>
  ) : (
    <img src={lineTypeIconWhite} />
  );
};

const SchemeDetailHeader = () => {
  const {
    view,
    setView,
    schemaTree,
    refetchSchemaTree,
    loading,
    pendingUpdates,
    setPendingUpdates,
    pendingGroupUpdates,
    setPendingGroupUpdates,
    pendingCreations,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDiscard, setOpenDiscard] = useState<boolean>(false);

  const [deleteSchema] = useMutation<{ schemaDelete: boolean }, { id: string }>(
    SCHEMA_DELETE,
  );

  const [updateSchema] = useMutation<
    { schemaUpdate: SchemaTree },
    { input: SchemaUpdateInput }
  >(SCHEMA_UPDATE);

  const isPreviewView = view === SchemaDetailView.PREVIEW;
  const hasPendingChanges =
    !isNilOrEmpty(pendingUpdates) ||
    !isNilOrEmpty(pendingCreations) ||
    !isNilOrEmpty(pendingGroupUpdates);

  const navigateBack = () => {
    setPendingUpdates(null);
    setPendingCreations(null);
    setPendingGroupUpdates(null);
    history.push('/admin/inventoryTypes');
  };

  const handleBackClick = () => {
    if (hasPendingChanges) {
      setOpenDiscard(true);
      return;
    }

    navigateBack();
  };

  const toggleView = () => {
    setView(
      isPreviewView ? SchemaDetailView.BUILDER : SchemaDetailView.PREVIEW,
    );
  };

  const handleUpdate = async (payload: SchemaSavePayload) => {
    await updateSchema({
      variables: {
        input: {
          schemaId: schemaTree?.id,
          assetType: payload?.name,
          ...payload,
        },
      },
    });

    refetchSchemaTree();
    setOpenEdit(false);
  };

  const handleDelete = async () => {
    try {
      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      await deleteSchema({
        variables: {
          id: schemaTree?.id,
        },
      });

      Snackbar.info({
        message: 'Successfully deleted inventory type.',
      });

      setOpenDelete(false);
      history.push('/admin/inventoryTypes');
    } catch (err) {
      setOpenDelete(false);

      if (err?.networkError?.statusCode === 422) {
        Snackbar.warning({
          message:
            'Work is associated with an inventory asset of this inventory type. Deletion is not allowed.',
        });
      } else {
        Snackbar.error({
          message: 'Failed to delete inventory type.',
        });
      }
    }
  };

  const tabs = getTabs(schemaTree?.isMaterial);

  const containerStyle = isPreviewView
    ? 'header-container preview'
    : 'header-container';

  const showEditButton = !schemaTree?.isPublished;
  const icon = getLocationTypeIcon(
    schemaTree?.locationType,
    schemaTree?.isMaterial,
  );
  const viewIcon = isPreviewView ? editSchemaIcon : tabIcon;
  const viewTooltip = isPreviewView
    ? 'Edit Attributes and Sub Items'
    : 'View Details';

  return (
    <>
      <div styleName={containerStyle}>
        <div styleName="top-header">
          <div styleName="header-left">
            <IconButton onClick={handleBackClick} tooltip="Go back">
              <Icon color={colors.neutral.white}>arrow_back</Icon>
            </IconButton>
            <div styleName="title-container">
              {icon}
              <div styleName="title-text">{schemaTree?.name}</div>
            </div>
            {hasPendingChanges && <div>{`(Unpublished changes)`}</div>}
          </div>
          <div styleName="header-right">
            {loading && <Progress size={25} thickness={2} />}
            {showEditButton && (
              <IconButton onClick={() => setOpenEdit(true)} tooltip="Edit">
                <Icon color={colors.neutral.white}>edit</Icon>
              </IconButton>
            )}
            <IconButton onClick={toggleView} tooltip={viewTooltip}>
              <img src={viewIcon} />
            </IconButton>
            <IconButton onClick={() => setOpenDelete(true)} tooltip="Delete">
              <Icon color={colors.neutral.white}>delete</Icon>
            </IconButton>
            <SchemaStatus />
          </div>
        </div>
        {isPreviewView && (
          <div>
            <div styleName="info-boxes-wrapper">
              <div styleName="info-box label">
                <div>Asset Name:</div>
                <div>Last Updated:</div>
              </div>
              <div styleName="info-box value">
                <div>-</div>
                <div>-</div>
              </div>
              <div styleName="info-box label">
                <div>Last Work ID:</div>
                <div>Last Work ID Updated:</div>
              </div>
              <div styleName="info-box value">
                <div>-</div>
                <div>-</div>
              </div>
            </div>
            <div styleName="icon-row">
              {tabs.map(tab => {
                return <HeaderNavigationButton key={tab.value} view={tab} />;
              })}
            </div>
          </div>
        )}
      </div>
      <AddEditSchemaModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        handleSave={handleUpdate}
        schema={schemaTree}
      />
      <DeleteModal
        open={openDelete}
        onConfirm={handleDelete}
        onCancel={() => setOpenDelete(false)}
        title={`Delete ${schemaTree?.name} Inventory Type?`}
        content="Deleting this inventory type will also delete all items under it and all associated assets."
      />
      <DeleteModal
        open={openDiscard}
        title="Discard Changes?"
        content="Changes will not be saved. Are you sure you want to discard all changes?"
        onCancel={() => setOpenDiscard(false)}
        onConfirm={navigateBack}
        confirmText="Discard"
      />
    </>
  );
};

export default SchemeDetailHeader;

import React, { useContext } from 'react';

import UsersFilter from '@atom/components/common/usersFilter/UsersFilter';
import WorkOrdersContext, {
  FilterSection,
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { Icon, IconButton } from '@atom/mui';
import { WorkOrdersConnectionInput } from '@atom/types/work';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import WorkOrdersAssigneeFilter from './WorkOrdersAssigneeFilter';

import './workOrdersFilters.css';

const styles = {
  icon: {
    marginRight: '1rem',
  },
};

const WorkOrdersFiltersTeamSection = () => {
  const {
    collapsedSections,
    setCollapsedSections,
    workOrdersInputCart,
    filtersDisabled,
    dispatch,
  } = useContext(WorkOrdersContext);

  const onToggle = (section: FilterSection) => {
    setCollapsedSections(toggleFromSet(collapsedSections, section));
  };

  const updateValue = (
    property: keyof WorkOrdersConnectionInput,
    value: string[],
  ) => {
    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property,
        value,
      },
    });
  };

  return (
    <div styleName="filters-section">
      <div styleName="filters-section-header">
        <div>
          <Icon style={styles.icon}>people</Icon>
          Team
        </div>
        <IconButton onClick={() => onToggle(FilterSection.TEAM)} edge="end">
          <Icon>
            {collapsedSections.has(FilterSection.TEAM)
              ? 'keyboard_arrow_down'
              : 'keyboard_arrow_up'}
          </Icon>
        </IconButton>
      </div>
      {!collapsedSections.has(FilterSection.TEAM) && (
        <div styleName="filters-section-content">
          <div styleName="filter-container">
            <WorkOrdersAssigneeFilter />
          </div>
          <div styleName="filter-container">
            <UsersFilter
              value={workOrdersInputCart?.completedByIds}
              updateValue={newValue => updateValue('completedByIds', newValue)}
              disabled={filtersDisabled}
              placeholder="Search for user"
              label="Completed By"
            />
          </div>
          <div styleName="filter-container">
            <UsersFilter
              value={workOrdersInputCart?.completedByIds}
              updateValue={newValue => updateValue('createdByIds', newValue)}
              disabled={filtersDisabled}
              placeholder="Search for user"
              label="Created By"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrdersFiltersTeamSection;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { GET_BUDGET_TYPE_FILTERS } from '@atom/graph/budget';
import { ComboSelect } from '@atom/mui';
import {
  BudgetType,
  BudgetTypesFilterConnection,
  BudgetTypesFilterConnectionInput,
} from '@atom/types/budget';
import { objectIdsMatch } from '@atom/utilities/objectCompareUtilities';

import BudgetDetailContext from '../BudgetDetailContext';
import { DEBOUNCE_TIME } from '../budgetDetailUtils';
import { FILTER_KEYS, useBudgetFilterStorage } from '../useBudgetFilterStorage';

import '../budgetDetail.css';

const LIMIT = 25;
const MIN_SEARCH_CHARS = 2;

const BudgetDetailTypeFilter = () => {
  const {
    typeFilters,
    setTypeFilters,
    budget,
    fetchParentUnit,
    parentBudgetUnit,
    loadingParentUnit,
  } = useContext(BudgetDetailContext);
  const { existingTypeFilters, saveFilterState } = useBudgetFilterStorage(
    budget.id,
  );

  const [typeFiltersCart, setTypeFiltersCart] = useState<BudgetType[]>(
    typeFilters,
  );
  const [typeFiltersSnapshot, setTypeFiltersSnapshot] = useState<BudgetType[]>(
    typeFilters,
  );

  const [searchOptions, setSearchOptions] = useState<BudgetType[]>([]);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>();
  const [options, setOptions] = useState<BudgetType[]>(existingTypeFilters);
  const [query, setQuery] = useState<string>('');

  const searching = useMemo(() => query.length >= MIN_SEARCH_CHARS, [query]);

  const [fetchTypeOptions, { loading: loadingOptions }] = useLazyQuery<
    { budgetTypesFilter: BudgetTypesFilterConnection },
    { input: BudgetTypesFilterConnectionInput }
  >(GET_BUDGET_TYPE_FILTERS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const nextOptions = R.pathOr(
        [],
        ['budgetTypesFilter', 'budgetTypes'],
        data,
      );
      const newTotal = data?.budgetTypesFilter?.totalCount || 0;
      if (searching) {
        setSearchOptions([...searchOptions, ...nextOptions]);
        setSearchTotal(newTotal);
      } else {
        setOptions(R.uniqBy(R.prop('id'), [...options, ...nextOptions]));
        setTotal(newTotal);
      }
    },
  });

  const handleSetTypeCart = (newTypes: BudgetType[]) => {
    setTypeFiltersCart(newTypes);
  };

  const handleSelectOption = (option: BudgetType) => {
    const selectedIds = typeFiltersCart.map(({ id }) => id);
    const newTypes = selectedIds.includes(option.id)
      ? typeFiltersCart.filter(({ id }) => id !== option.id)
      : [...typeFiltersCart, option];
    handleSetTypeCart(newTypes);
  };

  const handleClearSelections = () => {
    setTypeFilters([]);
    handleSetTypeCart([]);
    saveFilterState(FILTER_KEYS.TYPE, []);
    fetchParentUnit({
      budgetUnitId: parentBudgetUnit?.id,
      newBudgetTypes: [],
    });
  };

  const fetchTypeOptionsDebounced = useCallback(
    debounce((value: string) => {
      fetchTypeOptions({
        variables: {
          input: {
            budgetId: budget.id,
            name: value,
            limit: LIMIT,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const handleQueryChange = (value: string = '') => {
    setQuery(value);
    setSearchPage(1);
    setSearchOptions([]);
    if (!value) {
      fetchTypeOptionsDebounced.cancel();
    } else if (value.length >= MIN_SEARCH_CHARS) {
      fetchTypeOptionsDebounced(value);
    } else {
      fetchTypeOptionsDebounced.cancel();
    }
  };

  const handlePageScroll = (nextPage: number) => {
    if (searching) {
      setSearchPage(nextPage);
    } else {
      setPage(nextPage);
    }

    fetchTypeOptions({
      variables: {
        input: {
          budgetId: budget.id,
          limit: LIMIT,
          page: nextPage,
          name: query,
        },
      },
    });
  };

  useEffect(() => {
    // Cache initial selected item snapshot when user opens menu
    if (open === true) {
      setTypeFiltersSnapshot(typeFiltersCart);
    }
    if (
      open === false &&
      !objectIdsMatch(typeFiltersSnapshot, typeFiltersCart)
    ) {
      setTypeFilters(typeFiltersCart);
      saveFilterState(FILTER_KEYS.TYPE, typeFiltersCart);
      fetchParentUnit({
        budgetUnitId: parentBudgetUnit?.id,
        newBudgetTypes: typeFiltersCart.map(({ id }) => id),
      });
    }
  }, [open]);

  useEffect(() => {
    fetchTypeOptions({
      variables: {
        input: {
          budgetId: budget.id,
          limit: LIMIT,
        },
      },
    });
  }, []);

  return (
    <ComboSelect
      open={open}
      setOpen={setOpen}
      itemName="Type"
      loading={loadingOptions}
      options={searching ? searchOptions : options}
      total={searching ? searchTotal : total}
      page={searching ? searchPage : page}
      handlePageScroll={handlePageScroll}
      query={query}
      onQueryChange={handleQueryChange}
      selectedOptions={typeFiltersCart}
      clearSelections={() => handleClearSelections()}
      disableClear={loadingParentUnit}
      onSelectOption={handleSelectOption}
      dataCyLabel="BudgetTypeFilter"
    />
  );
};

export default BudgetDetailTypeFilter;

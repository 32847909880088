import React from 'react';
import { createFilterOptions } from '@mui/material';

import { SelectAutocomplete } from '@atom/mui';
import { LocationDataTitle } from '@atom/types/taskLocation';

import './taskLocationModal.css';

const styles = {
  leftInput: {
    marginRight: '0.5rem',
  },
  rightInput: {
    marginLeft: '0.5rem',
  },
  centerInput: {
    margin: 0,
  },
};

const filterOptions = createFilterOptions<string>({
  matchFrom: 'any',
  limit: 250,
});

interface Props {
  property: LocationDataTitle | LocationDataTitle;
  value: string;
  options: string[];
  updateValue: (
    property: LocationDataTitle | LocationDataTitle,
    value: any,
  ) => void;
  loading: boolean;
  disabled?: boolean;
  position?: 'left' | 'right' | 'center';
  onClick: () => void;
  dataCy: string;
}

const TaskLocationSelectInput = ({
  property,
  value,
  options,
  updateValue,
  loading,
  disabled = false,
  position = 'center',
  onClick,
  dataCy,
}: Props) => {
  const style = styles[`${position}Input`];

  return (
    <SelectAutocomplete
      id={property}
      label={`${property} *`}
      options={options}
      value={value}
      onChange={(_, newValue) => updateValue(property, newValue)}
      disabled={loading || disabled}
      loading={loading}
      filterOptions={filterOptions}
      style={style}
      blurOnSelect
      TextFieldProps={{ onClick }}
      data-cy={dataCy}
    />
  );
};

export default TaskLocationSelectInput;

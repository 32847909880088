import React, { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import WorkOrderPreviewContext from '@atom/components/workOrderPreview/WorkOrderPreviewContext';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useSchemas } from '@atom/hooks/useSchemas';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import api from '@atom/utilities/api';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { WORK_ORDERS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import iconUtilities from '@atom/utilities/iconUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import './assetSection.css';

interface Props {
  assetId: string;
  taskId: string;
}

const styles = {
  icon: {
    height: '24px',
    width: '24px',
  },
  container: {
    marginRight: '0',
  },
};

const AssetTile = ({ assetId, taskId }: Props) => {
  const preferences = usePreferences();
  const navigateToAssetDetail = R.pathOr(
    false,
    ['workOrders', 'navigateToAssetDetail'],
    preferences,
  );
  const { schemas = [] } = useSchemas();

  const {
    workOrderDetail,
    refetch,
    setActiveWorkOrderAsset,
    setActiveWorkAssetTaskId,
  } = useContext(WorkOrderPreviewContext);
  const { task } = useContext(TaskContext);

  const { workValidations } = useWorkValidations();

  const [open, setOpen] = useState<boolean>(false);

  const rootSchema = useMemo(() => {
    return R.find(
      R.propEq('id', workOrderDetail.inventoryAssetSchemaId),
      schemas,
    );
  }, [workOrderDetail, schemas]);

  const removeAsset = async () => {
    setOpen(false);

    Snackbar.info({
      message: 'Deleting...',
    });

    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/tasks/${task.id}/assets/${assetId}`;

    await api.delete(endpoint);

    Snackbar.info({
      message: `Removed Asset.`,
    });

    refetch();
  };

  const asset = workOrderDetail.assets[assetId];
  const hasPendingChanges = R.pathOr(false, ['hasPendingChanges'])(asset);
  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const handleClickAsset = (): void => {
    if (navigateToAssetDetail) {
      // If this is a root asset, go to that inventory page
      // - otherwise, go to the subItems tab of the rootAncestorAsset inventory page
      if (R.isEmpty(asset.ancestors)) {
        history.push(`/inventory/${assetId}`);
      } else {
        const rootAncestorAssetId = asset.ancestors[0]?.id;
        if (rootAncestorAssetId) {
          history.push(`/inventory/${rootAncestorAssetId}?activeView=subItems`);
        }
      }
      return;
    }
    setActiveWorkOrderAsset(assetId);
    setActiveWorkAssetTaskId(taskId);
  };

  const icon = (
    <div styleName="change-indicator-container">
      {workOrderDetail.inventoryAssetId === assetId
        ? schemaUtilities.getSchemaIconFromSchemaOrAsset(rootSchema, {
            imgOffset: '0',
            iconSize: '24px',
            marginTop: '0',
          })
        : iconUtilities.getDataManagementElementIcon(null, false, styles.icon)}
      {hasPendingChanges && <div styleName="change-indicator" />}
    </div>
  );

  const actionButton = isEditDisabled ? (
    <div />
  ) : (
    <IconButton onClick={() => setOpen(true)} size="small" tooltip="Remove">
      <Icon>close</Icon>
    </IconButton>
  );

  const hasError = R.has(assetId)(
    R.pathOr(
      {},
      ['taskValidations', task.id, 'invalidAssets'],
      workValidations,
    ),
  );

  return (
    <>
      <TaskItemTile
        content={R.pathOr('', ['name'], asset)}
        onTileClick={handleClickAsset}
        icon={icon}
        actionButton={actionButton}
        containerStyles={styles.container}
        hasError={hasError}
      />
      <DeleteModal
        open={open}
        onConfirm={removeAsset}
        onCancel={() => setOpen(false)}
        title="Remove Asset From Task"
        content="Are you sure you want to remove this asset from the task?"
        cancelText="Cancel"
        confirmText="Remove"
      />
    </>
  );
};

export default AssetTile;

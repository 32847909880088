import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as fileActionCreators from '@atom/actions/fileActions';
import * as mediaActionCreators from '@atom/actions/mediaActions';
import SearchBox from '@atom/components/common/SearchBox';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
import { Icon, IconButton, Menu, Modal } from '@atom/mui';
import config from '@atom/selectors/config';
import colors from '@atom/styles/colors';
import { FileActions, MediaActions } from '@atom/types/actions';
import { ReduxStore } from '@atom/types/store';
import { addItem, removeItem } from '@atom/utilities/listViewTableUtilities';

import CreateMediaFolderModal from './CreateMediaFolderModal';
import EditMediaFolderModal from './EditMediaFolderModal';
import FolderBreadcrumbs from './FolderBreadcrumbs';
import FolderTree from './FolderTree';
import MediaTable from './MediaTable';
import MediaTotalList from './MediaTotalList';
import MoveMediaFolderModal from './MoveMediaFolderModal';

import './folders.css';

const { MenuItem } = Menu;

const styles = {
  input: {
    display: 'none',
  },
};

const initialState = {
  selectedItems: [],
  selectedType: '',
  folderPath: [],
  name: '',
  isCreateModalOpen: false,
  searchValue: '',
  expanded: false,
  breadcrumb: [],
  selectedFolder: 'root',
  moveModalOpen: false,
  isBulkDeleteModalOpen: false,
  isEditMediaModalOpen: false,
  isDeleteFolderModalOpen: false,
};

interface PassedProps {
  subjectId: string;
  subjectType: string;
  canCreateMedia: boolean;
  canDeleteMedia: boolean;
  canUpdateMedia: boolean;
  onSelectedChange: (selected: string[]) => void;
  match: any;
}

interface ReduxStateProps {
  mediaFolderTree: any;
  mediaTotals: any;
  mediaFolders: any;
  media: any;
  loadingMedia: boolean;
  loadingMediaFolders: boolean;
}

interface ReduxDispatchProps {
  mediaActions: MediaActions;
  fileActions: FileActions;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

interface State {
  selectedItems: any[];
  selectedType: string;
  folderPath: any[];
  name: string;
  isCreateModalOpen: boolean;
  searchValue: string;
  expanded: boolean;
  breadcrumb: any[];
  selectedFolder: string;
  moveModalOpen: boolean;
  isBulkDeleteModalOpen: boolean;
  isEditMediaModalOpen: boolean;
  isDeleteFolderModalOpen: boolean;
}

class Folders extends React.Component<Props, State> {
  state = initialState;

  componentDidMount() {
    const { mediaActions, subjectId } = this.props;
    this.getRootFoldersAndFiles();
    mediaActions.requestMediaTotals({
      parentSubjectIds: subjectId,
    });
    mediaActions.requestMediaFolderTree({ subjectId });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { mediaActions, subjectId } = this.props;
      this.getRootFoldersAndFiles();
      mediaActions.requestMediaTotals({
        parentSubjectIds: subjectId,
      });
      mediaActions.requestMediaFolderTree({ subjectId });
    }
  }

  fileUploadRef: HTMLInputElement;

  getRootFoldersAndFiles = () => {
    const { mediaActions, subjectId, subjectType } = this.props;
    mediaActions.retrieveMedia({
      parentSubjectIds: subjectId,
      subjectTypes: subjectType,
      folderId: 'root',
    });
    mediaActions.retrieveMediaFolders({ subjectId, parentFolderId: 'root' });
  };

  onToggle = (id: string, path: any[], expanded: boolean, name: string) => {
    const { mediaActions, subjectId, subjectType } = this.props;
    this.setState({
      ...initialState,
      folderPath: path,
      name,
      selectedFolder: id,
    });
    mediaActions.retrieveMediaFolders({
      subjectId,
      parentFolderId: id,
    });
    mediaActions.retrieveMedia({
      parentSubjectIds: subjectId,
      subjectTypes: subjectType,
      folderId: id,
    });
    mediaActions.requestMediaFolderTreeUpdate({ folderPath: path, expanded });
    this.closeSearch();
  };

  onEdit = (folderId, folderPath, name) => {
    const { mediaActions } = this.props;
    mediaActions.requestMediaFolderUpdate({ folderId, folderPath, name });
  };

  onCreate = (parentFolderId, folderPath, name) => {
    const { subjectId, mediaActions } = this.props;
    mediaActions.requestMediaFolderCreate({
      parentFolderId,
      name,
      subjectId,
      folderPath,
    });
  };

  onDelete = (folderId, path) => {
    const { mediaActions, subjectId, subjectType } = this.props;
    const { selectedFolder } = this.state;

    mediaActions.requestMediaFolderDelete({
      folderId,
      folderPath: path,
      subjectId,
      subjectType,
      selectedFolder,
    });

    this.setState({ folderPath: [], selectedFolder: 'root' });

    return true;
  };

  onMoveComplete = (id: string, destId: string, destPath: any[]) => {
    const { selectedFolder, folderPath } = this.state;
    const selectedMoved = selectedFolder === id;

    this.setState(
      {
        selectedFolder: selectedMoved ? destId : selectedFolder,
        folderPath: selectedMoved ? destPath : folderPath,
        selectedItems: [],
      },
      this.refetch,
    );
  };

  refetch = () => {
    const { selectedFolder } = this.state;
    const { mediaActions, subjectId, subjectType } = this.props;

    mediaActions.retrieveMediaFolders({
      subjectId,
      parentFolderId: selectedFolder,
    });

    mediaActions.retrieveMedia({
      parentSubjectIds: subjectId,
      subjectTypes: subjectType,
      folderId: selectedFolder,
    });

    mediaActions.requestMediaFolderTree({ subjectId });
  };

  selectMediaType = selectedType => {
    const { mediaActions, subjectId, subjectType } = this.props;
    mediaActions.retrieveMedia({
      parentSubjectIds: subjectId,
      subjectTypes: subjectType,
      type: selectedType,
    });
    this.setState({ ...initialState, selectedType, selectedFolder: '' });
    this.closeSearch();
  };

  toggleCreateModal = (isOpen: boolean) => {
    this.setState({ isCreateModalOpen: isOpen });
  };

  toggleBulkDeleteModal = (isOpen: boolean) => {
    this.setState({ isBulkDeleteModalOpen: isOpen });
  };

  toggleEditMediaFolderModal = (isOpen: boolean) => {
    this.setState({ isEditMediaModalOpen: isOpen });
  };

  toggleDeleteFolderModal = (isOpen: boolean) => {
    this.setState({ isDeleteFolderModalOpen: isOpen });
  };

  onNameChange = event => {
    const { value } = event.target;

    this.setState({ name: value });
  };

  uploadFiles = event => {
    const { fileActions, subjectId, subjectType } = this.props;
    const { selectedFolder } = this.state;
    const files = event.target.files;
    const widths = [180, 1440];

    const data = {
      files,
      widths,
      subjectId,
      subjectType,
      parentSubjectId: subjectId,
      folderId: selectedFolder,
      queryParams: {
        subjectTypes: subjectType,
        parentSubjectIds: subjectId,
        folderId: selectedFolder,
      },
    };

    fileActions.requestUploadFiles(data);
  };

  deleteMedia = id => {
    const { mediaActions, subjectId, subjectType } = this.props;
    const data = {
      id,
      subjectId,
      subjectType,
    };
    mediaActions.requestDeleteMedia(data);
    return true;
  };

  renameMedia = (id, name) => {
    const { mediaActions } = this.props;

    const data = {
      id,
      name,
    };

    return mediaActions.requestPatchMedia(data);
  };

  searchFiles = event => {
    const { expanded, searchValue, selectedType } = this.state;
    const { mediaActions, subjectId, subjectType } = this.props;
    if (event.key === 'Enter' && expanded && searchValue) {
      if (selectedType) {
        mediaActions.retrieveMedia({
          subjectTypes: subjectType,
          parentSubjectIds: subjectId,
          type: selectedType,
          name: searchValue,
        });
      } else {
        mediaActions.retrieveMedia({
          parentSubjectIds: subjectId,
          subjectTypes: subjectType,
          name: searchValue,
        });
        mediaActions.retrieveMediaFolders({ subjectId, name: searchValue });
      }
    }
  };

  onChange = event => {
    this.setState({ searchValue: event.target.value });
  };

  clearSearch = () => {
    const { mediaActions, subjectId, subjectType } = this.props;
    const { selectedType } = this.state;
    if (selectedType) {
      mediaActions.retrieveMedia({
        parentSubjectIds: subjectId,
        subjectTypes: subjectType,
        type: selectedType,
      });
    } else {
      this.getRootFoldersAndFiles();
    }
    this.setState({ searchValue: '' });
  };

  openSearch = () => {
    const { selectedType, selectedFolder } = this.state;

    if (!selectedType && selectedFolder !== 'root') {
      this.getRootFoldersAndFiles();
      this.setState({ folderPath: [], expanded: true, selectedFolder: 'root' });
    }

    this.setState({ expanded: true });
  };

  closeSearch = () => {
    this.setState({ expanded: false });
  };

  toggleCheck = (itemId, isChecked) => {
    const { onSelectedChange } = this.props;
    const cb = () => onSelectedChange(this.state.selectedItems);
    // eslint-disable-next-line
    isChecked
      ? this.setState(removeItem(itemId), cb)
      : this.setState(addItem(itemId), cb);
  };

  toggleMoveModal = () => {
    const { moveModalOpen } = this.state;
    this.setState({ moveModalOpen: !moveModalOpen });
  };

  downloadMultipleFiles = () => {
    const { fileActions, media } = this.props;
    const { selectedItems } = this.state;

    const selectedMedia = media.filter(medium =>
      selectedItems.includes(medium.id),
    );

    fileActions.requestDownloadFiles(selectedMedia);
  };

  deleteMultipleFiles = () => {
    const { mediaActions, subjectId, subjectType } = this.props;
    const { selectedItems } = this.state;

    selectedItems.forEach(id => {
      const data = {
        id,
        subjectId,
        subjectType,
      };
      mediaActions.requestDeleteMedia(data);
    });
    this.setState({ selectedItems: [] });
  };

  getHeaderButtons = () => {
    const { canCreateMedia, canDeleteMedia, canUpdateMedia } = this.props;
    const {
      folderPath,
      isCreateModalOpen,
      selectedType,
      name,
      searchValue,
      expanded,
      selectedItems,
      selectedFolder,
      isEditMediaModalOpen,
      isDeleteFolderModalOpen,
    } = this.state;

    const isCreateDisabled = folderPath.length >= config.MAX_FOLDER_DEPTH * 2;

    const bulkButtons = (
      <div styleName="button-container">
        {canUpdateMedia && (
          <IconButton onClick={this.toggleMoveModal}>
            <img src={folderMoveIcon} />
          </IconButton>
        )}
        <IconButton onClick={this.downloadMultipleFiles}>
          <Icon color={colors.neutral.gray}>file_download</Icon>
        </IconButton>
        {canDeleteMedia && (
          <>
            <IconButton onClick={() => this.toggleBulkDeleteModal(true)}>
              <Icon color={colors.neutral.gray}>delete</Icon>
            </IconButton>
            <Modal
              open={this.state.isBulkDeleteModalOpen}
              title="Delete Files"
              onCancel={() => this.toggleBulkDeleteModal(false)}
              confirmButtonText="Delete"
              onConfirm={() => {
                this.deleteMultipleFiles();
                this.toggleBulkDeleteModal(false);
              }}
              ConfirmButtonProps={{ style: { background: colors.brand.red } }}
            >
              Are you sure you want to delete these files?
            </Modal>
          </>
        )}
      </div>
    );

    const showUploadButton = !selectedType && canCreateMedia;
    const showCreateButton = !selectedType && canCreateMedia;
    const showAdditionalOptions =
      selectedFolder !== 'root' &&
      !selectedType &&
      (canDeleteMedia || canUpdateMedia);

    const headerButtons = (
      <div styleName="button-container">
        <SearchBox
          searchValue={searchValue}
          onChange={this.onChange}
          clearSearch={this.clearSearch}
          openSearch={this.openSearch}
          expanded={expanded}
          // @ts-ignore
          searchFiles={this.searchFiles}
        />
        {showCreateButton && (
          <IconButton
            onClick={() => this.toggleCreateModal(true)}
            disabled={isCreateDisabled}
          >
            <Icon color={colors.neutral.gray}>create_new_folder</Icon>
          </IconButton>
        )}
        <CreateMediaFolderModal
          folderId={selectedFolder}
          folderPath={folderPath}
          type="folder"
          createAction={(folderId, path, folderName) =>
            this.onCreate(folderId, path, folderName)
          }
          open={isCreateModalOpen}
          closeModal={() => this.toggleCreateModal(false)}
        />
        {showUploadButton && (
          <>
            <IconButton onClick={() => this.fileUploadRef.click()}>
              <Icon color={colors.neutral.gray}>file_upload</Icon>
            </IconButton>
            <input
              type="file"
              style={styles.input}
              multiple
              ref={ref => (this.fileUploadRef = ref)}
              onChange={this.uploadFiles}
            />
          </>
        )}
        {showAdditionalOptions && (
          <Menu>
            {canUpdateMedia && (
              <MenuItem
                startAdornment={<Icon>edit</Icon>}
                onClick={() => this.toggleEditMediaFolderModal(true)}
              >
                Edit
              </MenuItem>
            )}
            {canDeleteMedia && (
              <MenuItem
                startAdornment={<Icon>delete</Icon>}
                onClick={() => this.toggleDeleteFolderModal(true)}
              >
                Delete
              </MenuItem>
            )}
          </Menu>
        )}
        {canUpdateMedia && (
          <EditMediaFolderModal
            open={isEditMediaModalOpen}
            folderId={selectedFolder}
            folderPath={folderPath}
            name={name}
            type="folder"
            editAction={(folderId, path, folderName) =>
              this.onEdit(folderId, path, folderName)
            }
            closeModal={() => this.toggleEditMediaFolderModal(false)}
          />
        )}
        {canDeleteMedia && (
          <Modal
            open={isDeleteFolderModalOpen}
            title={`Delete ${name} folder`}
            onCancel={() => this.toggleDeleteFolderModal(false)}
            confirmButtonText="Delete"
            ConfirmButtonProps={{ style: { background: colors.brand.red } }}
            onConfirm={() => {
              this.onDelete(selectedFolder, folderPath);
              this.toggleDeleteFolderModal(false);
            }}
          >
            Are you sure you want to delete this folder and all the files within
            the folder?
          </Modal>
        )}
      </div>
    );

    return R.isEmpty(selectedItems) ? headerButtons : bulkButtons;
  };

  render() {
    const {
      mediaFolderTree,
      mediaTotals,
      media,
      mediaFolders,
      loadingMedia,
      loadingMediaFolders,
      canUpdateMedia,
      canDeleteMedia,
      canCreateMedia,
    } = this.props;

    const {
      selectedType,
      folderPath,
      selectedItems,
      selectedFolder,
      moveModalOpen,
    } = this.state;

    const headerButtons = this.getHeaderButtons();
    const loading = loadingMedia || loadingMediaFolders;

    return (
      <div styleName="body-container">
        <div styleName="left-body-pane-fixed">
          <div styleName="folders-container">
            <div styleName="section-header">Folders</div>
            <FolderTree
              mediaFolderTree={mediaFolderTree}
              onToggle={this.onToggle}
              selectedFolder={selectedFolder}
              onEdit={this.onEdit}
              onCreate={this.onCreate}
              onDelete={this.onDelete}
              onMoveComplete={this.onMoveComplete}
              canCreateMedia={canCreateMedia}
              canUpdateMedia={canUpdateMedia}
              canDeleteMedia={canDeleteMedia}
            />
          </div>
          <div styleName="file-categories-container">
            <div styleName="section-header">File Categories</div>
            <MediaTotalList
              selectedType={selectedType}
              mediaTotals={mediaTotals}
              selectMediaType={this.selectMediaType}
            />
          </div>
        </div>
        <div styleName="right-body-pane-fill">
          <div styleName="right-pane-header">
            <FolderBreadcrumbs
              selectedType={selectedType}
              mediaTotals={mediaTotals}
              // @ts-ignore
              onToggle={this.onToggle}
              folderPath={folderPath}
              mediaFolderTree={mediaFolderTree}
            />
            {headerButtons}
          </div>
          <MediaTable
            media={media}
            loading={loading}
            mediaFolders={mediaFolders}
            selectedType={selectedType}
            onToggle={this.onToggle}
            folderPath={folderPath}
            mediaFolderTree={mediaFolderTree}
            onEdit={this.onEdit}
            onCreate={this.onCreate}
            onDelete={this.onDelete}
            onMoveComplete={this.onMoveComplete}
            renameMedia={this.renameMedia}
            deleteMedia={this.deleteMedia}
            toggleCheck={this.toggleCheck}
            selectedItems={selectedItems}
            canUpdateMedia={canUpdateMedia}
            canDeleteMedia={canDeleteMedia}
          />
        </div>
        <MoveMediaFolderModal
          mediaFolderTree={mediaFolderTree}
          type="media"
          ids={selectedItems}
          loading={loading}
          open={moveModalOpen}
          closeModal={this.toggleMoveModal}
          onEdit={this.onEdit}
          onCreate={this.onCreate}
          onComplete={this.onMoveComplete}
          disabled={[selectedFolder]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  mediaFolderTree: state.mediaFolderTree,
  mediaTotals: state.mediaTotals,
  mediaFolders: state.mediaFolders,
  media: state.media,
  loadingMedia: state.loading.loadingMedia,
  loadingMediaFolders: state.loading.loadingMediaFolders,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  mediaActions: bindActionCreators(mediaActionCreators, dispatch),
  fileActions: bindActionCreators(fileActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Folders);

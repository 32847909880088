import { createContext, Dispatch, SetStateAction } from 'react';

import { BudgetUnitSearch, BudgetUnitTree } from '@atom/types/budget';

interface Context {
  searchResults: BudgetUnitSearch[];
  setSearchResults: Dispatch<SetStateAction<BudgetUnitSearch[]>>;
  query: string;
  setQuery: (query: string) => void;
  collapsed: Set<string>;
  setCollapsed: Dispatch<SetStateAction<Set<string>>>;
  selectedUnitIds: Set<string>;
  handleChange: (unit: BudgetUnitTree) => void;
  allUnits: BudgetUnitTree[];
  rowHoverId: string;
  setRowHoverId: Dispatch<SetStateAction<string>>;
  handleToggleDescendants: (unit: BudgetUnitTree, toggled: boolean) => void;
}

export const initialState: Context = {
  searchResults: [],
  setSearchResults: () => {},
  query: '',
  setQuery: () => {},
  collapsed: new Set(),
  setCollapsed: () => {},
  selectedUnitIds: new Set(),
  handleChange: () => {},
  allUnits: [],
  rowHoverId: null,
  setRowHoverId: () => {},
  handleToggleDescendants: () => {},
};

const BudgetingApprovalSelectionContext = createContext<Context>(initialState);

export default BudgetingApprovalSelectionContext;

import * as React from 'react';

import { TextField } from '@atom/mui';
import { EventType } from '@atom/types/event';

import '../../formInstance.css';

interface PassedProps {
  type: string;
  value: any;
  isEditable: boolean;
  onChange: (event: EventType) => void;
}

type Props = PassedProps;

const TextAttribute = ({ type, value, onChange, isEditable }: Props) => {
  return (
    <div styleName="attribute-input">
      <TextField
        variant="standard"
        name={type}
        onChange={onChange}
        value={value}
        disabled={!isEditable}
        multiline={type === 'longtext'}
      />
    </div>
  );
};

export default TextAttribute;

import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import BulkCoverPhotoModal from '@atom/components/common/bulkCoverPhotoModal/BulkCoverPhotoModal';
import CreateWorkForCategoryModal from '@atom/components/common/createWorkModals/CreateWorkForCategoryModal';
import DeleteModal from '@atom/components/common/DeleteModal';
import NewWorkOrderIcon from '@atom/components/common/svgIcons/NewWorkOrderIcon';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import textConstants from '@atom/constants/textConstants';
import { Icon, Menu, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryCategoryTree } from '@atom/types/inventory';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { CATEGORIES_ENDPOINT } from '@atom/utilities/endpoints';

import CreateFolderModal from '../CreateFolderModal';
import EditFolderModal from '../EditFolderModal';
import InventoryPortalContext from '../InventoryPortalContext';

import './inventoryCategoryTree.css';

const { MenuItem } = Menu;

interface Props {
  category: InventoryCategoryTree;
  path: any[];
  isRootFolder?: boolean;
}

const errorStatusMap = {
  422: 'Folders containing subfolders cannot be deleted.',
  423: 'Folders that are used in Work cannot be deleted.',
  424: 'Folders containing inventory items that are used in Work cannot be deleted.',
};

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

const InventoryCategoryTreeOptions = ({
  category,
  isRootFolder,
  path,
}: Props) => {
  const {
    refetchCategoryFolderTree,
    activeCategory,
    setActiveCategory,
  } = useContext(InventoryPortalContext);

  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [workModalOpen, setWorkModalOpen] = useState<boolean>(false);
  const [coverPhotoOpen, setCoverPhotoOpen] = useState<boolean>(false);

  const handleDelete = async () => {
    const { name } = category;

    try {
      setLoadingDelete(true);
      setDeleteModalOpen(false);

      Snackbar.info({
        message: textConstants.GENERIC_APPLICATION_DELETING_TEXT,
      });

      const endpoint = `${CATEGORIES_ENDPOINT}/${category.id}`;

      await api.delete(endpoint);
      await refetchCategoryFolderTree();

      Snackbar.info({
        message: `Deleted folder ${name}.`,
      });

      setActiveCategory({ id: 'inventory', name: 'Inventory' }, []);
    } catch (error) {
      const message =
        errorStatusMap[error?.response?.status] ||
        'An unknown error occurred. Please try again.';

      Snackbar.error({ message });
    }

    setLoadingDelete(false);
  };

  const handleCreate = async (name: string, schemaId?: string) => {
    setLoadingCreate(true);

    const { data } = await api.post(CATEGORIES_ENDPOINT, {
      name,
      parentCategoryId: category.id,
      ...(schemaId && { schemaId }),
    });

    const tree = await refetchCategoryFolderTree();

    Snackbar.info({
      message: `Created folder ${data.name}.`,
      action: 'View',
      onActionClick: () => {
        // current path based on left pane
        const categories = R.pathOr([], [...path, 'categories'], tree);

        // checks if selected category id is from the main pane and returns index
        const mainPaneIndex = categories.findIndex(
          row => row.id === category.id,
        );

        // updates categories if selected category is on main pane
        const newCategories =
          mainPaneIndex > -1
            ? R.pathOr(
                [],
                [...path, 'categories', mainPaneIndex, 'categories'],
                tree,
              )
            : categories;

        const index = newCategories.findIndex(child => child.id === data.id);

        // updates path if selected category is on main pane
        const newPath =
          mainPaneIndex > -1
            ? [...path, 'categories', mainPaneIndex, 'categories', index]
            : [...path, 'categories', index];

        if (index !== -1) {
          setActiveCategory(newCategories[index], newPath);
        }
      },
    });

    setLoadingCreate(false);
    setCreateModalOpen(false);
  };

  const handleEdit = async (updatedName: string, schemaId?: string) => {
    const { name } = category;
    const isNameChanged = updatedName !== name;

    if (!schemaId && !isNameChanged) {
      setEditModalOpen(false);
      return;
    }

    setLoadingEdit(true);
    const endpoint = `${CATEGORIES_ENDPOINT}/${category.id}`;

    const { data } = await api.patch(endpoint, {
      name: updatedName,
      ...(schemaId && { schemaId }),
    });

    await refetchCategoryFolderTree();

    const message = schemaId
      ? `Updated ${
          isNameChanged ? 'folder name and data type' : 'data type'
        } for ${name}.`
      : `Renamed folder from ${name} to ${data.name}.`;

    Snackbar.info({ message });

    setLoadingEdit(false);
    setActiveCategory(activeCategory, path);
    setEditModalOpen(false);
  };

  const isEditable =
    !category.hasAssets &&
    // @ts-ignore
    !category.hasItems &&
    (!category.categories || R.isEmpty(category.categories));

  const showBulkCreateWork =
    !isRootFolder && hasRolePermissions(ROLE_SETS.INSPECTOR);
  const showAssignCoverPhoto = !isRootFolder && !R.isNil(category.schemaId);
  const showEdit = !isRootFolder;
  const showCreateFolder = R.isNil(category.schemaId);
  const showDelete = hasRolePermissions(ROLE_SETS.ORG_ADMIN) && !isRootFolder;

  return (
    <>
      <Menu>
        {showBulkCreateWork && (
          <MenuItem onClick={() => setWorkModalOpen(true)}>
            <Icon style={{ marginLeft: '3px', marginRight: '5px' }}>
              <NewWorkOrderIcon color={colors.neutral.gray} />
            </Icon>
            {`Create Work For ${category.name}`}
          </MenuItem>
        )}
        {showAssignCoverPhoto && (
          <MenuItem onClick={() => setCoverPhotoOpen(true)}>
            <Icon style={styles.icon}>add_photo_alternate</Icon>
            Assign Cover Photo
          </MenuItem>
        )}
        {showEdit && (
          <MenuItem onClick={() => setEditModalOpen(true)}>
            <Icon style={styles.icon}>
              <img src={renameIcon} />
            </Icon>
            {isEditable ? 'Edit' : 'Rename'}
          </MenuItem>
        )}
        {showCreateFolder && (
          <MenuItem onClick={() => setCreateModalOpen(true)}>
            <Icon style={styles.icon}>create_new_folder</Icon>
            Create Folder
          </MenuItem>
        )}
        {showDelete && (
          <MenuItem onClick={() => setDeleteModalOpen(true)}>
            <Icon style={styles.icon}>delete</Icon>
            Delete
          </MenuItem>
        )}
      </Menu>
      <DeleteModal
        open={deleteModalOpen}
        loading={loadingDelete}
        title={`Delete ${category.name} folder?`}
        content="Are you sure you want to delete this folder? Any folders and assets connected below the folder you are deleting will also be deleted."
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <CreateFolderModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreate}
        loading={loadingCreate}
      />
      <EditFolderModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleEdit}
        loading={loadingEdit}
        category={category}
      />
      <CreateWorkForCategoryModal
        open={workModalOpen}
        onClose={() => setWorkModalOpen(false)}
        category={category}
      />
      <BulkCoverPhotoModal
        open={coverPhotoOpen}
        onClose={() => setCoverPhotoOpen(false)}
        categoryIds={[category.id]}
      />
    </>
  );
};

export default InventoryCategoryTreeOptions;

import React, { useContext } from 'react';

import { TYPE_LABELS } from '@atom/components/common/requests/assetRequestConstants';
import { AssetRequestStatus } from '@atom/types/assetRequest';
import { getDisplayDateTime } from '@atom/utilities/timeUtilities';

import ViewRequestContext from '../ViewRequestContext';

import ApprovalControls from './ApprovalControls';
import ApprovalDisplay from './ApprovalDisplay';

import './sddotView.css';

const SDDOTInventoryView = () => {
  const { activeRequest } = useContext(ViewRequestContext);

  const assignedToText = activeRequest?.assignedTo
    .map(user => `${user.firstName} ${user.lastName}`)
    .join(', ');

  const createdByText = `Created by ${activeRequest?.createdBy?.firstName} ${
    activeRequest?.createdBy?.lastName
  } on ${getDisplayDateTime(activeRequest?.createdDate)}`;

  const showApprovalControls =
    activeRequest.status === AssetRequestStatus.PENDING;

  return (
    <div>
      <div styleName="view-row">
        <div styleName="row-title">Request Type</div>
        <div>{TYPE_LABELS[activeRequest?.type]}</div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">Transfer From</div>
        <div>{activeRequest?.fromAssetName || ''}</div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">Transfer To</div>
        <div>{activeRequest?.assetName || ''}</div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">{`Requested Quantity (${
          activeRequest?.assetUnit || ''
        })`}</div>
        <div>{activeRequest?.quantityOrdered}</div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">Assigned To</div>
        <div>{assignedToText}</div>
      </div>
      <div styleName="created-subtext">{createdByText}</div>
      {showApprovalControls ? <ApprovalControls /> : <ApprovalDisplay />}
    </div>
  );
};

export default SDDOTInventoryView;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

// @ts-ignore
import addGroupIcon from '@atom/components/common/svgIcons/add_group.svg';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ELEMENT_CREATE, ELEMENT_GROUP_CREATE } from '@atom/graph/schema';
import { IconButton, List, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  ElementCreateInput,
  ElementGroupCreateInput,
  SchemaTree,
} from '@atom/types/schema';
import { getSchemaIconFromSchemaOrAsset } from '@atom/utilities/schemaUtilities';

import AddItemModal from '../AddItemModal';

import ListItemWrapper from './ListItemWrapper';

import './subItemTree.css';

const { ListItemText } = List;

const RootRow = () => {
  const container = useRef<HTMLDivElement>();

  const {
    schemaTree,
    selectedSubItem,
    refetchSchemaTree,
    setSelectedSubItem,
    setSelectedSubItemPath,
    setSelectedAttribute,
    setSelectedAttributeGroup,
    setSelectedAttributeRoute,
  } = useContext(SchemaDetailContext);

  const [openGroupCreate, setOpenGroupCreate] = useState<boolean>(false);
  const [openSubItemCreate, setOpenSubItemCreate] = useState<boolean>(false);
  const [overflowing, setOverflowing] = useState<boolean>(false);

  const [createElementGroup] = useMutation<
    { elementGroupCreate: SchemaTree },
    { input: ElementGroupCreateInput }
  >(ELEMENT_GROUP_CREATE);

  const [createElement] = useMutation<
    { elementCreate: SchemaTree },
    { input: ElementCreateInput }
  >(ELEMENT_CREATE);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [schemaTree.name]);

  const handleListItemClick = () => {
    setSelectedSubItemPath([]);
    setSelectedSubItem(schemaTree);
    setSelectedAttribute(null);
    setSelectedAttributeGroup(null);
    setSelectedAttributeRoute(null);
  };

  const handleGroupCreate = async (name: string) => {
    const SUB_ITEM_NAME = 'Untitled Sub Item';

    await createElementGroup({
      variables: {
        input: {
          rootSchemaId: schemaTree?.id,
          parentSchemaId: schemaTree?.id,
          assetType: SUB_ITEM_NAME,
          name: SUB_ITEM_NAME,
          group: name,
        },
      },
    });

    await refetchSchemaTree();
    setOpenGroupCreate(false);
  };

  const handleSubItemCreate = async (name: string) => {
    await createElement({
      variables: {
        input: {
          rootSchemaId: schemaTree?.id,
          parentSchemaId: schemaTree?.id,
          assetType: name,
          name,
        },
      },
    });

    await refetchSchemaTree();
    setOpenSubItemCreate(false);
  };

  const showActionButtons = !schemaTree?.isPublished;
  const selected = selectedSubItem?.id === schemaTree?.id;

  const icon = getSchemaIconFromSchemaOrAsset(schemaTree, {
    imgOffset: '0',
    iconSize: '34px',
    marginTop: '0',
  });

  const textStyles = {
    fontWeight: '400',
    fontSize: fonts.md,
    color: selected ? colors.brand.blue : colors.neutral.dark,
  };

  const textContent = (
    <div styleName="sub-item-name root" ref={container}>
      {!overflowing ? (
        schemaTree.name
      ) : (
        <Tooltip title={schemaTree.name} placement="bottom-end">
          <p styleName="sub-item-overflow-container">{schemaTree.name}</p>
        </Tooltip>
      )}
    </div>
  );

  return (
    <>
      <ListItemWrapper
        onClick={handleListItemClick}
        selected={selected}
        hasChildren
      >
        <>
          <div styleName="schema-icon">{icon}</div>
          <ListItemText primary={textContent} primaryTextStyle={textStyles} />
          {showActionButtons && (
            <div styleName="root-button-container">
              <IconButton
                tooltip="Add Group"
                onClick={() => setOpenGroupCreate(true)}
                size="small"
              >
                <img src={addGroupIcon} />
              </IconButton>
              <IconButton
                tooltip="Add Sub Item"
                onClick={() => setOpenSubItemCreate(true)}
                size="small"
              >
                <img src={addItemIcon} />
              </IconButton>
            </div>
          )}
        </>
      </ListItemWrapper>
      <AddItemModal
        open={openGroupCreate}
        closeModal={() => setOpenGroupCreate(false)}
        type="Group"
        handleSave={handleGroupCreate}
      />
      <AddItemModal
        open={openSubItemCreate}
        closeModal={() => setOpenSubItemCreate(false)}
        type="Sub Item"
        handleSave={handleSubItemCreate}
      />
    </>
  );
};

export default RootRow;

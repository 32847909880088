import React from 'react';
import { SortDirection } from '@mui/material';

import { Budget, BudgetModal } from '@atom/types/budget';

export const INITIAL_PAGE: number = 1;
export const INITIAL_LIMIT: number = 25;
export const INITIAL_SORT_DIRECTION: SortDirection = 'desc';
export const INITIAL_SORT_BY: string = null;

interface Context {
  refetch: () => void;
  budgets: Budget[];
  totalCount: number;
  page: number;
  setPage: (page: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  setActiveModal: (modal: BudgetModal) => void;
  setActiveFormBudget: (budget: Budget) => void;
}

export const initialState: Context = {
  refetch: () => {},
  budgets: [],
  totalCount: null,
  page: INITIAL_PAGE,
  setPage: () => {},
  limit: INITIAL_LIMIT,
  setLimit: () => {},
  sortDirection: INITIAL_SORT_DIRECTION,
  setSortDirection: () => {},
  sortBy: INITIAL_SORT_BY,
  setSortBy: () => {},
  setActiveModal: () => {},
  setActiveFormBudget: null,
};

const BudgetsContext = React.createContext<Context>(initialState);

export default BudgetsContext;

import React, { useMemo, useState } from 'react';

import BulkCoverPhotoModal from '@atom/components/common/bulkCoverPhotoModal/BulkCoverPhotoModal';
import CreateAssetModal from '@atom/components/common/createAssetModal/CreateAssetModal';
import CreateWorkForAssetsModal from '@atom/components/common/createWorkModals/CreateWorkForAssetsModal';
// @ts-ignore
import dataExportIcon from '@atom/components/common/svgIcons/dataExportIcon.svg';
// @ts-ignore
import dataManagementIcon from '@atom/components/common/svgIcons/dataManagementIcon.svg';
// @ts-ignore
import fileUploadIcon from '@atom/components/common/svgIcons/fileUploadIcon.svg';
import InventoryUpload from '@atom/components/inventoryUpload/InventoryUpload';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  getCategoriesByIds,
  getCategoryDescendantSchemaIds,
} from '@atom/utilities/categoryUtilities';
// TODO: Remove UDOT specific code and move to RBAC
import {
  Environment,
  isCurrentEnvironment,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import NewWorkOrderIcon from '../common/svgIcons/NewWorkOrderIcon';

import InventoryBreadcrumbs from './InventoryBreadcrumbs';
import { CSVDownloadEndpoint } from './InventoryPortal';

import '../../styles/detail-header.css';

const DOWNLOAD_THRESHOLD = 50000;

const style = {
  open: {
    color: colors.neutral.white,
    backgroundColor: colors.opacity.black0_2,
    borderRadius: '100%',
  },
};

interface Props {
  filterState?: any;
  isFilterPaneOpen: boolean;
  isAssetsDownloadPossible: boolean;
  toggleFilterPane: () => void;
  downloadEndpoint: CSVDownloadEndpoint;
  inventoryTotal: number;
  downloadDisabled: boolean;
  isFilterApplied: boolean;
  createBulkWorkFromFilter: () => void;
  showNavButtons: boolean;
}

const navigateToAdminInventoryManagement = filterState => {
  history.push('/admin/inventoryTypes', filterState);
};

const navigateDataExport = filterState => {
  history.push('/inventory/export', filterState);
};

const navigateAssetRequests = filterState => {
  history.push('/inventory-requests', filterState);
};

const InventoryHeader = ({
  filterState,
  isFilterPaneOpen,
  isAssetsDownloadPossible,
  toggleFilterPane,
  downloadEndpoint,
  inventoryTotal,
  downloadDisabled,
  isFilterApplied,
  createBulkWorkFromFilter,
  showNavButtons,
}: Props) => {
  const {
    categoryIdsFilters,
    categoryTree,
    schemaId: filterSchemaId,
    activeCategory,
  } = filterState;

  const [workModalOpen, setWorkModalOpen] = useState<boolean>(false);
  const [assetModalOpen, setAssetModalOpen] = useState<boolean>(false);
  const [coverPhotoOpen, setCoverPhotoOpen] = useState<boolean>(false);
  const [downloadingCsv, setDownloadingCsv] = useState<boolean>(false);

  const getDownloadTooltip = () => {
    if (downloadDisabled) {
      return 'Apply filter to download';
    } else if (inventoryTotal >= DOWNLOAD_THRESHOLD) {
      return 'Filter below 25K assets to download';
    }
    return 'Download CSV';
  };

  const getDownloadEndpoint = (): CSVDownloadEndpoint => {
    return inventoryTotal <= DOWNLOAD_THRESHOLD && !downloadDisabled
      ? downloadEndpoint
      : {};
  };

  const tooltip = getDownloadTooltip();
  const csvEndpoint = getDownloadEndpoint();

  const categorySchemaId = useMemo(() => {
    const descendantSchemaIds = getCategoryDescendantSchemaIds(
      getCategoriesByIds(categoryIdsFilters || [], [categoryTree]),
    );

    return new Set(descendantSchemaIds).size === 1
      ? descendantSchemaIds[0]
      : null;
  }, [categoryIdsFilters]);

  // schemaId to filter work templates by
  // if null only work templates without schema will be enabled
  const schemaId = useMemo(() => {
    if (categoryIdsFilters?.length && !categorySchemaId) {
      return null;
    }
    if (filterSchemaId && categorySchemaId) {
      return filterSchemaId === categorySchemaId ? filterSchemaId : null;
    }
    return filterSchemaId || categorySchemaId;
  }, [filterSchemaId, categorySchemaId, categoryIdsFilters]);

  const showCreateWork =
    hasRolePermissions(ROLE_SETS.INSPECTOR) &&
    isFilterApplied &&
    isFilterPaneOpen &&
    inventoryTotal > 0;
  const showAssignCoverPhoto =
    isFilterApplied && isFilterPaneOpen && inventoryTotal > 0;
  const showAssetDownload =
    hasRolePermissions(ROLE_SETS.INSPECTOR) && isAssetsDownloadPossible;
  const showAddAsset = isCurrentTenant([Tenant.UDOT])
    ? hasRolePermissions(ROLE_SETS.INSPECTOR)
    : hasRolePermissions(ROLE_SETS.MANAGER);
  const showCVSUpload = hasRolePermissions(ROLE_SETS.MANAGER);
  const showDataManagement = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  // TODO: [AM-16021]: Update request button view rules
  // const showRequestsButton = isCurrentClient([Client.SDDOT]);
  const showRequestsButton = isCurrentEnvironment([
    Environment.DEV,
    Environment.QA,
  ]);

  const handleDownloadClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!downloadDisabled && !downloadingCsv) {
      setDownloadingCsv(true);
      const { data, headers } = await api.post(
        csvEndpoint.endpoint,
        csvEndpoint.body,
      );

      const fileNameSplit = headers['content-disposition'].split('filename=');
      const fileName = fileNameSplit[1] ? fileNameSplit[1] : 'assets.csv';

      // convert downloaded data to a Blob
      const blob = new Blob([data], { type: 'text/csv' });

      // create a download anchor tag
      const downloadLink = document.createElement('a');
      downloadLink.target = '_self';
      downloadLink.download = fileName;

      // create an object URL from the Blob
      const downloadUrl = URL.createObjectURL(blob);
      downloadLink.href = downloadUrl;
      document.body.append(downloadLink);
      downloadLink.click();

      // cleanup
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
      setDownloadingCsv(false);
    }
  };

  const currentLeafCategory = isNilOrEmpty(activeCategory?.categories)
    ? activeCategory
    : null;

  return (
    <div styleName="header-container inventory-header">
      <div styleName="name-container inventory-name-container">
        <InventoryBreadcrumbs />
      </div>
      {showNavButtons && (
        <div styleName="button-container inventory-button-container">
          {showCreateWork && (
            <>
              <IconButton
                tooltip="Create Work"
                onClick={() => setWorkModalOpen(true)}
                data-cy="inventoryHeaderCreateWork"
              >
                <NewWorkOrderIcon color={colors.neutral.white} />
              </IconButton>
              <CreateWorkForAssetsModal
                open={workModalOpen}
                onClose={() => setWorkModalOpen(false)}
                onConfirm={createBulkWorkFromFilter}
                schemaId={schemaId}
                count={inventoryTotal}
              />
            </>
          )}
          {showAssignCoverPhoto && (
            <>
              <IconButton
                tooltip="Assign cover photo"
                onClick={() => setCoverPhotoOpen(true)}
                data-cy="inventoryAssignCoverPhoto"
              >
                <Icon color={colors.neutral.white}>add_photo_alternate</Icon>
              </IconButton>
              <BulkCoverPhotoModal
                open={coverPhotoOpen}
                onClose={() => setCoverPhotoOpen(false)}
                schemaId={filterState.schemaId}
                categoryIds={filterState.categoryIdsFilters}
                hasPendingChangesOnly={filterState.hasPendingChangesOnly}
                attributeFilters={filterState.assetFilters}
              />
            </>
          )}
          {showAssetDownload && (
            <IconButton
              disabled={downloadingCsv}
              tooltip={tooltip}
              onClick={handleDownloadClick}
            >
              <Icon color={colors.neutral.white}>file_download</Icon>
            </IconButton>
          )}
          {showAddAsset && (
            <>
              <IconButton
                tooltip="Add Asset"
                onClick={() => setAssetModalOpen(true)}
                data-cy="inventoryHeaderAddAssetButton"
              >
                <Icon color={colors.neutral.white}>add</Icon>
              </IconButton>
              <CreateAssetModal
                open={assetModalOpen}
                onClose={() => setAssetModalOpen(false)}
                initialCategory={currentLeafCategory}
              />
            </>
          )}
          {showRequestsButton && (
            <>
              <IconButton
                tooltip="Inventory Requests"
                onClick={() => navigateAssetRequests(filterState)}
                data-cy="inventoryHeaderRequestsButton"
              >
                <Icon color={colors.neutral.white}>description</Icon>
              </IconButton>
            </>
          )}
          {showCVSUpload && (
            <>
              <IconButton
                tooltip="Upload CSV"
                onClick={InventoryUpload.open}
                data-cy="inventoryHeaderUploadCSVButton"
              >
                <Icon color={colors.neutral.white}>
                  <img src={fileUploadIcon} />
                </Icon>
              </IconButton>
            </>
          )}
          <IconButton
            onClick={toggleFilterPane}
            style={isFilterPaneOpen ? style.open : {}}
            tooltip="Filter"
            data-cy="inventoryHeaderFilterButton"
          >
            <Icon color={colors.neutral.white}>tune</Icon>
          </IconButton>
          <IconButton
            onClick={() => navigateDataExport(filterState)}
            tooltip="Data Export"
            children={<img src={dataExportIcon} />}
            data-cy="inventoryHeaderExportButton"
          />
          {showDataManagement && (
            <IconButton
              onClick={() => navigateToAdminInventoryManagement(filterState)}
              tooltip="Inventory Types"
              children={<img src={dataManagementIcon} />}
              data-cy="inventoryHeaderDataManagementButton"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InventoryHeader;

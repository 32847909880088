import * as React from 'react';

import TextLimit from '@atom/components/common/TextLimit';
import { TextField } from '@atom/mui';
import {
  MAX_CHAR_DESCRIPTION,
  MAX_CHAR_SHORT_INPUT,
  MAX_CHAR_UNIT,
  REVEAL_LONG,
} from '@atom/selectors/formSelectors';

import '../formBuilder.css';

interface Props {
  title: string;
  description: string;
  unit: string;
  onChange: (event: Object) => void;
}

const NumericInput = ({ title, description, unit, onChange }: Props) => {
  return (
    <div styleName="multi-input-container">
      <div styleName="numeric-title">
        <TextField
          value={title}
          onChange={onChange}
          name="title"
          label="Title"
        />
        <div styleName="text-limit-container">
          <TextLimit
            limit={MAX_CHAR_SHORT_INPUT}
            reveal={REVEAL_LONG}
            text={title}
          />
        </div>
      </div>
      <div styleName="numeric-unit">
        <TextField value={unit} onChange={onChange} name="unit" label="Unit" />
        <div styleName="text-limit-container">
          <TextLimit limit={MAX_CHAR_UNIT} reveal={REVEAL_LONG} text={unit} />
        </div>
      </div>
      <div styleName="numeric-description">
        <TextField
          value={description}
          onChange={onChange}
          name="description"
          label="Description"
        />
        <div styleName="text-limit-container">
          <TextLimit
            limit={MAX_CHAR_DESCRIPTION}
            reveal={REVEAL_LONG}
            text={description}
          />
        </div>
      </div>
    </div>
  );
};

export default NumericInput;

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  INITIAL_IS_ASCENDING,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT_BY,
} from '@atom/components/common/requests/assetRequestConstants';
import {
  cleanFilters,
  getCustomValues,
  getTypeChangeFilters,
} from '@atom/components/common/requests/customTenantUtilities';
import ViewRequestModal from '@atom/components/common/requests/viewRequest/ViewRequestModal';
import { GET_ASSET_REQUESTS } from '@atom/graph/assetRequest';
import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetRequestsConnection,
  AssetRequestsConnectionInput,
  AssetRequestsFilters,
  AssetRequestType,
} from '@atom/types/assetRequest';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RequestsFilters from './requestsFilters/RequestsFilters';
import RequestsTable from './requestsTable/RequestsTable';
import RequestsContext from './RequestsContext';

import './requests.css';

const RequestsPortal = () => {
  const { initialFilters } = getCustomValues();

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [sortBy, setSortBy] = useState<string>(INITIAL_SORT_BY);
  const [isAscending, setIsAscending] = useState<boolean>(INITIAL_IS_ASCENDING);
  const [filters, setFilters] = useState<AssetRequestsFilters>({});
  const [filtersCart, setFiltersCart] = useState<AssetRequestsFilters>({});
  const [activeRequestId, setActiveRequestId] = useState<string>();

  const resetInitialValues = () => {
    setPage(INITIAL_PAGE);
    setLimit(INITIAL_LIMIT);
    setSortBy(INITIAL_SORT_BY);
    setIsAscending(INITIAL_IS_ASCENDING);
  };

  const resetFilters = () => {
    resetInitialValues();
    setFilters(initialFilters);
    setFiltersCart(initialFilters);
  };

  useEffect(() => {
    if (
      isNilOrEmpty(filters) &&
      isNilOrEmpty(filtersCart) &&
      !isNilOrEmpty(initialFilters)
    ) {
      resetFilters();
    }
  }, [filters, filtersCart, initialFilters]);

  const {
    data,
    loading: assetRequestsLoading,
    refetch: refetchAssetRequests,
  } = useQuery<
    { assetRequests: AssetRequestsConnection },
    { input: AssetRequestsConnectionInput }
  >(GET_ASSET_REQUESTS, {
    variables: {
      input: {
        page,
        limit,
        sortBy,
        isAscending,
        ...(R.pathOr([], ['schemaIds'], filters)[0] !== '' && {
          schemaIds: filters.schemaIds,
        }),
        ...R.omit(['schemaIds'], filters),
      },
    },
    fetchPolicy: 'network-only',
  });

  const navigateBack = (): void => {
    const state = history.location.state;
    return history.push('/inventory', state);
  };

  // When type is changed, filters should be automatically applied.
  const changeType = (type: AssetRequestType) => {
    const updatedFilter = getTypeChangeFilters(type);

    // Handles refetching if a new request is created without changing types.
    if (type === filters?.type) {
      refetchAssetRequests();
    }

    setFiltersCart(updatedFilter);
    setFilters(cleanFilters(updatedFilter));
  };

  const applyFilters = () => {
    resetInitialValues();
    setFilters(cleanFilters(filtersCart));
  };

  const totalCount = R.pathOr(0, ['assetRequests', 'totalCount'], data);
  const requests = R.pathOr([], ['assetRequests', 'assetRequests'], data);

  return (
    <RequestsContext.Provider
      value={{
        requests,
        totalCount,
        filters,
        filtersCart,
        setFiltersCart,
        page,
        setPage,
        limit,
        setLimit,
        sortBy,
        setSortBy,
        isAscending,
        setIsAscending,
        changeType,
        setActiveRequestId,
      }}
    >
      <>
        <div styleName="header-container">
          <div styleName="header-buttons">
            <IconButton>
              <Icon color={colors.neutral.white} onClick={navigateBack}>
                arrow_back
              </Icon>
            </IconButton>
            <div>Inventory Requests</div>
          </div>
        </div>
        <div styleName="body-container">
          <div styleName="left-body-pane-fixed">
            <RequestsFilters
              applyFilters={applyFilters}
              resetFilters={resetFilters}
            />
          </div>
          <div styleName="right-body-pane-fill">
            {assetRequestsLoading ? (
              <div styleName="progress-container">
                <Progress />
              </div>
            ) : (
              <RequestsTable />
            )}
          </div>
        </div>
        <ViewRequestModal
          activeRequestId={activeRequestId}
          setActiveRequestId={setActiveRequestId}
        />
      </>
    </RequestsContext.Provider>
  );
};

export default RequestsPortal;

import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import { CREATE_REPLENISHMENT } from '@atom/graph/assetRequest';
import {
  Replenishment,
  ReplenishmentCreateInput,
} from '@atom/types/assetRequest';

import ViewRequestContext from '../ViewRequestContext';

import ReplenishmentForm from './ReplenishmentForm';

import './sddotView.css';

interface Props {
  onCancel: () => void;
}

const AddReplenishmentView = ({ onCancel }: Props) => {
  const { activeRequest, handleGetActiveRequest } = useContext(
    ViewRequestContext,
  );

  const [inputs, setInputs] = useState<ReplenishmentCreateInput>();

  const [createReplenishment] = useMutation<
    { replenishmentCreate: Replenishment },
    { input: ReplenishmentCreateInput }
  >(CREATE_REPLENISHMENT);

  const updateInput = (
    value: any,
    property: keyof ReplenishmentCreateInput,
  ) => {
    setInputs(prev => ({ ...prev, [property]: value }));
  };

  const handleReset = () => {
    setInputs(null);
    onCancel();
  };

  const onCreate = async () => {
    await createReplenishment({
      variables: {
        input: {
          id: activeRequest.id,
          ...inputs,
        },
      },
    });

    handleGetActiveRequest();
    handleReset();
  };

  const totalQuantityReceived = activeRequest?.replenishments?.reduce(
    (acc, replenishment) => {
      return acc + Number(replenishment.quantityReceived || 0);
    },
    0,
  );

  return (
    <ReplenishmentForm
      assetUnit={activeRequest?.assetUnit}
      totalOrdered={activeRequest?.quantityOrdered}
      totalReceived={totalQuantityReceived}
      replenishmentInputs={inputs}
      updateReplenishmentInput={updateInput}
      onCreate={onCreate}
      onCancel={handleReset}
    />
  );
};

export default AddReplenishmentView;

import React, { useContext } from 'react';

import { TYPE_LABELS } from '@atom/components/common/requests/assetRequestConstants';
import { getDisplayDateTime } from '@atom/utilities/timeUtilities';

import ViewRequestContext from '../ViewRequestContext';

import './sddotView.css';

const SDDOTStockpileView = () => {
  const { activeRequest } = useContext(ViewRequestContext);

  const createdByText = `Created by ${activeRequest?.createdBy?.firstName} ${
    activeRequest?.createdBy?.lastName
  } on ${getDisplayDateTime(activeRequest?.createdDate)}`;

  return (
    <div>
      <div styleName="view-row">
        <div styleName="row-title">Request Type</div>
        <div>{TYPE_LABELS[activeRequest?.type]}</div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">Transfer From</div>
        <div>{activeRequest?.fromAssetName || ''}</div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">Transfer To</div>
        <div>{activeRequest?.assetName || ''}</div>
      </div>
      <div styleName="view-row">
        <div styleName="row-title">{`Requested Quantity (${
          activeRequest?.assetUnit || ''
        })`}</div>
        <div>{activeRequest?.quantity}</div>
      </div>
      <div styleName="created-subtext">{createdByText}</div>
    </div>
  );
};

export default SDDOTStockpileView;

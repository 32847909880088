import React, { useMemo } from 'react';

import { ListTable } from '@atom/mui';
import {
  AssetRequest,
  AssetRequestsFilters,
  AssetRequestStatus,
  Column,
  ColumnType,
} from '@atom/types/assetRequest';
import { setDisplayDate } from '@atom/utilities/timeUtilities';

import { getTableColumns } from '../customTenantUtilities';

import RequestStatusPill from './RequestStatusPill';

import './requestsTable.css';

const { TableRow, TableCell } = ListTable;

const styles = {
  row: {
    height: '3.125rem',
  },
};

interface Props {
  request: AssetRequest;
  filters: AssetRequestsFilters;
  setActiveRequestId: (id: string) => void;
  assetId?: string;
  isPortalView?: boolean;
}

const RequestsTableRow = ({
  request,
  filters,
  assetId,
  setActiveRequestId,
  isPortalView = false,
}: Props) => {
  const columns = getTableColumns(filters.type, isPortalView);

  const quantityReceived = useMemo(() => {
    return request.replenishments.reduce((acc, replenishment) => {
      return acc + replenishment.quantityReceived;
    }, 0);
  }, [request, filters]);

  const assignedToDisplay = useMemo(() => {
    return request.assignedTo
      .reduce((acc, user) => {
        return [...acc, `${user.firstName} ${user.lastName}`];
      }, [])
      .join(', ');
  }, [request, filters]);

  const getTableCell = (column: Column) => {
    const cellContent = {
      [ColumnType.NAME]: (
        <div
          styleName="name-column"
          onClick={() => setActiveRequestId(request?.id)}
        >
          {request?.name}
        </div>
      ),
      [ColumnType.ASSET_NAME]: request?.assetName || '',
      [ColumnType.FROM_ASSET_NAME]: request?.fromAssetName || '',
      [ColumnType.STATUS]: <RequestStatusPill status={request.status} />,
      [ColumnType.DESCRIPTION]:
        request.assetId === assetId ? 'Outgoing' : 'Incoming',
      [ColumnType.QUANTITY]: `${request?.quantity || 0} ${request?.assetUnit}`,
      [ColumnType.ASSIGNED_TO]: assignedToDisplay,
      [ColumnType.CREATED_BY]: `${request.createdBy.firstName} ${request.createdBy.lastName}`,
      [ColumnType.CREATED_DATE]: setDisplayDate(request?.createdDate),
      [ColumnType.UPDATED_DATE]:
        request?.status === AssetRequestStatus.PENDING
          ? '-'
          : setDisplayDate(request?.updatedDate),
      [ColumnType.REPLENISHMENTS]: `${request?.replenishments.length || 0}`,
      [ColumnType.QUANTITY_ORDERED]: `${request?.quantityOrdered || 0} ${
        request?.assetUnit
      }`,
      [ColumnType.QUANTITY_RECEIVED]: `${quantityReceived} ${request?.assetUnit}`,
    };

    const content = cellContent[column.type] || <div />;
    return <TableCell key={column.type}>{content}</TableCell>;
  };

  return (
    <TableRow style={styles.row}>
      {columns.map(column => getTableCell(column))}
    </TableRow>
  );
};

export default RequestsTableRow;

import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as formTemplateActionCreators from '@atom/actions/formTemplateActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import {
  Icon,
  IconButton,
  Modal,
  Progress,
  Select,
  TextField,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  FormTemplateActions,
  InventorySchemaActions,
} from '@atom/types/actions';
import { EventType } from '@atom/types/event';
import { InventorySchemaItem } from '@atom/types/inventory';
import { InventorySchemasState, ReduxStore } from '@atom/types/store';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import './formRepository.css';

const { MenuItem } = Select;

const styles = {
  floatingLabelStyle: {
    fontSize: fonts.md,
    color: colors.neutral.gray,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
    fontSize: fonts.md,
  },
  underlineStyle: {
    borderColor: colors.neutral.silver,
  },
  selectFieldStyle: {
    input: {
      fontSize: fonts.md,
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      fontSize: fonts.md,
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
};

interface StateErrors {
  name: string;
}

interface State {
  open: boolean;
  errors: StateErrors;
  name: string;
  rootSchema: InventorySchemaItem | null;
}

interface ReduxStateProps {
  loading: boolean;
  inventorySchemas: InventorySchemasState;
}

interface ReduxDispatchProps {
  formTemplateActions: FormTemplateActions;
  inventorySchemaActions: InventorySchemaActions;
}

type Props = ReduxStateProps & ReduxDispatchProps;

const initialState = {
  open: false,
  rootSchema: null,
  errors: {
    name: '',
  },
  name: '',
};

class CreateFormModal extends React.Component<Props, State> {
  state = initialState;

  componentDidMount() {
    const { inventorySchemaActions, inventorySchemas } = this.props;

    if (R.isNil(inventorySchemas) || R.isEmpty(inventorySchemas)) {
      inventorySchemaActions.retrieveInventorySchemas({
        rootSchemas: true,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
    if (this.props.loading && !nextProps.loading) {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.setState(initialState);
  };

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  confirm = () => {
    const { name, rootSchema } = this.state;
    const { formTemplateActions } = this.props;

    if (!name) {
      this.setState({
        errors: {
          ...this.state.errors,
          name: 'The form title must be provided',
        },
      });
      return;
    }

    this.setState({
      errors: {
        ...this.state.errors,
        name: '',
      },
    });

    formTemplateActions.requestCreateFormTemplate({
      name,
      ...(!R.isNil(rootSchema) ? { schemaId: rootSchema.id } : {}),
    });
  };

  onChange = (event: EventType) => {
    const { value } = event.target;
    this.setState({ name: value });
  };

  onDataTypeChange = (event: any) => {
    const { value } = event.target;
    this.setState({ rootSchema: value });
  };

  isCreationDisabled = (): boolean => {
    const { name } = this.state;
    return R.isEmpty(name);
  };

  getSchemaTextAndColor = (schema: InventorySchemaItem) => {
    const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(schema);

    return (
      <div styleName="schema-menu-item-container">
        {icon}
        <div styleName="schema-menu-item-title">{schema.name}</div>
      </div>
    );
  };

  render() {
    const { open, name, rootSchema } = this.state;
    const { loading, inventorySchemas } = this.props;

    const creationDisabled = this.isCreationDisabled();

    const dialogContent = loading ? (
      <div styleName="modal-spinner-container">
        <Progress />
      </div>
    ) : (
      <div styleName="modal-container">
        <div>
          {/* 
            // @ts-ignore */}
          <TextField
            label="Form Title"
            value={name}
            name="name"
            onChange={this.onChange}
          />
        </div>
        <div>
          <Select
            fullWidth
            label="Inventory Type (Optional)"
            style={styles.textFieldStyle}
            onChange={this.onDataTypeChange}
            value={rootSchema}
          >
            {inventorySchemas.map((schema: InventorySchemaItem) => {
              const primaryText = this.getSchemaTextAndColor(schema);
              return (
                <MenuItem key={schema.id} value={schema}>
                  {primaryText}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
    );

    return (
      <div styleName="icon-container">
        <IconButton tooltip="Create" onClick={this.openModal}>
          <Icon color={colors.neutral.white}>add</Icon>
        </IconButton>
        <Modal
          title="Create Form"
          onConfirm={this.confirm}
          confirmButtonText="Create"
          onCancel={this.closeModal}
          disabled={creationDisabled}
          open={open}
        >
          {dialogContent}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loading: state.loading.loadingFormTemplateCreation,
  inventorySchemas: state.inventorySchemas,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  formTemplateActions: bindActionCreators(formTemplateActionCreators, dispatch),
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFormModal);

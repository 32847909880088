import * as React from 'react';
import { EventType } from 'client/types/event';

import TextLimit from '@atom/components/common/TextLimit';
import { TextField } from '@atom/mui';
import {
  MAX_CHAR_DESCRIPTION,
  MAX_CHAR_SHORT_INPUT,
  REVEAL_LONG,
} from '@atom/selectors/formSelectors';

import '../formBuilder.css';

interface Props {
  title: string;
  description: string;
  onChange: (event: EventType) => void;
}

const BasicInput = ({ title, description, onChange }: Props) => {
  return (
    <div styleName="multi-input-container">
      <div styleName="double-input">
        <TextField
          variant="standard"
          value={title}
          onChange={onChange}
          name="title"
          label="Title"
        />
        <div styleName="text-limit-container">
          <TextLimit
            limit={MAX_CHAR_SHORT_INPUT}
            reveal={REVEAL_LONG}
            text={title}
          />
        </div>
      </div>
      <div styleName="double-input">
        <TextField
          variant="standard"
          value={description}
          onChange={onChange}
          name="description"
          label="Description"
        />
        <div styleName="text-limit-container">
          <TextLimit
            limit={MAX_CHAR_DESCRIPTION}
            reveal={REVEAL_LONG}
            text={description}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInput;

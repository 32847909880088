import React, { useContext, useMemo } from 'react';

import { Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RequestsContext from '../../RequestsContext';

import './sddotCreate.css';

const styles = {
  icon: {
    marginRight: '1rem',
  },
};

interface Props {
  quantity?: number;
}

const QuantityAdjustmentSubtext = ({ quantity }: Props) => {
  const { asset } = useContext(RequestsContext);

  const updatedQuantity = useMemo(() => {
    return Number(asset?.budget?.quantityOnHand) + Number(quantity);
  }, [quantity, asset?.budget?.quantityOnHand]);

  const quantityError = updatedQuantity < 0;

  const content = quantityError
    ? `New stock level: ${updatedQuantity} ${asset?.budget?.unit}. Stock level cannot be negative.`
    : `New stock level: ${updatedQuantity} ${asset?.budget?.unit}`;
  const containerStyleName = quantityError
    ? 'quantity-subtext error'
    : 'quantity-subtext';

  const icon = quantityError ? 'error_outline' : 'info_outline';
  const iconColor = quantityError ? colors.brand.red : colors.brand.blue;

  return isNilOrEmpty(quantity) ? (
    <div />
  ) : (
    <div styleName={containerStyleName}>
      <Icon style={styles.icon} color={iconColor}>
        {icon}
      </Icon>
      {content}
    </div>
  );
};

export default QuantityAdjustmentSubtext;

import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';

import { Progress } from '@atom/mui';
import fonts from '@atom/styles/fonts';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailBreadcrumb from './BudgetDetailBreadcrumb';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailUnitTreeNav from './BudgetDetailUnitTreeNav';

import './budgetDetail.css';

const styles = {
  breadcrumbs: {
    fontSize: fonts.xl,
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    height: '1.75rem',
    paddingBottom: '0.75rem',
  },
};

const BudgetDetailBreadcrumbs = () => {
  const { parentBudgetUnit } = useContext(BudgetDetailContext);

  const crumbIds: string[] = useMemo(() => {
    return R.pathOr([], ['ancestors'], parentBudgetUnit);
  }, [parentBudgetUnit]);

  return (
    <>
      <div style={styles.breadcrumbs}>
        {isNilOrEmpty(parentBudgetUnit) ? (
          <Progress style={{ justifyContent: 'left' }} size={20} />
        ) : (
          <>
            {crumbIds?.map(crumb => (
              <BudgetDetailBreadcrumb key={crumb} crumb={crumb} />
            ))}
            <BudgetDetailBreadcrumb
              key={parentBudgetUnit.id}
              crumb={parentBudgetUnit}
              last
            />
            <BudgetDetailUnitTreeNav />
          </>
        )}
      </div>
    </>
  );
};

export default BudgetDetailBreadcrumbs;

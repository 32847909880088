import * as React from 'react';

import { MediaItem } from '@atom/types/media';
import { getMediaIcon } from '@atom/utilities/iconUtilities';

import './formInstance.css';

interface PassedProps {
  media: MediaItem;
}

type Props = PassedProps;

const FileUploadTile = ({ media }: Props) => {
  const { type, fileExtension, fileId, name } = media;
  const mediaIcon = getMediaIcon(type, fileExtension, fileId, name);

  return (
    <div styleName="media-tile">
      {mediaIcon}
      <div styleName="media-name">{`${name}.${fileExtension}`}</div>
    </div>
  );
};

export default FileUploadTile;

import React, { useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import WorkOrdersContext from '@atom/components/workOrders/WorkOrdersContext';
import {
  WORK_FILTER_CREATE,
  WORK_FILTER_DELETE,
  WORK_FILTER_UPDATE,
} from '@atom/graph/workFilters';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { FE_PARAMS, INITIAL_INPUT } from '@atom/hooks/useWorkOrdersFilters';
import { Icon, IconButton, Menu, Modal, Popover, Snackbar } from '@atom/mui';
import {
  presetDateFilterKeys,
  WorkDatePresetKey,
  WorkFilter,
  WorkFilterCreateInput,
  WorkFilterSource,
  WorkFilterUpdateInput,
} from '@atom/types/workFilters';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  DateRange,
  getDateKeyFromPreset,
} from '@atom/utilities/workOrdersDateFilterUtilities';

import './workOrdersFilters.css';

const { MenuItem } = Menu;
const TYPENAME = '__typename';
const SYSTEM_DEFAULT_FILTER_ID = 'webDefault';

const WorkFiltersControls = () => {
  const anchor = useRef();
  const userProfile = useUserProfile();
  const { workOrdersInputCart, getWorkFilters, input, setInput } = useContext(
    WorkOrdersContext,
  );

  const getWorkFiltersParams = {
    variables: {
      input: {
        userId: userProfile.userId,
        source: WorkFilterSource.WEB,
      },
    },
  };

  const { dateFilterPresetsCart } = workOrdersInputCart;

  const defaultFilter = input.defaultFilter;

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [restoreOpen, setRestoreOpen] = useState<boolean>(false);

  const [workFilterCreate, { loading: loadingCreate }] = useMutation<
    { workFilterCreate: WorkFilter },
    { input: WorkFilterCreateInput }
  >(WORK_FILTER_CREATE);

  const [workFilterUpdate, { loading: loadingUpdate }] = useMutation<
    { workFilterUpdate: WorkFilter },
    { input: WorkFilterUpdateInput }
  >(WORK_FILTER_UPDATE);

  const [workFilterDelete, { loading: loadingDelete }] = useMutation<
    { workFilterDelete: boolean },
    { id: string }
  >(WORK_FILTER_DELETE);

  const isSystemDefaultFilter = defaultFilter?.id === SYSTEM_DEFAULT_FILTER_ID;

  const resetState = () => {
    setPopoverOpen(false);
    setSaveOpen(false);
    setRestoreOpen(false);
  };

  const removePresetDates = (cartPortion: {}, type: WorkDatePresetKey) => {
    return presetDateFilterKeys.reduce((prev, key) => {
      if (
        R.isNil(dateFilterPresetsCart[type][key]) ||
        dateFilterPresetsCart[type][key] === DateRange.CUSTOM_RANGE
      ) {
        return prev;
      }
      return {
        ...prev,
        [getDateKeyFromPreset(key, 'Start')]: null,
        [getDateKeyFromPreset(key, 'End')]: null,
      };
    }, cartPortion);
  };

  const sanitizeDateParams = () => ({
    ...removePresetDates(workOrdersInputCart, WorkDatePresetKey.WORK),
    tasks: removePresetDates(workOrdersInputCart.tasks, WorkDatePresetKey.TASK),
  });

  const cleanDatePresets = presets =>
    R.omit([TYPENAME], {
      ...R.reject(isNilOrEmpty, {
        [WorkDatePresetKey.WORK]: {
          ...R.reject(isNilOrEmpty, presets[WorkDatePresetKey.WORK]),
        },
        [WorkDatePresetKey.TASK]: {
          ...R.reject(isNilOrEmpty, presets[WorkDatePresetKey.TASK]),
        },
      }),
    });

  const createWorkFilter = async () => {
    resetState();

    try {
      Snackbar.info({
        message: 'Saving filter ...',
      });

      await workFilterCreate({
        variables: {
          input: {
            userId: userProfile?.userId,
            source: WorkFilterSource.WEB,
            name: 'Custom',
            isDefault: true,
            params: R.omit(
              FE_PARAMS,
              R.reject(isNilOrEmpty, sanitizeDateParams()),
            ),
            ...R.omit([TYPENAME], cleanDatePresets(dateFilterPresetsCart)),
          },
        },
      });

      await getWorkFilters(getWorkFiltersParams);

      Snackbar.info({
        message: 'Current filter saved as default.',
      });
    } catch (err) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const updateWorkFilter = async () => {
    resetState();

    try {
      Snackbar.info({
        message: 'Saving filter ...',
      });

      await workFilterUpdate({
        variables: {
          input: {
            workFilterId: defaultFilter?.id,
            params: R.omit(
              FE_PARAMS,
              R.reject(isNilOrEmpty, sanitizeDateParams()),
            ),
            ...R.omit([TYPENAME], cleanDatePresets(dateFilterPresetsCart)),
          },
        },
      });

      await getWorkFilters(getWorkFiltersParams);

      Snackbar.info({
        message: 'Current filter saved as default.',
      });
    } catch (err) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const deleteWorkFilter = async () => {
    resetState();

    try {
      Snackbar.info({
        message: 'Restoring filter ...',
      });

      await workFilterDelete({
        variables: {
          id: defaultFilter?.id,
        },
      });

      setInput(INITIAL_INPUT);

      await getWorkFilters(getWorkFiltersParams);

      Snackbar.info({
        message: 'Restored default filter.',
      });
    } catch (err) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const loading = loadingCreate || loadingDelete || loadingUpdate;

  return (
    <>
      <div ref={anchor} styleName="filter-header">
        Filter
        <IconButton
          onClick={() => setPopoverOpen(true)}
          tooltip="Filter options"
        >
          <Icon>keyboard_arrow_down</Icon>
        </IconButton>
      </div>
      <Popover
        open={popoverOpen}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={() => setPopoverOpen(false)}
      >
        <div styleName="filter-popover">
          <div styleName="filter-popover-header">
            <div>Default Filter:</div>
            <div styleName="filter-value">
              {isSystemDefaultFilter ? 'System Default' : defaultFilter?.name}
            </div>
          </div>
          <MenuItem onClick={() => setSaveOpen(true)}>
            Save Current Filter as Default
          </MenuItem>
          {!isSystemDefaultFilter && (
            <MenuItem onClick={() => setRestoreOpen(true)}>
              Restore Default Filter
            </MenuItem>
          )}
        </div>
      </Popover>
      <Modal
        open={saveOpen}
        loading={loading}
        cancelButtonText="Cancel"
        confirmButtonText="Save & Apply"
        onCancel={resetState}
        onConfirm={isSystemDefaultFilter ? createWorkFilter : updateWorkFilter}
        title="Save Current Filter as Default?"
      >
        Are you sure you want to save the current filter as the default?
      </Modal>
      <Modal
        open={restoreOpen}
        loading={loading}
        cancelButtonText="Cancel"
        confirmButtonText="Restore & Apply"
        onCancel={resetState}
        onConfirm={deleteWorkFilter}
        title="Restore Default Filter?"
      >
        Are you sure you want to restore the default filter to system default?
        This will remove all of your custom changes to the default filter.
      </Modal>
    </>
  );
};

export default WorkFiltersControls;

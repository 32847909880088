import {
  AssetRequestStatus,
  AssetRequestType,
  ColumnType,
  DescriptionOption,
} from '@atom/types/assetRequest';

export const INITIAL_PAGE = 1;
export const INITIAL_LIMIT = 25;
export const INITIAL_SORT_BY = 'name';
export const INITIAL_IS_ASCENDING = false;

export const TYPE_LABELS = {
  [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]: 'Stockpile Transfer',
  [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: 'Inventory Transfer',
  [AssetRequestType.SDDOT_REPLENISHMENT]: 'Replenishment',
  [AssetRequestType.SDDOT_ADJUSTMENT]: 'Adjustment',
};

export const STATUS_LABELS = {
  [AssetRequestStatus.FULLY_RECEIVED]: 'Fully Received',
  [AssetRequestStatus.PARTIALLY_RECEIVED]: 'Partially Received',
  [AssetRequestStatus.PENDING]: 'Pending',
  [AssetRequestStatus.CANCELED]: 'Canceled',
  [AssetRequestStatus.REJECTED]: 'Rejected',
  [AssetRequestStatus.FULL_APPROVAL]: 'Fully Approved',
  [AssetRequestStatus.PARTIAL_APPROVAL]: 'Partially Approved',
};

export const STATUS_COLORS = {
  [AssetRequestStatus.FULLY_RECEIVED]: '#3FA142',
  [AssetRequestStatus.PARTIALLY_RECEIVED]: '#FF8F00',
  [AssetRequestStatus.PENDING]: '#FF8F00',
  [AssetRequestStatus.CANCELED]: '#999',
  [AssetRequestStatus.REJECTED]: '#ED3620',
  [AssetRequestStatus.FULL_APPROVAL]: '#3FA142',
  [AssetRequestStatus.PARTIAL_APPROVAL]: '#00A89A',
};

export const DESCRIPTION_LABELS = {
  [DescriptionOption.ALL]: 'All',
  [DescriptionOption.OUTGOING]: 'Outgoing',
  [DescriptionOption.INCOMING]: 'Incoming',
};

export const DESCRIPTION_OPTIONS = [
  DescriptionOption.ALL,
  DescriptionOption.OUTGOING,
  DescriptionOption.INCOMING,
];

// SDDOT Constants
export const SDDOT_STOCKPILE_SCHEMA_ID = '676ec09039095020a61ed979';
export const SDDOT_INVENTORY_SCHEMA_ID = '676d8107400a294a5634787c';
export const SDDOT_FUEL_SCHEMA_ID = '6736246a730f2a1d9dfc4086';
export const ITEM_CODE_ATTRIBUTE_NAME = 'Item Code';

export const INVENTORY_STATUS_OPTIONS = [
  AssetRequestStatus.PENDING,
  AssetRequestStatus.CANCELED,
  AssetRequestStatus.REJECTED,
  AssetRequestStatus.FULL_APPROVAL,
  AssetRequestStatus.PARTIAL_APPROVAL,
];

export const REPLENISHMENT_STATUS_OPTIONS = [
  AssetRequestStatus.FULLY_RECEIVED,
  AssetRequestStatus.PARTIALLY_RECEIVED,
];

export const sddotColumns = (type: AssetRequestType, isPortalView: boolean) => {
  const columns = {
    [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]: [
      {
        type: ColumnType.NAME,
        label: 'Request ID',
        sortValue: 'name',
      },
      ...(isPortalView && [
        {
          type: ColumnType.FROM_ASSET_NAME,
          label: 'Transfer From',
        },
        {
          type: ColumnType.ASSET_NAME,
          label: 'Transfer To',
        },
      ]),
      ...(!isPortalView && [
        {
          type: ColumnType.DESCRIPTION,
          label: 'Description',
        },
      ]),
      {
        type: ColumnType.QUANTITY,
        label: 'Quantity',
      },
      {
        type: ColumnType.CREATED_BY,
        label: 'Created By',
      },
      {
        type: ColumnType.CREATED_DATE,
        label: 'Created On',
        sortValue: 'createdDate',
      },
    ],
    [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: [
      {
        type: ColumnType.NAME,
        label: 'Request ID',
        sortValue: 'name',
      },
      ...(isPortalView && [
        {
          type: ColumnType.FROM_ASSET_NAME,
          label: 'Transfer From',
        },
        {
          type: ColumnType.ASSET_NAME,
          label: 'Transfer To',
        },
      ]),
      {
        type: ColumnType.STATUS,
        label: 'Status',
      },
      ...(!isPortalView && [
        {
          type: ColumnType.DESCRIPTION,
          label: 'Description',
        },
      ]),
      {
        type: ColumnType.QUANTITY_ORDERED,
        label: 'Quantity',
      },
      {
        type: ColumnType.ASSIGNED_TO,
        label: 'Assigned To',
      },
      {
        type: ColumnType.CREATED_BY,
        label: 'Created By',
      },
      {
        type: ColumnType.CREATED_DATE,
        label: 'Created On',
        sortValue: 'createdDate',
      },
      {
        type: ColumnType.UPDATED_DATE,
        label: 'Resolution Date',
        sortValue: 'updatedDate',
      },
    ],
    [AssetRequestType.SDDOT_REPLENISHMENT]: [
      {
        type: ColumnType.NAME,
        label: 'Request ID',
        sortValue: 'name',
      },
      ...(isPortalView && [
        {
          type: ColumnType.ASSET_NAME,
          label: 'Inventory Name',
        },
      ]),
      {
        type: ColumnType.STATUS,
        label: 'Status',
      },
      {
        type: ColumnType.REPLENISHMENTS,
        label: 'Replenishments',
      },
      {
        type: ColumnType.QUANTITY_ORDERED,
        label: 'Quantity Ordered',
      },
      {
        type: ColumnType.QUANTITY_RECEIVED,
        label: 'Quantity Received',
      },
      {
        type: ColumnType.CREATED_BY,
        label: 'Created By',
      },
      {
        type: ColumnType.CREATED_DATE,
        label: 'Created On',
        sortValue: 'createdDate',
      },
    ],
    [AssetRequestType.SDDOT_ADJUSTMENT]: [
      {
        type: ColumnType.NAME,
        label: 'Request ID',
        sortValue: 'name',
      },
      ...(isPortalView && [
        {
          type: ColumnType.ASSET_NAME,
          label: 'Inventory Name',
        },
      ]),
      {
        type: ColumnType.QUANTITY,
        label: 'Quantity',
      },
      {
        type: ColumnType.CREATED_BY,
        label: 'Created By',
      },
      {
        type: ColumnType.CREATED_DATE,
        label: 'Created On',
        sortValue: 'createdDate',
      },
    ],
  };

  return columns[type] || [];
};

export const SDDOT_CONFIRM_MODAL_CONTENT = {
  [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]: {
    title: `Create Request: ${
      TYPE_LABELS[AssetRequestType.SDDOT_STOCKPILE_TRANSFER]
    }?`,
    content:
      'Once the request is created, the inventory stock will be updated immediately. ',
  },
  [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: {
    title: `Create Request: ${
      TYPE_LABELS[AssetRequestType.SDDOT_INVENTORY_TRANSFER]
    }?`,
    content:
      'Once the request is created, it will be marked as pending and will need another user’s approval before the stock is updated. ',
  },
  [AssetRequestType.SDDOT_REPLENISHMENT]: {
    title: `Create Request: ${
      TYPE_LABELS[AssetRequestType.SDDOT_REPLENISHMENT]
    }?`,
    content:
      'Once the request is created, the inventory stock will be updated immediately. ',
  },
  [AssetRequestType.SDDOT_ADJUSTMENT]: {
    title: `Create Request: ${TYPE_LABELS[AssetRequestType.SDDOT_ADJUSTMENT]}?`,
    content:
      'Once the request is created, the inventory stock will be updated immediately. ',
  },
};

export const ADJUSTMENT_REASON_OPTIONS = [
  '750CRDT - Credits',
  '750CYCL - Random Count',
  '750FUEL - Fuel',
  '750SALE - Assets to Annual Sales',
  '750SIGN - Signage',
  '750STKP - Stockpile',
  '750WHSE - Annual Inventory',
];

import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_GROUP_UPDATE } from '@atom/graph/schema';
import { Checkbox, Icon, IconButton, Snackbar } from '@atom/mui';
import { AttributeGroupUpdateInput, SchemaTree } from '@atom/types/schema';

import EditItemModal from '../EditItemModal';

import { updatePendingGroupUpdates } from './attributeGroupDetailUtilities';

import './attributeGroupDetail.css';

const AttributeGroupDetail = () => {
  const {
    schemaTree,
    selectedAttributeGroup,
    setSelectedAttributeGroup,
    selectedSubItem,
    refetchSchemaTree,
    setPendingGroupUpdates,
  } = useContext(SchemaDetailContext);

  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);

  const [updateAttributeGroup, { loading }] = useMutation<
    { attributeGroupUpdate: SchemaTree },
    { input: AttributeGroupUpdateInput }
  >(ATTRIBUTE_GROUP_UPDATE);

  const handleEditName = async (name: string) => {
    try {
      await updateAttributeGroup({
        variables: {
          input: {
            schemaId: selectedSubItem?.id,
            attributeGroupId: selectedAttributeGroup.id,
            name,
          },
        },
      });

      setSelectedAttributeGroup(prev => ({ ...prev, name }));
      refetchSchemaTree();
    } catch (error) {
      Snackbar.error({ message: 'Something went wrong.' });
    } finally {
      setIsRenameModalOpen(false);
    }
  };

  // Updating expand by default on unpublished schema
  const handleExpandUpdate = async (value: boolean) => {
    await updateAttributeGroup({
      variables: {
        input: {
          schemaId: selectedSubItem?.id,
          attributeGroupId: selectedAttributeGroup.id,
          expandByDefault: value,
        },
      },
    });

    setSelectedAttributeGroup(prev => ({ ...prev, expandByDefault: value }));

    await refetchSchemaTree();
  };

  // Updating expand by default on published schema
  const handlePendingExpandUpdate = (value: boolean) => {
    setPendingGroupUpdates(prev =>
      updatePendingGroupUpdates(
        selectedSubItem.id,
        prev,
        selectedAttributeGroup,
        'expandByDefault',
        value,
      ),
    );
    setSelectedAttributeGroup({
      ...selectedAttributeGroup,
      expandByDefault: value,
    });
  };

  const showRenameIcon = !schemaTree.isPublished;
  const expandByDefault = selectedAttributeGroup.expandByDefault;

  const toggleExpandByDefault = () => {
    if (schemaTree.isPublished) {
      handlePendingExpandUpdate(!expandByDefault);
    } else {
      handleExpandUpdate(!expandByDefault);
    }
  };

  return (
    <>
      <div styleName="attribute-header-container">
        <div styleName="attribute-header-text">
          {selectedAttributeGroup.name}
        </div>
        {showRenameIcon && (
          <IconButton
            onClick={() => setIsRenameModalOpen(true)}
            size="small"
            tooltip="Rename"
          >
            <Icon>edit</Icon>
          </IconButton>
        )}
      </div>
      <div styleName="attribute-section">
        <Checkbox
          checked={expandByDefault}
          onChange={toggleExpandByDefault}
          disabled={loading}
          label={'Expanded by default'}
        />
      </div>
      <EditItemModal
        name={selectedAttributeGroup.name}
        type="Group"
        handleSave={handleEditName}
        open={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        isDisabled={loading}
      />
    </>
  );
};

export default AttributeGroupDetail;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_BASIC_BUDGET_UNIT } from '@atom/graph/budget';
import { Icon, Progress, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BasicBudgetUnit,
  BudgetUnit,
  BudgetUnitConnectionInput,
} from '@atom/types/budget';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';

import './budgetDetail.css';

// If a string is passed as crumb, BudgetDetailBreadcrumb
//  hydrates it with the unit data.
interface Props {
  crumb: BudgetUnit | string;
  last?: boolean;
}

const styles = {
  crumbDivider: {
    color: colors.neutral.gray,
    height: '100%',
  },
  crumb: {
    color: colors.neutral.black,
    maxWidth: '12.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
};

const BudgetDetailBreadcrumb = ({ crumb, last = false }: Props) => {
  const containerRef = useRef<HTMLDivElement>();
  const { budget, parentBudgetUnit } = useContext(BudgetDetailContext);

  const [crumbObj, setCrumbObj] = useState<any>(crumb);

  const overflowing: boolean = useMemo(() => {
    if (isNilOrEmpty(containerRef.current)) {
      return false;
    }
    const { offsetWidth, scrollWidth } = containerRef.current;
    return offsetWidth < scrollWidth;
  }, [containerRef.current]);

  const [getBudgetUnit] = useLazyQuery<
    { basicBudgetUnit: BasicBudgetUnit },
    { input: BudgetUnitConnectionInput }
  >(GET_BASIC_BUDGET_UNIT, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setCrumbObj(res.basicBudgetUnit);
    },
  });

  useEffect(() => {
    if (typeof crumb === 'string') {
      getBudgetUnit({
        variables: {
          input: {
            budgetId: budget.id,
            budgetUnitId: crumb,
          },
        },
      });
    }
  }, [crumb]);

  const crumbStyle = useMemo(
    () => ({ ...styles.crumb, ...(!last && { cursor: 'pointer' }) }),
    [last],
  );

  const handleNavigateToUnit = () => {
    history.push(`/budget/${budget.id}/${crumbObj.id}`);
  };

  return (
    <>
      <div
        ref={containerRef}
        style={crumbStyle}
        onClick={() =>
          !isNilOrEmpty(crumbObj) &&
          crumbObj?.id !== parentBudgetUnit?.id &&
          handleNavigateToUnit()
        }
      >
        {typeof crumbObj === 'string' ? (
          <Progress size={20} />
        ) : overflowing ? (
          <Tooltip title={crumbObj?.name}>
            <p styleName="text-no-wrap">{crumbObj?.name}</p>
          </Tooltip>
        ) : (
          crumbObj?.name
        )}
      </div>
      {!last && (
        <span style={styles.crumbDivider}>
          <Icon>chevron_right</Icon>
        </span>
      )}
    </>
  );
};

export default BudgetDetailBreadcrumb;

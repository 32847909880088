import React, { useContext } from 'react';

import { TYPE_LABELS } from '@atom/components/common/requests/assetRequestConstants';
import { getCustomValues } from '@atom/components/common/requests/customTenantUtilities';
import SDDOTAdjustmentFilters from '@atom/components/common/requests/sddotFilters/SDDOTAdjustmentFilters';
import SDDOTInventoryFilters from '@atom/components/common/requests/sddotFilters/SDDOTInventoryFilters';
import SDDOTReplenishmentsFilters from '@atom/components/common/requests/sddotFilters/SDDOTReplenishmentFilters';
import SDDOTStockpileFilters from '@atom/components/common/requests/sddotFilters/SDDOTStockpileFilters';
import { Button, Progress, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetRequestType } from '@atom/types/assetRequest';

import RequestsContext from '../RequestsContext';

import './requestsFilters.css';

const { MenuItem } = Select;

const filterStyle = {
  resetButtonStyle: {
    marginLeft: '2rem',
  },
};

interface Props {
  applyFilters: () => void;
  resetFilters: () => void;
}

const RequestsFilters = ({ applyFilters, resetFilters }: Props) => {
  const {
    schema,
    filtersCart,
    setFiltersCart,
    asset,
    changeType,
    loadingSchema,
  } = useContext(RequestsContext);
  const { typeOptions } = getCustomValues(schema, asset);

  const handleTypeChange = event => {
    changeType(event.target.value);
  };

  const filterProps = {
    filtersCart,
    setFiltersCart,
    asset,
  };

  const customFilters = {
    [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: (
      <SDDOTInventoryFilters {...filterProps} />
    ),
    [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]: (
      <SDDOTStockpileFilters {...filterProps} />
    ),
    [AssetRequestType.SDDOT_REPLENISHMENT]: (
      <SDDOTReplenishmentsFilters {...filterProps} />
    ),
    [AssetRequestType.SDDOT_ADJUSTMENT]: (
      <SDDOTAdjustmentFilters {...filterProps} />
    ),
  };

  return (
    <>
      <div styleName="filters-header">
        <div styleName="header-title">Filter</div>
        <div>
          <Button style={filterStyle.resetButtonStyle} onClick={resetFilters}>
            Reset
          </Button>
          <Button color="primary" onClick={applyFilters}>
            Apply
          </Button>
        </div>
      </div>
      {loadingSchema ? (
        <div>
          <Progress />
        </div>
      ) : (
        <div styleName="filters-container">
          <Select
            key="type"
            label="Request Type"
            InputLabelProps={{ style: { color: colors.neutral.dim } }}
            fullWidth
            onChange={event => handleTypeChange(event)}
            value={filtersCart.type || ''}
          >
            {typeOptions.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {TYPE_LABELS[option]}
              </MenuItem>
            ))}
          </Select>
          {customFilters[filtersCart.type]}
        </div>
      )}
    </>
  );
};

export default RequestsFilters;

import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import { TASK_ASSET_REMOVE } from '@atom/graph/task';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import { TaskAssetRemoveInput } from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './assetSection.css';

interface Props {
  assetId: string;
  handleAssetTileClick: (assetId: string) => void;
}

const AssetTile = ({ assetId, handleAssetTileClick }: Props) => {
  const { workOrderDetail, task, refetch, dataCySuffix } = useContext(
    LocationsAndAssetsContext,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const [deleteAsset] = useMutation<
    { taskAssetRemove: boolean },
    { input: TaskAssetRemoveInput }
  >(TASK_ASSET_REMOVE);

  const removeAsset = async () => {
    setOpen(false);

    Snackbar.info({
      message: 'Deleting...',
    });

    await deleteAsset({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          taskId: task.id,
          assetId,
        },
      },
    });

    Snackbar.info({
      message: `Removed Asset.`,
    });

    refetch();
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
    setOpen(true);
  };

  const handleAddClick =
    task.isCompleted &&
    (task?.requireAtLeastOneMultiAsset ||
      !isNilOrEmpty(task?.requiredMultiAssetSchemaIds))
      ? () => setOpenFieldsConfirm(true)
      : () => setOpen(true);

  const asset = workOrderDetail.multiAssets[assetId];
  const isEditDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);

  const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(asset, {
    imgOffset: '0',
    iconSize: '24px',
    marginTop: '0',
  });

  const actionButton = isEditDisabled ? (
    <div />
  ) : (
    <IconButton
      onClick={handleAddClick}
      size="small"
      tooltip="Remove"
      data-cy={`assetTileDelete_${dataCySuffix}`}
    >
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <TaskItemTile
        content={R.pathOr('', ['name'], asset)}
        onTileClick={() => handleAssetTileClick(asset?.id)}
        icon={icon}
        actionButton={actionButton}
      />
      <DeleteModal
        open={open}
        onConfirm={removeAsset}
        onCancel={() => setOpen(false)}
        title="Remove Asset?"
        content="Are you sure you want to remove this asset?"
        cancelText="Cancel"
        confirmText="Remove"
      />
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default AssetTile;

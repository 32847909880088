import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import DeleteModal from '@atom/components/common/DeleteModal';
import { Draggable, Droppable } from '@atom/components/common/dragAndDrop';
import SchemaDetailContext, {
  DragDropType,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import {
  ATTRIBUTE_CREATE,
  ATTRIBUTE_GROUP_DELETE,
  ATTRIBUTE_GROUP_UPDATE,
} from '@atom/graph/schema';
import { Icon, IconButton, List, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { DataType } from '@atom/types/dataType';
import {
  AttributeCreateInput,
  AttributeGroupDeleteInput,
  AttributeGroupUpdateInput,
  Schema,
  SchemaTree,
  SchemaTreeAttribute,
  SchemaTreeAttributeGroup,
} from '@atom/types/schema';

import AddItemModal from '../AddItemModal';
import EditItemModal from '../EditItemModal';
import ListItemWrapper from '../subItemTree/ListItemWrapper';

import AttributeRow from './AttributeRow';
import {
  addPendingCreation,
  INITIAL_TEMP_ATTRIBUTE,
  mergePendingCreationsAndGroupChanges,
} from './subItemDetailUtilities';

import './subItemDetail.css';

const styles = {
  list: {
    padding: 0,
    minHeight: '1rem',
  },
};

const DEFAULT_ATTRIBUTE = {
  dataType: DataType.SHORT_TEXT,
  isFilterable: false,
  isVisibleAsSubtext: false,
  isEditable: true,
  isRequired: false,
};

interface Props {
  attributeGroup: SchemaTreeAttributeGroup;
}

const AttributeGroupRow = ({ attributeGroup }: Props) => {
  const container = useRef<HTMLDivElement>();

  const {
    schemaTree,
    refetchSchemaTree,
    selectedSubItem,
    setSelectedAttributeRoute,
    setSelectedAttribute,
    selectedAttributeGroup,
    setSelectedAttributeGroup,
    pendingGroupUpdates,
    pendingCreations,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [createdAttributeId, setCreatedAttributeId] = useState<string>();

  // If pending creations or group updates exist, they will be merged with the
  // attribute group and attributes data
  const mergedAttributeGroup = mergePendingCreationsAndGroupChanges(
    selectedSubItem.id,
    attributeGroup,
    pendingCreations,
    pendingGroupUpdates,
  );

  const [overflowing, setOverflowing] = useState<boolean>(false);
  const [openAttributeCreate, setOpenAttributeCreate] = useState<boolean>(
    false,
  );
  const [openAttributeGroupEdit, setOpenAttributeGroupEdit] = useState<boolean>(
    false,
  );
  const [openAttributeGroupDelete, setOpenAttributeGroupDelete] = useState<
    boolean
  >(false);

  const [createAttribute] = useMutation<
    { attributeCreate: Schema },
    { input: AttributeCreateInput }
  >(ATTRIBUTE_CREATE);

  const [updateAttributeGroup] = useMutation<
    { attributeGroupUpdate: SchemaTree },
    { input: AttributeGroupUpdateInput }
  >(ATTRIBUTE_GROUP_UPDATE);

  const [deleteAttributeGroup] = useMutation<
    { attributeGroupDelete: boolean },
    { input: AttributeGroupDeleteInput }
  >(ATTRIBUTE_GROUP_DELETE);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [mergedAttributeGroup.name]);

  const getNewAttribute = (schema: Schema) => {
    const currentGroupIndex = R.findIndex(R.propEq('id', attributeGroup.id))(
      schema.attributeGroups,
    );
    const newAttributeId = R.last(
      schema.attributeGroups[currentGroupIndex]?.attributes,
    );

    return { id: newAttributeId, ...schema.attributes[newAttributeId] };
  };

  const handleAttributeCreate = async (name: string) => {
    if (schemaTree?.isPublished) {
      const tempAttribute = {
        ...INITIAL_TEMP_ATTRIBUTE,
        name,
        attributeGroupId: mergedAttributeGroup.id,
        id: uuid(),
      };

      setPendingCreations(prev =>
        addPendingCreation(selectedSubItem.id, prev, tempAttribute),
      );

      setOpenAttributeCreate(false);

      setSelectedAttributeRoute({
        attributeGroupId: attributeGroup.id,
        attributeId: tempAttribute.id,
      });
      setSelectedAttributeGroup(null);
      setSelectedAttribute(tempAttribute);
      setCreatedAttributeId(tempAttribute.id);
    } else {
      const { data } = await createAttribute({
        variables: {
          input: {
            ...DEFAULT_ATTRIBUTE,
            schemaId: selectedSubItem?.id,
            attributeGroupId: mergedAttributeGroup?.id,
            name,
          },
        },
      });

      await refetchSchemaTree();

      const newAttribute = getNewAttribute(data?.attributeCreate);

      setSelectedAttributeRoute({
        attributeGroupId: attributeGroup.id,
        attributeId: newAttribute.id,
      });
      setSelectedAttributeGroup(null);
      setSelectedAttribute(newAttribute);
      setCreatedAttributeId(newAttribute.id);
    }

    setOpenAttributeCreate(false);
  };

  const handleAttributeGroupEdit = async (name: string) => {
    await updateAttributeGroup({
      variables: {
        input: {
          schemaId: selectedSubItem?.id,
          attributeGroupId: mergedAttributeGroup?.id,
          name,
        },
      },
    });

    await refetchSchemaTree();

    setOpenAttributeGroupEdit(false);
  };

  const handleAttributeGroupDelete = async () => {
    await deleteAttributeGroup({
      variables: {
        input: {
          schemaId: selectedSubItem?.id,
          attributeGroupId: mergedAttributeGroup?.id,
        },
      },
    });

    await refetchSchemaTree();

    setSelectedAttributeRoute(null);
    setSelectedAttribute(null);
    setSelectedAttributeGroup(null);
    setOpenAttributeGroupDelete(false);
  };

  const handleAttributeGroupClick = () => {
    setSelectedAttribute(null);
    setSelectedAttributeGroup(mergedAttributeGroup);
  };

  const isDisabled = schemaTree?.isPublished;
  const showDeleteAttribute =
    !schemaTree?.isPublished && selectedSubItem.attributeGroups.length > 1;

  const isSelected = selectedAttributeGroup?.id === mergedAttributeGroup.id;

  const textStyles = {
    color: isSelected ? colors.brand.blue : colors.neutral.dark,
  };

  return (
    <>
      <ListItemWrapper
        selected={isSelected}
        onClick={handleAttributeGroupClick}
        attributeGroupVersion
      >
        <div styleName="attribute-group-row-list-item">
          <div
            styleName="attribute-group-title"
            ref={container}
            style={textStyles}
          >
            {!overflowing ? (
              mergedAttributeGroup.name
            ) : (
              <Tooltip
                title={mergedAttributeGroup.name}
                placement="bottom-start"
              >
                <p styleName="attribute-group-title" style={textStyles}>
                  {mergedAttributeGroup.name}
                </p>
              </Tooltip>
            )}
          </div>
          <div styleName="icon-container">
            {mergedAttributeGroup?.expandByDefault && (
              <span styleName="expand-default-text">Expanded by default</span>
            )}
            <IconButton
              onClick={() => setOpenAttributeCreate(true)}
              size="small"
              tooltip="Add Attribute"
            >
              <Icon>control_point</Icon>
            </IconButton>
            {showDeleteAttribute && (
              <IconButton
                onClick={() => setOpenAttributeGroupDelete(true)}
                size="small"
                tooltip="Delete"
              >
                <Icon>delete</Icon>
              </IconButton>
            )}
          </div>
        </div>
      </ListItemWrapper>
      <Droppable
        droppableId={mergedAttributeGroup.id}
        type={DragDropType.ATTRIBUTE}
        isDropDisabled={isDisabled}
      >
        <List style={styles.list}>
          {mergedAttributeGroup.attributes.map(
            (attribute: SchemaTreeAttribute, index: number) => {
              return (
                <Draggable
                  key={attribute.id}
                  draggableId={attribute.id}
                  index={index}
                  type={DragDropType.ATTRIBUTE}
                  isDragDisabled={isDisabled}
                >
                  <AttributeRow
                    key={attribute?.id}
                    attribute={attribute}
                    attributeGroupId={mergedAttributeGroup.id}
                    createdAttributeId={createdAttributeId}
                    setCreatedAttributeId={setCreatedAttributeId}
                  />
                </Draggable>
              );
            },
          )}
        </List>
      </Droppable>
      <AddItemModal
        open={openAttributeCreate}
        closeModal={() => setOpenAttributeCreate(false)}
        type="Attribute"
        handleSave={handleAttributeCreate}
      />
      <EditItemModal
        name={mergedAttributeGroup.name}
        type="Group"
        handleSave={handleAttributeGroupEdit}
        open={openAttributeGroupEdit}
        closeModal={() => setOpenAttributeGroupEdit(false)}
      />
      <DeleteModal
        open={openAttributeGroupDelete}
        onConfirm={handleAttributeGroupDelete}
        onCancel={() => setOpenAttributeGroupDelete(false)}
        title="Delete Group?"
        content={`Deleting ${mergedAttributeGroup.name} will also delete all the items under that group.`}
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  );
};

export default AttributeGroupRow;

import React, { useContext } from 'react';
import * as R from 'ramda';

import TimeEntryStatusIcon from '@atom/components/common/timeEntryStatusIcon/TimeEntryStatusIcon';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import TeamContext, { View } from '@atom/components/teamPortal/TeamContext';
import { Checkbox, ListTable } from '@atom/mui';
import { TimeEntryStatus } from '@atom/types/timeEntry';
import { UserDetail } from '@atom/types/user';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { convertMillisToExactHours } from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import './timeSheetList.css';

const { TableRow, TableCell } = ListTable;

const styles = {
  smallCell: {
    padding: '0.5rem',
  },
};

interface Props {
  user: UserDetail;
  selectedPayPeriodWeek: number;
  selectedUserIds: Set<string>;
  setSelectedUserIds: (selectedUserIds: Set<string>) => void;
}

const TimeSheetListTableRow = ({
  user,
  selectedPayPeriodWeek,
  selectedUserIds,
  setSelectedUserIds,
}: Props) => {
  const {
    payPeriodWeeks,
    navigateToView,
    setSelectedTimeSheetUser,
  } = useContext(TeamContext);

  const handleClick = () => {
    navigateToView(View.TIME_SHEET);
    setSelectedTimeSheetUser({ id: user.id, name: getUserFullName(user) });
  };

  const toggleCheck = () => {
    setSelectedUserIds(toggleFromSet(selectedUserIds, user.id));
  };

  const currentWeekDates = payPeriodWeeks[selectedPayPeriodWeek];
  const hasNoTimeEntries = R.isEmpty(user?.timeEntries?.timeEntries);

  const disableCheckbox =
    hasNoTimeEntries || user?.timeEntries?.status !== TimeEntryStatus.pending;

  return (
    <>
      <TableRow key={user.id}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedUserIds.has(user.id)}
            onClick={toggleCheck}
            disabled={disableCheckbox}
          />
        </TableCell>
        <TableCell width="30px" center style={styles.smallCell}>
          <UserThumbnail
            image={user.photoUrl}
            alt={`${user.firstName} ${user.lastName}`}
          />
        </TableCell>
        <TableCell>
          <div styleName="name-cell" onClick={handleClick}>
            {getUserFullName(user)}
          </div>
        </TableCell>
        <TableCell>
          {hasNoTimeEntries ? '-' : user?.timeEntries?.totalOpenWorkOrders}
        </TableCell>
        <TableCell>
          {hasNoTimeEntries ? '-' : user?.timeEntries?.totalWorkTypes}
        </TableCell>
        <TableCell>
          {hasNoTimeEntries ? '-' : user?.timeEntries?.totalWorkOrders}
        </TableCell>
        <TableCell width="30px" center style={styles.smallCell} />
        {currentWeekDates.map(day => {
          const dailyTotal = R.pathOr(
            [],
            ['timeEntries', 'dailyTotals'],
            user,
          ).find(item => item.date === day);

          const hours =
            dailyTotal && dailyTotal?.duration !== 0
              ? convertMillisToExactHours(dailyTotal?.duration)
              : '-';

          return (
            <TableCell width="30px" center style={styles.smallCell} key={day}>
              {hours}
            </TableCell>
          );
        })}
        <TableCell width="30px" center style={styles.smallCell} />
        <TableCell center style={styles.smallCell}>
          {hasNoTimeEntries || !user?.timeEntries?.totalDuration
            ? '-'
            : convertMillisToExactHours(user?.timeEntries?.totalDuration)}
        </TableCell>
        <TableCell center style={styles.smallCell}>
          {user?.timeEntriesTotalDuration
            ? convertMillisToExactHours(user?.timeEntriesTotalDuration)
            : '-'}
        </TableCell>
        <TableCell>
          {hasNoTimeEntries ? (
            <div styleName="empty-title">Not Started</div>
          ) : (
            <div styleName="status-container">
              <TimeEntryStatusIcon status={user?.timeEntries?.status} />
              <div styleName="status-title">{user?.timeEntries?.status}</div>
            </div>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default TimeSheetListTableRow;

import React, { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { TextField } from '@atom/mui';
import { LocationDataTitle } from '@atom/types/taskLocation';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TaskLocationSelectInput from './TaskLocationSelectInput';
import TaskLocationSidebarContext from './TaskLocationSidebarContext';

import './taskLocationModal.css';

const styles = {
  leftInput: {
    marginRight: '0.5rem',
  },
  rightInput: {
    marginLeft: '0.5rem',
  },
};

const TaskLocationAldotInputs = () => {
  const { workOrderDetail, task, refetch, dataCySuffix } = useContext(
    LocationsAndAssetsContext,
  );
  const {
    values,
    options,
    updateValue,
    loading,
    inputsLocked,
    autoFillMilepost,
    showRanges,
    isRangeError,
    rangeSubtext,
    rangeSubtextStyles,
    handleMilepostChange,
  } = useContext(TaskLocationSidebarContext);

  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const isMilepostDisabled = useMemo(() => {
    const managementUnitValue = values[LocationDataTitle.MANAGEMENT_UNIT];
    const countyValue = values[LocationDataTitle.COUNTY];
    const roadClassValue = values[LocationDataTitle.ROAD_CLASS];
    const routeValue = values[LocationDataTitle.ROUTE];
    const directionValue = values[LocationDataTitle.DIRECTION];

    return (
      R.any(isNilOrEmpty)([
        managementUnitValue,
        countyValue,
        roadClassValue,
        routeValue,
        directionValue,
      ]) ||
      workOrderDetail?.isClosed ||
      task.isCompleted
    );
  }, [workOrderDetail, task, values, options]);

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
  };

  const onClick =
    task?.isCompleted && task?.requireAtLeastOneLocation
      ? () => setOpenFieldsConfirm(true)
      : () => {};

  const disableSharedInput =
    inputsLocked || workOrderDetail?.isClosed || task.isCompleted;
  const disableSelectInput = workOrderDetail?.isClosed || task.isCompleted;

  return (
    <>
      <div styleName="input-section" onClick={onClick}>
        <TaskLocationSelectInput
          property={LocationDataTitle.MANAGEMENT_UNIT}
          options={options[LocationDataTitle.MANAGEMENT_UNIT]}
          value={values[LocationDataTitle.MANAGEMENT_UNIT]}
          updateValue={updateValue}
          loading={loading}
          disabled={disableSharedInput}
          onClick={onClick}
          dataCy={`locationManagementUnit_${dataCySuffix}`}
        />
        <div styleName="input-row">
          <TaskLocationSelectInput
            property={LocationDataTitle.COUNTY}
            options={options[LocationDataTitle.COUNTY]}
            value={values[LocationDataTitle.COUNTY]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSharedInput}
            position="left"
            onClick={onClick}
            dataCy={`locationCounty_${dataCySuffix}`}
          />
          <TaskLocationSelectInput
            property={LocationDataTitle.ROAD_CLASS}
            options={options[LocationDataTitle.ROAD_CLASS]}
            value={values[LocationDataTitle.ROAD_CLASS]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSharedInput}
            position="right"
            onClick={onClick}
            dataCy={`locationRoadClass_${dataCySuffix}`}
          />
        </div>
        <div styleName="input-row">
          <TaskLocationSelectInput
            property={LocationDataTitle.ROUTE}
            options={options[LocationDataTitle.ROUTE]}
            value={values[LocationDataTitle.ROUTE]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSelectInput}
            position="left"
            onClick={onClick}
            dataCy={`locationRoute_${dataCySuffix}`}
          />
          <TaskLocationSelectInput
            property={LocationDataTitle.DIRECTION}
            options={options[LocationDataTitle.DIRECTION]}
            value={values[LocationDataTitle.DIRECTION]}
            updateValue={updateValue}
            loading={loading}
            disabled={disableSelectInput}
            position="right"
            onClick={onClick}
            dataCy={`locationDirection_${dataCySuffix}`}
          />
        </div>
        <div styleName="input-row">
          <TextField
            type="number"
            value={values[LocationDataTitle.START_MILEPOST]}
            label={`${LocationDataTitle.START_MILEPOST} *`}
            onChange={event =>
              handleMilepostChange(
                LocationDataTitle.START_MILEPOST,
                event.target.value,
              )
            }
            onBlur={() => autoFillMilepost(LocationDataTitle.START_MILEPOST)}
            style={styles.leftInput}
            error={isRangeError}
            disabled={isMilepostDisabled}
            onClick={onClick}
            data-cy={`locationStartMilepost_${dataCySuffix}`}
          />
          <TextField
            type="number"
            value={values[LocationDataTitle.END_MILEPOST]}
            label={`${LocationDataTitle.END_MILEPOST} *`}
            onChange={event =>
              handleMilepostChange(
                LocationDataTitle.END_MILEPOST,
                event.target.value,
              )
            }
            onBlur={() => autoFillMilepost(LocationDataTitle.END_MILEPOST)}
            style={styles.rightInput}
            error={isRangeError}
            disabled={isMilepostDisabled}
            onClick={onClick}
            data-cy={`locationEndMilepost_${dataCySuffix}`}
          />
        </div>
        {showRanges && (
          <div style={rangeSubtextStyles} styleName="range-subtext">
            {rangeSubtext}
          </div>
        )}
      </div>
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default TaskLocationAldotInputs;

import React from 'react';

// @ts-ignore
import csvIcon from '@atom/components/common/svgIcons/csvIcon.svg';
// @ts-ignore
import elementIcon from '@atom/components/common/svgIcons/elementIcon.svg';
// @ts-ignore
import elementIconBlue from '@atom/components/common/svgIcons/elementIconBlue.svg';
// @ts-ignore
import elementIconRed from '@atom/components/common/svgIcons/elementIconRed.svg';
// @ts-ignore
import pdfIcon from '@atom/components/common/svgIcons/pdfIcon.svg';
// @ts-ignore
import wordIcon from '@atom/components/common/svgIcons/wordIcon.svg';
import { Icon } from '@atom/mui';
import {
  DEFECT_MARKER_ID,
  PROTECTIVE_COATING_MARKER_ID,
} from '@atom/selectors/schemaSelectors';
import colors from '@atom/styles/colors';
import { MediaType } from '@atom/types/media';

import { FILES_ENDPOINT } from './endpoints';
import schemaUtilities from './schemaUtilities';
import workOrderStatusUtilities from './workOrderStatusUtilities';

// TODO: [ATOM-1818] Extract left-pane styles and persist into iconUtilities.css
import '../styles/left-pane.css';

/**
 *
 * @param {string} [iconType=none] The type of icon to display.
 *
 * If anything other than 'down-arrow' or 'right-arrow' is used, the default will be 'none'
 *
 * Available Options:
 * "down-arrow"
 * "right-arrow"
 * "none"
 *
 * @returns {ReactElement}
 */
export function leftIcon(iconType) {
  if (iconType !== 'down-arrow' && iconType !== 'right-arrow') {
    return <div />;
  }

  const rotation = iconType !== 'down-arrow' ? 'rotate' : '';
  return (
    <Icon styleName={rotation} color={colors.neutral.dim}>
      arrow_drop_down
    </Icon>
  );
}

/**
 *
 * @param {string} [iconType=none] The type of icon to display.
 * @param {object} [style] additional icon style properties
 *
 * If anything other than 'down-arrow' or 'right-arrow' is used, the default will be 'none'
 *
 * Available Options:
 * "down-arrow"
 * "right-arrow"
 * "none"
 *
 * @returns {ReactElement}
 */
export function leftFolderIcon(iconType, style?: React.CSSProperties) {
  if (iconType !== 'down-arrow' && iconType !== 'right-arrow') {
    return <div />;
  }

  const rotation = iconType !== 'down-arrow' ? 'rotate' : '';
  return (
    <div style={{ marginRight: '10px' }}>
      <Icon styleName={rotation} color={colors.neutral.dim}>
        arrow_drop_down
      </Icon>
      <Icon style={{ ...style }} color={colors.neutral.gray}>
        folder
      </Icon>
    </div>
  );
}

export const buildElementIcon = (markerId, style, isBlue) => {
  switch (markerId) {
    case DEFECT_MARKER_ID: {
      const styles = isBlue
        ? { color: colors.brand.blue, ...style.fhwaStyle }
        : style.fhwaStyle;

      return <Icon style={styles}>report_problem</Icon>;
    }
    case PROTECTIVE_COATING_MARKER_ID: {
      const styles = isBlue
        ? { color: colors.brand.blue, ...style.fhwaStyle }
        : style.fhwaStyle;

      return <Icon style={styles}>verified_user</Icon>;
    }
    default: {
      const src = isBlue ? elementIconBlue : elementIcon;
      return <img style={style.imageStyle} src={src} />;
    }
  }
};

export const buildFHWAElementIcon = (markerId, isValid) => {
  const iconStyles = isValid
    ? { marginRight: '0.5rem', color: colors.neutral.gray }
    : { marginRight: '0.5rem', color: colors.brand.red };

  const fhwaElementIcon = isValid ? elementIcon : elementIconRed;

  switch (markerId) {
    case DEFECT_MARKER_ID: {
      return <Icon style={iconStyles}>report_problem</Icon>;
    }
    case PROTECTIVE_COATING_MARKER_ID: {
      return <Icon style={iconStyles}>verified_user</Icon>;
    }
    default: {
      return <img style={{ marginRight: '0.5rem' }} src={fhwaElementIcon} />;
    }
  }
};

export function leftElementIcon(markerId, iconType, isBlue) {
  if (iconType !== 'down-arrow' && iconType !== 'right-arrow') {
    return <div />;
  }

  const rotation = iconType !== 'down-arrow' ? 'rotate' : '';
  const imageStyle = { margin: '0 0 0 6px' };
  const fhwaStyle = {
    margin: '0 0 0 6px',
    color: colors.neutral.gray,
  };

  const styles = { imageStyle, fhwaStyle };
  const newElementIcon = buildElementIcon(markerId, styles, isBlue);

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
      }}
    >
      <Icon styleName={rotation} color={colors.neutral.dim}>
        arrow_drop_down
      </Icon>
      {newElementIcon}
    </div>
  );
}

export function getElementIcon(markerId, isBlue) {
  const imageStyle = { margin: '0 0 0 30px' };
  const fhwaStyle = {
    margin: '0 0 0 30px',
    color: colors.neutral.gray,
  };

  const styles = { imageStyle, fhwaStyle };

  return buildElementIcon(markerId, styles, isBlue);
}

export function assetFolderIcon(schemaColorId, style?: React.CSSProperties) {
  const color = schemaUtilities.getSchemaColorFromColorId(schemaColorId);

  return (
    <Icon
      style={{
        bottom: '10px',
        marginLeft: '30px',
        ...style,
      }}
      color={color}
    >
      folder
    </Icon>
  );
}

export const getMediaIcon = (
  type: MediaType,
  fileExtension: string,
  fileId: string,
  name: string,
) => {
  switch (true) {
    case fileExtension === 'pdf': {
      return <img className="pdf-icon" src={pdfIcon} />;
    }
    case fileExtension === 'csv': {
      return <img className="csv-icon" src={csvIcon} />;
    }
    case fileExtension === 'doc':
    case fileExtension === 'docx': {
      return <img className="word-icon" src={wordIcon} />;
    }
    case fileExtension === 'folder': {
      return <Icon color={colors.neutral.gray}>folder</Icon>;
    }
    case type === MediaType.IMAGE: {
      return (
        <img
          style={{ width: '24px', height: '24px' }}
          src={`${FILES_ENDPOINT}/${fileId}_24`}
          alt={name}
        />
      );
    }
    case type === MediaType.VIDEO: {
      return <Icon color={colors.brand.red}>movie</Icon>;
    }
    default: {
      return <Icon color={colors.brand.blue}>insert_drive_file</Icon>;
    }
  }
};

export function getWorkIconByStatusId(statusId) {
  const color =
    workOrderStatusUtilities.getWorkOrderStatusColor(statusId) ||
    colors.neutral.gray;

  return <Icon color={color}>work</Icon>;
}

export function getDataManagementElementIcon(
  markerId: number,
  isSelected: boolean,
  style?: React.CSSProperties,
) {
  const defaultIcon = isSelected ? elementIconBlue : elementIcon;
  const color = isSelected ? colors.brand.blue : colors.neutral.gray;

  switch (markerId) {
    case DEFECT_MARKER_ID:
    case PROTECTIVE_COATING_MARKER_ID: {
      const icon =
        markerId === DEFECT_MARKER_ID ? 'report_problem' : 'verified_user';
      return (
        <Icon style={{ margin: 0 }} color={color}>
          {icon}
        </Icon>
      );
    }
    default: {
      return <img style={{ fontWeight: 300, ...style }} src={defaultIcon} />;
    }
  }
}

export const fullCategoryLeftTreeIcon = () => {
  return (
    <>
      <Icon children="arrow_drop_down" />
      <Icon children="folder" style={{ marginLeft: '0.5rem' }} />
    </>
  );
};

const iconUtilities = {
  leftIcon,
  getElementIcon,
  getMediaIcon,
  leftFolderIcon,
  leftElementIcon,
  assetFolderIcon,
  getWorkIconByStatusId,
  getDataManagementElementIcon,
  buildElementIcon,
  buildFHWAElementIcon,
  fullCategoryLeftTreeIcon,
  DEFECT_MARKER_ID,
  PROTECTIVE_COATING_MARKER_ID,
};

export default iconUtilities;

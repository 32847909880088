import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import CategoryFilters from '@atom/components/common/categoryFilters/CategoryFilters';
import {
  SDDOT_FUEL_SCHEMA_ID,
  SDDOT_INVENTORY_SCHEMA_ID,
  SDDOT_STOCKPILE_SCHEMA_ID,
  TYPE_LABELS,
} from '@atom/components/common/requests/assetRequestConstants';
import { getCustomValues } from '@atom/components/common/requests/customTenantUtilities';
import SDDOTAdjustmentFilters from '@atom/components/common/requests/sddotFilters/SDDOTAdjustmentFilters';
import SDDOTInventoryFilters from '@atom/components/common/requests/sddotFilters/SDDOTInventoryFilters';
import SDDOTReplenishmentsFilters from '@atom/components/common/requests/sddotFilters/SDDOTReplenishmentFilters';
import SDDOTStockpileFilters from '@atom/components/common/requests/sddotFilters/SDDOTStockpileFilters';
import { GET_SCHEMAS } from '@atom/graph/schema';
import { Button, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetRequestsFilters,
  AssetRequestType,
} from '@atom/types/assetRequest';
import { Schema, SchemasConnection, SchemasInput } from '@atom/types/schema';

import RequestsContext from '../RequestsContext';

import './requestsFilters.css';

const { MenuItem } = Select;

const filterStyle = {
  resetButtonStyle: {
    marginLeft: '2rem',
  },
};

interface Props {
  applyFilters: () => void;
  resetFilters: () => void;
}

const RequestsFilters = ({ applyFilters, resetFilters }: Props) => {
  const { filtersCart, changeType, setFiltersCart } = useContext(
    RequestsContext,
  );

  const schemaIds = [
    SDDOT_FUEL_SCHEMA_ID,
    SDDOT_INVENTORY_SCHEMA_ID,
    SDDOT_STOCKPILE_SCHEMA_ID,
  ];

  const { data, loading: loadingSchemas } = useQuery<
    { schemas: SchemasConnection },
    { input: SchemasInput }
  >(GET_SCHEMAS, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        ids: schemaIds,
      },
    },
  });

  const schemaOptions = data?.schemas?.schemas || [];

  const { typeOptions } = getCustomValues();

  const handleTypeChange = event => {
    changeType(event.target.value);
  };

  const updateFilter = (value: any, property: keyof AssetRequestsFilters) => {
    setFiltersCart(prev => ({ ...prev, [property]: value }));
  };

  const updateCategoryIds = (categoryIds: string[]) => {
    updateFilter(categoryIds, 'categoryIds');
  };

  const filterProps = {
    filtersCart,
    setFiltersCart,
  };

  const customFilters = {
    [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: (
      <SDDOTInventoryFilters {...filterProps} />
    ),
    [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]: (
      <SDDOTStockpileFilters {...filterProps} />
    ),
    [AssetRequestType.SDDOT_REPLENISHMENT]: (
      <SDDOTReplenishmentsFilters {...filterProps} />
    ),
    [AssetRequestType.SDDOT_ADJUSTMENT]: (
      <SDDOTAdjustmentFilters {...filterProps} />
    ),
  };

  const filterSchemaId = R.pathOr([], ['schemaIds'], filtersCart)[0];

  return (
    <>
      <div styleName="filters-header">
        <div styleName="header-title">Filter</div>
        <div>
          <Button style={filterStyle.resetButtonStyle} onClick={resetFilters}>
            Reset
          </Button>
          <Button color="primary" onClick={applyFilters}>
            Apply
          </Button>
        </div>
      </div>
      <div styleName="filters-container">
        <Select
          displayEmpty
          key="schemas"
          label="Inventory Type"
          InputLabelProps={{ style: { color: colors.neutral.dim } }}
          fullWidth
          onChange={event => updateFilter([event.target.value], 'schemaIds')}
          renderValue={filterSchemaId !== '' ? null : () => 'All'}
          value={filterSchemaId || ''}
          disabled={loadingSchemas}
        >
          <MenuItem key="all" value={''}>
            All
          </MenuItem>
          {schemaOptions.map((option: Schema, index: number) => (
            <MenuItem key={index} value={option?.id}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
        <div styleName="category-filter">
          <CategoryFilters
            categoryIds={filtersCart?.categoryIds || []}
            updateCategoryIds={updateCategoryIds}
            schemaIds={schemaIds}
          />
        </div>
        <Select
          key="type"
          label="Request Type"
          InputLabelProps={{ style: { color: colors.neutral.dim } }}
          fullWidth
          onChange={event => handleTypeChange(event)}
          value={filtersCart.type || ''}
        >
          {typeOptions.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {TYPE_LABELS[option]}
            </MenuItem>
          ))}
        </Select>
        {customFilters[filtersCart.type]}
      </div>
    </>
  );
};

export default RequestsFilters;

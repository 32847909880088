// Returns access for budget states

import { useMemo } from 'react';

import { PolicyAction } from '@atom/types/policy';
import { hasAccess } from '@atom/utilities/accessUtilities';

export const useBudgetApprovalAccess = (actions: PolicyAction[]) => {
  const canAccessApprove = useMemo(
    () => hasAccess(PolicyAction.APPROVE, actions),
    [actions],
  );
  const canAccessSubmit = useMemo(
    () => hasAccess(PolicyAction.SUBMIT_DRAFT, actions),
    [actions],
  );
  const canAccessRevertToDraftFromApproved = useMemo(
    () => hasAccess(PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED, actions),
    [actions],
  );
  const canAccessRevertToDraftFromSubmitted = useMemo(
    () => hasAccess(PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED, actions),
    [actions],
  );
  const canAccessRevertToSubmittedFromApproved = useMemo(
    () => hasAccess(PolicyAction.REVERT_TO_SUBMITTED_FROM_APPROVED, actions),
    [actions],
  );

  return {
    canAccessApprove,
    canAccessSubmit,
    canAccessRevertToDraftFromApproved,
    canAccessRevertToDraftFromSubmitted,
    canAccessRevertToSubmittedFromApproved,
  };
};

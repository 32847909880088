import React from 'react';

import { AssetRequestStatus } from '@atom/types/assetRequest';

import { STATUS_COLORS, STATUS_LABELS } from '../assetRequestConstants';

import './requestsTable.css';

interface Props {
  status: AssetRequestStatus;
}

const RequestStatusPill = ({ status }: Props) => {
  const pillStyle = {
    backgroundColor: STATUS_COLORS[status] || '#FFF',
  };

  return (
    <span style={pillStyle} styleName="status-pill">
      {STATUS_LABELS[status] || ''}
    </span>
  );
};

export default RequestStatusPill;

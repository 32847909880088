import React, { useCallback, useEffect, useState } from 'react';

import {
  useGoogleMapsStateUpdater,
  useGoogleMapsStateValue,
} from '@atom/components/common/map/hooks/googleMapsStateHook';
import { Icon, IconButton, Menu } from '@atom/mui';
import {
  MapAreaUnit,
  MapData,
  MapDistanceUnit,
  MapShapeType,
} from '@atom/types/map';
import {
  Client,
  isCurrentClient,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import {
  getMapAreaUnitLabel,
  getMapDistanceUnitLabel,
  getShapeArea,
  getShapeDistance,
} from '@atom/utilities/mapShapeUtilities';

import './mapStats.css';

const { MenuItem } = Menu;

const styles = {
  icon: {
    height: '20px',
    width: '20px',
    fontSize: '20px',
  },
  helpIconButton: {
    padding: '0',
  },
};

const distanceUnitOptions = [
  MapDistanceUnit.MILES,
  MapDistanceUnit.FEET,
  MapDistanceUnit.YARDS,
  MapDistanceUnit.METERS,
];

const areaUnitOptions = [
  MapAreaUnit.MILES,
  MapAreaUnit.FEET,
  MapAreaUnit.YARDS,
  MapAreaUnit.ACRES,
  MapAreaUnit.METERS,
];

enum MessageType {
  MEASURE = 'MEASURE',
  POLYGON = 'POLYGON',
  NO_ITEM = 'NO_ITEM',
  ZOOM = 'ZOOM',
  NONE = 'NONE',
}

interface Message {
  message: string;
  messageStyle: string;
}

const messages = {
  [MessageType.POLYGON]: {
    message: 'Draw an area now.',
    messageStyle: 'message-row green',
  },
  [MessageType.MEASURE]: {
    message: 'Click points on the map to measure distance.',
    messageStyle: 'message-row',
  },
  [MessageType.NO_ITEM]: {
    message: 'No items to show.',
    messageStyle: 'message-row orange',
  },
  [MessageType.ZOOM]: {
    message: 'Please zoom closer on the map to view items.',
    messageStyle: 'message-row orange',
  },
  [MessageType.NONE]: { message: '', messageStyle: 'message-row hidden' },
};

interface Props {
  data: MapData;
  loading: boolean;
}

const MapStats = ({ data, loading }: Props) => {
  const dispatch = useGoogleMapsStateUpdater();
  const { drawingMode, shape } = useGoogleMapsStateValue();

  const {
    clusters = [],
    workOrders = [],
    users = [],
    assets = [],
    workOrdersCount,
    assetsCount,
    usersCount,
  } = data;

  const [open, setOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<MapShapeType>(drawingMode);
  const [areaUnit, setAreaUnit] = useState<MapAreaUnit>(MapAreaUnit.FEET);
  const [distanceUnit, setDistanceUnit] = useState<MapDistanceUnit>(
    MapDistanceUnit.FEET,
  );

  useEffect(() => {
    if (drawingMode) {
      setMode(drawingMode);
    }
  }, [drawingMode]);

  const isDrawing =
    (mode === MapShapeType.CIRCLE ||
      mode === MapShapeType.POLYGON ||
      mode === MapShapeType.POLYLINE) &&
    !shape;

  useEffect(() => {
    setOpen(!!shape || isDrawing);
  }, [shape, isDrawing]);

  const getLabel = () => {
    switch (mode) {
      case MapShapeType.POLYLINE: {
        return 'Measure';
      }
      case MapShapeType.POLYGON: {
        return 'Draw Polygon Area';
      }
      case MapShapeType.CIRCLE: {
        return 'Draw Radius Area';
      }
      default: {
        return '';
      }
    }
  };

  const isMeasureTool = mode === MapShapeType.POLYLINE;
  const totalItems = workOrders.length + users.length + assets.length;
  const label = getLabel();
  const tooltipText =
    'Distance and area values are rounded to the nearest 3 decimal places.';

  // Bulk work warning and info message section is toggled via client
  const enableBulkImplementation =
    isCurrentClient([Client.HENNEPIN]) ||
    isCurrentTenant([
      Tenant.DEV_GWRR,
      Tenant.QA_GWRR,
      Tenant.UAT_GWRR,
      Tenant.DEV_MACOMB,
      Tenant.QA_MACOMB,
      Tenant.UAT_MACOMB,
    ]);
  const showContent = !loading && !isDrawing;

  const getMessageData = (): Message => {
    if (isMeasureTool) {
      return messages[MessageType.MEASURE];
    }

    if (enableBulkImplementation && totalItems === 0 && clusters.length > 0) {
      return messages[MessageType.ZOOM];
    }

    if (enableBulkImplementation && totalItems === 0 && clusters.length <= 0) {
      return messages[MessageType.NO_ITEM];
    }

    if (isDrawing || loading) {
      return messages[MessageType.POLYGON];
    }

    return messages[MessageType.NONE];
  };

  const { message, messageStyle } = getMessageData();

  const formatNumber = (value: number) => {
    return Number(value).toLocaleString('en-US', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3,
    });
  };

  const distance = formatNumber(getShapeDistance(shape, distanceUnit));
  const distanceUnitLabel = getMapDistanceUnitLabel(distanceUnit);

  const area = formatNumber(getShapeArea(shape, areaUnit));
  const areaUnitLabel = getMapAreaUnitLabel(areaUnit);

  const handleClose = useCallback(() => {
    setOpen(false);
    setMode(null);
    dispatch({ type: 'SET', payload: { key: 'shape', data: null } });
    dispatch({ type: 'SET', payload: { key: 'drawingMode', data: null } });
  }, [dispatch]);

  const content = (
    <div styleName="container">
      <div styleName="header">
        <div styleName="title-content">
          <div styleName="main-title">{label}</div>
          <IconButton tooltip={tooltipText} style={styles.helpIconButton}>
            <Icon style={styles.icon}>help</Icon>
          </IconButton>
        </div>
        <IconButton onClick={handleClose} edge="end">
          <Icon>close</Icon>
        </IconButton>
      </div>
      <div styleName={messageStyle}>
        <div>{message}</div>
      </div>
      {showContent && (
        <>
          <div styleName="content-row">
            <div styleName="row-title">Distance</div>
            <div styleName="row-value">
              <div>{`${distance} ${distanceUnitLabel}`} </div>
              <Menu
                icon={<Icon>arrow_drop_down</Icon>}
                IconButtonProps={{ size: 'small' }}
              >
                {distanceUnitOptions.map(option => {
                  const optionLabel = getMapDistanceUnitLabel(option);

                  return (
                    <MenuItem
                      key={optionLabel}
                      onClick={() => setDistanceUnit(option)}
                      selected={option === distanceUnit}
                    >
                      <div>{optionLabel}</div>
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </div>
          {!isMeasureTool && (
            <>
              <div styleName="content-row">
                <div styleName="row-title">Area</div>
                <div styleName="row-value">
                  <div>{`${area} ${areaUnitLabel}`} </div>
                  <Menu
                    icon={<Icon>arrow_drop_down</Icon>}
                    IconButtonProps={{ size: 'small' }}
                  >
                    {areaUnitOptions.map(option => {
                      const optionLabel = getMapAreaUnitLabel(option);

                      return (
                        <MenuItem
                          key={optionLabel}
                          onClick={() => setAreaUnit(option)}
                          selected={option === areaUnit}
                        >
                          <div>{optionLabel}</div>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              </div>
              <div styleName="content-row">
                <div styleName="row-title">Inventory Items</div>
                <div>{assetsCount}</div>
              </div>
              <div styleName="content-row">
                <div styleName="row-title">Work</div>
                <div>{workOrdersCount}</div>
              </div>
              <div styleName="content-row">
                <div styleName="row-title">Team Members</div>
                <div>{usersCount}</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );

  return <div styleName="wrapper">{open && content}</div>;
};

export default MapStats;

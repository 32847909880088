import { gql } from '@apollo/client';

import { BASE_TASK_FIELD } from '@atom/graph/task';
import { BASE_WORK_ORDER_FIELD } from '@atom/graph/work';

const BASE_WORK_ORDER_TEMPLATE_TASK = gql`
  fragment BaseWorkTemplateTask on WorkOrderTemplateTask {
    id
    name
    inheritedComponents {
      type
      parentId
      ids
      required
    }
    description
    dueDate
    estimatedCost
    isEstimatedCostOverridden
    userIds
    users {
      id
      firstName
      lastName
      email
      photoUrl
    }
    formTemplateIds
    formTemplates {
      id
      name
    }
    materials {
      assetId
      name
      schemaId
      colorId
      isTimeBased
      isStockBased
      rate
      cost
      quantity
      unit
    }
    userGroups {
      userGroupId
      name
      colorId
      quantity
      workTime
      budget {
        budgetId
        rate
        restored
        reopenedWorkOrderIds
      }
    }
    categoryIds
    categories {
      id
      name
      categoryPath
    }
    schemaIds
    schemas {
      id
      name
      markerId
      colorId
      ancestors
      isMaterial
    }
    fields {
      ...BaseTaskField
    }
    fieldOrder
    modules
    createdDate
    locationsEnabled
    requireAtLeastOneLocation
    multiAssetsEnabled
    allowedMultiAssetSchemaIds
    allowedMultiAssetSchemas {
      id
      name
      markerId
      colorId
      ancestors
    }
    requireAtLeastOneMultiAsset
    requiredMultiAssetSchemaIds
    requiredMultiAssetSchemas {
      id
      name
      markerId
      colorId
      ancestors
    }
  }
  ${BASE_TASK_FIELD}
`;

const BASE_WORK_ORDER_TEMPLATE = gql`
  fragment BaseWorkOrderTemplate on WorkOrderTemplate {
    id
    type
    externalId
    published
    parentId
    isParent
    inheritedComponents {
      type
      parentId
      ids
      required
    }
    requiredInheritedComponents
    name
    schemaId
    estimatedCost
    priorityId
    leadAssigneeId
    tasks {
      ...BaseWorkTemplateTask
    }
    fields {
      ...BaseWorkOrderField
    }
    fieldOrder
    folderPath
    summary {
      title
      contents
    }
    media {
      fileId
      name
      type
      fileExtension
      mediaId
    }
    createdDate
    createdBy {
      id
      firstName
      lastName
      photoUrl
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE_TASK}
  ${BASE_WORK_ORDER_FIELD}
`;

export const GET_WORK_ORDER_TEMPLATE = gql`
  query workOrderTemplate($id: ID!) {
    workOrderTemplate(id: $id) {
      ...BaseWorkOrderTemplate
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE}
`;

export const GET_WORK_ORDER_TEMPLATE_FOLDERS = gql`
  query workOrderTemplateFolders(
    $input: WorkOrderTemplateFoldersConnectionInput
  ) {
    workOrderTemplateFolders(input: $input) {
      totalCount
      workOrderTemplateFolders {
        id
        name
        parentFolderId
        ancestors
        hasTemplates
        createdDate
        updatedDate
        createdBy
        updatedBy
      }
    }
  }
`;

export const GET_WORK_ORDER_TEMPLATES = gql`
  query workOrderTemplates($input: WorkOrderTemplatesConnectionInput) {
    workOrderTemplates(input: $input) {
      totalCount
      workOrderTemplates {
        id
        name
        published
        type
        schemaId
        schemaName
        parentId
        isParent
        parentName
        priorityId
        createdDate
        createdBy {
          id
          firstName
          lastName
          photoUrl
        }
      }
    }
  }
`;

export const WORK_TEMPLATE_SEARCH = gql`
  query workOrderTemplateSearch($input: WorkOrderTemplateSearchInput!) {
    workOrderTemplateSearch(input: $input) {
      totalCount
      workOrderTemplates {
        id
        name
        schemaId
        parentId
        isParent
        type
        folderPath {
          id
          name
        }
      }
    }
  }
`;

export const WORK_TEMPLATE_CREATE = gql`
  mutation workOrderTemplateCreate($input: WorkOrderTemplateCreateInput!) {
    workOrderTemplateCreate(input: $input) {
      ...BaseWorkOrderTemplate
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE}
`;

export const WORK_TEMPLATE_UPDATE = gql`
  mutation workOrderTemplateUpdate($input: WorkOrderTemplateUpdateInput!) {
    workOrderTemplateUpdate(input: $input) {
      ...BaseWorkOrderTemplate
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE}
`;

export const WORK_TEMPLATE_DUPLICATE = gql`
  mutation workOrderTemplateDuplicate($id: ID!) {
    workOrderTemplateDuplicate(id: $id) {
      id
      name
    }
  }
`;

export const WORK_TEMPLATE_DELETE = gql`
  mutation workOrderTemplateDelete($id: ID!) {
    workOrderTemplateDelete(id: $id)
  }
`;

export const WORK_TEMPLATE_FIELD_CREATE = gql`
  mutation workOrderTemplateFieldCreate(
    $input: WorkOrderTemplateFieldCreateInput!
  ) {
    workOrderTemplateFieldCreate(input: $input) {
      ...BaseWorkOrderField
    }
  }
  ${BASE_WORK_ORDER_FIELD}
`;

export const WORK_TEMPLATE_FIELD_UPDATE = gql`
  mutation workOrderTemplateFieldUpdate(
    $input: WorkOrderTemplateFieldUpdateInput!
  ) {
    workOrderTemplateFieldUpdate(input: $input) {
      ...BaseWorkOrderField
    }
  }
  ${BASE_WORK_ORDER_FIELD}
`;

export const WORK_TEMPLATE_FIELD_DELETE = gql`
  mutation workOrderTemplateFieldDelete(
    $input: WorkOrderTemplateFieldDeleteInput!
  ) {
    workOrderTemplateFieldDelete(input: $input)
  }
`;

export const WORK_TEMPLATE_TASK_CREATE = gql`
  mutation workOrderTemplateTaskCreate(
    $input: WorkOrderTemplateTaskCreateInput!
  ) {
    workOrderTemplateTaskCreate(input: $input) {
      ...BaseWorkTemplateTask
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE_TASK}
`;

export const WORK_TEMPLATE_TASK_UPDATE = gql`
  mutation workOrderTemplateTaskUpdate(
    $input: WorkOrderTemplateTaskUpdateInput!
  ) {
    workOrderTemplateTaskUpdate(input: $input) {
      ...BaseWorkTemplateTask
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE_TASK}
`;

export const WORK_TEMPLATE_TASK_DELETE = gql`
  mutation workOrderTemplateTaskDelete(
    $input: WorkOrderTemplateTaskDeleteInput!
  ) {
    workOrderTemplateTaskDelete(input: $input)
  }
`;

export const WORK_TEMPLATE_TASK_DUPLICATE = gql`
  mutation workOrderTemplateTaskDuplicate(
    $input: WorkOrderTemplateTaskDuplicateInput!
  ) {
    workOrderTemplateTaskDuplicate(input: $input)
  }
`;

export const WORK_TEMPLATE_TASK_FIELD_CREATE = gql`
  mutation workOrderTemplateTaskFieldCreate(
    $input: WorkOrderTemplateTaskFieldCreateInput!
  ) {
    workOrderTemplateTaskFieldCreate(input: $input) {
      ...BaseTaskField
    }
  }
  ${BASE_TASK_FIELD}
`;

export const WORK_TEMPLATE_TASK_FIELD_UPDATE = gql`
  mutation workOrderTemplateTaskFieldUpdate(
    $input: WorkOrderTemplateTaskFieldUpdateInput!
  ) {
    workOrderTemplateTaskFieldUpdate(input: $input) {
      ...BaseTaskField
    }
  }
  ${BASE_TASK_FIELD}
`;

export const WORK_TEMPLATE_TASK_FIELD_DELETE = gql`
  mutation workOrderTemplateTaskFieldDelete(
    $input: WorkOrderTemplateTaskFieldDeleteInput!
  ) {
    workOrderTemplateTaskFieldDelete(input: $input)
  }
`;

export const WORK_TEMPLATE_TASK_ASSIGNEE_CREATE = gql`
  mutation workOrderTemplateAssigneeCreate(
    $input: WorkOrderTemplateAssigneeCreateInput!
  ) {
    workOrderTemplateAssigneeCreate(input: $input) {
      ...BaseWorkTemplateTask
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE_TASK}
`;

export const WORK_TEMPLATE_TASK_ASSIGNEE_DELETE = gql`
  mutation workOrderTemplateAssigneeDelete(
    $input: WorkOrderTemplateAssigneeDeleteInput!
  ) {
    workOrderTemplateAssigneeDelete(input: $input)
  }
`;

export const WORK_TEMPLATE_TASK_USER_GROUP_CREATE = gql`
  mutation workOrderTemplateUserGroupCreate(
    $input: WorkOrderTemplateUserGroupCreateInput!
  ) {
    workOrderTemplateUserGroupCreate(input: $input) {
      ...BaseWorkTemplateTask
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE_TASK}
`;

export const WORK_TEMPLATE_TASK_USER_GROUP_UPDATE = gql`
  mutation workOrderTemplateUserGroupUpdate(
    $input: WorkOrderTemplateUserGroupUpdateInput!
  ) {
    workOrderTemplateUserGroupUpdate(input: $input) {
      ...BaseWorkTemplateTask
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE_TASK}
`;

export const WORK_TEMPLATE_TASK_USER_GROUP_DELETE = gql`
  mutation workOrderTemplateUserGroupDelete(
    $input: WorkOrderTemplateUserGroupDeleteInput!
  ) {
    workOrderTemplateUserGroupDelete(input: $input)
  }
`;

export const WORK_TEMPLATE_TASK_MATERIAL_CREATE = gql`
  mutation workOrderTemplateMaterialCreate(
    $input: WorkOrderTemplateMaterialCreateInput!
  ) {
    workOrderTemplateMaterialCreate(input: $input) {
      assetId
      name
      schemaId
      colorId
      isTimeBased
      isStockBased
      rate
      cost
      quantity
      unit
    }
  }
`;

export const WORK_TEMPLATE_TASK_MATERIAL_DELETE = gql`
  mutation workOrderTemplateMaterialDelete(
    $input: WorkOrderTemplateMaterialDeleteInput!
  ) {
    workOrderTemplateMaterialDelete(input: $input)
  }
`;

export const WORK_TEMPLATE_TASK_FORM_TEMPLATE_CREATE = gql`
  mutation workOrderTemplateFormCreate(
    $input: WorkOrderTemplateFormCreateInput!
  ) {
    workOrderTemplateFormCreate(input: $input) {
      ...BaseWorkTemplateTask
    }
  }
  ${BASE_WORK_ORDER_TEMPLATE_TASK}
`;

export const WORK_TEMPLATE_TASK_FORM_TEMPLATE_DELETE = gql`
  mutation workOrderTemplateFormDelete(
    $input: WorkOrderTemplateFormDeleteInput!
  ) {
    workOrderTemplateFormDelete(input: $input)
  }
`;

import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_DELETE } from '@atom/graph/schema';
import { Icon, IconButton } from '@atom/mui';
import { getAttributeIconName } from '@atom/selectors/schemaSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { AttributeDeleteInput, SchemaTreeAttribute } from '@atom/types/schema';

import ListItemWrapper from '../subItemTree/ListItemWrapper';

import { removePendingCreation } from './subItemDetailUtilities';

import './subItemDetail.css';

const styles = {
  dragIcon: {
    color: colors.neutral.silver,
    paddingRight: '0.25rem',
  },
};

interface Props {
  attribute: SchemaTreeAttribute;
  attributeGroupId: string;
  createdAttributeId: string;
  setCreatedAttributeId: Dispatch<SetStateAction<string>>;
}

const AttributeRow = ({
  attribute,
  attributeGroupId,
  createdAttributeId,
  setCreatedAttributeId,
}: Props) => {
  const container = useRef<HTMLDivElement>(null);

  const {
    setSelectedAttributeRoute,
    selectedAttributeRoute,
    schemaTree,
    refetchSchemaTree,
    selectedSubItem,
    setSelectedAttribute,
    selectedAttributeGroup,
    setSelectedAttributeGroup,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const [hovering, setHovering] = useState<boolean>(false);
  const [openAttributeDelete, setOpenAttributeDelete] = useState<boolean>(
    false,
  );

  const [deleteAttribute] = useMutation<
    { attributeDelete: boolean },
    { input: AttributeDeleteInput }
  >(ATTRIBUTE_DELETE);

  const selected =
    R.isNil(selectedAttributeGroup) &&
    selectedAttributeRoute?.attributeId === attribute?.id;

  useEffect(() => {
    if (createdAttributeId === attribute?.id) {
      container?.current?.scrollIntoView({ behavior: 'smooth' });
      setCreatedAttributeId(null);
    }
  }, [container, createdAttributeId, attribute]);

  const handleAttributeClick = () => {
    setSelectedAttributeGroup(null);
    setSelectedAttributeRoute({
      attributeGroupId,
      attributeId: attribute.id,
    });
    setSelectedAttribute(attribute);
  };

  const handleAttributeDelete = async () => {
    if (attribute.isTempAttribute) {
      setPendingCreations(prev =>
        removePendingCreation(
          selectedSubItem.id,
          attributeGroupId,
          attribute.id,
          prev,
        ),
      );

      setSelectedAttribute(null);
      setSelectedAttributeRoute(null);
    } else {
      await deleteAttribute({
        variables: {
          input: {
            schemaId: selectedSubItem?.id,
            attributeGroupId,
            attributeId: attribute?.id,
          },
        },
      });

      setSelectedAttribute(null);
      setSelectedAttributeRoute(null);
      await refetchSchemaTree();
    }
  };

  const attributeIcon = getAttributeIconName(attribute.dataType);
  const textStyles = {
    fontWeight: '400',
    fontSize: fonts.md,
    paddingLeft: '0.5rem',
    flex: '1 1 auto',
    color: selected ? colors.brand.blue : colors.neutral.dark,
  };
  const iconStyles = {
    color: selected ? colors.brand.blue : colors.neutral.gray,
  };

  const showActionButtons =
    (hovering || selected) &&
    (!schemaTree.isPublished || attribute.isTempAttribute);

  const attributeName = attribute.isRequired
    ? `* ${attribute?.name}`
    : attribute.name;

  return (
    <>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        ref={container}
      >
        <ListItemWrapper
          onClick={handleAttributeClick}
          selected={selected}
          attributeVersion
        >
          <>
            <Icon style={styles.dragIcon}>drag_indicator_icon</Icon>
            <Icon style={iconStyles}>{attributeIcon}</Icon>
            <div style={textStyles}>{attributeName}</div>
            {showActionButtons && (
              <div>
                <IconButton
                  tooltip="Delete"
                  onClick={() => setOpenAttributeDelete(true)}
                  size="small"
                >
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            )}
          </>
        </ListItemWrapper>
      </div>
      <DeleteModal
        open={openAttributeDelete}
        onConfirm={handleAttributeDelete}
        onCancel={() => setOpenAttributeDelete(false)}
        title="Delete Attribute?"
        content={`Are you  sure you want to delete the ${attribute.name} attribute?`}
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  );
};

export default AttributeRow;

import React, { useContext } from 'react';
import * as R from 'ramda';

import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { AttributesType } from '@atom/types/inventory';
import { SchemaTreeAttribute } from '@atom/types/schema';

import BooleanAttribute from '../../attributeControls/BooleanAttribute';
import CurrencyAttribute from '../../attributeControls/CurrencyAttribute';
import DateAttribute from '../../attributeControls/DateAttribute';
import EnumMultipleAttribute from '../../attributeControls/EnumMultipleAttribute';
import EnumSingleAttribute from '../../attributeControls/EnumSingleAttribute';
import LongTextAttribute from '../../attributeControls/LongTextAttribute';
import NumberAttribute from '../../attributeControls/NumberAttribute';
import ShortTextAttribute from '../../attributeControls/ShortTextAttribute';

import './attributeDetailResponsive.css';

interface Props {
  attributes: AttributesType[] | SchemaTreeAttribute[];
  width?: string;
  schemaPreview?: boolean;
  errorAttributeIds?: string[];
}

const EditAttributeListResponsive = ({
  attributes,
  width,
  schemaPreview = false,
  errorAttributeIds = [],
}: Props) => {
  const { setUnsavedChanges } = useContext(WorkOrderAssetViewContext);

  const buildAttribute = (attribute: AttributesType & SchemaTreeAttribute) => {
    const {
      id,
      value,
      newValue,
      defaultValue,
      dataType,
      enumeration,
      unit,
      changeId,
      isEditable,
    } = attribute;

    const displayValue = !!changeId
      ? newValue
      : schemaPreview
      ? defaultValue
      : value;

    const props = {
      name: id,
      value: displayValue,
      isEditable,
      onUpdate: () => setUnsavedChanges(true),
      unit,
      enumeration,
    };

    switch (dataType) {
      case 'boolean': {
        return <BooleanAttribute {...props} />;
      }
      case 'longtext': {
        return <LongTextAttribute {...props} />;
      }
      case 'date': {
        return (
          // @ts-ignore
          <DateAttribute {...props} />
        );
      }
      case 'number': {
        return <NumberAttribute {...props} />;
      }
      case 'currency': {
        return <CurrencyAttribute {...props} />;
      }
      case 'enumsingle': {
        return <EnumSingleAttribute {...props} />;
      }
      case 'enummultiple': {
        return <EnumMultipleAttribute {...props} />;
      }
      default: {
        return <ShortTextAttribute {...props} />;
      }
    }
  };

  return (
    <div styleName="attribute-list-container">
      {attributes.map((attribute, key) => {
        const hasError = R.includes(attribute?.id, errorAttributeIds);

        const nameStyles = hasError
          ? 'attribute-label edit error'
          : 'attribute-label edit';
        const attributeName = attribute.isRequired
          ? `* ${attribute.name}`
          : attribute.name;

        return (
          <div styleName="attribute-list-row" key={key}>
            <div style={{ minWidth: width }} styleName={nameStyles}>
              {attributeName}
              {attribute.unit && ` (${attribute.unit})`}:
            </div>
            <div styleName="attribute-value">{buildAttribute(attribute)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default EditAttributeListResponsive;

import * as React from 'react';

import TextLimit from '@atom/components/common/TextLimit';
import { TextField } from '@atom/mui';
import {
  MAX_CHAR_MEDIUM_INPUT,
  MAX_CHAR_SHORT_INPUT,
  REVEAL_LONG,
} from '@atom/selectors/formSelectors';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import { FormFieldType } from '@atom/types/form';

import '../formBuilder.css';

const styles = {
  titleStyles: {
    fontSize: fontStyles.xl,
    fontWeight: '500',
    color: colors.neutral.dark,
  },
  inputStyle: {
    fontSize: fontStyles.md,
    color: colors.neutral.dark,
  },
};

interface Props {
  field: FormFieldType;
  text: string;
  onChange: (event: Object) => void;
}

const TitleAndDescriptionInput = ({ field, text, onChange }: Props) => {
  const textStyle =
    field.type === 'title' ? styles.titleStyles : styles.inputStyle;
  const textLimit =
    field.type === 'title' ? MAX_CHAR_SHORT_INPUT : MAX_CHAR_MEDIUM_INPUT;

  return (
    <div>
      <TextField
        value={text}
        onChange={onChange}
        name="text"
        inputProps={{ style: textStyle }}
      />
      <div styleName="text-limit-container">
        <TextLimit limit={textLimit} reveal={REVEAL_LONG} text={text} />
      </div>
    </div>
  );
};

export default TitleAndDescriptionInput;

import * as R from 'ramda';

import { SchemaTreeAttributeGroup } from '@atom/types/schema';

import { PendingGroupUpdates } from '../../SchemaDetailContext';

export const updatePendingGroupUpdates = (
  schemaId: string,
  existingPendingUpdates: PendingGroupUpdates,
  attributeGroupToUpdate: Partial<SchemaTreeAttributeGroup>,
  propertyToUpdate: keyof SchemaTreeAttributeGroup,
  value: any,
): PendingGroupUpdates => {
  const hasSchemaEntry = R.has(schemaId)(existingPendingUpdates);
  const hasAttributeGroupEntry = hasSchemaEntry
    ? R.has(attributeGroupToUpdate.id)(existingPendingUpdates[schemaId])
    : false;

  const updatedPendingGroupUpdates = {
    ...existingPendingUpdates,
    [schemaId]: {
      ...(hasSchemaEntry ? existingPendingUpdates[schemaId] : {}),
      [attributeGroupToUpdate.id]: {
        id: attributeGroupToUpdate.id,
        ...(hasAttributeGroupEntry
          ? existingPendingUpdates[schemaId][attributeGroupToUpdate.id]
          : {}),
        [propertyToUpdate]: value,
      },
    },
  };

  return updatedPendingGroupUpdates;
};

import React, { useContext } from 'react';

import RequestStatusPill from '@atom/components/common/requests/requestsTable/RequestStatusPill';
import { Icon, IconButton } from '@atom/mui';
import { AssetRequestType } from '@atom/types/assetRequest';

import ViewRequestContext from './ViewRequestContext';

import './viewRequest.css';

const ViewRequestHeader = () => {
  const { activeRequest, setActiveRequestId } = useContext(ViewRequestContext);

  const showStatus =
    activeRequest?.type === AssetRequestType.SDDOT_INVENTORY_TRANSFER ||
    activeRequest?.type === AssetRequestType.SDDOT_REPLENISHMENT;

  return (
    <div styleName="modal-header">
      <div>{activeRequest?.name}</div>
      <div styleName="right-header-container">
        {showStatus && <RequestStatusPill status={activeRequest?.status} />}
        <IconButton onClick={() => setActiveRequestId(null)} edge="end">
          <Icon>close</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default ViewRequestHeader;

import React, { useContext } from 'react';
import { SortDirection } from '@mui/material';

import { getTableColumns } from '@atom/components/common/requests/customTenantUtilities';
import { ListTable } from '@atom/mui';
import { Column } from '@atom/types/assetRequest';

import RequestsContext from '../RequestsContext';

const { TableHead, TableRow, TableCell } = ListTable;

const RequestsTableHeader = () => {
  const {
    filters,
    sortBy,
    setSortBy,
    isAscending,
    setIsAscending,
  } = useContext(RequestsContext);

  const columns = getTableColumns(filters.type, false);

  const getSortDirection = (field: string): SortDirection => {
    if (sortBy === field) {
      return isAscending ? 'asc' : 'desc';
    }

    return false;
  };

  const handleSortDirectionChange = (field: string) => (
    sortByValue: SortDirection,
  ) => {
    setSortBy(field);
    setIsAscending(sortByValue === 'asc');
  };

  const getHeaderCell = (column: Column) => {
    const columnProps = {
      key: column.label,
      ...(column.sortValue && {
        sortDirection: getSortDirection(column.sortValue),
        onSortChange: handleSortDirectionChange(column.sortValue),
      }),
    };

    return (
      <TableCell variant="head" {...columnProps}>
        {column.label}
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow header>{columns.map(column => getHeaderCell(column))}</TableRow>
    </TableHead>
  );
};

export default RequestsTableHeader;

export default {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_SET: 'USER_SET',

  REQUEST_USER_PASSWORD_RESET: 'REQUEST_USER_PASSWORD_RESET',
  USER_PASSWORD_RESET_SUCCESS: 'USER_PASSWORD_RESET_SUCCESS',
  USER_PASSWORD_RESET_FAILURE: 'USER_PASSWORD_RESET_FAILURE',

  REQUEST_USER_TOKEN_PERSIST: 'REQUEST_USER_TOKEN_PERSIST',
  USER_TOKEN_PERSIST_SUCCESS: 'USER_TOKEN_PERSIST_SUCCESS',
  USER_TOKEN_PERSIST_FAILURE: 'USER_TOKEN_PERSIST_FAILURE',

  REQUEST_USER_ORGANIZATIONS: 'REQUEST_USER_ORGANIZATIONS',
  USER_ORGANIZATIONS_GET_SUCCESS: 'USER_ORGANIZATIONS_GET_SUCCESS',
  USER_ORGANIZATIONS_GET_FAILURE: 'USER_ORGANIZATIONS_GET_FAILURE',

  REQUEST_TENANT_CREATION: 'REQUEST_TENANT_CREATION',
  CREATE_TENANT_SUCCESS: 'CREATE_TENANT_SUCCESS',
  CREATE_TENANT_FAILURE: 'CREATE_TENANT_FAILURE',

  REQUEST_EMAIL_VERIFICATION_LINK: 'REQUEST_EMAIL_VERIFICATION_LINK',
  GET_EMAIL_VERIFICATION_LINK_SUCCESS: 'GET_EMAIL_VERIFICATION_LINK_SUCCESS',
  GET_EMAIL_VERIFICATION_LINK_FAILURE: 'GET_EMAIL_VERIFICATION_LINK_FAILURE',

  REQUEST_USER_AUTH_TOKEN_EXCHANGE: 'REQUEST_USER_AUTH_TOKEN_EXCHANGE',
  USER_AUTH_TOKEN_EXCHANGE_SUCCESS: 'USER_TOKEN_PERSIST_SUCCESS',
  USER_AUTH_TOKEN_EXCHANGE_FAILURE: 'USER_AUTH_TOKEN_EXCHANGE_FAILURE',

  REQUEST_USER_REGISTER: 'REQUEST_USER_REGISTER',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

  USER_FORGOT_PASSWORD: 'USER_FORGOT_PASSWORD',
  USER_CHANGE_PASSWORD: 'USER_CHANGE_PASSWORD',
  USER_UPDATE_PROFILE: 'USER_UPDATE_PROFILE',
  USER_VERIFY_EMAIL: 'USER_VERIFY_EMAIL',

  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  TOGGLE_NAVIGATION_MENU: 'TOGGLE_NAVIGATION_MENU',
  TOGGLE_DASHBOARDS: 'TOGGLE_DASHBOARDS',

  REQUEST_POOL_ATTRIBUTES: 'REQUEST_POOL_ATTRIBUTES',
  POOL_ATTRIBUTES_GET_SUCCESS: 'POOL_ATTRIBUTES_GET_SUCCESS',
  POOL_ATTRIBUTES_GET_FAILURE: 'POOL_ATTRIBUTES_GET_FAILURE',

  CLEAR_APPLICATION_SEARCH: 'CLEAR_APPLICATION_SEARCH',
  REQUEST_APPLICATION_SEARCH: 'REQUEST_APPLICATION_SEARCH',
  GET_APPLICATION_SEARCH_SUCCESS: 'GET_APPLICATION_SEARCH_SUCCESS',
  GET_APPLICATION_SEARCH_FAILURE: 'GET_APPLICATION_SEARCH_FAILURE',

  REQUEST_USERS: 'REQUEST_USERS',
  USERS_GET_SUCCESS: 'USERS_GET_SUCCESS',
  USERS_GET_FAILURE: 'USERS_GET_FAILURE',
  USERS_TOTAL_GET_SUCCESS: 'USERS_TOTAL_GET_SUCCESS',

  REQUEST_USER: 'REQUEST_USER',
  USER_GET_SUCCESS: 'USER_GET_SUCCESS',
  USER_GET_FAILURE: 'USER_GET_FAILURE',

  REQUEST_DEACTIVATE_USER: 'REQUEST_DEACTIVATE_USER',
  DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
  DEACTIVATE_USER_FAILURE: 'DEACTIVATE_USER_FAILURE',

  REQUEST_VERIFY_USER: 'REQUEST_VERIFY_USER',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',

  REQUEST_USER_DELETION: 'REQUEST_USER_DELETION',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  REQUEST_USER_ACTIVATION: 'REQUEST_USER_ACTIVATION',
  ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAILURE: 'ACTIVATE_USER_FAILURE',

  REQUEST_USER_UPDATE: 'REQUEST_USER_UPDATE',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  REQUEST_LOGGED_IN_USER: 'REQUEST_LOGGED_IN_USER',
  LOGGED_IN_USER_GET_SUCCESS: 'LOGGED_IN_USER_GET_SUCCESS',
  LOGGED_IN_USER_GET_FAILURE: 'LOGGED_IN_USER_GET_FAILURE',
  LOGGED_IN_USER_FORBIDDEN: 'LOGGED_IN_USER_FORBIDDEN',
  LOADING_USER: 'LOADING_USER',
  TRIGGER_LOGOUT: 'TRIGGER_LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  REQUEST_ASSIGNEE_DELETE: 'REQUEST_ASSIGNEE_DELETE',
  DELETE_ASSIGNEE_SUCCESS: 'DELETE_ASSIGNEE_SUCCESS',
  DELETE_ASSIGNEE_FAILURE: 'DELETE_ASSIGNEE_FAILURE',

  MAP_LOCATION_SELECTOR: 'MAP_LOCATION_SELECTOR',
  CANCEL_MAP_LOCATION_SELECTOR: 'CANCEL_MAP_LOCATION_SELECTOR',
  SET_MAP_LOCATION_POINT: 'SET_MAP_LOCATION_POINT',
  SET_MAP_LOCATION_TYPE_ID: 'SET_MAP_LOCATION_TYPE_ID',
  EDIT_MAP_LOCATION_POINT: 'EDIT_MAP_LOCATION_POINT',
  RESET_MAP_LOCATION_POINT: 'RESET_MAP_LOCATION_POINT',
  SET_MAP_CENTER: 'SET_MAP_CENTER',

  REQUEST_POLL_MAP_USERS: 'REQUEST_POLL_MAP_USERS',
  REQUEST_STOP_POLL_MAP_USERS: 'REQUEST_STOP_POLL_MAP_USERS',

  UPDATE_WORK_ORDER_DATA_SUCCESS: 'UPDATE_WORK_ORDER_DATA_SUCCESS',

  REQUEST_WORK_ORDERS: 'REQUEST_WORK_ORDERS',
  GET_WORK_ORDERS_SUCCESS: 'GET_WORK_ORDERS_SUCCESS',
  GET_WORK_ORDERS_FAILURE: 'GET_WORK_ORDERS_FAILURE',

  GET_WORK_TOTAL_SUCCESS: 'GET_WORK_TOTAL_SUCCESS',
  GET_WORK_TOTAL_FAILURE: 'GET_WORK_TOTAL_FAILURE',

  REQUEST_WORK_ORDER_DETAIL: 'REQUEST_WORK_ORDER_DETAIL',
  GET_WORK_ORDER_DETAIL_SUCCESS: 'GET_WORK_ORDER_DETAIL_SUCCESS',
  GET_WORK_ORDER_DETAIL_FAILURE: 'GET_WORK_ORDER_DETAIL_FAILURE',

  REQUEST_CREATE_WORK_ORDER: 'REQUEST_CREATE_WORK_ORDER',
  CREATE_WORK_ORDER_SUCCESS: 'CREATE_WORK_ORDER_SUCCESS',
  CREATE_WORK_ORDER_FAILURE: 'CREATE_WORK_ORDER_FAILURE',

  REQUEST_PATCH_WORK_ORDER: 'REQUEST_PATCH_WORK_ORDER',
  PATCH_WORK_ORDER_SUCCESS: 'PATCH_WORK_ORDER_SUCCESS',
  PATCH_WORK_ORDER_FAILURE: 'PATCH_WORK_ORDER_FAILURE',

  REQUEST_DELETE_WORK_ORDER: 'REQUEST_DELETE_WORK_ORDER',
  DELETE_WORK_ORDER_SUCCESS: 'DELETE_WORK_ORDER_SUCCESS',
  DELETE_WORK_ORDER_FAILURE: 'DELETE_WORK_ORDER_FAILURE',

  REQUEST_BULK_WORK_CREATION: 'REQUEST_BULK_WORK_CREATION',
  BULK_WORK_CREATION_SUCCESS: 'BULK_WORK_CREATION_SUCCESS',
  BULK_WORK_CREATION_FAILURE: 'BULK_WORK_CREATION_FAILURE',

  UPDATE_WORK_ORDERS_INPUT: 'UPDATE_WORK_ORDERS_INPUT',

  REQUEST_BULK_WORK_CREATION_FROM_FILTER:
    'REQUEST_BULK_WORK_CREATION_FROM_FILTER',
  BULK_WORK_CREATION_FROM_FILTER_SUCCESS:
    'BULK_WORK_CREATION_FROM_FILTER_SUCCESS',
  BULK_WORK_CREATION_FROM_FILTER_FAILURE:
    'BULK_WORK_CREATION_FROM_FILTER_FAILURE',

  CLEAR_WORK_ORDER_LOADING_STATE: 'CLEAR_WORK_ORDER_LOADING_STATE',

  TOKEN_SET: 'TOKEN_SET',
  TOKEN_REFRESH: 'TOKEN_REFRESH',

  REQUEST_FORM_TEMPLATES: 'REQUEST_FORM_TEMPLATES',
  GET_FORM_TEMPLATES_SUCCESS: 'GET_FORM_TEMPLATES_SUCCESS',
  GET_FORM_TEMPLATES_FAILURE: 'GET_FORM_TEMPLATES_FAILURE',
  GET_FORM_TEMPLATES_TOTAL_SUCCESS: 'GET_FORM_TEMPLATES_TOTAL_SUCCESS',

  REQUEST_FORM_TEMPLATE_DELETE: 'REQUEST_FORM_TEMPLATE_DELETE',
  DELETE_FORM_TEMPLATE_SUCCESS: 'DELETE_FORM_TEMPLATE_SUCCESS',
  DELETE_FORM_TEMPLATE_FAILURE: 'DELETE_FORM_TEMPLATE_FAILURE',

  REQUEST_FORM_TEMPLATE: 'REQUEST_FORM_TEMPLATE',
  FORM_TEMPLATE_GET_SUCCESS: 'FORM_TEMPLATE_GET_SUCCESS',
  FORM_TEMPLATE_GET_FAILURE: 'FORM_TEMPLATE_GET_FAILURE',

  REQUEST_CLEAR_FORM_TEMPLATE: 'REQUEST_CLEAR_FORM_TEMPLATE',
  FORM_TEMPLATE_CLEAR_SUCCESS: 'FORM_TEMPLATE_CLEAR_SUCCESS',

  REQUEST_UPDATE_FORM_TEMPLATE: 'REQUEST_UPDATE_FORM_TEMPLATE',
  REQUEST_UPDATE_FORM_TEMPLATE_TEXT: 'REQUEST_UPDATE_FORM_TEMPLATE_TEXT',
  UPDATE_FORM_TEMPLATE_SUCCESS: 'UPDATE_FORM_TEMPLATE_SUCCESS',
  UPDATE_FORM_TEMPLATE_FAILURE: 'UPDATE_FORM_TEMPLATE_FAILURE',

  REQUEST_CREATE_FORM_TEMPLATE: 'REQUEST_CREATE_FORM_TEMPLATE',
  CREATE_FORM_TEMPLATE_SUCCESS: 'CREATE_FORM_TEMPLATE_SUCCESS',
  CREATE_FORM_TEMPLATE_FAILURE: 'CREATE_FORM_TEMPLATE_FAILURE',

  REQUEST_DUPLICATE_FORM_TEMPLATE: 'REQUEST_DUPLICATE_FORM_TEMPLATE',
  DUPLICATE_FORM_TEMPLATE_SUCCESS: 'DUPLICATE_FORM_TEMPLATE_SUCCESS',
  DUPLICATE_FORM_TEMPLATE_FAILURE: 'DUPLICATE_FORM_TEMPLATE_FAILURE',

  REQUEST_RENAME_FORM_TEMPLATE: 'REQUEST_RENAME_FORM_TEMPLATE',
  RENAME_FORM_TEMPLATE_SUCCESS: 'RENAME_FORM_TEMPLATE_SUCCESS',
  RENAME_FORM_TEMPLATE_FAILURE: 'RENAME_FORM_TEMPLATE_FAILURE',

  REQUEST_FORM_INSTANCE: 'REQUEST_FORM_INSTANCE',
  GET_FORM_INSTANCE_SUCCESS: 'GET_FORM_INSTANCE_SUCCESS',
  GET_FORM_INSTANCE_FAILURE: 'GET_FORM_INSTANCE_FAILURE',

  REQUEST_CLEAR_FORM_INSTANCE: 'REQUEST_CLEAR_FORM_INSTANCE',
  CLEAR_FORM_INSTANCE_SUCCESS: 'CLEAR_FORM_INSTANCE_SUCCESS',

  REQUEST_CREATE_FORM_INSTANCE: 'REQUEST_CREATE_FORM_INSTANCE',
  CREATE_FORM_INSTANCE_SUCCESS: 'CREATE_FORM_INSTANCE_SUCCESS',
  CREATE_FORM_INSTANCE_FAILURE: 'CREATE_FORM_INSTANCE_FAILURE',

  REQUEST_PATCH_FORM_INSTANCE: 'REQUEST_PATCH_FORM_INSTANCE',
  PATCH_FORM_INSTANCE_SUCCESS: 'PATCH_FORM_INSTANCE_SUCCESS',
  PATCH_FORM_INSTANCE_FAILURE: 'PATCH_FORM_INSTANCE_FAILURE',

  REQUEST_UPDATE_FORM_INSTANCE_FIELD: 'REQUEST_UPDATE_FORM_INSTANCE_FIELD',
  REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FIELD:
    'REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FIELD',
  UPDATE_FORM_INSTANCE_FIELD_SUCCESS: 'UPDATE_FORM_INSTANCE_FIELD_SUCCESS',
  UPDATE_FORM_INSTANCE_FIELD_FAILURE: 'UPDATE_FORM_INSTANCE_FIELD_FAILURE',

  REQUEST_UPDATE_FORM_INSTANCE_ATTRIBUTE:
    'REQUEST_UPDATE_FORM_INSTANCE_ATTRIBUTE',
  UPDATE_FORM_INSTANCE_ATTRIBUTE_SUCCESS:
    'UPDATE_FORM_INSTANCE_ATTRIBUTE_SUCCESS',
  UPDATE_FORM_INSTANCE_ATTRIBUTE_FAILURE:
    'UPDATE_FORM_INSTANCE_ATTRIBUTE_FAILURE',
  REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_ATTRIBUTE:
    'REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_ATTRIBUTE',

  REQUEST_UPDATE_FORM_INSTANCE_FHWA: 'REQUEST_UPDATE_FORM_INSTANCE_FHWA',
  UPDATE_FORM_INSTANCE_FHWA_SUCCESS: 'UPDATE_FORM_INSTANCE_FHWA_SUCCESS',
  UPDATE_FORM_INSTANCE_FHWA_FAILURE: 'UPDATE_FORM_INSTANCE_FHWA_FAILURE',
  REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FHWA:
    'REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_FHWA',

  REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_CI:
    'REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_CI',
  UPDATE_FORM_INSTANCE_CI_SUCCESS: 'UPDATE_FORM_INSTANCE_CI_SUCCESS',
  UPDATE_FORM_INSTANCE_CI_FAILURE: 'UPDATE_FORM_INSTANCE_CI_FAILURE',

  REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_PCI:
    'REQUEST_PROGRESSIVE_UPDATE_FORM_INSTANCE_PCI',
  UPDATE_FORM_INSTANCE_PCI_SUCCESS: 'UPDATE_FORM_INSTANCE_PCI_SUCCESS',
  UPDATE_FORM_INSTANCE_PCI_FAILURE: 'UPDATE_FORM_INSTANCE_PCI_FAILURE',

  REQUEST_DELETE_FORM_INSTANCE: 'REQUEST_DELETE_FORM_INSTANCE',
  DELETE_FORM_INSTANCE_SUCCESS: 'DELETE_FORM_INSTANCE_SUCCESS',
  DELETE_FORM_INSTANCE_FAILURE: 'DELETE_FORM_INSTANCE_FAILURE',

  REQUEST_ADD_FORM_INSTANCE_MEDIA: 'REQUEST_ADD_FORM_INSTANCE_MEDIA',
  ADD_FORM_INSTANCE_MEDIA_SUCCESS: 'ADD_FORM_INSTANCE_MEDIA_SUCCESS',
  ADD_FORM_INSTANCE_MEDIA_FAILURE: 'ADD_FORM_INSTANCE_MEDIA_FAILURE',

  REQUEST_DELETE_FORM_INSTANCE_MEDIA: 'REQUEST_DELETE_FORM_INSTANCE_MEDIA',
  DELETE_FORM_INSTANCE_MEDIA_SUCCESS: 'DELETE_FORM_INSTANCE_MEDIA_SUCCESS',
  DELETE_FORM_INSTANCE_MEDIA_FAILURE: 'DELETE_FORM_INSTANCE_MEDIA_FAILURE',

  REQUEST_INVENTORY_SCHEMAS: 'REQUEST_INVENTORY_SCHEMAS',
  GET_INVENTORY_SCHEMAS_SUCCESS: 'GET_INVENTORY_SCHEMAS_SUCCESS',
  GET_INVENTORY_SCHEMAS_FAILURE: 'GET_INVENTORY_SCHEMAS_FAILURE',

  REQUEST_SCHEMA_BY_IDS: 'REQUEST_SCHEMA_BY_IDS',
  GET_SCHEMA_BY_IDS_SUCCESS: 'GET_SCHEMA_BY_IDS_SUCCESS',
  GET_SCHEMA_BY_IDS_FAILURE: 'GET_SCHEMA_BY_IDS_FAILURE',

  REQUEST_INVENTORY_SCHEMA: 'REQUEST_INVENTORY_SCHEMA',
  REQUEST_INVENTORY_SCHEMA_BY_ASSET_ID: 'REQUEST_INVENTORY_SCHEMA_BY_ASSET_ID',
  GET_INVENTORY_SCHEMA_SUCCESS: 'GET_INVENTORY_SCHEMA_SUCCESS',
  GET_INVENTORY_SCHEMA_FAILURE: 'GET_INVENTORY_SCHEMA_FAILURE',

  REQUEST_INVENTORY_SCHEMA_TREE_NODE: 'REQUEST_INVENTORY_SCHEMA_TREE_NODE',
  GET_INVENTORY_SCHEMA_TREE_NODE_SUCCESS:
    'GET_INVENTORY_SCHEMA_TREE_NODE_SUCCESS',
  GET_INVENTORY_SCHEMA_TREE_NODE_FAILURE:
    'GET_INVENTORY_SCHEMA_TREE_NODE_FAILURE',

  REQUEST_INVENTORY_SCHEMA_CLEAR: 'REQUEST_INVENTORY_SCHEMA_CLEAR',
  CLEAR_INVENTORY_SCHEMA_SUCCESS: 'CLEAR_INVENTORY_SCHEMA_SUCCESS',

  REQUEST_SCHEMA_TREE: 'REQUEST_SCHEMA_TREE',
  GET_SCHEMA_TREE_SUCCESS: 'GET_SCHEMA_TREE_SUCCESS',
  GET_SCHEMA_TREE_FAILURE: 'GET_SCHEMA_TREE_FAILURE',

  REQUEST_SCHEMA_DETAIL: 'REQUEST_SCHEMA_DETAIL',
  GET_SCHEMA_DETAIL_SUCCESS: 'GET_SCHEMA_DETAIL_SUCCESS',
  GET_SCHEMA_DETAIL_FAILURE: 'GET_SCHEMA_DETAIL_FAILURE',

  REQUEST_SCHEMA_ATTRIBUTE_UPDATE: 'REQUEST_SCHEMA_ATTRIBUTE_UPDATE',
  SCHEMA_ATTRIBUTE_UPDATE_SUCCESS: 'SCHEMA_ATTRIBUTE_UPDATE_SUCCESS',
  SCHEMA_ATTRIBUTE_UPDATE_FAILURE: 'SCHEMA_ATTRIBUTE_UPDATE_FAILURE',

  REQUEST_SCHEMA_TREE_UPDATE: 'REQUEST_SCHEMA_TREE_UPDATE',
  UPDATE_SCHEMA_TREE_SUCCESS: 'UPDATE_SCHEMA_TREE_SUCCESS',
  UPDATE_SCHEMA_TREE_FAILURE: 'UPDATE_SCHEMA_TREE_FAILURE',

  REQUEST_CREATE_SCHEMA: 'REQUEST_CREATE_SCHEMA',
  CREATE_SCHEMA_SUCCESS: 'CREATE_SCHEMA_SUCCESS',
  CREATE_SCHEMA_FAILURE: 'CREATE_SCHEMA_FAILURE',

  REQUEST_DELETE_SCHEMA: 'REQUEST_DELETE_SCHEMA',
  DELETE_SCHEMA_SUCCESS: 'DELETE_SCHEMA_SUCCESS',
  DELETE_SCHEMA_FAILURE: 'DELETE_SCHEMA_FAILURE',

  REQUEST_UPDATE_SCHEMA: 'REQUEST_UPDATE_SCHEMA',
  UPDATE_SCHEMA_SUCCESS: 'UPDATE_SCHEMA_SUCCESS',
  UPDATE_SCHEMA_FAILURE: 'UPDATE_SCHEMA_FAILURE',

  REQUEST_UPDATE_GROUP: 'REQUEST_UPDATE_GROUP',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_FAILURE: 'UPDATE_GROUP_FAILURE',

  REQUEST_DELETE_GROUP: 'REQUEST_DELETE_GROUP',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE: 'DELETE_GROUP_FAILURE',

  REQUEST_CREATE_ATTRIBUTE_GROUP: 'REQUEST_CREATE_ATTRIBUTE_GROUP',
  CREATE_ATTRIBUTE_GROUP_SUCCESS: 'CREATE_ATTRIBUTE_GROUP_SUCCESS',
  CREATE_ATTRIBUTE_GROUP_FAILURE: 'CREATE_ATTRIBUTE_GROUP_FAILURE',

  REQUEST_UPDATE_ATTRIBUTE_GROUP: 'REQUEST_UPDATE_ATTRIBUTE_GROUP',
  UPDATE_ATTRIBUTE_GROUP_SUCCESS: 'UPDATE_ATTRIBUTE_GROUP_SUCCESS',
  UPDATE_ATTRIBUTE_GROUP_FAILURE: 'UPDATE_ATTRIBUTE_GROUP_FAILURE',

  REQUEST_DELETE_ATTRIBUTE_GROUP: 'REQUEST_DELETE_ATTRIBUTE_GROUP',
  DELETE_ATTRIBUTE_GROUP_SUCCESS: 'DELETE_ATTRIBUTE_GROUP_SUCCESS',
  DELETE_ATTRIBUTE_GROUP_FAILURE: 'DELETE_ATTRIBUTE_GROUP_FAILURE',

  REQUEST_CREATE_ATTRIBUTE: 'REQUEST_CREATE_ATTRIBUTE',
  CREATE_ATTRIBUTE_SUCCESS: 'CREATE_ATTRIBUTE_SUCCESS',
  CREATE_ATTRIBUTE_FAILURE: 'CREATE_ATTRIBUTE_FAILURE',

  REQUEST_UPDATE_ATTRIBUTE: 'REQUEST_UPDATE_ATTRIBUTE',
  UPDATE_ATTRIBUTE_SUCCESS: 'UPDATE_ATTRIBUTE_SUCCESS',
  UPDATE_ATTRIBUTE_FAILURE: 'UPDATE_ATTRIBUTE_FAILURE',

  REQUEST_DELETE_ATTRIBUTE: 'REQUEST_DELETE_ATTRIBUTE',
  DELETE_ATTRIBUTE_SUCCESS: 'DELETE_ATTRIBUTE_SUCCESS',
  DELETE_ATTRIBUTE_FAILURE: 'DELETE_ATTRIBUTE_FAILURE',

  REQUEST_INVENTORY_CATEGORY_TREE_NODE: 'REQUEST_INVENTORY_CATEGORY_TREE_NODE',
  GET_INVENTORY_CATEGORY_TREE_NODE_SUCCESS:
    'GET_INVENTORY_CATEGORY_TREE_NODE_SUCCESS',
  GET_INVENTORY_CATEGORY_TREE_NODE_FAILURE:
    'GET_INVENTORY_CATEGORY_TREE_NODE_FAILURE',

  REQUEST_INVENTORY_FILTER_CATEGORY_TREE_NODE:
    'REQUEST_INVENTORY_FILTER_CATEGORY_TREE_NODE',
  GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS:
    'GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS',
  GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_FAILURE:
    'GET_INVENTORY_FILTER_CATEGORY_TREE_NODE_FAILURE',

  CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE:
    'CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE',
  CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS:
    'CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE_SUCCESS',
  CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE_FAILURE:
    'CLEAR_INVENTORY_FILTER_CATEGORY_TREE_NODE_FAILURE',

  REQUEST_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE:
    'REQUEST_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE',
  GET_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE_SUCCESS:
    'GET_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE_SUCCESS',
  GET_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE_FAILURE:
    'GET_INVENTORY_ASSET_TREE_NODE_EXPANDED_UPDATE_FAILURE',

  REQUEST_INVENTORY_CATEGORY: 'REQUEST_INVENTORY_CATEGORY',
  GET_INVENTORY_CATEGORY_SUCCESS: 'GET_INVENTORY_CATEGORY_SUCCESS',
  GET_INVENTORY_CATEGORY_ERROR: 'GET_INVENTORY_CATEGORY_ERROR',

  REQUEST_INVENTORY_CATEGORY_UPDATE: 'REQUEST_INVENTORY_CATEGORY_UPDATE',
  UPDATE_INVENTORY_CATEGORY_SUCCESS: 'UPDATE_INVENTORY_CATEGORY_SUCCESS',
  UPDATE_INVENTORY_CATEGORY_FAILURE: 'UPDATE_INVENTORY_CATEGORY_FAILURE',

  REQUEST_INVENTORY_CATEGORY_CREATE: 'REQUEST_INVENTORY_CATEGORY_CREATE',
  CREATE_INVENTORY_CATEGORY_SUCCESS: 'CREATE_INVENTORY_CATEGORY_SUCCESS',
  CREATE_INVENTORY_CATEGORY_FAILURE: 'CREATE_INVENTORY_CATEGORY_FAILURE',

  REQUEST_INVENTORY_CATEGORY_DELETE: 'REQUEST_INVENTORY_CATEGORY_DELETE',
  DELETE_INVENTORY_CATEGORY_SUCCESS: 'DELETE_INVENTORY_CATEGORY_SUCCESS',
  DELETE_INVENTORY_CATEGORY_FAILURE: 'DELETE_INVENTORY_CATEGORY_FAILURE',

  REQUEST_CATEGORY_TREE_UPDATE: 'REQUEST_CATEGORY_TREE_UPDATE',
  UPDATE_CATEGORY_TREE_SUCCESS: 'UPDATE_CATEGORY_TREE_SUCCESS',
  UPDATE_CATEGORY_TREE_FAILURE: 'UPDATE_CATEGORY_TREE_FAILURE',

  REQUEST_CREATE_CATEGORY: 'REQUEST_CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',

  REQUEST_FULL_CATEGORY_TREE: 'REQUEST_FULL_CATEGORY_TREE',
  GET_FULL_CATEGORY_TREE_SUCCESS: 'GET_FULL_CATEGORY_TREE_SUCCESS',
  GET_FULL_CATEGORY_TREE_FAILURE: 'GET_FULL_CATEGORY_TREE_FAILURE',

  REQUEST_INVENTORY_CATEGORIES: 'REQUEST_INVENTORY_CATEGORIES',
  GET_INVENTORY_CATEGORIES_SUCCESS: 'GET_INVENTORY_CATEGORIES_SUCCESS',
  GET_INVENTORY_CATEGORIES_FAILURE: 'GET_INVENTORY_CATEGORIES_FAILURE',

  REQUEST_CREATE_ASSET: 'REQUEST_CREATE_ASSET',
  CREATE_ASSET_SUCCESS: 'CREATE_ASSET_SUCCESS',
  CREATE_ASSET_FAILURE: 'CREATE_ASSET_FAILURE',

  REQUEST_INVENTORY_ASSETS: 'REQUEST_INVENTORY_ASSETS',
  GET_INVENTORY_ASSETS_SUCCESS: 'GET_INVENTORY_ASSETS_SUCCESS',
  GET_INVENTORY_ASSETS_FAILURE: 'GET_INVENTORY_ASSETS_FAILURE',

  REQUEST_ASSET_PENDING_APPROVAL: 'REQUEST_ASSET_PENDING_APPROVAL',
  ASSET_PENDING_APPROVAL_SUCCESS: 'ASSET_PENDING_APPROVAL_SUCCESS',
  ASSET_PENDING_APPROVAL_FAILURE: 'ASSET_PENDING_APPROVAL_FAILURE',

  REQUEST_ELEMENT_ATTRIBUTE_PENDING_APPROVAL:
    'REQUEST_ELEMENT_ATTRIBUTE_PENDING_APPROVAL',
  REQUEST_ASSET_ATTRIBUTE_PENDING_APPROVAL:
    'REQUEST_ASSET_ATTRIBUTE_PENDING_APPROVAL',

  REQUEST_INVENTORY_ASSETS_FILTER: 'REQUEST_INVENTORY_ASSETS_FILTER',
  GET_INVENTORY_ASSETS_FILTER_SUCCESS: 'GET_INVENTORY_ASSETS_FILTER_SUCCESS',
  GET_INVENTORY_ASSETS_FILTER_FAILURE: 'GET_INVENTORY_ASSETS_FILTER_FAILURE',

  REQUEST_ELEMENT_BATCH_CREATE: 'REQUEST_ELEMENT_BATCH_CREATE',
  CREATE_ELEMENT_BATCH_SUCCESS: 'CREATE_ELEMENT_BATCH_SUCCESS',
  CREATE_ELEMENT_BATCH_FAILURE: 'CREATE_ELEMENT_BATCH_FAILURE',

  REQUEST_ROOT_ASSET_BATCH_CREATE: 'REQUEST_ROOT_ASSET_BATCH_CREATE',
  CREATE_ROOT_ASSET_BATCH_SUCCESS: 'CREATE_ROOT_ASSET_BATCH_SUCCESS',
  CREATE_ROOT_ASSET_BATCH_FAILURE: 'CREATE_ROOT_ASSET_BATCH_FAILURE',

  REQUEST_ROOT_ASSET_BATCH_CREATE_FHWA: 'REQUEST_ROOT_ASSET_BATCH_CREATE_FHWA',
  CREATE_ROOT_ASSET_BATCH_FHWA_SUCCESS: 'CREATE_ROOT_ASSET_BATCH_FHWA_SUCCESS',
  CREATE_ROOT_ASSET_BATCH_FHWA_FAILURE: 'CREATE_ROOT_ASSET_BATCH_FHWA_FAILURE',

  REQUEST_FHWA_ELEMENT_DELETE: 'REQUEST_FHWA_ELEMENT_DELETE',
  DELETE_FHWA_ELEMENT_SUCCESS: 'DELETE_FHWA_ELEMENT_SUCCESS',
  DELETE_FHWA_ELEMENT_FAILURE: 'DELETE_FHWA_ELEMENT_FAILURE',

  REQUEST_INVENTORY_ASSET_DETAIL: 'REQUEST_INVENTORY_ASSET_DETAIL',
  GET_INVENTORY_ASSET_DETAIL_SUCCESS: 'GET_INVENTORY_ASSET_DETAIL_SUCCESS',
  GET_INVENTORY_ASSET_DETAIL_FAILURE: 'GET_INVENTORY_ASSET_DETAIL_FAILURE',

  REQUEST_INVENTORY_ASSET_TREE: 'REQUEST_INVENTORY_ASSET_TREE',
  GET_INVENTORY_ASSET_TREE_SUCCESS: 'GET_INVENTORY_ASSET_TREE_SUCCESS',
  GET_INVENTORY_ASSET_TREE_FAILURE: 'GET_INVENTORY_ASSET_TREE_FAILURE',

  REQUEST_INVENTORY_ELEMENT_DETAIL: 'REQUEST_INVENTORY_ELEMENT_DETAIL',
  GET_INVENTORY_ELEMENT_DETAIL_SUCCESS: 'GET_INVENTORY_ELEMENT_DETAIL_SUCCESS',
  GET_INVENTORY_ELEMENT_DETAIL_FAILURE: 'GET_INVENTORY_ELEMENT_DETAIL_FAILURE',

  REQUEST_INVENTORY_PORTAL_PATH_UPDATE: 'REQUEST_INVENTORY_PORTAL_PATH_UPDATE',
  INVENTORY_PORTAL_PATH_UPDATE_SUCCESS: 'INVENTORY_PORTAL_PATH_UPDATE_SUCCESS',

  REQUEST_INVENTORY_ASSET_TREE_NODE: 'REQUEST_INVENTORY_ASSET_TREE_NODE',
  GET_INVENTORY_ASSET_TREE_NODE_SUCCESS:
    'GET_INVENTORY_ASSET_TREE_NODE_SUCCESS',
  GET_INVENTORY_ASSET_TREE_NODE_FAILURE:
    'GET_INVENTORY_ASSET_TREE_NODE_FAILURE',

  REQUEST_INVENTORY_ELEMENT_TREE_NODE: 'REQUEST_INVENTORY_ELEMENT_TREE_NODE',
  GET_INVENTORY_ELEMENT_TREE_NODE_SUCCESS:
    'GET_INVENTORY_ELEMENT_TREE_NODE_SUCCESS',
  GET_INVENTORY_ELEMENT_TREE_NODE_FAILURE:
    'GET_INVENTORY_ELEMENT_TREE_NODE_FAILURE',

  REQUEST_INVENTORY_ASSET_DETAIL_UPDATE:
    'REQUEST_INVENTORY_ASSET_DETAIL_UPDATE',
  GET_INVENTORY_ASSET_DETAIL_UPDATE_SUCCESS:
    'GET_INVENTORY_ASSET_DETAIL_UPDATE_SUCCESS',
  GET_INVENTORY_ASSET_DETAIL_UPDATE_FAILURE:
    'GET_INVENTORY_ASSET_DETAIL_UPDATE_FAILURE',

  REQUEST_INVENTORY_ELEMENT_DETAIL_UPDATE:
    'REQUEST_INVENTORY_ELEMENT_DETAIL_UPDATE',
  GET_INVENTORY_ELEMENT_DETAIL_UPDATE_SUCCESS:
    'GET_INVENTORY_ELEMENT_DETAIL_UPDATE_SUCCESS',
  GET_INVENTORY_ELEMENT_DETAIL_UPDATE_FAILURE:
    'GET_INVENTORY_ELEMENT_DETAIL_UPDATE_FAILURE',

  REQUEST_INVENTORY_ASSET_TREE_NODE_UPDATE:
    'REQUEST_INVENTORY_ASSET_TREE_NODE_UPDATE',
  GET_INVENTORY_ASSET_TREE_NODE_UPDATE_SUCCESS:
    'GET_INVENTORY_ASSET_TREE_NODE_UPDATE_SUCCESS',
  GET_INVENTORY_ASSET_TREE_NODE_UPDATE_FAILURE:
    'GET_INVENTORY_ASSET_TREE_NODE_UPDATE_FAILURE',

  REQUEST_INVENTORY_ELEMENT_TREE_NODE_UPDATE:
    'REQUEST_INVENTORY_ELEMENT_TREE_NODE_UPDATE',
  GET_INVENTORY_ELEMENT_TREE_NODE_UPDATE_SUCCESS:
    'GET_INVENTORY_ELEMENT_TREE_NODE_UPDATE_SUCCESS',
  GET_INVENTORY_ELEMENT_TREE_NODE_UPDATE_FAILURE:
    'GET_INVENTORY_ELEMENT_TREE_NODE_UPDATE_FAILURE',

  REQUEST_INVENTORY_ASSET_DELETE: 'REQUEST_INVENTORY_ASSET_DELETE',
  DELETE_INVENTORY_ASSET_SUCCESS: 'DELETE_INVENTORY_ASSET_SUCCESS',
  DELETE_INVENTORY_ASSET_FAILURE: 'DELETE_INVENTORY_ASSET_FAILURE',

  REQUEST_ROOT_INVENTORY_ASSET_DELETION:
    'REQUEST_ROOT_INVENTORY_ASSET_DELETION',
  ROOT_INVENTORY_ASSET_DELETION_SUCCESS:
    'ROOT_INVENTORY_ASSET_DELETION_SUCCESS',
  ROOT_INVENTORY_ASSET_DELETION_FAILURE:
    'ROOT_INVENTORY_ASSET_DELETION_FAILURE',

  REQUEST_INVENTORY_ELEMENT_DELETE: 'REQUEST_INVENTORY_ELEMENT_DELETE',
  DELETE_INVENTORY_ELEMENT_SUCCESS: 'DELETE_INVENTORY_ELEMENT_SUCCESS',
  DELETE_INVENTORY_ELEMENT_FAILURE: 'DELETE_INVENTORY_ELEMENT_FAILURE',

  REQUEST_UPLOAD_FILES: 'REQUEST_UPLOAD_FILES',
  UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAILURE: 'UPLOAD_FILES_FAILURE',

  REQUEST_DOWNLOAD_FILES: 'REQUEST_DOWNLOAD_FILES',
  DOWNLOAD_FILES_SUCCESS: 'DOWNLOAD_FILES_SUCCESS',
  DOWNLOAD_FILES_FAILURE: 'DOWNLOAD_FILES_FAILURE',

  REQUEST_UPLOAD_ASSETS_CSV: 'REQUEST_UPLOAD_ASSETS_CSV',
  UPLOAD_ASSETS_CSV_SUCCESS: 'UPLOAD_ASSETS_CSV_SUCCESS',
  UPLOAD_ASSETS_CSV_FAILURE: 'UPLOAD_ASSETS_CSV_FAILURE',

  REQUEST_WORK_ORDER_REPORT_CREATION: 'REQUEST_WORK_ORDER_REPORT_CREATION',
  WORK_ORDER_CREATION_REPORT_SUCCESS: 'WORK_ORDER_CREATION_REPORT_SUCCESS',
  WORK_ORDER_CREATION_REPORT_FAILURE: 'WORK_ORDER_CREATION_REPORT_FAILURE',

  REQUEST_WORK_ORDER_HISTORY_SNAPSHOT_CREATION:
    'REQUEST_WORK_ORDER_HISTORY_SNAPSHOT_CREATION',
  WORK_ORDER_HISTORY_SNAPSHOT_SUCCESS: 'WORK_ORDER_HISTORY_SNAPSHOT_SUCCESS',
  WORK_ORDER_HISTORY_SNAPSHOT_FAILURE: 'WORK_ORDER_HISTORY_SNAPSHOT_FAILURE',

  REQUEST_WORK_STATUS: 'REQUEST_WORK_STATUS',
  WORK_STATUS_SUCCESS: 'WORK_STATUS_SUCCESS',
  WORK_STATUS_FAILURE: 'WORK_STATUS_FAILURE',

  REQUEST_TASK_MATERIAL_DELETE: 'REQUEST_TASK_MATERIAL_DELETE',
  DELETE_TASK_MATERIAL_SUCCESS: 'DELETE_TASK_MATERIAL_SUCCESS',
  DELETE_TASK_MATERIAL_FAILURE: 'DELETE_TASK_MATERIAL_FAILURE',

  REQUEST_TASK_MATERIAL_UPDATE: 'REQUEST_TASK_MATERIAL_UPDATE',
  UPDATE_TASK_MATERIAL_SUCCESS: 'UPDATE_TASK_MATERIAL_SUCCESS',
  UPDATE_TASK_MATERIAL_FAILURE: 'UPDATE_TASK_MATERIAL_FAILURE',

  REQUEST_TASK_MATERIAL_CREATE: 'REQUEST_TASK_MATERIAL_CREATE',
  CREATE_TASK_MATERIAL_SUCCESS: 'CREATE_TASK_MATERIAL_SUCCESS',
  CREATE_TASK_MATERIAL_FAILURE: 'CREATE_TASK_MATERIAL_FAILURE',

  REQUEST_TASK_ASSET_ADD: 'REQUEST_TASK_ASSET_ADD',
  ADD_TASK_ASSET_SUCCESS: 'ADD_TASK_ASSET_SUCCESS',
  ADD_TASK_ASSET_FAILURE: 'ADD_TASK_ASSET_FAILURE',

  REQUEST_TASK_ASSET_DELETE: 'REQUEST_TASK_ASSET_DELETE',
  DELETE_TASK_ASSET_SUCCESS: 'DELETE_TASK_ASSET_SUCCESS',
  DELETE_TASK_ASSET_FAILURE: 'DELETE_TASK_ASSET_FAILURE',

  REQUEST_TASK_USER_ADD: 'REQUEST_TASK_USER_ADD',
  ADD_TASK_USER_SUCCESS: 'ADD_TASK_USER_SUCCESS',
  ADD_TASK_USER_FAILURE: 'ADD_TASK_USER_FAILURE',

  REQUEST_MEDIA: 'REQUEST_MEDIA',
  GET_MEDIA_SUCCESS: 'GET_MEDIA_SUCCESS',
  GET_MEDIA_FAILURE: 'GET_MEDIA_FAILURE',
  GET_MEDIA_TOTAL_SUCCESS: 'GET_MEDIA_TOTAL_SUCCESS',

  REQUEST_PATCH_MEDIA: 'REQUEST_PATCH_MEDIA',
  PATCH_MEDIA_SUCCESS: 'PATCH_MEDIA_SUCCESS',
  PATCH_MEDIA_FAILURE: 'PATCH_MEDIA_FAILURE',

  REQUEST_DELETE_MEDIA: 'REQUEST_DELETE_MEDIA',
  DELETE_MEDIA_SUCCESS: 'DELETE_MEDIA_SUCCESS',
  DELETE_MEDIA_FAILURE: 'DELETE_MEDIA_FAILURE',

  REQUEST_MEDIA_FOLDER_TREE: 'REQUEST_MEDIA_FOLDER_TREE',
  GET_MEDIA_FOLDER_TREE_SUCCESS: 'GET_MEDIA_FOLDER_TREE_SUCCESS',
  GET_MEDIA_FOLDER_TREE_FAILURE: 'GET_MEDIA_FOLDER_TREE_FAILURE',

  REQUEST_MEDIA_FOLDER_TREE_UPDATE: 'REQUEST_MEDIA_FOLDER_TREE_UPDATE',
  UPDATE_MEDIA_FOLDER_TREE_SUCCESS: 'UPDATE_MEDIA_FOLDER_TREE_SUCCESS',
  UPDATE_MEDIA_FOLDER_TREE_FAILURE: 'UPDATE_MEDIA_FOLDER_TREE_FAILURE',

  REQUEST_MEDIA_TOTALS: 'REQUEST_MEDIA_TOTALS',
  GET_MEDIA_TOTALS_SUCCESS: 'GET_MEDIA_TOTALS_SUCCESS',
  GET_MEDIA_TOTALS_FAILURE: 'GET_MEDIA_TOTALS_FAILURE',

  REQUEST_MEDIA_FOLDERS: 'REQUEST_MEDIA_FOLDERS',
  GET_MEDIA_FOLDERS_SUCCESS: 'GET_MEDIA_FOLDERS_SUCCESS',
  GET_MEDIA_FOLDERS_FAILURE: 'GET_MEDIA_FOLDERS_FAILURE',

  REQUEST_MEDIA_FOLDER_UPDATE: 'REQUEST_MEDIA_FOLDER_UPDATE',
  UPDATE_MEDIA_FOLDER_SUCCESS: 'UPDATE_MEDIA_FOLDER_SUCCESS',
  UPDATE_MEDIA_FOLDER_FAILURE: 'UPDATE_MEDIA_FOLDER_FAILURE',

  REQUEST_MEDIA_FOLDER_CREATE: 'REQUEST_MEDIA_FOLDER_CREATE',
  CREATE_MEDIA_FOLDER_SUCCESS: 'CREATE_MEDIA_FOLDER_SUCCESS',
  CREATE_MEDIA_FOLDER_FAILURE: 'CREATE_MEDIA_FOLDER_FAILURE',

  REQUEST_MEDIA_FOLDER_DELETE: 'REQUEST_MEDIA_FOLDER_DELETE',
  DELETE_MEDIA_FOLDER_SUCCESS: 'DELETE_MEDIA_FOLDER_SUCCESS',
  DELETE_MEDIA_FOLDER_FAILURE: 'DELETE_MEDIA_FOLDER_FAILURE',

  REQUEST_TASK: 'REQUEST_TASK',
  GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
  GET_TASK_FAILURE: 'GET_TASK_FAILURE',

  REQUEST_CREATE_TASK: 'REQUEST_CREATE_TASK',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  CREATE_TASK_FAILURE: 'CREATE_TASK_FAILURE',

  REQUEST_PATCH_TASK: 'REQUEST_PATCH_TASK',
  PATCH_TASK_SUCCESS: 'PATCH_TASK_SUCCESS',
  PATCH_TASK_FAILURE: 'PATCH_TASK_FAILURE',

  REQUEST_DELETE_TASK: 'REQUEST_DELETE_TASK',
  DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILURE: 'DELETE_TASK_FAILURE',

  REQUEST_WORK_TEMPLATES: 'REQUEST_WORK_TEMPLATES',
  GET_WORK_TEMPLATES_SUCCESS: 'GET_WORK_TEMPLATES_SUCCESS',
  GET_WORK_TEMPLATES_FAILURE: 'GET_WORK_TEMPLATES_FAILURE',
  GET_WORK_TEMPLATES_TOTAL_SUCCESS: 'GET_WORK_TEMPLATES_TOTAL_SUCCESS',

  REQUEST_WORK_TEMPLATE_DELETION: 'REQUEST_WORK_TEMPLATE_DELETION',
  DELETE_WORK_TEMPLATE_SUCCESS: 'DELETE_WORK_TEMPLATE_SUCCESS',
  DELETE_WORK_TEMPLATE_FAILURE: 'DELETE_WORK_TEMPLATE_FAILURE',

  REQUEST_WORK_TEMPLATE_CREATION: 'REQUEST_WORK_TEMPLATE_CREATION',
  CREATE_WORK_TEMPLATE_SUCCESS: 'CREATE_WORK_TEMPLATE_SUCCESS',
  CREATE_WORK_TEMPLATE_FAILURE: 'CREATE_WORK_TEMPLATE_FAILURE',

  REQUEST_WORK_ORDER_ASSET: 'REQUEST_WORK_ORDER_ASSET',
  GET_WORK_ORDER_ASSET_SUCCESS: 'GET_WORK_ORDER_ASSET_SUCCESS',
  GET_WORK_ORDER_ASSET_FAILURE: 'GET_WORK_ORDER_ASSET_FAILURE',

  REQUEST_CREATE_WORK_ORDER_ASSET: 'REQUEST_CREATE_WORK_ORDER_ASSET',
  CREATE_WORK_ORDER_ASSET_SUCCESS: 'CREATE_WORK_ORDER_ASSET_SUCCESS',
  CREATE_WORK_ORDER_ASSET_FAILURE: 'CREATE_WORK_ORDER_ASSET_FAILURE',

  REQUEST_PATCH_WORK_ORDER_ASSET: 'REQUEST_PATCH_WORK_ORDER_ASSET',
  PATCH_WORK_ORDER_ASSET_SUCCESS: 'PATCH_WORK_ORDER_ASSET_SUCCESS',
  PATCH_WORK_ORDER_ASSET_FAILURE: 'PATCH_WORK_ORDER_ASSET_FAILURE',

  REQUEST_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE:
    'REQUEST_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE',
  GET_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE_SUCCESS:
    'GET_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE_SUCCESS',
  GET_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE_FAILURE:
    'GET_WORK_ORDER_ELEMENT_TREE_NODE_UPDATE_FAILURE',

  REQUEST_DELETE_WORK_ORDER_ASSET: 'REQUEST_DELETE_WORK_ORDER_ASSET',
  DELETE_WORK_ORDER_ASSET_SUCCESS: 'DELETE_WORK_ORDER_ASSET_SUCCESS',
  DELETE_WORK_ORDER_ASSET_FAILURE: 'DELETE_WORK_ORDER_ASSET_FAILURE',

  REQUEST_WORK_ORDER_ELEMENT_BATCH_CREATE:
    'REQUEST_WORK_ORDER_ELEMENT_BATCH_CREATE',
  CREATE_WORK_ORDER_ELEMENT_BATCH_SUCCESS:
    'CREATE_WORK_ORDER_ELEMENT_BATCH_SUCCESS',
  CREATE_WORK_ORDER_ELEMENT_BATCH_FAILURE:
    'CREATE_WORK_ORDER_ELEMENT_BATCH_FAILURE',

  REQUEST_WORK_ORDER_ASSET_ELEMENTS: 'REQUEST_WORK_ORDER_ASSET_ELEMENTS',
  GET_WORK_ORDER_ASSET_ELEMENTS_SUCCESS:
    'GET_WORK_ORDER_ASSET_ELEMENTS_SUCCESS',
  GET_WORK_ORDER_ASSET_ELEMENTS_FAILURE:
    'GET_WORK_ORDER_ASSET_ELEMENTS_FAILURE',

  REQUEST_INVENTORY_SEARCH: 'REQUEST_INVENTORY_SEARCH',
  GET_INVENTORY_SEARCH_SUCCESS: 'GET_INVENTORY_SEARCH_SUCCESS',
  GET_INVENTORY_SEARCH_FAILURE: 'GET_INVENTORY_SEARCH_FAILURE',

  UPDATE_TASK_BREADCRUMBS: 'UPDATE_TASK_BREADCRUMBS',
  UPDATE_INVENTORY_BREADCRUMBS: 'UPDATE_INVENTORY_BREADCRUMBS',

  REQUEST_VALIDATE_ASSETS_CSV: 'REQUEST_VALIDATE_ASSETS_CSV',
  VALIDATE_ASSETS_CSV_SUCCESS: 'VALIDATE_ASSETS_CSV_SUCCESS',
  VALIDATE_ASSETS_CSV_FAILURE: 'VALIDATE_ASSETS_CSV_FAILURE',

  REQUEST_HISTORY: 'REQUEST_HISTORY',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  GET_HISTORY_FAILURE: 'GET_HISTORY_FAILURE',

  REQUEST_EXPORT_TEMPLATES: 'REQUEST_EXPORT_TEMPLATES',
  GET_EXPORT_TEMPLATES_SUCCESS: 'GET_EXPORT_TEMPLATES_SUCCESS',
  GET_EXPORT_TEMPLATES_FAILURE: 'GET_EXPORT_TEMPLATES_FAILURE',

  REQUEST_EXPORT_TEMPLATE_DETAIL: 'REQUEST_EXPORT_TEMPLATE_DETAIL',
  GET_EXPORT_TEMPLATE_DETAIL_SUCCESS: 'GET_EXPORT_TEMPLATE_DETAIL_SUCCESS',
  GET_EXPORT_TEMPLATE_DETAIL_FAILURE: 'GET_EXPORT_TEMPLATE_DETAIL_FAILURE',

  REQUEST_EXPORT_TEMPLATE_CREATE: 'REQUEST_EXPORT_TEMPLATE_CREATE',
  CREATE_EXPORT_TEMPLATE_SUCCESS: 'CREATE_EXPORT_TEMPLATE_SUCCESS',
  CREATE_EXPORT_TEMPLATE_FAILURE: 'CREATE_EXPORT_TEMPLATE_FAILURE',

  REQUEST_EXPORT_TEMPLATE_DELETE: 'REQUEST_EXPORT_TEMPLATE_DELETE',
  DELETE_EXPORT_TEMPLATE_SUCCESS: 'DELETE_EXPORT_TEMPLATE_SUCCESS',
  DELETE_EXPORT_TEMPLATE_FAILURE: 'DELETE_EXPORT_TEMPLATE_FAILURE',

  REQUEST_EXPORT_TEMPLATE_UPDATE: 'REQUEST_EXPORT_TEMPLATE_UPDATE',
  UPDATE_EXPORT_TEMPLATE_SUCCESS: 'UPDATE_EXPORT_TEMPLATE_SUCCESS',
  UPDATE_EXPORT_TEMPLATE_FAILURE: 'UPDATE_EXPORT_TEMPLATE_FAILURE',

  REQUEST_ADD_EXPORT_TEMPLATE_SCHEMA: 'REQUEST_ADD_EXPORT_TEMPLATE_SCHEMA',
  ADD_EXPORT_TEMPLATE_SCHEMA_SUCCESS: 'ADD_EXPORT_TEMPLATE_SCHEMA_SUCCESS',
  ADD_EXPORT_TEMPLATE_SCHEMA_FAILURE: 'ADD_EXPORT_TEMPLATE_SCHEMA_FAILURE',

  REQUEST_DELETE_EXPORT_TEMPLATE_SCHEMA:
    'REQUEST_DELETE_EXPORT_TEMPLATE_SCHEMA',
  DELETE_EXPORT_TEMPLATE_SCHEMA_SUCCESS:
    'DELETE_EXPORT_TEMPLATE_SCHEMA_SUCCESS',
  DELETE_EXPORT_TEMPLATE_SCHEMA_FAILURE:
    'DELETE_EXPORT_TEMPLATE_SCHEMA_FAILURE',

  REQUEST_UPDATE_EXPORT_TEMPLATE_SCHEMA:
    'REQUEST_UPDATE_EXPORT_TEMPLATE_SCHEMA',
  UPDATE_EXPORT_TEMPLATE_SCHEMA_SUCCESS:
    'UPDATE_EXPORT_TEMPLATE_SCHEMA_SUCCESS',
  UPDATE_EXPORT_TEMPLATE_SCHEMA_FAILURE:
    'UPDATE_EXPORT_TEMPLATE_SCHEMA_FAILURE',

  UPDATE_WORK_VALIDATIONS: 'UPDATE_WORK_VALIDATIONS',

  UPDATE_TEAM_NAVIGATION: 'UPDATE_TEAM_NAVIGATION',
};

import React, { useContext } from 'react';

import { Icon, Menu } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Budget, BudgetModal } from '@atom/types/budget';

import BudgetsContext from './BudgetsContext';

import './budgets.css';

const { MenuItem } = Menu;

interface Props {
  budget: Budget;
  canEdit: boolean;
  canDuplicate: boolean;
  canDelete: boolean;
}

const BudgetsOptions = ({
  budget,
  canEdit,
  canDuplicate,
  canDelete,
}: Props) => {
  const { setActiveModal, setActiveFormBudget } = useContext(BudgetsContext);

  const handleOpenEdit = () => {
    setActiveModal(BudgetModal.EDIT);
    setActiveFormBudget(budget);
  };
  const handleOpenDuplicate = () => {
    setActiveModal(BudgetModal.DUPLICATE);
    setActiveFormBudget(budget);
  };
  const handleOpenDelete = () => {
    setActiveModal(BudgetModal.DELETE);
    setActiveFormBudget(budget);
  };

  return (
    <div
      styleName="options-wrapper"
      data-cy={`budgetOptionMenu_${budget?.name}`}
    >
      <Menu IconProps={{ color: colors.neutral.gray }}>
        {canEdit && (
          <MenuItem
            styleName="menu-item"
            onClick={handleOpenEdit}
            data-cy="budgetEditOption"
          >
            <div styleName="primary-text-box">
              <div styleName="icon-box">
                <Icon>edit</Icon>
                <div styleName="primary-text">Edit Budget</div>
              </div>
            </div>
          </MenuItem>
        )}
        {canDuplicate && (
          <MenuItem
            styleName="menu-item"
            onClick={handleOpenDuplicate}
            data-cy="budgetDuplicateOption"
          >
            <div styleName="primary-text-box">
              <div styleName="icon-box">
                <Icon>content_copy</Icon>
                <div styleName="primary-text">Duplicate Budget</div>
              </div>
            </div>
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem
            styleName="menu-item"
            onClick={handleOpenDelete}
            data-cy="budgetDeleteOption"
          >
            <div styleName="primary-text-box">
              <div styleName="icon-box">
                <Icon color={colors.neutral.gray}>delete</Icon>
                <div styleName="primary-text">Delete</div>
              </div>
            </div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default BudgetsOptions;

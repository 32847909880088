import React from 'react';
import * as R from 'ramda';

import { LocationDataTitle } from '@atom/types/taskLocation';

import './locationsAndAssetsSection.css';

const initialDisplayValues = {
  [LocationDataTitle.MANAGEMENT_UNIT]: '',
  [LocationDataTitle.COUNTY]: '',
  [LocationDataTitle.ROAD_CLASS]: '',
};

interface Props {
  attributes: any;
}

const AldotAttributeSection = ({ attributes }: Props) => {
  const getAldotDisplayAttributes = () =>
    R.values(attributes).reduce(
      (acc, attribute) =>
        R.values(LocationDataTitle).includes(attribute?.name)
          ? { ...acc, [attribute?.name]: attribute?.value }
          : acc,
      initialDisplayValues,
    );

  const values = getAldotDisplayAttributes();

  return (
    <>
      <div styleName="asset-subtext">{`${LocationDataTitle.MANAGEMENT_UNIT}: ${
        values[LocationDataTitle.MANAGEMENT_UNIT]
      }`}</div>
      <div styleName="asset-subtext">{`${LocationDataTitle.COUNTY}: ${
        values[LocationDataTitle.COUNTY]
      }`}</div>
      <div styleName="asset-subtext">{`${LocationDataTitle.ROAD_CLASS}: ${
        values[LocationDataTitle.ROAD_CLASS]
      }`}</div>
    </>
  );
};

export default AldotAttributeSection;

import { gql } from '@apollo/client';

export const BASE_ASSET_REQUEST = gql`
  fragment BaseAssetRequest on AssetRequest {
    id
    type
    name
    assetId
    assetName
    assetUnit
    quantity
    quantityOrdered
    quantityApproved
    reason
    comments
    status
    assignedTo {
      id
      firstName
      lastName
    }
    fromAssetId
    fromAssetName
    replenishments {
      id
      name
      quantityReceived
      unitCost
      totalCost
      invoiceNumber
      comments
      createdDate
      createdBy {
        id
        firstName
        lastName
      }
    }
    createdDate
    createdBy {
      id
      firstName
      lastName
    }
    updatedDate
    updatedBy {
      id
      firstName
      lastName
    }
  }
`;

export const GET_ASSET_REQUEST = gql`
  query assetRequest($id: ID!) {
    assetRequest(id: $id) {
      ...BaseAssetRequest
    }
  }
  ${BASE_ASSET_REQUEST}
`;

export const GET_ASSET_REQUESTS = gql`
  query assetRequests($input: AssetRequestsConnectionInput!) {
    assetRequests(input: $input) {
      totalCount
      assetRequests {
        ...BaseAssetRequest
      }
    }
  }
  ${BASE_ASSET_REQUEST}
`;

export const CREATE_ASSET_REQUEST = gql`
  mutation assetRequestCreate($input: AssetRequestCreateInput!) {
    assetRequestCreate(input: $input) {
      ...BaseAssetRequest
    }
  }
  ${BASE_ASSET_REQUEST}
`;

export const UPDATE_ASSET_REQUEST = gql`
  mutation assetRequestUpdate($input: AssetRequestUpdateInput!) {
    assetRequestUpdate(input: $input) {
      ...BaseAssetRequest
    }
  }
  ${BASE_ASSET_REQUEST}
`;

// Unique to SDDOT related tenants
export const CREATE_REPLENISHMENT = gql`
  mutation replenishmentCreate($input: ReplenishmentCreateInput!) {
    replenishmentCreate(input: $input) {
      ...BaseAssetRequest
    }
  }
  ${BASE_ASSET_REQUEST}
`;

import React, { useContext, useMemo } from 'react';

import { ADJUSTMENT_REASON_OPTIONS } from '@atom/components/common/requests/assetRequestConstants';
import { Select, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetRequestCreateInput } from '@atom/types/assetRequest';

import RequestsContext from '../../RequestsContext';

import QuantityAdjustmentSubtext from './QuantityAdjustmentSubtext';

import './sddotCreate.css';

const { MenuItem } = Select;

const styles = {
  label: {
    color: colors.neutral.dim,
  },
};

interface Props {
  createInputs: AssetRequestCreateInput;
  updateCreateInput: (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => void;
}

const SDDOTAdjustmentCreate = ({ createInputs, updateCreateInput }: Props) => {
  const { asset } = useContext(RequestsContext);

  const quantityError = useMemo(() => {
    const updatedQuantity =
      Number(asset?.budget?.quantityOnHand) + Number(createInputs?.quantity);

    return updatedQuantity < 0;
  }, [createInputs.quantity]);

  const numberUpdate = (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => {
    const updatedValue = value === '' ? null : Number(value);
    updateCreateInput(updatedValue, property);
  };

  return (
    <>
      <div styleName="input-container">
        <TextField
          InputLabelProps={{ style: styles.label }}
          type="number"
          value={createInputs?.quantity}
          label={`Quantity Adjusted (${asset?.budget?.unit})`}
          onChange={event => numberUpdate(event.target.value, 'quantity')}
          helperText={`${asset?.budget?.quantityOnHand} ${asset?.budget?.unit} in stock`}
          error={quantityError}
        />
        <QuantityAdjustmentSubtext quantity={createInputs?.quantity} />
      </div>
      <div styleName="input-container ">
        <Select
          key="adjustmentReason"
          label="Adjustment Reason"
          InputLabelProps={{ style: { color: colors.neutral.dim } }}
          fullWidth
          onChange={event => updateCreateInput(event.target.value, 'reason')}
          value={createInputs.reason}
        >
          {ADJUSTMENT_REASON_OPTIONS.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div styleName="input-container">
        <TextField
          multiline
          InputLabelProps={{ style: styles.label }}
          value={createInputs?.comments}
          label="Comments (optional)"
          onChange={event => updateCreateInput(event.target.value, 'comments')}
        />
      </div>
    </>
  );
};

export default SDDOTAdjustmentCreate;

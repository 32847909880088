import React, { useState } from 'react';
import * as R from 'ramda';

import ElementNavigationIcon from '@atom/components/common/elements/ElementNavigationIcon';
import { SchemaTree } from '@atom/types/schema';

import PreviewAttributeDetail from './PreviewAttributeDetail';
import PreviewDetailTree from './PreviewDetailTree';

import './previewSubItems.css';

const PreviewSubItems = () => {
  const [selectedPreviewSubItem, setSelectedPreviewSubItem] = useState<
    SchemaTree
    // @ts-ignore
  >({});

  return (
    <>
      <div styleName="left-body-pane-half">
        <PreviewDetailTree
          updateSelectedItem={setSelectedPreviewSubItem}
          selectedItem={selectedPreviewSubItem}
        />
      </div>
      <div styleName="right-body-pane-half">
        {!R.isEmpty(selectedPreviewSubItem) ? (
          <>
            <div styleName="icon-row">
              <div styleName="element-name">{selectedPreviewSubItem.name}</div>
              <ElementNavigationIcon
                key="info"
                value="info"
                icon="info"
                activeView="info"
                setActiveView={() => {}}
              />
            </div>
            <PreviewAttributeDetail
              schema={selectedPreviewSubItem}
              // Key is needed so state initialization works properly
              key={selectedPreviewSubItem.id}
            />
          </>
        ) : (
          <div />
        )}
      </div>
    </>
  );
};

export default PreviewSubItems;

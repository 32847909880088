import React, { Dispatch, SetStateAction } from 'react';

import { AssetRequest, AssetRequestStatus } from '@atom/types/assetRequest';
import { AssetDetail } from '@atom/types/inventory';

interface Context {
  fromAsset: AssetDetail;
  activeRequest: AssetRequest;
  handleGetActiveRequest: () => void;
  setActiveRequestId: (id: string) => void;
  status: AssetRequestStatus;
  setStatus: Dispatch<SetStateAction<AssetRequestStatus>>;
  quantityApproved: number;
  setQuantityApproved: Dispatch<SetStateAction<number>>;
  comments: string;
  setComments: Dispatch<SetStateAction<string>>;
  setConfirmOpen: Dispatch<SetStateAction<boolean>>;
}

export const initialState: Context = {
  // @ts-ignore
  fromAsset: {},
  // @ts-ignore
  activeRequest: {},
  handleGetActiveRequest: () => {},
  setActiveRequestId: () => {},
  status: null,
  setStatus: () => {},
  quantityApproved: null,
  setQuantityApproved: () => {},
  comments: null,
  setComments: () => {},
  setConfirmOpen: () => {},
};

const ViewRequestContext = React.createContext<Context>(initialState);

export default ViewRequestContext;

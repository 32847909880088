import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as R from 'ramda';

import InputDateRange from '@atom/components/common/inputDateRange/InputDateRange';
import UsersFilter from '@atom/components/common/usersFilter/UsersFilter';
import { TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetRequestsFilters } from '@atom/types/assetRequest';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import {
  DateRange,
  DateRangeTerminator,
} from '@atom/utilities/workOrdersDateFilterUtilities';

import './sddotFilters.css';

const styles = {
  input: {
    marginTop: '1rem',
  },
  label: {
    color: colors.neutral.dim,
  },
};

interface Props {
  filtersCart: AssetRequestsFilters;
  setFiltersCart: Dispatch<SetStateAction<AssetRequestsFilters>>;
}

const SDDOTAdjustmentFilters = ({ filtersCart, setFiltersCart }: Props) => {
  const [presetSelected, setPresetSelect] = useState<string>(DateRange.ANYTIME);

  useEffect(() => {
    // If filter is cleared, reset date input preset to ANYTIME
    if (
      R.isNil(filtersCart.createdDateEnd) &&
      R.isNil(filtersCart.createdDateStart)
    ) {
      setPresetSelect(DateRange.ANYTIME);
    }
  }, [filtersCart.createdDateEnd, filtersCart.createdDateStart]);

  const updateFilter = (value: any, property: keyof AssetRequestsFilters) => {
    setFiltersCart(prev => ({ ...prev, [property]: value }));
  };

  const getDateValue = (property: keyof AssetRequestsFilters) => {
    if (!isNilOrEmpty(filtersCart[property])) {
      return new Date(Number(filtersCart[property]));
    }

    return null;
  };

  const handleDateChange = (
    property: keyof AssetRequestsFilters,
    terminator: DateRangeTerminator,
  ) => (val: Date) => {
    const dateMillis = val
      ? terminator === DateRangeTerminator.START
        ? convertDateToLocalDayStart(val)
        : convertDateToLocalDayEnd(val)
      : null;
    updateFilter(dateMillis, property);
  };

  return (
    <>
      <div styleName="input-container">
        <TextField
          style={styles.input}
          InputLabelProps={{ style: styles.label }}
          type="number"
          value={Number(filtersCart.name)}
          placeholder="Enter number only"
          label="Request ID"
          onChange={event => updateFilter(String(event.target.value), 'name')}
        />
      </div>
      <div styleName="input-container">
        <UsersFilter
          value={filtersCart?.assignedTo}
          updateValue={newValue => updateFilter(newValue, 'createdBy')}
          placeholder="Search users"
          label="Created By"
          showTitle
        />
      </div>
      <div styleName="input-container">
        <InputDateRange
          label="Created Date"
          presetSelected={presetSelected}
          startDateValue={getDateValue('createdDateStart')}
          handleStartChange={handleDateChange(
            'createdDateStart',
            DateRangeTerminator.START,
          )}
          endDateValue={getDateValue('createdDateEnd')}
          handleEndChange={handleDateChange(
            'createdDateEnd',
            DateRangeTerminator.END,
          )}
          handleSelectDatePreset={selected => setPresetSelect(selected?.value)}
        />
      </div>
    </>
  );
};

export default SDDOTAdjustmentFilters;

import React, { ReactNode } from 'react';
import * as R from 'ramda';

// @ts-ignore
import budgetDraft from '@atom/components/common/svgIcons/budgetDraft.svg';
// @ts-ignore
import budgetSubmitted from '@atom/components/common/svgIcons/budgetSubmitted.svg';
// @ts-ignore
import compare from '@atom/components/common/svgIcons/compare.svg';
import { Icon, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BasicBudget,
  Budget,
  BudgetStatus,
  BudgetSummary,
  BudgetUnit,
  BudgetUnitTree,
} from '@atom/types/budget';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { formatDateMoment } from '@atom/utilities/timeUtilities';

import './budgetDetail.css';

// TODO: Manage list of locked budgets in BE or preference instead
//       Locked budgets by ID cannot change status except by ADMIN
export const LOCKED_STATUS_BUDGET_IDS: string[] = [
  '6788110d4ea3d5745ae39e0d',
  '66e9fdffa16e966a5fd051bf',
  '66fc4f2e16f68006f34a2f4d',
  '66fc4f47ea35437eadd27ab6',
];
export const isStatusLocked = (budgetId: string) =>
  !hasRolePermissions(ROLE_SETS.ADMIN) &&
  LOCKED_STATUS_BUDGET_IDS.includes(budgetId);

export const COST_TYPE_COL_WIDTH = '8rem';
export const BUDGET_COL_WIDTH = '9rem';
export const SELECT_COL_WIDTH = '3rem';
export const COMMENT_COL_WIDTH = '18rem';
export const ICON_COL_WIDTH = '4.25rem';
export const POPOVER_HEIGHT = '25rem';
export const COMPARE_MENU_HEIGHT = '17rem';
export const CATEGORY_EXPAND_LIMIT = 10;
export const DEBOUNCE_TIME = 500;
export const MAX_ITEM_VALUE = {
  ERROR: 'The maximum budget item value is $999,999,999,999.99.',
  VALUE: 999999999999.99,
};
export const BUDGET_OPTIONS_LIMIT = 25;

export const DEBOUNCE_CHECKBOX = 400;

// Used to manage budget item virtualization
export const ITEM_ROW_HEIGHT = 3.4375; // 55px in rem
export const MAX_ROWS_PER_CATEGORY = 10;

export const AUTH_BUDGET_TOOLTIP_COPY = 'Will not be affected by filters.';

export const FIXED_COST_TOOLTIP_COPY =
  'Remaining accounts for actual cost and future fixed cost not spent yet.';

export const HAS_DAMAGE_CLAIM_TOOLTIP_COPY =
  'Total Remaining = Total Budget - Total Actual - Future Fixed Cost + Damage Claim Revenue + Federal Revenue.';

export const DAMAGE_CLAIM_EXPENSE_TOOLTIP_COPY =
  'Actual values represent 7M03 and 7M07 expenditures.';

export const DEDICATED_CREDIT_TOOLTIP_COPY =
  'Actual and remaining values are $0.00 because they are accounted for in the Overview section.';

export const STATUS_CHANGE_ERROR_MSG =
  'Please try again or contact administrator.';

export enum EditField {
  VALUE = 'VALUE',
  COMMENT = 'COMMENT',
}

export const FIELD_KEYS = {
  VALUE: 'budgetValue',
  COMMENT: 'comment',
};

export const FIELD_NAMES = {
  VALUE: 'budget',
  COMMENT: 'comment',
};

export enum BudgetStatusModals {
  SUBMIT_BUDGET = 'SUBMIT_BUDGET',
  APPROVE_BUDGET = 'APPROVE_BUDGET',
  REVERT_BUDGET_TO_DRAFT = 'REVERT_BUDGET_TO_DRAFT',
  REVERT_BUDGET_TO_SUBMITTED = 'REVERT_BUDGET_TO_SUBMITTED',
}

const formatDate = (millis: number) => formatDateMoment(millis, 'MM/DD/YYYY');

export const getDateRange = (
  budget: BasicBudget | BudgetSummary | Budget,
): string =>
  ` (${formatDate(budget?.startDate?.valueOf())} - ${formatDate(
    budget?.endDate?.valueOf(),
  )})`;

export const getComparisonIcon = (hovering: boolean, open: boolean) => (
  <img
    style={{
      padding: '0 1.5rem',
      visibility: hovering || open ? 'visible' : 'hidden',
    }}
    src={compare}
  />
);

export const EmptyComparisonChart = (
  <div styleName="comparison-none-selected">
    No comparison budgets selected. Select budgets from the Compare menu to
    compare this data across budgets.
  </div>
);

const styles = {
  statusLabel: { display: 'flex', gap: '0.5rem', alignItems: 'center' },
};

export const getStatusLabel = (
  status: BudgetStatus,
  iconOnly?: boolean,
): ReactNode => {
  switch (true) {
    case status === BudgetStatus.APPROVED:
      return (
        <div style={styles.statusLabel}>
          <Icon style={{ color: colors.brand.green }}>
            check_circle_outlined
          </Icon>
          {!iconOnly && <span>Approved</span>}
        </div>
      );
    case status === BudgetStatus.SUBMITTED:
      return (
        <div style={styles.statusLabel}>
          <img src={budgetSubmitted} />
          {!iconOnly && <span>Submitted</span>}
        </div>
      );
    default:
      return (
        <div style={styles.statusLabel}>
          <img src={budgetDraft} />
          {!iconOnly && <span>Draft</span>}
        </div>
      );
  }
};

export const displayError = () => {
  Snackbar.error({
    message: STATUS_CHANGE_ERROR_MSG,
  });
};

export const getAllDescendantIds = (unit: BudgetUnitTree): string[] => {
  return unit.children.reduce(
    (acc: string[], child): string[] => [
      ...acc,
      child.id,
      ...getAllDescendantIds(child),
    ],
    [],
  );
};

export const getTypeName = (unit: BudgetUnit): string =>
  R.pathOr('', ['budgetTypes', '0'], unit);

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import {
  GET_TASK_TEMPLATES,
  TASK_TEMPLATE_DELETE,
} from '@atom/graph/taskTemplate';
import { Icon, IconButton, Modal, Snackbar, TextField } from '@atom/mui';
import fonts from '@atom/styles/fonts';
import { TaskCreateInput, TaskUpdateInput } from '@atom/types/task';
import {
  TaskTemplate,
  TaskTemplatesConnection,
  TaskTemplatesConnectionInput,
} from '@atom/types/taskTemplate';

import EmptySearchTemplate from './EmptySearchTemplate';
import EmptyTaskTemplate from './EmptyTaskTemplate';
import TaskTemplateContent from './TaskTemplateContent';

import './taskCreate.css';

const useStyles = makeStyles({
  root: {
    fontSize: fonts.md,
    height: '3rem',
    paddingLeft: '1rem',
  },
  input: {
    paddingLeft: '1rem',
  },
});

const LIMIT = 25;
const SEARCH_THRESHOLD = 3;
const DEBOUNCE_TIME = 500;
const HTTP_STATUS_CONFLICT = 409;

export interface Props {
  workOrderId: string;
  open: boolean;
  onClose: () => void;
  handleConfirm: (
    property: keyof TaskCreateInput | keyof TaskUpdateInput,
    value: string,
  ) => void;
  confirmButtonText?: string;
  disableDelete?: boolean;
  title?: string;
}

const styles = {
  modal: {
    padding: '2rem',
  },
};

const TaskCreateTemplateModal = ({
  workOrderId,
  open,
  onClose,
  handleConfirm,
  confirmButtonText = 'Create',
  disableDelete,
  title = 'Create Task',
}: Props) => {
  const classes = useStyles();
  const ref = useRef(null);

  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);
  const [searchTaskTemplates, setSearchTaskTemplates] = useState<
    TaskTemplate[]
  >([]);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<
    TaskTemplate
  >();

  const searching: boolean = useMemo(() => search.length >= SEARCH_THRESHOLD, [
    search,
  ]);

  const [getTaskTemplates, { loading: taskTemplatesLoading }] = useLazyQuery<
    { taskTemplates: TaskTemplatesConnection },
    { input: TaskTemplatesConnectionInput }
  >(GET_TASK_TEMPLATES, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const nextTaskTemplates = R.pathOr(
        [],
        ['taskTemplates', 'taskTemplates'],
        data,
      );

      if (searching) {
        setSearchTaskTemplates(nextTaskTemplates);
      } else {
        setTaskTemplates([...taskTemplates, ...nextTaskTemplates]);
        setTotal(data?.taskTemplates?.totalCount || 0);
      }
    },
  });

  const [taskTemplateDelete] = useMutation<{}, { id: string }>(
    TASK_TEMPLATE_DELETE,
  );

  const resetState = () => {
    setTaskTemplates([]);
    setPage(1);
    setTotal(0);
    setSearch('');
    setSearchTaskTemplates([]);
    setSelectedTaskTemplate(null);
  };

  const handleGetTaskTemplates = () => {
    getTaskTemplates({
      variables: {
        input: {
          workOrderId,
          sortBy: 'name',
          isAscending: true,
          limit: LIMIT,
          page,
        },
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      await taskTemplateDelete({ variables: { id } });

      resetState();
      handleGetTaskTemplates();
    } catch (err) {
      const message =
        err?.networkError?.statusCode === HTTP_STATUS_CONFLICT
          ? 'The task template cannot be deleted because it was used to create a task'
          : 'An unknown error occurred';

      Snackbar.error({ message });

      resetState();
      handleGetTaskTemplates();
    }
  };

  useEffect(() => {
    if (open) {
      handleGetTaskTemplates();
    } else {
      resetState();
    }
  }, [open]);

  const clearSearch = () => {
    resetState();

    getTaskTemplates({
      variables: {
        input: {
          workOrderId,
          sortBy: 'name',
          isAscending: true,
          limit: LIMIT,
          page: 1,
        },
      },
    });
  };

  const debouncedSearch = useCallback(
    debounce((name: string) => {
      getTaskTemplates({
        variables: {
          input: {
            workOrderId,
            sortBy: 'name',
            isAscending: true,
            limit: LIMIT,
            page,
            name,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const handleSearch = (value: string) => {
    setSearch(value);
    setPage(1);

    if (!value) {
      debouncedSearch.cancel();
    } else if (value.length >= SEARCH_THRESHOLD) {
      debouncedSearch(value);
    } else {
      debouncedSearch.cancel();
    }
  };

  const handlePageScroll = (nextPage: number) => {
    setPage(nextPage);

    getTaskTemplates({
      variables: {
        input: {
          workOrderId,
          sortBy: 'name',
          isAscending: true,
          limit: LIMIT,
          page: nextPage,
        },
      },
    });
  };

  const handleSubmit = () => {
    if (selectedTaskTemplate) {
      handleConfirm('templateId', selectedTaskTemplate?.id);
      onClose();
    }
  };

  const taskTemplatesData =
    searching && !R.isNil(searchTaskTemplates)
      ? searchTaskTemplates
      : taskTemplates;

  const showEmptyResults = searching && R.isEmpty(searchTaskTemplates);

  return (
    <Modal
      title={title}
      open={open}
      onConfirm={handleSubmit}
      onCancel={onClose}
      onExited={resetState}
      confirmButtonText={confirmButtonText}
      contentStyle={styles.modal}
      disabled={!selectedTaskTemplate}
    >
      <div>
        <div styleName="search-content">
          <div styleName="search-box">
            <TextField
              InputProps={{
                startAdornment: <Icon>search</Icon>,
                endAdornment: (
                  <IconButton onClick={clearSearch}>
                    <Icon>close</Icon>
                  </IconButton>
                ),
                classes: {
                  root: classes.root,
                  input: classes.input,
                },
              }}
              placeholder="Search Task Templates"
              name="search"
              value={search}
              onChange={event => handleSearch(event.target.value)}
            />
          </div>
        </div>
        <div styleName="content-container" ref={ref}>
          {showEmptyResults ? (
            <EmptySearchTemplate taskTemplatesLoading={taskTemplatesLoading} />
          ) : !total ? (
            <EmptyTaskTemplate />
          ) : (
            <TaskTemplateContent
              outerRef={ref}
              taskTemplates={taskTemplatesData}
              taskTemplatesLoading={taskTemplatesLoading}
              total={total}
              searching={searching}
              page={page}
              selectedTaskTemplate={selectedTaskTemplate}
              setSelectedTaskTemplate={setSelectedTaskTemplate}
              handleDelete={handleDelete}
              handlePageScroll={handlePageScroll}
              disableDelete={disableDelete}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TaskCreateTemplateModal;

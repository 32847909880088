import * as R from 'ramda';

const subDomain: string = window.location.hostname.split('.')[0];
const environment: string = window.location.hostname.split('.')[1];

export enum Environment {
  LOCAL = 'local-atom',
  DEV = 'dev-atom',
  QA = 'qa-atom',
  UAT = 'uat-atom',
  PRODUCTION = 'atomapp',
}

export enum Tenant {
  // local development
  LOCAL = 'local-acme',

  // SADA - internal client for AtomAI
  DEV_SADA = 'dev-sada',
  QA_SADA = 'qa-sada',
  UAT_SADA = 'uat-sada',
  SADA = 'sada',

  // ALDOT
  DEV_ALDOT = 'dev-aldot',
  QA_ALDOT = 'qa-aldot',
  UAT_ALDOT = 'uat-aldot',
  UAT_ALDOT_DEMO = 'uat-aldot-demo',
  ALDOT = 'aldot',

  // UDOT
  DEV_UDOT = 'dev-udot',
  QA_UDOT = 'qa-udot',
  UAT_UDOT = 'uat-udot',
  UDOT = 'udot',

  // Hennepin
  DEV_HENNEPIN = 'dev-hennepin',
  QA_HENNEPIN = 'qa-hennepin',
  UAT_HENNEPIN = 'uat-hennepin',
  HENNEPIN = 'hennepin',

  // GWRR
  DEV_GWRR = 'dev-gwrr',
  QA_GWRR = 'qa-gwrr',
  UAT_GWRR = 'uat-gwrr',
  GWRR = 'gwrr',

  // CBIT
  DEV_CBIT = 'dev-cbit',
  QA_CBIT = 'qa-cbit',
  UAT_CBIT = 'uat-cbit',
  CBIT = 'cbit',

  // SDDOT
  DEV_SDDOT = 'dev-sddot',
  QA_SDDOT = 'qa-sddot',
  UAT_SDDOT = 'uat-sddot',
  SDDOT = 'sddot',

  // Macombtownship
  DEV_MACOMB = 'dev-macombtownship',
  QA_MACOMB = 'qa-macombtownship',
  UAT_MACOMB = 'uat-macombtownship',
  // TODO: MACOMB has no PROD env yet, needs to be added

  // Misc UAT
  UAT_ATOM_DEMO = 'uat-atom-demo',
  UAT_DEMO = 'uat-demo',
  UAT_GDOT = 'uat-gdot',
  UAT_ITS_UDOT = 'uat-its-udot',
  UAT_LADOTD = 'uat-ladotd',
  UAT_VDOT_DEMO = 'uat-vdot-demo',
  UAT_WABTEC = 'uat-wabtec',

  // Misc Production
  COLLINS_EQUIPMENT = 'collins-equipment',
  LARIMER_COUNTY = 'larimercounty',
  MOBILE_COUNTY = 'mobilecounty',
  SCDOT = 'scdot',
}

export enum Client {
  LOCAL = 'LOCAL',
  ALDOT = 'ALDOT',
  GWRR = 'GWRR',
  HENNEPIN = 'HENNEPIN',
  SADA = 'SADA',
  UDOT = 'UDOT',
  CBIT = 'CBIT',
  SDDOT = 'SDDOT',
  MACOMB = 'MACOMB',
}

export const CLIENTS = {
  [Client.LOCAL]: [Tenant.LOCAL],
  [Client.ALDOT]: [
    Tenant.DEV_ALDOT,
    Tenant.QA_ALDOT,
    Tenant.UAT_ALDOT,
    Tenant.UAT_ALDOT_DEMO,
    Tenant.ALDOT,
  ],
  [Client.GWRR]: [
    Tenant.DEV_GWRR,
    Tenant.QA_GWRR,
    Tenant.UAT_GWRR,
    Tenant.GWRR,
  ],
  [Client.HENNEPIN]: [
    Tenant.DEV_HENNEPIN,
    Tenant.QA_HENNEPIN,
    Tenant.UAT_HENNEPIN,
    Tenant.HENNEPIN,
  ],
  [Client.SADA]: [
    Tenant.DEV_SADA,
    Tenant.QA_SADA,
    Tenant.UAT_SADA,
    Tenant.SADA,
  ],
  [Client.UDOT]: [
    Tenant.DEV_UDOT,
    Tenant.QA_UDOT,
    Tenant.UAT_UDOT,
    Tenant.UDOT,
  ],
  [Client.CBIT]: [
    Tenant.DEV_CBIT,
    Tenant.QA_CBIT,
    Tenant.UAT_CBIT,
    Tenant.CBIT,
  ],
  [Client.SDDOT]: [
    Tenant.DEV_SDDOT,
    Tenant.QA_SDDOT,
    Tenant.UAT_SDDOT,
    Tenant.SDDOT,
  ],
  [Client.MACOMB]: [
    Tenant.DEV_MACOMB,
    Tenant.QA_MACOMB,
    Tenant.UAT_MACOMB,
    // TODO: need to add prod env
  ],
};

// Returns true if the current environment is in the passed in list of environments
export const isCurrentEnvironment = (environments: Environment[]) => {
  return R.includes(environment, [...environments, Environment.LOCAL]);
};

// Returns true if the current tenant is in the passed in list of tenants
export const isCurrentTenant = (tenants: Tenant[]) => {
  return R.includes(subDomain, [...tenants, Tenant.LOCAL]);
};

// Returns true if the current tenant is in the passed in client grouping
// ex Client.SADA will return true for dev-sada, qa-sada, uat-sada, and sada tenants
export const isCurrentClient = (clients: Client[]) => {
  const tenantsList = clients.reduce((acc, client) => {
    return [...acc, ...CLIENTS[client]];
  }, []);

  return R.includes(subDomain, [...tenantsList, Tenant.LOCAL]);
};

// Returns the current client. For local dev, this will return Client.LOCAL
export const getCurrentClient = () => {
  return R.keys(CLIENTS).find(client => R.includes(subDomain, CLIENTS[client]));
};

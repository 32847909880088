import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import client from '@atom/graph/client';
import { GET_USERS } from '@atom/graph/user';
import { Autocomplete, Avatar, Chip, List, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  UserDetail,
  UsersConnection,
  UsersConnectionInput,
} from '@atom/types/user';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const { ListItemText } = List;

interface Props {
  updateValue: (userIds: string[]) => void;
  value: string[];
  label: string;
  placeholder: string;
  disabled?: boolean;
  showTitle?: boolean;
}

const DEBOUNCE_TIME = 300;
const MIN_CHAR = 3;
const SEARCH_LIMIT = 250;
const SEARCH_PAGE = 1;

type DebouncedUserSearch = (input: UsersConnectionInput) => void;

const styles = {
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
  chip: {
    marginTop: '5px',
    marginRight: '5px',
    backgroundColor: colors.neutral.ash,
    borderRadius: '20px',
  },
};

const UsersFilter = ({
  label,
  placeholder,
  value,
  updateValue,
  disabled,
  showTitle = false,
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPreselected, setLoadingPreselected] = useState<boolean>(false);
  const [users, setUsers] = useState<UserDetail[]>([]);
  const [selected, setSelected] = useState<UserDetail[]>([]);

  const searchUsers = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    input: UsersConnectionInput,
    isPreselected?: boolean,
  ) => {
    if (isPreselected) {
      setLoadingPreselected(true);
    } else {
      setLoadingSearch(true);
    }

    const { data } = await client.query<{ users: UsersConnection }>({
      query: GET_USERS,
      variables: {
        input: {
          ...input,
          limit: SEARCH_LIMIT,
          page: SEARCH_PAGE,
          showAdmin: hasRolePermissions(ROLE_SETS.ADMIN),
        },
      },
    });

    if (isPreselected) {
      setLoadingPreselected(false);
      setSelected(data?.users?.users || []);
    } else {
      setUsers(data?.users?.users || []);
      setLoadingSearch(false);
    }
  };

  const searchUsersDebounced = useCallback<DebouncedUserSearch>(
    debounce(searchUsers, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (query.length >= MIN_CHAR) {
      searchUsersDebounced({ name: query });
    } else {
      setUsers([]);
    }
  }, [query]);

  useEffect(() => {
    // initial page load with user id filters already applied
    if (!selected.length && value?.length) {
      searchUsers(
        {
          ids: value,
        },
        true,
      );
    }

    // filter reset
    if (!value?.length && selected?.length) {
      setSelected([]);
    }
  }, [value]);

  const handleChange = (values: UserDetail[]) => {
    setSelected(values);

    updateValue(values.map(val => val.id));
  };

  const handleDeselect = (id: string) => {
    setSelected(state => state.filter(user => user.id !== id));

    updateValue(value.filter(userId => userId !== id));
  };

  return (
    <Autocomplete<UserDetail, true, false>
      multiple
      label={label}
      options={users}
      loading={loadingSearch}
      disabled={loadingPreselected || disabled}
      inputValue={query}
      onInputChange={(event, newValue) => setQuery(newValue || '')}
      getOptionLabel={user => getUserFullName(user)}
      placeholder={!selected.length ? placeholder : ''}
      value={selected}
      onChange={(event, values) => {
        // @ts-ignore
        handleChange(values);
      }}
      getOptionDisabled={user => selected.some(({ id }) => id === user.id)}
      renderOption={(optionProps, user) => {
        const secondaryText = `${user.email}${
          showTitle && !isNilOrEmpty(user.title) ? ` - ${user.title}` : ''
        }`;

        return (
          <li key={user.id} {...optionProps}>
            <Avatar
              src={user.photoUrl}
              alt={user.firstName}
              style={{ marginRight: '0.5rem' }}
            />
            <ListItemText
              primary={getUserFullName(user)}
              secondary={secondaryText}
            />
          </li>
        );
      }}
      renderTags={values => {
        return values.map(user => (
          <Chip
            key={user.id}
            style={styles.chip}
            avatar={
              <Avatar
                src={user.photoUrl}
                alt={user.firstName}
                style={{ marginRight: '0.5rem' }}
              />
            }
            label={getUserFullName(user)}
            onDelete={() => handleDeselect(user.id)}
          />
        ));
      }}
      endAdornment={loadingPreselected && <Progress size={20} />}
      labelStyle={styles.label}
    />
  );
};

export default UsersFilter;

import * as R from 'ramda';

import {
  AssetRequestsFilters,
  AssetRequestStatus,
  AssetRequestType,
  Column,
  ConfirmModalContent,
} from '@atom/types/assetRequest';
import {
  InventoryAssetDetailType,
  InventorySchemaItem,
} from '@atom/types/inventory';
import {
  Client,
  getCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import {
  INVENTORY_STATUS_OPTIONS,
  REPLENISHMENT_STATUS_OPTIONS,
  SDDOT_CONFIRM_MODAL_CONTENT,
  SDDOT_INVENTORY_SCHEMA_ID,
  SDDOT_STOCKPILE_SCHEMA_ID,
  sddotColumns,
  TYPE_LABELS,
} from './assetRequestConstants';

interface CustomValues {
  initialFilters: AssetRequestsFilters;
  typeOptions: AssetRequestType[];
}

// INITIAL FILTERS
const getSDDOTInitialFilters = (
  schema?: InventorySchemaItem,
  asset?: InventoryAssetDetailType,
): AssetRequestsFilters => {
  if (isNilOrEmpty(schema) && isNilOrEmpty(asset)) {
    return {
      type: AssetRequestType.SDDOT_INVENTORY_TRANSFER,
      schemaIds: [SDDOT_INVENTORY_SCHEMA_ID],
    };
  }

  const type =
    schema?.id === SDDOT_STOCKPILE_SCHEMA_ID
      ? AssetRequestType.SDDOT_STOCKPILE_TRANSFER
      : AssetRequestType.SDDOT_INVENTORY_TRANSFER;

  const addAssetFilters =
    type === AssetRequestType.SDDOT_INVENTORY_TRANSFER ||
    type === AssetRequestType.SDDOT_STOCKPILE_TRANSFER;

  return {
    type,
    assetId: asset.id,
    ...(addAssetFilters && {
      fromAssetId: asset.id,
    }),
  };
};

// TYPE OPTIONS
const getSDDOTTypeOptions = (
  schema: InventorySchemaItem,
): AssetRequestType[] => {
  if (isNilOrEmpty(schema)) {
    return [
      AssetRequestType.SDDOT_INVENTORY_TRANSFER,
      AssetRequestType.SDDOT_STOCKPILE_TRANSFER,
      AssetRequestType.SDDOT_REPLENISHMENT,
      AssetRequestType.SDDOT_ADJUSTMENT,
    ];
  }

  return [
    ...[
      schema?.id === SDDOT_STOCKPILE_SCHEMA_ID
        ? AssetRequestType.SDDOT_STOCKPILE_TRANSFER
        : AssetRequestType.SDDOT_INVENTORY_TRANSFER,
    ],
    AssetRequestType.SDDOT_REPLENISHMENT,
    AssetRequestType.SDDOT_ADJUSTMENT,
  ];
};

// STATUS OPTIONS
export const getSDDOTStatusOptions = (
  type: AssetRequestType,
): AssetRequestStatus[] => {
  const statusOptions = {
    [AssetRequestType.SDDOT_REPLENISHMENT]: REPLENISHMENT_STATUS_OPTIONS,
    [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: INVENTORY_STATUS_OPTIONS,
  };

  return statusOptions[type] || [];
};

// FULL CUSTOM VALUES
export const getCustomValues = (
  schema?: InventorySchemaItem,
  asset?: InventoryAssetDetailType,
): CustomValues => {
  const currentClient = getCurrentClient();

  const initialFilterValues = {
    [Client.SDDOT]: getSDDOTInitialFilters(schema, asset),
    // This value is used for local dev
    [Client.LOCAL]: getSDDOTInitialFilters(schema, asset),
  };

  const typeOptionValues = {
    [Client.SDDOT]: getSDDOTTypeOptions(schema),
    // This value is used for local dev
    [Client.LOCAL]: getSDDOTTypeOptions(schema),
  };

  return {
    initialFilters: R.pathOr({}, [currentClient], initialFilterValues),
    typeOptions: R.pathOr([], [currentClient], typeOptionValues),
  };
};

// This method will adjust filters before they are applied with tenant specific logic
export const cleanFilters = (
  filters: AssetRequestsFilters,
): AssetRequestsFilters => {
  const currentClient = getCurrentClient();

  switch (currentClient) {
    case Client.SDDOT:
      return {
        ...R.reject(isNilOrEmpty, {
          ...filters,
        }),
        // Name filter only allows user to enter the sequence suffix, so we must
        // hydrate the type prefix here.
        ...(filters.name && {
          name: `${TYPE_LABELS[filters.type]} - ${filters.name}`,
        }),
      };
    // This value is used for local dev
    case Client.LOCAL:
      return {
        ...R.reject(isNilOrEmpty, {
          ...filters,
        }), // Name filter only allows user to enter the sequence suffix, so we must
        // hydrate the type prefix here.
        ...(filters.name && {
          name: `${TYPE_LABELS[filters.type]} - ${filters.name}`,
        }),
      };
    default:
      return filters;
  }
};

// Gets filters for when the request type is changed
export const getTypeChangeFilters = (
  type: AssetRequestType,
  asset?: InventoryAssetDetailType,
): AssetRequestsFilters => {
  if (isNilOrEmpty(asset)) {
    return { type };
  }

  const updateAssetFilters =
    type === AssetRequestType.SDDOT_INVENTORY_TRANSFER ||
    type === AssetRequestType.SDDOT_STOCKPILE_TRANSFER;

  return {
    type,
    ...(updateAssetFilters && {
      assetId: asset.id,
      fromAssetId: asset.id,
    }),
  };
};

export const getSDDOTColumns = (
  type: AssetRequestType,
  isPortalView: boolean,
): Column[] => {
  return sddotColumns(type, isPortalView);
};

export const getTableColumns = (
  type: AssetRequestType,
  isPortalView: boolean,
) => {
  const currentClient = getCurrentClient();

  const columns = {
    [Client.SDDOT]: getSDDOTColumns(type, isPortalView),
    // This value is used for local dev
    [Client.LOCAL]: getSDDOTColumns(type, isPortalView),
  };

  return R.pathOr([], [currentClient], columns);
};

const defaultModalContent = { title: '', content: '' };

export const getSDDOTConfirmModalContent = (
  type: AssetRequestType,
): ConfirmModalContent => {
  return SDDOT_CONFIRM_MODAL_CONTENT[type] || defaultModalContent;
};

export const getConfirmModalContent = (
  type: AssetRequestType,
): ConfirmModalContent => {
  const currentClient = getCurrentClient();

  const columns = {
    [Client.SDDOT]: getSDDOTConfirmModalContent(type),
    // This value is used for local dev
    [Client.LOCAL]: getSDDOTConfirmModalContent(type),
  };

  return R.pathOr(defaultModalContent, [currentClient], columns);
};

export const getSDDOTReplenishmentSubtextOptions = (
  totalOrdered: number,
  totalReceived: number = 0,
  newReceived: number,
  assetUnit: string,
): { icon: string; message: string } => {
  if (R.isNil(newReceived) || R.isNil(totalOrdered)) {
    return null;
  }

  const totalRemaining = totalOrdered - totalReceived - newReceived;

  switch (true) {
    case totalRemaining > 0:
      return {
        icon: 'warning',
        message: `Total remaining quantity on order: ${totalRemaining} ${assetUnit}. You can add more replenishments later.`,
      };
    case totalRemaining < 0:
      return {
        icon: 'warning',
        message:
          'You received more than you ordered. This is OK - no further action is required.',
      };
    case totalRemaining === 0:
      return {
        icon: 'check_circle',
        message: 'You have received the full quantity.',
      };
    default:
      return null;
  }
};

export const getAttributeFilterByName = (
  asset: InventoryAssetDetailType,
  attributeName: string,
) => {
  if (isNilOrEmpty(asset)) {
    return null;
  }

  const attributeId = R.keys(asset?.attributes).filter(id => {
    return asset?.attributes[id].name === attributeName;
  })[0];

  return {
    [`attributes.${attributeId}`]: [asset?.attributes[attributeId].value],
  };
};

export const getApprovalKeyword = (status: AssetRequestStatus): string => {
  const keywords = {
    [AssetRequestStatus.CANCELED]: 'Cancel',
    [AssetRequestStatus.REJECTED]: 'Reject',
    [AssetRequestStatus.FULL_APPROVAL]: 'Approve',
    [AssetRequestStatus.PARTIAL_APPROVAL]: 'Approve',
  };

  return keywords[status];
};

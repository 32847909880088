import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@mui/material';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import Logo from '@atom/components/common/navigation/Logo';
import { Button, Progress, TextField } from '@atom/mui';
import userSelectors from '@atom/selectors/userSelectors';
import colors from '@atom/styles/colors';
import layoutStyles from '@atom/styles/layout';
import { UserActions } from '@atom/types/actions';
import { EventType, KeyPressEventType } from '@atom/types/event';
import { ReduxStore } from '@atom/types/store';
import accountUtilities from '@atom/utilities/accountUtilities';
import history from '@atom/utilities/history';

import LoginFooter from './LoginFooter';

import './login.css';

const navBarStyle = {
  height: layoutStyles.topNavHeight,
  backgroundColor: colors.neutral.white,
  boxShadow: '0 2px 4px 1px',
  color: colors.utility.boxShadow,
  position: 'fixed',
  top: 0,
};

interface ReduxDispatchProps {
  userActions: UserActions;
}

interface ReduxStateProps {
  loading: boolean;
}

type Props = ReduxDispatchProps & ReduxStateProps;

interface ErrorsState {
  tenant: string;
}

interface State {
  email: string;
  tenant: string;
  errors: ErrorsState;
}

const DEV_TENANT_NAME_VALIDATION = new RegExp('^dev\\-[-a-zA-Z]+$');
const QA_TENANT_NAME_VALIDATION = new RegExp('^qa\\-[-a-zA-Z]+$');
const UAT_TENANT_NAME_VALIDATION = new RegExp('^uat\\-[-a-zA-Z]+$');
const DEFAULT_TENANT_NAME_VALIDATION = new RegExp('^[a-zA-Z][-a-zA-Z]+$');

class TenantEntry extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state.email = this.getEmailAccount();
  }

  state = {
    email: '',
    tenant: '',
    errors: {
      tenant: '',
    },
  };

  componentDidMount() {
    if (!accountUtilities.isEmailVerified()) {
      history.push('/verify-email');
    }
  }

  navigateBack = () => {
    history.goBackWithState();
  };

  getEmailAccount = (): string => {
    const email = accountUtilities.getLoggedInEmail();
    if (!email) {
      history.push('/login');
    }

    return email;
  };

  onChange = (event: EventType) => {
    const { value } = event.target;
    this.setState({ tenant: value });
  };

  onKeyPress = (event: KeyPressEventType) => {
    if (event.key === 'Enter') {
      this.submit();
    }
  };

  isValidTenantName = (host: string): boolean => {
    const { tenant } = this.state;

    if (host === 'dev-atom' || host === 'local-atom') {
      return DEV_TENANT_NAME_VALIDATION.test(tenant);
    }

    if (host === 'qa-atom') {
      return QA_TENANT_NAME_VALIDATION.test(tenant);
    }

    if (host === 'uat-atom') {
      return UAT_TENANT_NAME_VALIDATION.test(tenant);
    }

    if (
      host === 'atomapp' &&
      (DEV_TENANT_NAME_VALIDATION.test(tenant) ||
        QA_TENANT_NAME_VALIDATION.test(tenant) ||
        UAT_TENANT_NAME_VALIDATION.test(tenant))
    ) {
      return false;
    } else if (host === 'atomapp') {
      return DEFAULT_TENANT_NAME_VALIDATION.test(tenant);
    }

    return false;
  };

  getCurrentHost = (): string => {
    return window.location.hostname.split('.')[2]
      ? R.pipe(
          R.pathOr('www.atomapp.com', ['location', 'hostname']),
          R.split('.'),
          R.drop(1),
          R.join('.'),
        )(window)
      : R.pipe(R.pathOr('www.atomapp.com', ['location', 'hostname']))(window);
  };

  isValid = (): boolean => {
    const host = window.location.hostname.split('.')[2]
      ? window.location.hostname.split('.')[1]
      : window.location.hostname.split('.')[0];
    const tenant = !this.isValidTenantName(host)
      ? 'Please enter a valid tenant name.  The name cannot include any numbers or special characters.'
      : '';

    this.setState({
      errors: {
        ...this.state.errors,
        tenant,
      },
    });

    return !tenant;
  };

  submit = () => {
    const { tenant } = this.state;

    if (this.isValid()) {
      const protocol = window.location.protocol;
      const token = accountUtilities.getToken();
      const domain = userSelectors.getDomain(tenant);

      window.location.href = `${protocol}//${domain}/callback/${token}?tenant=${tenant}`;
    }
  };

  getErrorText = () => {
    const { errors } = this.state;

    if (errors.tenant) {
      return <div styleName="tenant-creation-error-text">{errors.tenant}</div>;
    }

    return <div />;
  };

  logout = () => {
    const { userActions } = this.props;
    userActions.triggerLogout();
  };

  render() {
    const { loading } = this.props;
    const { email, tenant } = this.state;
    const errors = this.getErrorText();
    const host = this.getCurrentHost();

    const content = loading ? (
      <div styleName="spinner-container">
        <Progress />
      </div>
    ) : (
      <React.Fragment>
        {errors}
        <div styleName="text-field-container entry">
          Enter the workspaces URL. If you do not know the workspaces URL,
          please contact the team manager.
        </div>
        <div styleName="tenant-creation-input-container">
          <TextField
            variant="standard"
            name="tenant"
            value={tenant}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
          />
          <div styleName="tenant-creation-input-label">{`.${host}`}</div>
        </div>
        <div styleName="tenant-creation-button-container">
          <Button color="primary" variant="contained" onClick={this.submit}>
            Go to Atom
          </Button>
        </div>
        <div styleName="tenant-selection-container">
          <div styleName="tenant-decision-container">
            <div styleName="tenant-decision-container">
              <div
                styleName="tenant-selection-link"
                onClick={this.navigateBack}
              >
                Go back
              </div>
              or
              <Link to="/tenant-creation">
                <div styleName="tenant-selection-link">Create a workspace</div>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <AppBar style={navBarStyle}>
          <Toolbar>
            <div>
              <Logo />
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <div styleName="nav-right-container">
              <div styleName="nav-right-text">{email}</div>
              <div styleName="nav-right-login" onClick={this.logout}>
                Sign Out
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div styleName="container">
          <div styleName="auth-background complete" />
          <div styleName="heading-text">Join a workspace</div>

          {content}
          <LoginFooter />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loading: state.loading.loadingTenantCreation,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantEntry);

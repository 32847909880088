// Contains Modal Form Inputs for selecting a location
// Common Start and End Mileage marker logic handled here
// - unique logic and fields in ./TaskLocation[tenant]Inputs.tsx

import React, { ReactNode, useContext, useMemo, useState } from 'react';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { Button, Icon, IconButton, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  LocationDataState,
  LocationDataTitle,
  LocationOptionsState,
} from '@atom/types/taskLocation';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { isMilepostsValid } from '../taskLocationUtilities';

import TaskLocationAldotInputs from './TaskLocationAldotInputs';
import TaskLocationSidebarContext from './TaskLocationSidebarContext';
import TaskLocationUdotInputs from './TaskLocationUdotInputs';

import './taskLocationModal.css';

const styles = {
  refreshIcon: {
    transform: 'rotateY(180deg)',
  },
  tooltipIconButton: {
    padding: '0',
  },
};

interface Props {
  name: string;
  values: LocationDataState;
  options: LocationOptionsState;
  updateValue: (property: LocationDataTitle, value: any) => void;
  loadingAutocompleteData: boolean;
  loadingFindLocation: boolean;
  clearValues: () => void;
  inputsLocked: boolean;
  rangeGapError: boolean;
  setRangeGapError: (rangeGapError: boolean) => void;
}

const TaskLocationSidebar = ({
  values,
  options,
  updateValue,
  name,
  loadingAutocompleteData,
  loadingFindLocation,
  clearValues,
  inputsLocked,
  rangeGapError,
  setRangeGapError,
}: Props) => {
  const { workOrderDetail, task, refetch } = useContext(
    LocationsAndAssetsContext,
  );

  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const loading = loadingAutocompleteData || loadingFindLocation;
  const loadingText = loadingFindLocation
    ? 'Finding location, please wait...'
    : 'Filtering options, please wait...';
  const tooltipText =
    'Management Unit, County, and Road Class for locations and assets must remain the same on the task. To modify, please delete all assets and locations and start over.';

  const showRanges =
    !isNilOrEmpty(options[LocationDataTitle.START_MILEPOST]) &&
    !isNilOrEmpty(options[LocationDataTitle.END_MILEPOST]);

  const isRangeError = useMemo(() => {
    if (loading) {
      return false;
    }

    return !isMilepostsValid(options, values) || rangeGapError;
  }, [loading, options, values, rangeGapError]);

  const rangeSubtext = isRangeError
    ? `Provide a range from ${options[LocationDataTitle.START_MILEPOST]} to ${
        options[LocationDataTitle.END_MILEPOST]
      }. If the value is within the range but there is still an error, please visit the Knowledge Center for a route map.`
    : `Provide a range from ${options[LocationDataTitle.START_MILEPOST]} to ${
        options[LocationDataTitle.END_MILEPOST]
      }.`;

  const rangeSubtextStyles = {
    color: isRangeError ? colors.brand.red : colors.neutral.dim,
  };

  const isClearDisabled =
    workOrderDetail?.isClosed ||
    loading ||
    (task.isCompleted && !task?.requireAtLeastOneLocation);
  const clearIconStyle = {
    ...styles.refreshIcon,
    color: isClearDisabled ? colors.neutral.silver : colors.brand.blue,
  };

  const onClearClick = () => {
    if (task.isCompleted) {
      return setOpenFieldsConfirm(true);
    }

    return clearValues();
  };

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
    clearValues();
  };

  // onBlur of milepost input, auto fill the other milepost input if it is empty
  const autoFillMilepost = (currentInput: LocationDataTitle) => {
    setRangeGapError(false);

    const startValue = values[LocationDataTitle.START_MILEPOST];
    const endValue = values[LocationDataTitle.END_MILEPOST];

    if (
      !isNilOrEmpty(startValue) &&
      isNilOrEmpty(endValue) &&
      currentInput === LocationDataTitle.START_MILEPOST
    ) {
      updateValue(LocationDataTitle.END_MILEPOST, startValue);
    } else if (
      !isNilOrEmpty(endValue) &&
      isNilOrEmpty(startValue) &&
      currentInput === LocationDataTitle.END_MILEPOST
    ) {
      updateValue(LocationDataTitle.START_MILEPOST, endValue);
    } else {
      return;
    }
  };

  // Using ALDOT for default as other tenants already see ALDOT fields
  const getTenantInputs = (): ReactNode => {
    switch (true) {
      case isCurrentClient([Client.UDOT]):
        return <TaskLocationUdotInputs />;
      case isCurrentClient([Client.ALDOT]):
        return <TaskLocationAldotInputs />;
      default:
        return <></>;
    }
  };

  const handleMilepostChange = (title: LocationDataTitle, value: any) => {
    setRangeGapError(false);

    if (value === '') {
      updateValue(title, '');
      return;
    }

    updateValue(title, Number(value));
  };

  return (
    <TaskLocationSidebarContext.Provider
      value={{
        values,
        options,
        updateValue,
        loading,
        inputsLocked,
        rangeGapError,
        setRangeGapError,
        autoFillMilepost,
        isRangeError,
        showRanges,
        rangeSubtext,
        rangeSubtextStyles,
        handleMilepostChange,
      }}
    >
      <>
        <div styleName="sidebar-container">
          <TextField value={name} label="Name" onChange={() => {}} disabled />
          <div styleName="info-row">
            <div styleName="info-content">
              Provide all of the following information to create a location on
              the map.
            </div>
            {isCurrentClient([Client.ALDOT]) && (
              <IconButton
                tooltip={tooltipText}
                style={styles.tooltipIconButton}
              >
                <Icon>help_outline</Icon>
              </IconButton>
            )}
          </div>
          <Button
            color="primary"
            startIcon={<Icon style={clearIconStyle}>refresh</Icon>}
            disabled={isClearDisabled}
            onClick={onClearClick}
          >
            Clear All Fields
          </Button>
          {getTenantInputs()}
          {loading && (
            <div styleName="loading-row">
              <Progress size={16} thickness={5} />
              <div styleName="loading-text">{loadingText}</div>
            </div>
          )}
        </div>
        <RequiredFieldsConfirmModal
          open={openFieldsConfirm}
          onCancel={() => setOpenFieldsConfirm(false)}
          onComplete={onProceedComplete}
          task={task}
          workOrderId={workOrderDetail?.id}
        />
      </>
    </TaskLocationSidebarContext.Provider>
  );
};

export default TaskLocationSidebar;

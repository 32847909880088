import React, { useCallback, useEffect, useState } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import {
  TASK_FIELD_CREATE,
  TASK_FIELD_DELETE,
  TASK_FIELD_UPDATE,
  TASK_UPDATE,
  TASK_USERS_STATUS_UPDATE,
} from '@atom/graph/task';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import { useWorkValidations } from '@atom/hooks/useWorkValidations';
import { Button, Icon, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { DataType, TaskFieldDataType } from '@atom/types/dataType';
import {
  Task,
  TASK_FIELD_DATA_TYPE_OPTIONS,
  TaskField,
  TaskFieldCreateInput,
  TaskFieldCreatePayload,
  TaskFieldDeleteInput,
  TaskFieldUpdateInput,
  TaskFieldUpdatePayload,
  TaskUpdateInput,
  TaskUsersStatusUpdateInput,
  TaskUserStatus,
} from '@atom/types/task';
import { WorkOrderDetailType } from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  hasRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { addToSet, removeFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import TaskDateField from './TaskDateField';
import TaskEnumMultipleField from './TaskEnumMultipleField';
import TaskEnumSingleField from './TaskEnumSingleField';
import TaskFieldCreateModal from './TaskFieldCreateModal';
import TaskFieldEditModal from './TaskFieldEditModal';
import TaskFieldMenu from './TaskFieldMenu';
import TaskHyperlinkField from './TaskHyperlinkField';
import TaskNumericField from './TaskNumericField';
import TaskTextField from './TaskTextField';

import './taskFields.css';

const DEBOUNCE_TIME = 1000;
const DROPPABLE_ID = 'fields';

const styles = {
  fieldIconStyles: {
    marginRight: '1.25rem',
  },
  addButtonStyles: {
    marginLeft: '4rem',
  },
};

interface Props {
  workOrderDetail: WorkOrderDetailType;
  task: Task;
  dispatch: any;
  WorkOrderActionTypes: any;
  /**
   * Denotes which view the list is being rendered in.
   * Various styles will change downstream depending on this boolean.
   */
  previewView?: boolean;
  refetch?: () => void;
}

const TaskFields = ({
  workOrderDetail,
  task,
  dispatch,
  WorkOrderActionTypes,
  previewView = false,
  refetch = () => {},
}: Props) => {
  const userProfile = useUserProfile();

  const { workValidations, resolveTaskFieldError } = useWorkValidations();

  const [selectedField, setSelectedField] = useState<TaskField>(null);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [fieldsConfirmModalOpen, setFieldsConfirmModalOpen] = useState<boolean>(
    false,
  );
  const [fieldsLoading, setFieldsLoading] = useState<Set<string>>(new Set());
  const [hoveredField, setHoveredField] = useState<string>();
  const [fieldOrder, setFieldOrder] = useState<string[]>(task.fieldOrder || []);
  // This is used to maintain the current active path of enumsingle custom fields
  // that have cascaded values. The data assists with resolving error states.
  const [cascadeActivePaths, setCascadeActivePaths] = useState<{
    [key: string]: string[];
  }>();

  useEffect(() => {
    setFieldOrder(task.fieldOrder || []);
  }, [task.id, task.taskTemplateId]);

  const [createField, { loading: loadingCreate }] = useMutation<
    { taskFieldCreate: TaskField },
    { input: TaskFieldCreateInput }
  >(TASK_FIELD_CREATE);

  const [updateField, { loading: loadingUpdate }] = useMutation<
    { taskFieldUpdate: TaskField },
    { input: TaskFieldUpdateInput }
  >(TASK_FIELD_UPDATE);

  const [deleteField, { loading: loadingDelete }] = useMutation<
    { taskFieldDelete: boolean },
    { input: TaskFieldDeleteInput }
  >(TASK_FIELD_DELETE);

  const [taskUpdate] = useMutation<
    { taskUpdate: Task },
    { input: TaskUpdateInput }
  >(TASK_UPDATE);

  const [updateUsersTaskStatus] = useMutation<
    { taskUsersStatusUpdate: boolean },
    { input: TaskUsersStatusUpdateInput }
  >(TASK_USERS_STATUS_UPDATE);

  const handleEditClick = (field: TaskField) => {
    setSelectedField(field);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (field: TaskField) => {
    setSelectedField(field);
    setDeleteModalOpen(true);
  };

  const handleAddField = (field: TaskField) => {
    dispatch({
      type: WorkOrderActionTypes.ADD_TASK_FIELD,
      data: {
        taskId: task.id,
        field,
      },
    });

    setFieldOrder([...fieldOrder, field.id]);
  };

  const handleEditField = (field: TaskField) => {
    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_FIELD,
      data: {
        taskId: task.id,
        field,
      },
    });
  };

  const handleEditFieldValue = (field: TaskField) => {
    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_FIELD_VALUE,
      data: {
        taskId: task.id,
        field,
      },
    });
  };

  // If a non leaf cascade node's value is changed, the values below
  // are removed from the UI. If there were any errors in the nodes removed
  // this way, they need to be marked as resolved

  // If there are no descendant cascade errors, then the error on the field
  // being edited will be resolved
  const resolveDescendantCascadeErrors = (fieldId: string) => {
    const activePath = R.values(cascadeActivePaths).reduce((acc, path) => {
      return R.includes(fieldId, path) ? path : acc;
    }, []);

    const childrenBelowUpdate = R.remove(
      0,
      R.findIndex(R.equals(fieldId), activePath) + 1,
      activePath,
    );

    const leafNodeFieldId = R.last(childrenBelowUpdate);

    if (!!leafNodeFieldId) {
      resolveTaskFieldError(task.id, leafNodeFieldId);
    } else {
      resolveTaskFieldError(task.id, fieldId);
    }
  };

  const handleChange = useCallback(
    async (fieldId: string, value: any) => {
      setFieldsLoading(loading => addToSet(loading, fieldId));

      resolveDescendantCascadeErrors(fieldId);

      const res = await updateField({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            fieldId,
            value,
          },
        },
      });

      const field = res?.data?.taskFieldUpdate;

      if (field.subFieldsTruncated) {
        handleEditField(field);
      } else {
        handleEditFieldValue(field);
      }

      setFieldsLoading(loading => removeFromSet(loading, fieldId));
    },
    [
      workOrderDetail.id,
      task.id,
      task.fields,
      workValidations,
      resolveTaskFieldError,
      resolveDescendantCascadeErrors,
      cascadeActivePaths,
    ],
  );

  const handleChangeDebounced = useCallback(
    debounce(handleChange, DEBOUNCE_TIME),
    [handleChange],
  );

  const handleDeleteConfirm = async () => {
    const fieldOrderIndex = R.indexOf(selectedField.id, fieldOrder);
    const newFieldOrder = R.remove(fieldOrderIndex, 1, fieldOrder);
    setFieldOrder(newFieldOrder);

    const res = await deleteField({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          taskId: task.id,
          fieldId: selectedField.id,
        },
      },
    });

    if (res?.data?.taskFieldDelete) {
      setSelectedField(null);
      setDeleteModalOpen(false);

      dispatch({
        type: WorkOrderActionTypes.DELETE_TASK_FIELD,
        data: {
          taskId: task.id,
          fieldId: selectedField.id,
        },
      });
    }
  };

  const handleCreate = async (payload: TaskFieldCreatePayload) => {
    const res = await createField({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          taskId: task.id,
          ...payload,
        },
      },
    });

    handleAddField(res?.data?.taskFieldCreate);
    setCreateModalOpen(false);
  };

  const handleUpdate = async (payload: TaskFieldUpdatePayload) => {
    const res = await updateField({
      variables: {
        input: {
          workOrderId: workOrderDetail.id,
          taskId: task.id,
          ...payload,
        },
      },
    });

    handleEditField(res?.data?.taskFieldUpdate);
    setEditModalOpen(false);
  };

  const onDragEnd = (result: DragDrop) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const newFieldOrder = R.move(source.index, destination.index, fieldOrder);
    setFieldOrder(newFieldOrder);

    taskUpdate({
      variables: {
        input: {
          id: task.id,
          workOrderId: workOrderDetail.id,
          fieldOrder: newFieldOrder,
        },
      },
    });

    dispatch({
      type: WorkOrderActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task.id,
        property: 'fieldOrder',
        value: newFieldOrder,
      },
    });
  };

  const handleRequiredFieldsEdit = (field: TaskField) => {
    if (!field.required || !task.isCompleted || workOrderDetail.isClosed) {
      return;
    }

    setFieldsConfirmModalOpen(true);
  };

  const submitRequiredFieldsEdit = async () => {
    const userIsAssignedToTask = task.users
      .map(item => item.id)
      .includes(userProfile.userId);

    if (userIsAssignedToTask) {
      await updateUsersTaskStatus({
        variables: {
          input: {
            workOrderId: workOrderDetail.id,
            taskId: task.id,
            users: [
              {
                userId: userProfile.userId,
                status: TaskUserStatus.IN_PROGRESS,
              },
            ],
          },
        },
      });

      setFieldsConfirmModalOpen(false);
      refetch();
    } else {
      await taskUpdate({
        variables: {
          input: {
            id: task.id,
            workOrderId: workOrderDetail.id,
            isCompleted: false,
          },
        },
      });

      setFieldsConfirmModalOpen(false);
      refetch();
    }
  };

  const handleHover = (id?: string) => {
    if (!workOrderDetail?.isClosed) {
      setHoveredField(id);
    }
  };

  const showButton =
    !workOrderDetail.workTemplateId &&
    !task.taskTemplateId &&
    !workOrderDetail.isClosed &&
    hasRolePermissions(ROLE_SETS.INSPECTOR);

  const isDragDisabled =
    workOrderDetail?.isClosed || !isNilOrEmpty(workOrderDetail?.workTemplateId);

  const getTaskFieldIcon = (dataType: TaskFieldDataType) => {
    const option = TASK_FIELD_DATA_TYPE_OPTIONS.find(
      item => item.dataType === dataType,
    );

    return option?.icon || '';
  };

  const getContent = (field: TaskField) => {
    const isFieldEditDisabled =
      doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR) ||
      workOrderDetail.isClosed ||
      (field.required && task.isCompleted);

    const invalidFieldIds = R.pathOr(
      new Set([]),
      ['taskValidations', task.id, 'invalidTaskFields'],
      workValidations,
    );

    const props = {
      field,
      taskId: task?.id,
      error: invalidFieldIds.has(field.id),
      fieldsLoading,
      onChange: handleChange,
      isDisabled: isFieldEditDisabled,
      previewView,
      onClick: () => handleRequiredFieldsEdit(field),
      onBlur: handleChange,
    };

    const hasSubFields = !isNilOrEmpty(field.subFields);

    const components = {
      [DataType.SHORT_TEXT]: (
        <TaskTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.LONG_TEXT]: (
        <TaskTextField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE]: (
        <TaskDateField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.DATE_TIME]: (
        <TaskDateField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.ENUM_SINGLE]: hasSubFields ? (
        <div styleName="enum-single-nested-container">
          <TaskEnumSingleField
            {...props}
            onChange={handleChangeDebounced}
            invalidFieldIds={invalidFieldIds}
            setCascadeActivePaths={setCascadeActivePaths}
          />
        </div>
      ) : (
        <TaskEnumSingleField
          {...props}
          onChange={handleChangeDebounced}
          invalidFieldIds={invalidFieldIds}
        />
      ),
      [DataType.ENUM_MULTIPLE]: <TaskEnumMultipleField {...props} />,
      [DataType.NUMBER]: (
        <TaskNumericField {...props} onChange={handleChangeDebounced} />
      ),
      [DataType.HYPERLINK]: <TaskHyperlinkField {...props} />,
    };

    const iconColor =
      hoveredField === field.id ? colors.neutral.ash : colors.neutral.white;

    const dragStyleName = previewView
      ? 'field-drag-container preview'
      : 'field-drag-container';

    return (
      <div
        styleName={dragStyleName}
        onMouseEnter={() => handleHover(field.id)}
        onMouseDown={() => handleHover(field.id)}
        onMouseUp={() => handleHover(null)}
      >
        <Icon color={iconColor}>drag_indicator</Icon>
        <Icon color={colors.neutral.gray} style={styles.fieldIconStyles}>
          {getTaskFieldIcon(field?.dataType)}
        </Icon>
        {components[field.dataType]}
        {showButton && (
          <TaskFieldMenu
            field={field}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div onMouseLeave={() => handleHover(null)}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={DROPPABLE_ID}>
            {fieldOrder.map((fieldId: string, index: number) => {
              const field = task.fields.find(item => item.id === fieldId);

              if (!field) {
                return null;
              }

              return (
                <Draggable
                  key={`${field.title}-${index}`}
                  draggableId={field.id}
                  isDragDisabled={isDragDisabled}
                  index={index}
                >
                  {getContent(field)}
                </Draggable>
              );
            })}
          </Droppable>
        </DragDropContext>
        {showButton && (
          <Button
            color="primary"
            style={styles.addButtonStyles}
            startIcon={<Icon color={colors.brand.blue}>add</Icon>}
            onClick={() => setCreateModalOpen(true)}
          >
            Add Field
          </Button>
        )}
      </div>
      <TaskFieldCreateModal
        fields={task?.fields || []}
        handleCreate={handleCreate}
        loadingCreate={loadingCreate}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
      <TaskFieldEditModal
        fields={task?.fields || []}
        handleUpdate={handleUpdate}
        loadingUpdate={loadingUpdate}
        onClose={() => setEditModalOpen(false)}
        field={selectedField}
        open={editModalOpen}
      />
      <DeleteModal
        open={deleteModalOpen}
        loading={loadingDelete}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Delete Field"
        content="Are you sure you want to delete this field?"
      />
      <Modal
        open={fieldsConfirmModalOpen}
        cancelButtonText="Cancel"
        confirmButtonText="Proceed"
        onCancel={() => setFieldsConfirmModalOpen(false)}
        onConfirm={submitRequiredFieldsEdit}
        title="Make Changes to Required Fields?"
      >
        This task has been marked complete, any changes made to required fields
        will mark the task incomplete. Do you want to proceed?
      </Modal>
    </>
  );
};

export default TaskFields;

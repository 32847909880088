import React, { useContext } from 'react';

import TeamContext, { View } from '@atom/components/teamPortal/TeamContext';
import { Icon, IconButton } from '@atom/mui';

import './timeHeaders.css';

interface Props {
  title: string;
  showBackButton?: boolean;
}

const TitleHeader = ({ title, showBackButton }: Props) => {
  const { navigateToView, setSelectedTimeSheetUser } = useContext(TeamContext);

  const handleClick = () => {
    navigateToView(View.TIME_SHEET_LIST);
    setSelectedTimeSheetUser(null);
  };

  const titleStyle = showBackButton ? 'title full-name' : 'title';

  return (
    <div styleName="time-sheet-header">
      {showBackButton && (
        <IconButton onClick={handleClick}>
          <Icon>arrow_back</Icon>
        </IconButton>
      )}
      <div styleName={titleStyle}>{title}</div>
    </div>
  );
};

export default TitleHeader;

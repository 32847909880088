import React from 'react';
import * as R from 'ramda';

import { LocationDataTitle } from '@atom/types/taskLocation';

import './workOrderReport.css';

interface Props {
  rowMultiAsset: any;
}

const MultiAssetRow = ({ rowMultiAsset }: Props) => {
  const convertNumberToLocaleString = (input: string | number): string => {
    return R.isNil(input) ? '' : Number(input).toLocaleString('en-US');
  };

  const attributes: any[] =
    R.values(R.pathOr({}, ['attributes'], rowMultiAsset)) || [];

  const route =
    attributes.find(item => item.name === LocationDataTitle.ROUTE)?.value || '';

  const direction =
    attributes.find(item => item.name === LocationDataTitle.DIRECTION)?.value ||
    '';

  const startMilepost = attributes.find(
    item => item.name === LocationDataTitle.START_MILEPOST,
  )?.value;

  const endMilepost = attributes.find(
    item => item.name === LocationDataTitle.END_MILEPOST,
  )?.value;

  return (
    <div styleName="asset-row">
      <div styleName="asset-name">{rowMultiAsset?.name}</div>
      <div styleName="asset-row-sub">
        <div styleName="asset-value route">{route}</div>
        <div styleName="asset-value">{direction}</div>
        <div styleName="asset-value">
          {convertNumberToLocaleString(startMilepost)}
        </div>
        <div styleName="asset-value">
          {convertNumberToLocaleString(endMilepost)}
        </div>
      </div>
    </div>
  );
};

export default MultiAssetRow;

import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

// @ts-ignore
import cursorIcon from '@atom/components/common/svgIcons/mapCursor.svg';
// @ts-ignore
import cursorIconSelected from '@atom/components/common/svgIcons/mapCursorSelected.svg';
// @ts-ignore
import grabIcon from '@atom/components/common/svgIcons/mapGrab.svg';
// @ts-ignore
import grabIconSelected from '@atom/components/common/svgIcons/mapGrabSelected.svg';
// @ts-ignore
import polygonIcon from '@atom/components/common/svgIcons/polygonDraw.svg';
// @ts-ignore
import polygonIconSelected from '@atom/components/common/svgIcons/polygonDrawSelected.svg';
// @ts-ignore
import radiusIcon from '@atom/components/common/svgIcons/radiusDraw.svg';
// @ts-ignore
import radiusIconSelected from '@atom/components/common/svgIcons/radiusDrawSelected.svg';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MapShapeType } from '@atom/types/map';
import {
  Client,
  isCurrentClient,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';

import './drawingControls.css';
interface Props {
  drawingMode: MapShapeType;
  onChange: (drawingMode: MapShapeType) => void;
  grab: boolean;
  onCursorChange: (cursor: Boolean) => void;
  clearDrawing: () => void;
}

const styles = {
  icon: {
    width: '2.375rem',
    height: '2.375rem',
    padding: '0.4375rem',
    zIndex: '200',
  },
};

const DrawingControls = ({
  drawingMode,
  onChange,
  grab,
  onCursorChange,
  clearDrawing,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setNavigation] = useQueryParam('view', StringParam);

  // Bulk work warning and info message section is toggled via client
  const enableBulkImplementation =
    isCurrentClient([Client.HENNEPIN]) ||
    isCurrentTenant([
      Tenant.DEV_GWRR,
      Tenant.QA_GWRR,
      Tenant.UAT_GWRR,
      Tenant.DEV_MACOMB,
      Tenant.QA_MACOMB,
      Tenant.UAT_MACOMB,
    ]);

  const handleClick = (mode: MapShapeType) => {
    if (drawingMode === mode) {
      onChange(null);
    } else {
      clearDrawing();
      onChange(mode);
      if (enableBulkImplementation) {
        setNavigation('list');
      }
    }
  };

  const handleCursorClick = (value: Boolean) => {
    clearDrawing();
    onCursorChange(value);
  };

  return (
    <>
      <div styleName="layer-controls-container">
        <div styleName="icon-container">
          <IconButton
            tooltip="Select"
            style={styles.icon}
            onClick={() => handleCursorClick(false)}
          >
            <Icon>
              <img src={grab ? cursorIcon : cursorIconSelected} alt="Cursor" />
            </Icon>
          </IconButton>
        </div>
        <div styleName="icon-container">
          <IconButton
            tooltip="Grab"
            style={styles.icon}
            onClick={() => handleCursorClick(true)}
          >
            <Icon>
              <img src={grab ? grabIconSelected : grabIcon} alt="Draw Radius" />
            </Icon>
          </IconButton>
        </div>
        <div styleName="icon-container">
          <IconButton
            tooltip="Draw a radius"
            style={styles.icon}
            onClick={() => handleClick(MapShapeType.CIRCLE)}
          >
            <Icon>
              <img
                src={
                  drawingMode === MapShapeType.CIRCLE
                    ? radiusIconSelected
                    : radiusIcon
                }
                alt="Draw Radius"
              />
            </Icon>
          </IconButton>
        </div>
        <div styleName="icon-container">
          <IconButton
            tooltip="Draw a polygon"
            style={styles.icon}
            onClick={() => handleClick(MapShapeType.POLYGON)}
          >
            <Icon>
              <img
                src={
                  drawingMode === MapShapeType.POLYGON
                    ? polygonIconSelected
                    : polygonIcon
                }
                alt="Draw Polygon"
              />
            </Icon>
          </IconButton>
        </div>
        <div styleName="icon-container">
          <IconButton
            tooltip="Measure distance"
            style={styles.icon}
            onClick={() => handleClick(MapShapeType.POLYLINE)}
          >
            <Icon
              color={
                drawingMode === MapShapeType.POLYLINE
                  ? colors.brand.blue
                  : colors.neutral.gray
              }
            >
              straighten
            </Icon>
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default DrawingControls;

import React, { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  DELETE_BUDGET,
  GET_BUDGET_TEMPLATES,
  UPDATE_BUDGET,
} from '@atom/graph/budget';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  Budget,
  BudgetModal,
  BudgetTemplatesConnection,
  BudgetUpdateInput,
} from '@atom/types/budget';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';

import BudgetsFormModal from '../budgets/budgetsModal/BudgetsFormModal';
import DeleteModal from '../common/DeleteModal';

import BudgetDetailBudgetNav from './BudgetDetailBudgetNav';
import BudgetDetailContext from './BudgetDetailContext';
import { BUDGET_OPTIONS_LIMIT, getDateRange } from './budgetDetailUtils';
import {
  deleteBudgetFilters,
  useBudgetFilterStorage,
} from './useBudgetFilterStorage';

import '../../styles/detail-header.css';

const BudgetDetailHeader = () => {
  const {
    budget,
    budgetOptions,
    totalBudgetOptions,
    loadingBudgetOptions,
    getBudgetOptions,
  } = useContext(BudgetDetailContext);
  const { saveFilterState } = useBudgetFilterStorage();

  const [activeModal, setActiveModal] = useState<BudgetModal>(null);
  const [page, setPage] = useState<number>(1);

  /*
    TODO: Replace temporary permissions here with RBAC
    NOTE: This is shared behavior with the BudgetsTable component
    
    Current Rules:
    QA and Production:
    - Create button is hidden for everyone except Admin for all budgets
    Dev and UAT:
    - Create button is hidden for everyone below Org Admin (show for Admin and Org Admin)
  
    source: https://atomai.atlassian.net/browse/AM-15294
  */
  const roleNeededToEditOrDelete: string[] = isCurrentEnvironment([
    Environment.QA,
    Environment.PRODUCTION,
  ])
    ? ROLE_SETS.ADMIN
    : ROLE_SETS.ORG_ADMIN;

  const canEditBudgets: boolean = hasRolePermissions(roleNeededToEditOrDelete);
  const canDeleteBudgets: boolean = hasRolePermissions(
    roleNeededToEditOrDelete,
  );

  const handlePageScroll = (nextPage: number) => {
    setPage(nextPage);
    getBudgetOptions({
      variables: {
        input: {
          templateId: budget.templateId,
          page: nextPage,
          limit: BUDGET_OPTIONS_LIMIT,
          sortBy: 'name,asc',
        },
      },
    });
  };

  const budgetNamesUsed = useMemo(
    () => new Set(budgetOptions?.map(({ name }) => name) || []),
    [budgetOptions],
  );

  const { data: templatesData, loading: loadingTemplates } = useQuery<{
    budgetTemplates: BudgetTemplatesConnection;
  }>(GET_BUDGET_TEMPLATES);

  const templates = R.pathOr(
    [],
    ['budgetTemplates', 'budgetTemplates'],
    templatesData,
  );

  const [deleteBudget, { loading: loadingDelete }] = useMutation<
    {},
    { id: string }
  >(DELETE_BUDGET);

  const handleDeleteBudget = async () => {
    try {
      await deleteBudget({ variables: { id: budget.id } });
      deleteBudgetFilters(budget.id);
      Snackbar.info({
        message: `Deleted budget "${budget.name}"`,
      });
      history.push('/budgets');
    } catch {
      Snackbar.error({
        message: `An error occurred while deleting ${budget.name}. Please Try Again.`,
      });
    } finally {
      setActiveModal(null);
    }
  };

  const [updateBudget, { loading: loadingUpdate }] = useMutation<
    { budgetUpdate: Budget },
    { input: BudgetUpdateInput }
  >(UPDATE_BUDGET);

  const handleUpdateBudget = async (input: BudgetUpdateInput) => {
    try {
      await updateBudget({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: `Updated budget "${input.name}"`,
      });
    } catch (error) {
      Snackbar.error({
        message:
          'An error occurred while updating your budget. Please Try Again.',
      });
    } finally {
      setActiveModal(null);
    }
  };

  const title: string = !R.isEmpty(budget)
    ? `${budget.name}${getDateRange(budget)}`
    : '';

  const handleGoBack = () => {
    saveFilterState();
    history.goBack();
  };

  return (
    <div styleName="header-container budgets-header">
      <div styleName="name-container budgets-name-container">
        <IconButton onClick={handleGoBack} tooltip="Go back">
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <span>{title}</span>
        <BudgetDetailBudgetNav
          options={budgetOptions}
          total={totalBudgetOptions}
          loading={loadingBudgetOptions}
          page={page}
          handlePageScroll={handlePageScroll}
        />
      </div>
      <div>
        {canEditBudgets && (
          <IconButton
            onClick={() => setActiveModal(BudgetModal.EDIT)}
            tooltip="Edit Budget"
          >
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
        )}
        {canDeleteBudgets && (
          <IconButton
            onClick={() => setActiveModal(BudgetModal.DELETE)}
            tooltip="Delete Budget"
          >
            <Icon color={colors.neutral.white}>delete</Icon>
          </IconButton>
        )}
        {activeModal === BudgetModal.EDIT && !loadingTemplates && (
          <BudgetsFormModal
            onClose={() => setActiveModal(null)}
            budget={budget}
            modalType={activeModal}
            templates={templates}
            budgetNamesUsed={budgetNamesUsed}
            handleUpdateBudget={handleUpdateBudget}
            saving={loadingUpdate}
          />
        )}
        <DeleteModal
          open={activeModal === BudgetModal.DELETE}
          title="Delete Budget?"
          content={`Are you sure you want to delete budget "${budget?.name}"?`}
          onCancel={() => setActiveModal(null)}
          onConfirm={handleDeleteBudget}
          disabled={loadingDelete}
          loading={loadingDelete}
        />
      </div>
    </div>
  );
};

export default BudgetDetailHeader;

export enum DescriptionOption {
  ALL = 'ALL',
  OUTGOING = 'OUTGOING',
  INCOMING = 'INCOMING',
}

export enum ColumnType {
  NAME = 'NAME',
  ASSET_NAME = 'ASSET_NAME',
  FROM_ASSET_NAME = 'FROM_ASSET_NAME',
  STATUS = 'STATUS',
  DESCRIPTION = 'DESCRIPTION',
  QUANTITY = 'QUANTITY',
  ASSIGNED_TO = 'ASSIGNED_TO',
  CREATED_BY = 'CREATED_BY',
  CREATED_DATE = 'CREATED_DATE',
  UPDATED_DATE = 'UPDATED_DATE',
  REPLENISHMENTS = 'REPLENISHMENTS',
  QUANTITY_ORDERED = 'QUANTITY_ORDERED',
  QUANTITY_RECEIVED = 'QUANTITY_RECEIVED',
}

export interface Column {
  type: ColumnType;
  label: string;
  sortValue?: string;
}

export interface ConfirmModalContent {
  title: string;
  content: string;
}

export enum AssetRequestType {
  SDDOT_ADJUSTMENT = 'SDDOT_ADJUSTMENT',
  SDDOT_REPLENISHMENT = 'SDDOT_REPLENISHMENT',
  SDDOT_INVENTORY_TRANSFER = 'SDDOT_INVENTORY_TRANSFER',
  SDDOT_STOCKPILE_TRANSFER = 'SDDOT_STOCKPILE_TRANSFER',
}

// AssetRequestStatus definition contains all possible status for requests.
// Request status are per request type.
export enum AssetRequestStatus {
  FULLY_RECEIVED = 'FULLY_RECEIVED',
  PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  FULL_APPROVAL = 'FULL_APPROVAL',
  PARTIAL_APPROVAL = 'PARTIAL_APPROVAL',
}

export interface Replenishment {
  id: string;
  name: string;
  quantityReceived: number;
  unitCost: number;
  totalCost: number;
  invoiceNumber: string;
  comments?: string;
  createdDate: number;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

// AssetRequest definition contains all properties for all types of requests.
// Request types are per tenant, and will only utilize specific properties.
export interface AssetRequest {
  id: string;
  type: AssetRequestType;
  name: string;
  assetId: string;
  assetName?: string;
  assetUnit?: string;
  quantity?: number;
  quantityOrdered?: number;
  quantityApproved?: number;
  reason?: string;
  comments?: string;
  status?: AssetRequestStatus;
  assignedTo?: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  fromAssetId?: string;
  fromAssetName?: string;
  replenishments?: Replenishment[];
  createdDate: number;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  updatedDate?: number;
  updatedBy?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface AssetRequestsConnection {
  totalCount: number;
  assetRequests: AssetRequest[];
}

export interface AssetRequestsConnectionInput {
  page: number;
  limit: number;
  type?: AssetRequestType;
  assetId?: string;
  fromAssetId?: string;
  name?: string;
  sortBy?: string;
  isAscending?: boolean;
  statuses?: AssetRequestStatus[];
  createdDateStart?: number;
  createdDateEnd?: number;
  updatedDateStart?: number;
  updatedDateEnd?: number;
  createdBy?: string[];
  assignedTo?: string[];
  categoryIds?: string[];
  schemaIds?: string[];
}

export interface AssetRequestsFilters {
  type?: AssetRequestType;
  assetId?: string;
  fromAssetId?: string;
  name?: string;
  statuses?: AssetRequestStatus[];
  createdDateStart?: number;
  createdDateEnd?: number;
  updatedDateStart?: number;
  updatedDateEnd?: number;
  createdBy?: string[];
  assignedTo?: string[];
  categoryIds?: string[];
  schemaIds?: string[];
}

export interface AssetRequestCreateInput {
  assetId: string;
  type: AssetRequestType;
  quantity?: number;
  reason?: string;
  comments?: string;
  fromAssetId?: string;
  assignedTo?: string[];
  quantityOrdered?: number;
  replenishment?: {
    quantityReceived: number;
    unitCost: number;
    totalCost: number;
    comments?: string;
    invoiceNumber: string;
  };
}

export interface AssetRequestUpdateInput {
  id: string;
  status?: AssetRequestStatus;
  quantityApproved?: number;
  comments?: string;
}

export interface ReplenishmentCreateInput {
  id: string;
  quantityReceived: number;
  unitCost: number;
  totalCost: number;
  invoiceNumber: string;
  comments?: string;
}

import * as React from 'react';
import { connect } from 'react-redux';
import { AppBar, Box, Toolbar } from '@mui/material';
import { bindActionCreators, Dispatch } from 'redux';

import * as userActionCreators from '@atom/actions/userActions';
import Logo from '@atom/components/common/navigation/Logo';
import { Icon, IconButton, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import layoutStyles from '@atom/styles/layout';
import { UserActions } from '@atom/types/actions';
import { EventType, KeyPressEventType } from '@atom/types/event';
import { ReduxStore } from '@atom/types/store';
import accountUtilities from '@atom/utilities/accountUtilities';
import api from '@atom/utilities/api';
import { CALLBACK_ENDPOINT, TENANT_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

import GoogleSocialLoginButton from './GoogleSocialLoginButton';
import LoginFooter from './LoginFooter';
import ResetPasswordModal from './ResetPasswordModal';

import './login.css';

interface ReduxDispatchProps {
  userActions: UserActions;
}

interface ReduxStateProps {
  loading: boolean;
}

type Props = ReduxDispatchProps & ReduxStateProps;

interface ErrorsState {
  emailPassword: string;
}

interface State {
  email: string;
  password: string;
  passwordVisible: boolean;
  tenant: {
    id: string;
    name: string;
    preferences: {
      authentication: {
        connections: Array<{
          type: string;
          name: string;
        }>;
      };
    };
  };
  loading: boolean;
  errors: ErrorsState;
}

const navBarStyle = {
  height: layoutStyles.topNavHeight,
  backgroundColor: colors.neutral.white,
  boxShadow: '0 2px 4px 1px',
  color: colors.utility.boxShadow,
  position: 'fixed',
  top: 0,
};

const styles = {
  passwordField: {
    paddingTop: '0.4rem',
  },
};

const initialState: State = {
  email: '',
  password: '',
  passwordVisible: false,
  errors: {
    emailPassword: '',
  },
  tenant: {
    id: '',
    name: '',
    preferences: {
      authentication: {
        connections: [],
      },
    },
  },
  loading: false,
};

const activeDirectoryLogin = connection => () => {
  const webAuth = accountUtilities.getAuth0WebAuth();

  webAuth.authorize({
    connection,
    responseType: 'token',
    redirectUri: CALLBACK_ENDPOINT,
  });
};

class Login extends React.Component<Props, State> {
  state = initialState;

  componentDidMount() {
    this.getTenant();
  }

  onChange = (event: EventType) => {
    const { name, value } = event.target;
    // @ts-ignore
    this.setState({ [name]: value });
  };

  onKeyPress = (event: KeyPressEventType) => {
    if (event.key === 'Enter') {
      this.submit();
    }
  };

  submit = async () => {
    const { userActions } = this.props;
    const { email, password } = this.state;

    try {
      const result = await accountUtilities.individualAccountLogin(
        email,
        password,
      );
      userActions.requestUserAuthTokenExchange({
        accessToken: result.accessToken,
      });
    } catch (error) {
      await this.setState({
        errors: {
          ...this.state.errors,
          emailPassword: 'Invalid email or password',
        },
      });
    }
  };

  getTenant = async () => {
    const name = window.location.host.split('.')[1]
      ? window.location.host.split('.')[0]
      : '';

    if (name && name !== 'www') {
      this.setState({ loading: true });

      const { data: tenant } = await api
        .get(`${TENANT_ENDPOINT}/${name}`)
        .catch(() => ({ data: initialState.tenant }));

      this.setState({
        tenant,
        loading: false,
      });
    }
  };

  navigateToRegistration = () => {
    history.push('/registration');
  };

  togglePasswordVisibleState = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  render() {
    const { loading: loginLoading } = this.props;
    const {
      email,
      password,
      passwordVisible,
      errors,
      tenant,
      loading: tenantLoading,
    } = this.state;

    const connections = tenant?.preferences?.authentication?.connections || [];
    const content =
      tenantLoading || loginLoading ? (
        <div styleName="spinner-container">
          <Progress />
        </div>
      ) : (
        <React.Fragment>
          <div styleName="auth-background complete" />
          <div styleName="heading-text">Sign in to Atom</div>
          {errors.emailPassword && (
            <div styleName="error-text">{errors.emailPassword}</div>
          )}
          <div styleName="text-field-container">
            <div styleName="text-field">
              <TextField
                variant="standard"
                type="email"
                value={email}
                name="email"
                label="Email"
                onChange={this.onChange}
                onKeyPress={this.onKeyPress}
              />
            </div>
            <div styleName="text-field">
              <TextField
                variant="standard"
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                name="password"
                label="Password"
                onChange={this.onChange}
                onKeyPress={this.onKeyPress}
                style={styles.passwordField}
              />
              <div styleName="password-visible-toggle-container">
                <IconButton onClick={this.togglePasswordVisibleState}>
                  <Icon>remove_red_eye</Icon>
                </IconButton>
              </div>
            </div>
          </div>
          <ResetPasswordModal />
          <div styleName="generic-button-container">
            <button styleName="generic-button" onClick={this.submit}>
              Sign In
            </button>
          </div>
          <div styleName="registration-social-login-container">
            <div styleName="login-decision-break">
              <span>or</span>
            </div>
            <div styleName="social-login-container">
              <GoogleSocialLoginButton text="Sign In with Google" />
            </div>
          </div>
          {connections.map(({ type, name }) => {
            switch (type) {
              case 'azure-ad': {
                return (
                  <div styleName="registration-social-login-container">
                    <div styleName="login-decision-break">
                      <span>or</span>
                    </div>
                    <button
                      styleName="generic-button-secondary"
                      onClick={activeDirectoryLogin(name)}
                    >
                      Sign In with Active Directory
                    </button>
                  </div>
                );
              }
              default: {
                return <div />;
              }
            }
          })}
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <AppBar style={navBarStyle}>
          <Toolbar>
            <div>
              <Logo />
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <div styleName="nav-right-container">
              <div styleName="nav-right-text">Don't have an account?</div>
              <div
                styleName="nav-right-login"
                onClick={this.navigateToRegistration}
              >
                Sign Up
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div styleName="container">
          {content}
          <LoginFooter />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loading: state.loading.loadingUserLogin,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

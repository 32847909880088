import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import AttributeDetails from '@atom/components/common/attributeDetail/AttributeDetails';
import AttributeDetailResponsive from '@atom/components/common/attributeDetailResponsive/AttributeDetailResponsive';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetActions } from '@atom/types/actions';
import { AttributesType } from '@atom/types/inventory';
import { LocationUpdatePayload } from '@atom/types/map';
import { PolicyAction } from '@atom/types/policy';
import { InventoryAssetDetailState, ReduxStore } from '@atom/types/store';
import { hasAccess } from '@atom/utilities/accessUtilities';

import InventoryDetailMap from '../inventoryDetailMap/InventoryDetailMap';

import '../../../../styles/body-pane.css';

const styles = {
  hideMapButton: {
    zIndex: '1000',
    position: 'fixed',
    right: '1rem',
    top: '11rem',
    color: colors.neutral.white,
    display: 'flex',
    columnGap: '0.5rem',
  },
  hideMapButtonIcon: {
    color: colors.neutral.white,
  },
  fullBodyPane: {
    display: 'inline-block',
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  leftBodyPaneHalf: {
    display: 'inline-block',
    height: '100%',
    width: '50%',
    overflow: 'auto',
  },
};

const initialState = {
  isMapHidden: false,
};

interface ReduxStateProps {
  loading: any[];
  inventoryAssetDetail: InventoryAssetDetailState;
}

interface ReduxDispatchProps {
  inventoryAssetActions: InventoryAssetActions;
}

type Props = ReduxStateProps & ReduxDispatchProps;

interface State {
  isMapHidden: boolean;
}

class InventoryAssetInfo extends React.Component<Props, State> {
  state = initialState;

  onToggleMap = () => {
    this.setState(prevState => ({
      isMapHidden: !prevState.isMapHidden,
    }));
  };

  onLocationUpdate = (payload: LocationUpdatePayload) => {
    const { inventoryAssetActions, inventoryAssetDetail } = this.props;

    inventoryAssetActions.requestInventoryAssetDetailUpdate({
      id: inventoryAssetDetail?.id,
      rootAssetId: inventoryAssetDetail?.id,
      location: payload?.location,
    });
  };

  onPendingApproval = (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => {
    const { inventoryAssetDetail, inventoryAssetActions } = this.props;

    inventoryAssetActions.requestAssetAttributePendingApproval({
      action,
      attributeGroupName,
      attribute,
      assetId: inventoryAssetDetail.id,
    });
  };

  onAttributeUpdate = (payload: Object) => {
    const { inventoryAssetActions, inventoryAssetDetail } = this.props;

    inventoryAssetActions.requestInventoryAssetDetailUpdate({
      ...payload,
      rootAssetId: inventoryAssetDetail.id,
    });
  };

  render() {
    const { inventoryAssetDetail, loading } = this.props;
    const { isMapHidden } = this.state;

    const canManageChanges = hasAccess(
      PolicyAction.MANAGE_INVENTORY_CHANGES,
      inventoryAssetDetail.actions,
    );

    const canUpdate = hasAccess(
      PolicyAction.UPDATE,
      inventoryAssetDetail.actions,
    );

    return (
      <div styleName="body-container">
        {inventoryAssetDetail.isMaterial ? (
          <div styleName="center-pane-container">
            <div styleName="center-pane" style={{ overflow: 'auto' }}>
              <AttributeDetails
                onPendingApproval={this.onPendingApproval}
                inventoryAssetDetail={inventoryAssetDetail}
                onAttributeUpdate={this.onAttributeUpdate}
                loading={loading}
                canManageChanges={canManageChanges}
                canUpdate={canUpdate}
              />
            </div>
          </div>
        ) : (
          <>
            <Button
              variant="text"
              style={styles.hideMapButton}
              onClick={this.onToggleMap}
            >
              <Icon color={styles.hideMapButtonIcon.color}>map</Icon>
              {isMapHidden ? 'Show Map' : 'Hide Map'}
            </Button>

            <div
              style={
                isMapHidden ? styles.fullBodyPane : styles.leftBodyPaneHalf
              }
            >
              <AttributeDetailResponsive
                onPendingApproval={this.onPendingApproval}
                inventoryAssetDetail={inventoryAssetDetail}
                onAttributeUpdate={this.onAttributeUpdate}
                loading={loading}
                canManageChanges={canManageChanges}
                canUpdate={canUpdate}
              />
            </div>
            {!isMapHidden && (
              <div styleName="right-body-pane-half">
                <InventoryDetailMap
                  onSave={this.onLocationUpdate}
                  asset={inventoryAssetDetail}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  inventoryAssetDetail: state.inventoryAssetDetail,
  loading: state.loading.loadingInventoryAssetAttributes,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryAssetInfo);

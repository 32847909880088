import React from 'react';

import { useScrollPos } from '@atom/hooks/useScrollPos';
import colors from '@atom/styles/colors';

const styles = {
  fixedFiltersContainer: {
    position: 'fixed',
    height: '75px',
    marginTop: '110px',
    padding: '1rem 0 1rem 1.5rem',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.neutral.white,
    zIndex: 100,
  },
  breadcrumbRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const getBorder = (scrolled: boolean) => ({
  borderBottom: scrolled ? `1px solid ${colors.neutral.ash}` : 'none',
});

const BudgetFixedHeader = ({ children }) => {
  const scrolled = useScrollPos() > 0;
  return (
    <div
      style={{
        ...styles.fixedFiltersContainer,
        ...getBorder(scrolled),
      }}
    >
      {children}
    </div>
  );
};

export default BudgetFixedHeader;

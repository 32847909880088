import React, { useCallback, useContext, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useLazyQuery, useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';

import { GET_BASIC_SCHEMAS, GET_SCHEMA } from '@atom/graph/schema';
import { Icon, List, Progress, TextField } from '@atom/mui';
import {
  BasicSchema,
  BasicSchemasConnection,
  BasicSchemasInput,
  Schema,
} from '@atom/types/schema';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import CreateAssetModalContext from './CreateAssetModalContext';

import './createAssetModal.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  close: {
    cursor: 'pointer',
  },
  list: {
    padding: '0',
  },
  listItem: {
    padding: '4px 8px',
  },
  listText: {
    paddingLeft: '0.5rem',
  },
  virtuoso: {
    height: '360px',
  },
};

const MIN_SEARCH_CHARS = 2;
const DEBOUNCE_TIME = 500;

const SchemaSelectionTab = () => {
  const { schema, setSchema } = useContext(CreateAssetModalContext);

  const [basicSchemas, setBasicSchemas] = useState<BasicSchema[]>([]);
  const [searchResultBasicSchemas, setSearchResultBasicSchemas] = useState<
    BasicSchema[]
  >([]);
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const [getSchema, { loading: loadingHydratedSchema }] = useLazyQuery<
    { schema: Schema },
    { id: string }
  >(GET_SCHEMA, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setSchema(data?.schema || null);
    },
  });

  const [
    getBasicSchemas,
    { loading: loadingSearchBasicSchemas },
  ] = useLazyQuery<
    { basicSchemas: BasicSchemasConnection },
    { input: BasicSchemasInput }
  >(GET_BASIC_SCHEMAS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setSearchResultBasicSchemas(data?.basicSchemas?.basicSchemas || []);
    },
  });

  const { data: basicSchemaData, loading: loadingBasicSchemas } = useQuery<
    { basicSchemas: BasicSchemasConnection },
    { input: BasicSchemasInput }
  >(GET_BASIC_SCHEMAS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        rootSchemas: true,
        published: true,
        page,
        limit: 50,
        sortBy: 'name',
        isAscending: true,
      },
    },
    onCompleted: data => {
      setBasicSchemas(prevSchemas => [
        ...prevSchemas,
        ...data?.basicSchemas?.basicSchemas,
      ]);
    },
  });

  const fetchSearchOptionsDebounced = useCallback(
    debounce((value: string) => {
      getBasicSchemas({
        variables: {
          input: {
            name: value,
            rootSchemas: true,
            published: true,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const handleQueryChange = (value: string = '') => {
    setQuery(value);

    if (!value) {
      fetchSearchOptionsDebounced.cancel();
    } else if (value.length >= MIN_SEARCH_CHARS) {
      fetchSearchOptionsDebounced(value);
    } else {
      fetchSearchOptionsDebounced.cancel();
    }
  };

  const handleSchemaSelection = basicSchema => {
    getSchema({
      variables: {
        id: basicSchema.id,
      },
    });
  };

  const isSearching = query.length >= MIN_SEARCH_CHARS;
  const noMoreResults =
    basicSchemas.length >= basicSchemaData?.basicSchemas?.totalCount;

  return (
    <div styleName="tab-container">
      {basicSchemas.length === 0 ? (
        <Progress size={20} thickness={2} />
      ) : (
        <>
          <div styleName="title-container">
            <div styleName="tab-title">Inventory Type</div>
            {(loadingSearchBasicSchemas || loadingHydratedSchema) && (
              <Progress size={20} />
            )}
          </div>
          <TextField
            disabled={loadingHydratedSchema}
            value={query}
            onChange={event => handleQueryChange(event.target.value)}
            data-cy="globalCreateAssetModalSchemaInput"
            placeholder="Search"
            InputProps={{
              startAdornment: <Icon>search</Icon>,
              endAdornment: query && (
                <Icon
                  style={styles.close}
                  onClick={() => handleQueryChange('')}
                >
                  close
                </Icon>
              ),
            }}
          />
          <div styleName="list-container">
            <List style={styles.list}>
              <Virtuoso
                style={styles.virtuoso}
                data={isSearching ? searchResultBasicSchemas : basicSchemas}
                itemContent={(_, item) => (
                  <ListItemButton
                    disabled={loadingHydratedSchema}
                    key={item.id}
                    onClick={() => handleSchemaSelection(item)}
                    selected={item.id === schema?.id}
                    style={styles.listItem}
                  >
                    {schemaUtilities.getSchemaIconFromSchemaOrAsset(item)}
                    <ListItemText
                      primary={item.name}
                      primaryTextStyle={styles.listText}
                    />
                  </ListItemButton>
                )}
                endReached={() => {
                  if (!noMoreResults) {
                    setPage(prevPage => prevPage + 1);
                  }
                }}
                components={
                  isSearching
                    ? {}
                    : {
                        Footer: () => {
                          if (loadingBasicSchemas) {
                            return <Progress size={20} />;
                          }
                          return null;
                        },
                      }
                }
              />
            </List>
          </div>
        </>
      )}
    </div>
  );
};

export default SchemaSelectionTab;

import React from 'react';

// @ts-ignore
import taskTypeLargeIcon from '@atom/components/common/svgIcons/taskTypeLarge.svg';
import { Progress } from '@atom/mui';

import './taskCreate.css';

interface Props {
  taskTemplatesLoading: boolean;
}

const EmptySearchTemplate = ({ taskTemplatesLoading }: Props) => {
  return taskTemplatesLoading ? (
    <Progress />
  ) : (
    <div styleName="empty-task-template-container">
      <img src={taskTypeLargeIcon} />
      <div styleName="empty-title">No Search Results</div>
    </div>
  );
};

export default EmptySearchTemplate;

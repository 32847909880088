import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';
import { StringParam, withQueryParams } from 'use-query-params';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import * as inventoryCategoriesActionCreators from '@atom/actions/inventoryCategoryActions';
import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import * as poolAttributeActionCreators from '@atom/actions/poolAttributeActions';
import * as workOrderActionCreators from '@atom/actions/workOrderActions';
import * as workTemplateActionCreators from '@atom/actions/workTemplateActions';
import ListViewTable from '@atom/components/common/listViewTable/ListViewTable';
import MoveInventoryModal from '@atom/components/common/MoveInventoryModal';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
import InventoryCategoryTreeOptions from '@atom/components/inventory/inventoryCategoryTree/InventoryCategoryTreeOptions';
import { Checkbox, Icon, Menu, Modal, Progress } from '@atom/mui';
import { getColorAndAssetType } from '@atom/selectors/categorySelectors';
import {
  columnsSelector,
  inventoryCollectionSelector,
  inventorySortColumnsSelector,
} from '@atom/selectors/inventorySelectors';
import { getPoolAttributeColumnsSelector } from '@atom/selectors/poolAttributeSelectors';
import { inventoryPortalAssetColumnsSelector } from '@atom/selectors/preferencesSelectors';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import {
  InventoryAssetActions,
  InventoryCategoryActions,
  InventorySchemaActions,
  PoolAttributeActions,
  WorkOrderActions,
  WorkTemplateActions,
} from '@atom/types/actions';
import {
  InventoryAssetDetailType,
  InventoryCategoryTree as InventoryCategoryTreeType,
  InventoryPortalView,
  InventorySchemaItem,
} from '@atom/types/inventory';
import { InventoryMapFilters } from '@atom/types/map';
import { PolicyAction } from '@atom/types/policy';
import { ReduxStore } from '@atom/types/store';
import { hasAccess } from '@atom/utilities/accessUtilities';
import api from '@atom/utilities/api';
import { formatAssetAttributeFilters } from '@atom/utilities/assetUtilities';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  CATEGORIES_ENDPOINT,
  FILES_INVENTORY_CSV_DOWNLOAD_ENDPOINT,
  INVENTORY_ASSETS_ENDPOINT,
} from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';
import { incrementPage } from '@atom/utilities/listViewTableUtilities';
import { buildEndpointWithParamsFromData } from '@atom/utilities/requestUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import tableDisplayUtilities from '@atom/utilities/tableDisplayUtilities';

import InventoryCategoryTree from './inventoryCategoryTree/InventoryCategoryTree';
import InventoryMap from './inventoryMap/InventoryMap';
import InventoryMapDetail from './inventoryMap/InventoryMapDetail';
import BulkSelectHeader from './BulkSelectHeader';
import InventoryAssetFilters from './InventoryAssetFilters';
import InventoryHeader from './InventoryHeader';
import InventoryPortalContext from './InventoryPortalContext';
import InventoryPortalSubNav from './InventoryPortalSubNav';

import '../../styles/table.css';

const { MenuItem } = Menu;

const LIMIT_OPTIONS = [25, 50, 100, 250];

interface ReduxStateProps {
  preferences: any;
  inventorySchemas?: InventorySchemaItem[];
  inventory: InventoryAssetDetailType[];
  columns: any[];
  poolAttributesColumns: any[];
  sortableColumns: string[];
  displayHeaders: string[];
  displayFields: string[];
  loading: boolean;
  inventoryTotal: number;
  userId: string;
}

interface ReduxDispatchProps {
  poolAttributeActions: PoolAttributeActions;
  inventoryCategoryActions: InventoryCategoryActions;
  inventoryAssetActions: InventoryAssetActions;
  inventorySchemaActions: InventorySchemaActions;
  workOrderActions: WorkOrderActions;
  workTemplateActions: WorkTemplateActions;
}

interface HOCProps {
  query: { id: string; center: string; zoomLevel: string };
  setQuery: Function;
}

type Props = ReduxStateProps & ReduxDispatchProps & HOCProps;

export interface CSVDownloadEndpoint {
  endpoint?: string;
  body?: any;
}

enum ModalType {
  APPROVE = 'APPROVE',
  MOVE = 'MOVE',
  DELETE = 'DELETE',
  NONE = 'NONE',
}

interface State {
  view: InventoryPortalView;
  showFilterPane: boolean;
  context: string;
  page: number;
  limit: number;
  sortBy: any;
  isAscending: boolean;
  columns: any[];
  schemaId: string;
  rootSchema: any;
  categoryIdsFilters: any;
  schemaFilters: any[];
  assetFilters: any;
  downloadDisabled: boolean;
  isFilterApplied: boolean;
  filters: any;
  topLevelAssetsOnly: any;
  hasPendingChangesOnly: boolean;
  loadingChangeApprovals: boolean;
  loadingCategoryTree: boolean;
  categoryTree: InventoryCategoryTreeType;
  activeCategory: InventoryCategoryTreeType;
  activePath: any[];
  open: Set<string>;
  selectedAssets: Set<string>;
  openModal: { type: ModalType; id: any };
}

const initialState: State = {
  view: InventoryPortalView.LIST,
  showFilterPane: false,
  context: 'category',
  page: 1,
  limit: 25,
  sortBy: 'name',
  isAscending: true,
  columns: [],
  schemaId: '',
  rootSchema: { id: 'all', name: 'All', attributes: {}, attributeGroups: [] },
  categoryIdsFilters: null,
  schemaFilters: [],
  assetFilters: {},
  downloadDisabled: true,
  isFilterApplied: false,
  filters: null,
  topLevelAssetsOnly: null,
  hasPendingChangesOnly: false,
  loadingChangeApprovals: false,
  loadingCategoryTree: true,
  categoryTree: { id: 'inventory', name: 'Inventory' },
  activeCategory: { id: 'inventory', name: 'Inventory' },
  activePath: [],
  open: new Set(['inventory']),
  selectedAssets: new Set([]),
  openModal: { type: ModalType.NONE, id: null },
};

const styles = {
  mainPane: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: `calc(100% - ${layout.sidePaneWidth})`,
    height: '100vh',
  },
  table: {
    width: '100%',
    marginTop: 0,
    marginBottom: '3.125rem',
  },
  iconContainer: {
    position: 'relative',
    padding: '.125rem',
  },
  changesIcon: {
    height: '0.375rem',
    width: '0.375rem',
    background: colors.brand.blue,
    position: 'absolute',
    top: 0,
    right: '4px',
    borderRadius: '50%',
  },
};

class InventoryPortal extends React.Component<Props, State> {
  state = initialState;

  componentDidMount() {
    const { inventorySchemas, poolAttributeActions } = this.props;

    poolAttributeActions.retrievePoolAttributes();

    const previousState =
      // null or empty schemas indicate the entire application was unmounted
      // i.e the browser was refreshed and state should not be preserved
      !R.isNil(inventorySchemas) &&
      !R.isEmpty(inventorySchemas) &&
      history.location?.state
        ? history.location.state
        : {};

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState(
      {
        ...initialState,
        ...previousState,
        loadingCategoryTree: true,
      },
      this.getInitialData,
    );
  }

  getInitialData = () => {
    const {
      activeCategory,
      showFilterPane,
      page,
      limit,
      sortBy,
      isAscending,
    } = this.state;

    const {
      inventorySchemaActions,
      workTemplateActions,
      inventorySchemas,
    } = this.props;

    if (R.isNil(inventorySchemas) || R.isEmpty(inventorySchemas)) {
      inventorySchemaActions.retrieveInventorySchemas({
        rootSchemas: true,
      });
    }

    if (showFilterPane) {
      this.requestInventoryFilteredAssetPage(page, limit, sortBy, isAscending);
    } else if (activeCategory.hasAssets) {
      this.getAssets();
    } else {
      this.getCategories();
    }

    this.getCategoryTree();
    workTemplateActions.requestWorkTemplates({ page: 1, limit: 100 });
  };

  requestInventoryCategoryPage = (page, limit, sortBy, isAscending) => {
    const { inventoryCategoryActions, preferences } = this.props;
    const { activeCategory } = this.state;
    const { id: parentCategoryId } = activeCategory;
    const { columns } = preferences.categories;

    inventoryCategoryActions.retrieveInventoryCategories({
      parentCategoryId,
      columns,
      page,
      limit,
      sortBy,
      isAscending,
    });

    this.resetSelectedAssets();
  };

  refetchInventoryAssetPage = () => {
    const { page, limit, sortBy, isAscending, showFilterPane } = this.state;

    if (!showFilterPane) {
      this.requestInventoryAssetPage(page, limit, sortBy, isAscending);
    }
  };

  requestInventoryAssetPage = (page, limit, sortBy, isAscending) => {
    const { inventoryAssetActions } = this.props;
    const { activeCategory, columns } = this.state;
    const { id: parentCategoryId } = activeCategory;

    this.resetSelectedAssets();

    return inventoryAssetActions.retrieveInventoryAssets({
      parentCategoryId,
      columns,
      sortBy,
      isAscending,
      page,
      limit,
      includeInactive: false,
    });
  };

  requestInventoryFilteredAssetPage = (page, limit, sortBy, isAscending) => {
    const { inventoryAssetActions } = this.props;
    const {
      columns,
      assetFilters,
      schemaId,
      hasPendingChangesOnly,
      // @ts-ignore
      topLevelAssetsOnly: rootAssets,
      categoryIdsFilters: categoryIds,
    } = this.state;

    const data = {
      schemaId,
      rootAssets,
      hasPendingChangesOnly,
      body: formatAssetAttributeFilters(assetFilters),
      sortBy,
      isAscending,
      categoryIds,
      columns,
      page,
      limit,
      includeInactive: false,
    };

    this.resetSelectedAssets();

    return inventoryAssetActions.retrieveInventoryAssetsFilter(data);
  };

  updatePagination = (page, limit, sortBy, isAscending) => {
    const { context } = this.state;
    switch (context) {
      case 'category': {
        return this.requestInventoryCategoryPage(
          page,
          limit,
          sortBy,
          isAscending,
        );
      }
      case 'asset': {
        return this.requestInventoryAssetPage(page, limit, sortBy, isAscending);
      }
      case 'assetsFilter': {
        return this.requestInventoryFilteredAssetPage(
          page,
          limit,
          sortBy,
          isAscending,
        );
      }
      default: {
        return true;
      }
    }
  };

  toggleModal = (modalType: ModalType, openId: any) => {
    this.setState({ openModal: { type: modalType, id: openId } });
  };

  toggleSelectedAssets = (assetId: string) => {
    const { selectedAssets } = this.state;

    this.setState({ selectedAssets: toggleFromSet(selectedAssets, assetId) });
  };

  resetSelectedAssets = () => {
    this.setState({ selectedAssets: new Set([]) });
  };

  incrementPage = increment => {
    const { page: prevPage, limit, sortBy, isAscending } = this.state;
    const page = prevPage + increment;

    this.updatePagination(page, limit, sortBy, isAscending);
    this.setState(incrementPage(increment));
  };

  updateLimit = limit => {
    const { sortBy, isAscending } = this.state;
    const page = 1;

    this.updatePagination(page, limit, sortBy, isAscending);
    this.setState({ limit, page });
  };

  onSort = sortByField => {
    const { page } = initialState;
    const { sortBy, isAscending, limit } = this.state;
    const direction = sortByField === sortBy && !isAscending;

    this.setState({
      sortBy: sortByField,
      isAscending: direction,
      page,
      limit,
    });

    this.updatePagination(page, limit, sortByField, direction);
  };

  navigateToAssetDetail = assetId => {
    const { query } = this.props;
    history.push(`/inventory/${assetId}`, {
      ...this.state,
      query,
    });
  };

  getCategoryTree = async () => {
    const { activeCategory } = this.state;

    const { data } = await api.get<InventoryCategoryTreeType>(
      `${CATEGORIES_ENDPOINT}/tree`,
      { includeAssetInfo: true },
    );

    this.setState({
      categoryTree: data,
      loadingCategoryTree: false,
      ...(activeCategory.id === 'inventory' && {
        activeCategory: {
          ...activeCategory,
          categories: data.categories,
        },
      }),
    });

    return data;
  };

  getCategories = () => {
    const { activeCategory } = this.state;

    const { inventoryCategoryActions, preferences } = this.props;

    const { columns } = preferences.categories;
    const page = 1;
    const limit = this.state.limit;

    inventoryCategoryActions.retrieveInventoryCategories({
      parentCategoryId: activeCategory.id,
      columns,
      page,
      limit,
    });
  };

  getAssets = () => {
    const { inventoryAssetActions, inventorySchemas, preferences } = this.props;
    const { activeCategory, limit } = this.state;
    const { assetType, id: parentCategoryId } = activeCategory;

    const columns = inventoryPortalAssetColumnsSelector(
      assetType,
      preferences.assets,
      inventorySchemas,
    );

    const page = 1;

    inventoryAssetActions.retrieveInventoryAssets({
      parentCategoryId,
      columns,
      page,
      limit,
      includeInactive: false,
    });

    this.setState({
      page,
      columns,
      context: 'asset',
    });
  };

  getFilteredAssets = () => {
    const {
      limit,
      rootSchema,
      assetFilters,
      categoryIdsFilters,
      topLevelAssetsOnly,
      hasPendingChangesOnly,
    } = this.state;

    const {
      inventorySchemas,
      preferences,
      inventoryAssetActions,
      poolAttributesColumns,
    } = this.props;

    const body = formatAssetAttributeFilters(assetFilters);
    const columns =
      R.isNil(rootSchema) || rootSchema?.id === 'all' || !topLevelAssetsOnly
        ? poolAttributesColumns
        : inventoryPortalAssetColumnsSelector(
            rootSchema.assetType,
            preferences.assets,
            inventorySchemas,
          );

    const data = {
      schemaId: rootSchema?.id === 'all' ? null : rootSchema.id,
      categoryIds: categoryIdsFilters,
      body,
      columns,
      rootAssets: topLevelAssetsOnly,
      hasPendingChangesOnly,
      page: 1,
      limit,
      includeInactive: false,
    };

    inventoryAssetActions.retrieveInventoryAssetsFilter(data);

    this.setState({
      page: 1,
      columns,
      context: 'asset',
    });

    this.resetSelectedAssets();
  };

  deleteAsset = id => {
    const { inventoryAssetActions } = this.props;
    inventoryAssetActions.requestRootInventoryAssetDeletion(id);
    return true;
  };

  createBulkWorkFromFilter = (workTemplateId, dueDate) => {
    const { workOrderActions } = this.props;
    const {
      categoryIdsFilters,
      assetFilters,
      hasPendingChangesOnly,
      schemaId,
    } = this.state;

    const data = {
      workTemplateId,
      dueDate,
      schemaId,
      categoryIdsFilters,
      hasPendingChangesOnly,
      assetFilters: formatAssetAttributeFilters(assetFilters),
    };

    workOrderActions.requestBulkWorkFromFilterCreation(data);
  };

  approveChanges = async ({ id, includeChangesBefore }) => {
    this.setState({ loadingChangeApprovals: true });

    await api.post(`${INVENTORY_ASSETS_ENDPOINT}/${id}/approve`, {
      includeChangesBefore,
    });

    await Promise.all([this.getCategoryTree(), this.getFilteredAssets()]);

    this.setState({ loadingChangeApprovals: false });
  };

  buildCheckbox = row => {
    const { selectedAssets } = this.state;

    if (!R.isNil(row.hasCategories) || !R.isEmpty(row.ancestors)) {
      return <div />;
    }

    return (
      <Checkbox
        style={{ padding: 0 }}
        color="primary"
        edge="start"
        onClick={() => this.toggleSelectedAssets(row.id)}
        checked={selectedAssets.has(row.id)}
      />
    );
  };

  buildRowIcon = row => {
    if (!R.isNil(row.hasCategories)) {
      const { inventorySchemas } = this.props;
      const { colorId } = getColorAndAssetType(inventorySchemas, row.schemaId);
      const color = schemaUtilities.getSchemaColorFromColorId(colorId);

      return (
        <div styleName="row-icon-svg">
          <div style={styles.iconContainer}>
            <Icon color={color}>folder</Icon>
            {row.hasAssetChanges && <div style={styles.changesIcon} />}
          </div>
        </div>
      );
    }

    if (R.isEmpty(row.ancestors)) {
      const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(row);

      return (
        <div styleName="row-icon-svg">
          <div style={styles.iconContainer}>
            {icon}
            {row.includeChangesBefore && (
              <div
                style={{
                  ...styles.changesIcon,
                  top: '4px',
                  right: '-2px',
                }}
              />
            )}
          </div>
        </div>
      );
    }

    return <Icon color={colors.neutral.gray}>group_work</Icon>;
  };

  buildCell = (row, field, index) => {
    const { activeCategory, activePath, openModal } = this.state;

    if (field === 'additionalOptions') {
      const canMoveAsset = hasRolePermissions(ROLE_SETS.MANAGER);
      const canDelete = hasRolePermissions(ROLE_SETS.ORG_ADMIN);
      const canManageChanges = hasAccess(
        PolicyAction.MANAGE_INVENTORY_CHANGES,
        row?.actions,
      );

      const showApproveAll = canManageChanges && row.includeChangesBefore;
      const showOptions = showApproveAll || canMoveAsset || canDelete;

      const isModalOpen = (modalType: ModalType) => {
        return openModal.type === modalType && openModal.id === row.id;
      };

      const onConfirmApproveAllModal = () => {
        this.approveChanges(row);
        this.toggleModal(ModalType.NONE, null);
      };

      const onConfirmDeleteModal = () => {
        this.deleteAsset(row.id);
        this.toggleModal(ModalType.NONE, null);
      };

      return (
        <td key={index} styleName="additional-options">
          {row.externalId && showOptions ? (
            <>
              <Menu>
                {showApproveAll && (
                  <MenuItem
                    startAdornment={<Icon>done_all</Icon>}
                    onClick={() => this.toggleModal(ModalType.APPROVE, row.id)}
                  >
                    Approve Pending Changes
                  </MenuItem>
                )}
                {canMoveAsset && (
                  <MenuItem
                    startAdornment={
                      <Icon>
                        <img src={folderMoveIcon} />
                      </Icon>
                    }
                    onClick={() => this.toggleModal(ModalType.MOVE, row.id)}
                  >
                    Move To Folder
                  </MenuItem>
                )}
                {canDelete && (
                  <MenuItem
                    startAdornment={<Icon>delete</Icon>}
                    onClick={() => this.toggleModal(ModalType.DELETE, row.id)}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
              {showApproveAll && (
                <Modal
                  open={isModalOpen(ModalType.APPROVE)}
                  title="Approve All Changes"
                  onCancel={() => this.toggleModal(ModalType.NONE, null)}
                  confirmButtonText="Approve All"
                  onConfirm={onConfirmApproveAllModal}
                >
                  Are you sure you want to approve all pending changes?
                </Modal>
              )}
              {canMoveAsset && (
                <MoveInventoryModal
                  open={isModalOpen(ModalType.MOVE)}
                  closeModal={() => this.toggleModal(ModalType.NONE, null)}
                  id={row.id}
                  onComplete={this.refetchInventoryAssetPage}
                />
              )}
              {canDelete && (
                <Modal
                  open={isModalOpen(ModalType.DELETE)}
                  title="Delete Asset"
                  onCancel={() => this.toggleModal(ModalType.NONE, null)}
                  confirmButtonText="Delete"
                  ConfirmButtonProps={{
                    style: { background: colors.brand.red },
                  }}
                  onConfirm={onConfirmDeleteModal}
                >
                  Are you sure you want to delete this asset?
                </Modal>
              )}
            </>
          ) : !row.externalId ? (
            <InventoryCategoryTreeOptions category={row} path={activePath} />
          ) : null}
        </td>
      );
    }

    if (field === 'checkbox') {
      return (
        <td key={index} styleName="row-icon">
          {this.buildCheckbox(row)}
        </td>
      );
    }

    if (field === 'icon') {
      const icon = this.buildRowIcon(row);
      return (
        <td key={index} styleName="row-icon">
          {icon}
        </td>
      );
    }

    if (field.includes('quantityOnHand')) {
      return (
        <td>
          {Number(row?.budget?.quantityOnHand || 0).toLocaleString('en-US')}
        </td>
      );
    }

    if (
      field === 'workOrderCount' ||
      field === 'activeWorkOrderCount' ||
      field === 'pastDueWorkOrderCount' ||
      field === 'assignedWorkOrderCount' ||
      field === 'assetCount'
    ) {
      return (
        <td key={`${index}+${field}`}>
          {Number(row?.[field] || 0).toLocaleString('en-US')}
        </td>
      );
    }

    if (field === 'estimatedWorkOrdersCost') {
      return (
        <td key={`${index}+${field}`}>
          {Number(row?.[field] || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </td>
      );
    }

    const { columns } = this.props;
    const data = field.includes('rate') ? row?.budget : row[field];

    const column = columns.find(col => col.fieldName === field);
    const { dataType, unit } = column;

    if (field === 'externalId' || field === 'name' || field === 'assetName') {
      return tableDisplayUtilities.displayNavigationalCell(index, data, () => {
        if (row.externalId) {
          return this.navigateToAssetDetail(row.rootAssetId);
        }

        const categoryIndex = activeCategory.categories.findIndex(
          ({ id }) => id === row.id,
        );

        // @ts-ignore
        const category = activeCategory.categories[categoryIndex] || {
          ...initialState.activeCategory,
        };

        return this.setActiveCategory(category, [
          ...activePath,
          'categories',
          categoryIndex,
        ]);
      });
    }

    return tableDisplayUtilities.displayTableCell(index, data, dataType, unit);
  };

  toggleFilterPane = () => {
    const { poolAttributesColumns } = this.props;
    const { showFilterPane, view } = this.state;

    this.setState(
      {
        // reset tree and filters state on toggle
        showFilterPane: !showFilterPane,
        activeCategory: initialState.activeCategory,
        activePath: [],
        schemaId: null,
        rootSchema: initialState.rootSchema,
        categoryIdsFilters: null,
        filters: null,
        topLevelAssetsOnly: true,
        hasPendingChangesOnly: false,
        assetFilters: {},
        columns: poolAttributesColumns,
        page: 1,
        sortBy: showFilterPane ? 'name' : 'assetName',
        context: showFilterPane ? 'category' : 'assetsFilter',
      },
      () => {
        const { page, limit, sortBy, isAscending } = this.state;

        const isListView = view === InventoryPortalView.LIST;

        if (isListView && this.state.showFilterPane) {
          this.requestInventoryFilteredAssetPage(
            page,
            limit,
            sortBy,
            isAscending,
          );
        }

        if (isListView && !this.state.showFilterPane) {
          this.getCategories();
        }
      },
    );
  };

  toggleOpen = (id: string) => {
    const { open } = this.state;

    if (open.has(id)) {
      open.delete(id);
    } else {
      open.add(id);
    }

    this.setState({ open: new Set([...open]) });
  };

  getActivePathIds = (activePath: any[]): string[] => {
    const { categoryTree } = this.state;
    // @ts-ignore
    return activePath
      .reduce((acc, item, index) => {
        const path = R.slice(0, R.inc(index), activePath);

        return R.equals('categories', item)
          ? acc
          : [...acc, { ...R.pathOr({}, path, categoryTree), path }];
      }, [])
      .map(({ id }) => id);
  };

  setOpen = (open: Set<string>) => {
    this.setState({ open });
  };

  setActiveCategory = (
    activeCategory: InventoryCategoryTreeType,
    activePath: any[],
  ) => {
    const { open, view } = this.state;
    const isListView = view === InventoryPortalView.LIST;

    this.setState(
      {
        activeCategory,
        activePath,
        open: new Set([...open, ...this.getActivePathIds(activePath)]),
      },
      // eslint-disable-next-line consistent-return
      () => {
        if (isListView) {
          return activeCategory.schemaId
            ? this.getAssets()
            : this.getCategories();
        }
      },
    );
  };

  setActivePath = (activePath: any[]) => {
    this.setState({ activePath });
  };

  updatePortalState = stateUpdate => {
    this.setState({
      ...stateUpdate,
    });
  };

  buildCsvDownloadEndpoint = (): CSVDownloadEndpoint => {
    const {
      schemaId,
      assetFilters,
      hasPendingChangesOnly,
      categoryIdsFilters: categoryIds,
      // @ts-ignore
      topLevelAssetsOnly: rootAssets,
    } = this.state;

    const data = {
      rootAssets,
      ...(schemaId && { schemaId }),
      ...(categoryIds ? { categoryIds: categoryIds.join(',') } : {}),
      ...(hasPendingChangesOnly && { hasPendingChangesOnly }),
    };

    const body = formatAssetAttributeFilters(assetFilters);

    return {
      endpoint: buildEndpointWithParamsFromData(
        FILES_INVENTORY_CSV_DOWNLOAD_ENDPOINT,
        data,
      ),
      body,
    };
  };

  onViewChange = (view: InventoryPortalView) => {
    const { setQuery } = this.props;
    const isListView = view === InventoryPortalView.LIST;

    if (isListView) {
      setQuery({ id: '' });
    }

    // eslint-disable-next-line consistent-return
    this.setState({ view }, () => {
      const {
        activeCategory,
        showFilterPane,
        page,
        limit,
        sortBy,
        isAscending,
      } = this.state;

      if (isListView && showFilterPane) {
        return this.requestInventoryFilteredAssetPage(
          page,
          limit,
          sortBy,
          isAscending,
        );
      }

      if (isListView) {
        return activeCategory.schemaId
          ? this.getAssets()
          : this.getCategories();
      }
    });
  };

  getMapFilters = (): InventoryMapFilters => {
    const {
      showFilterPane,
      schemaId,
      hasPendingChangesOnly,
      categoryIdsFilters,
      activeCategory,
      assetFilters,
    } = this.state;

    const categoryIds = showFilterPane
      ? R.join(',', categoryIdsFilters || [])
      : activeCategory.id;

    return { schemaId, categoryIds, hasPendingChangesOnly, assetFilters };
  };

  render() {
    const {
      inventory,
      inventorySchemas,
      loading,
      displayHeaders,
      displayFields,
      inventoryTotal,
      sortableColumns,
      query,
    } = this.props;

    const {
      view,
      showFilterPane,
      page,
      limit,
      sortBy,
      isAscending,
      rootSchema,
      schemaFilters,
      filters,
      topLevelAssetsOnly,
      assetFilters,
      categoryIdsFilters,
      downloadDisabled,
      isFilterApplied,
      activeCategory,
      activePath,
      open,
      categoryTree,
      loadingCategoryTree,
      loadingChangeApprovals,
      hasPendingChangesOnly,
      selectedAssets,
    } = this.state;

    const headers = ['', '', ...displayHeaders, ''];
    const fields = ['checkbox', 'icon', ...displayFields, 'additionalOptions'];

    const isListView = view === InventoryPortalView.LIST;
    const isMapAssetSelected = !!query.id;

    const mainPaneContent =
      (loading || loadingChangeApprovals || loadingCategoryTree) &&
      isListView ? (
        <Progress style={{ height: '100%' }} />
      ) : isListView ? (
        <ListViewTable
          style={styles.table}
          sortableFields={sortableColumns}
          isAscending={isAscending}
          sortBy={sortBy}
          // @ts-ignore
          sortRows={this.onSort}
          displayHeaders={headers}
          dataRows={inventory}
          fields={fields}
          // @ts-ignore
          buildCell={this.buildCell}
          // @ts-ignore
          incrementPage={this.incrementPage}
          // @ts-ignore
          updateLimit={this.updateLimit}
          totalRows={inventoryTotal}
          page={page}
          limit={limit}
          limitOptions={LIMIT_OPTIONS}
        />
      ) : (
        <InventoryMap filters={this.getMapFilters()} />
      );

    const leftPaneContent = query.id ? (
      <InventoryMapDetail onClick={this.navigateToAssetDetail} />
    ) : showFilterPane ? (
      <InventoryAssetFilters
        rootSchema={rootSchema}
        categoryIdsFilters={categoryIdsFilters}
        topLevelAssetsOnly={topLevelAssetsOnly}
        hasPendingChangesOnly={hasPendingChangesOnly}
        filters={filters}
        assetFilters={assetFilters}
        schemaFilters={schemaFilters}
        updatePortalState={this.updatePortalState}
        sortBy={sortBy}
        isAscending={isAscending}
        limit={limit}
        view={view}
        resetSelectedAssets={this.resetSelectedAssets}
      />
    ) : (
      <InventoryCategoryTree />
    );

    return (
      <InventoryPortalContext.Provider
        value={{
          schemas: inventorySchemas,
          categoryTree,
          loading: loadingCategoryTree,
          activeCategory,
          setActiveCategory: this.setActiveCategory,
          activePath,
          setActivePath: this.setActivePath,
          open,
          toggleOpen: this.toggleOpen,
          setOpen: this.setOpen,
          refetchCategoryFolderTree: this.getCategoryTree,
          selectedAssets,
          toggleSelectedAssets: this.toggleSelectedAssets,
          resetSelectedAssets: this.resetSelectedAssets,
        }}
      >
        {selectedAssets.size > 0 ? (
          <BulkSelectHeader />
        ) : (
          <InventoryHeader
            filterState={this.state}
            isFilterPaneOpen={showFilterPane}
            isAssetsDownloadPossible={showFilterPane}
            toggleFilterPane={this.toggleFilterPane}
            inventoryTotal={inventoryTotal}
            isFilterApplied={isFilterApplied}
            // @ts-ignore
            createBulkWorkFromFilter={this.createBulkWorkFromFilter}
            downloadDisabled={downloadDisabled}
            downloadEndpoint={this.buildCsvDownloadEndpoint()}
            showNavButtons={!isMapAssetSelected}
          />
        )}
        {leftPaneContent}
        <div style={styles.mainPane}>
          <InventoryPortalSubNav
            activeView={view}
            setActiveView={this.onViewChange}
          />
          {mainPaneContent}
        </div>
      </InventoryPortalContext.Provider>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  preferences: state.preferences.inventory,
  inventory: inventoryCollectionSelector(state),
  inventoryTotal: state.inventoryTotal,
  inventorySchemas: state.inventorySchemas,
  loading: state.loading.loadingInventory,
  columns: columnsSelector(state),
  poolAttributesColumns: getPoolAttributeColumnsSelector(state),
  sortableColumns: inventorySortColumnsSelector(state),
  displayHeaders: state.inventory.columns.map(column => column.name),
  displayFields: state.inventory.columns.map(column => column.fieldName),
  userId: state.userProfile.userId,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  poolAttributeActions: bindActionCreators(
    poolAttributeActionCreators,
    dispatch,
  ),
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
  inventoryCategoryActions: bindActionCreators(
    inventoryCategoriesActionCreators,
    dispatch,
  ),
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
  workOrderActions: bindActionCreators(workOrderActionCreators, dispatch),
  workTemplateActions: bindActionCreators(workTemplateActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withQueryParams(
    { id: StringParam, center: StringParam, zoom: StringParam },
    // @ts-ignore
    InventoryPortal,
  ),
);

import React, { useContext } from 'react';

import { getApprovalKeyword } from '@atom/components/common/requests/customTenantUtilities';
import { AssetRequestStatus } from '@atom/types/assetRequest';
import { getDisplayDateTime } from '@atom/utilities/timeUtilities';

import ViewRequestContext from '../ViewRequestContext';

import './sddotView.css';

const ApprovalDisplay = () => {
  const { activeRequest } = useContext(ViewRequestContext);

  const approvalKeyword = getApprovalKeyword(activeRequest.status);

  const isApproved =
    activeRequest.status === AssetRequestStatus.FULL_APPROVAL ||
    activeRequest.status === AssetRequestStatus.PARTIAL_APPROVAL;

  const timestampText = `${approvalKeyword}ed by ${
    activeRequest?.updatedBy?.firstName
  } ${activeRequest?.updatedBy?.lastName} on ${getDisplayDateTime(
    activeRequest?.updatedDate,
  )}`;

  return (
    <div styleName="approval-form">
      <div styleName="form-title">{`Request ${approvalKeyword}ed`}</div>
      {isApproved && (
        <div styleName="view-row">
          <div styleName="row-title">{`Approved Quantity (${
            activeRequest?.assetUnit || ''
          })`}</div>
          <div>{activeRequest?.quantityApproved}</div>
        </div>
      )}
      <div styleName="view-row">
        <div styleName="row-title">Comments</div>
        <div>{activeRequest?.comments}</div>
      </div>
      <div styleName="created-subtext">{timestampText}</div>
    </div>
  );
};

export default ApprovalDisplay;

import * as R from 'ramda';

import { DataType } from '@atom/types/dataType';
import {
  LocationDataItem,
  LocationDataState,
  LocationDataTitle,
  LocationOptionsState,
  TaskLocation,
  TaskLocationAutocomplete,
  TaskLocationSimpleInput,
} from '@atom/types/taskLocation';
import {
  Client,
  isCurrentClient,
} from '@atom/utilities/featureToggleUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export enum AddAssetError {
  ALDOT_CROSS = 'ALDOT_CROSS',
  ALDOT_CART = 'ALDOT_CART',
  NONE = 'NONE',
}

const COMMON_DATA_STATE: LocationDataState = {
  [LocationDataTitle.ROUTE]: null,
  [LocationDataTitle.START_MILEPOST]: '',
  [LocationDataTitle.END_MILEPOST]: '',
};

const ALDOT_DATA_STATE: LocationDataState = {
  [LocationDataTitle.MANAGEMENT_UNIT]: null,
  [LocationDataTitle.COUNTY]: null,
  [LocationDataTitle.ROAD_CLASS]: null,
  [LocationDataTitle.DIRECTION]: null,
};

const UDOT_DATA_STATE: LocationDataState = {
  [LocationDataTitle.STATION]: null,
};

const COMMON_OPTIONS_STATE = {
  [LocationDataTitle.ROUTE]: [],
  [LocationDataTitle.START_MILEPOST]: null,
  [LocationDataTitle.END_MILEPOST]: null,
};

const ALDOT_OPTIONS_STATE = {
  [LocationDataTitle.MANAGEMENT_UNIT]: [],
  [LocationDataTitle.COUNTY]: [],
  [LocationDataTitle.ROAD_CLASS]: [],
  [LocationDataTitle.DIRECTION]: [],
};

const UDOT_OPTIONS_STATE = {
  [LocationDataTitle.STATION]: [],
};

const getInitialState = () => {
  switch (true) {
    case isCurrentClient([Client.UDOT]):
      return { ...COMMON_DATA_STATE, ...UDOT_DATA_STATE };
    case isCurrentClient([Client.ALDOT]):
      return { ...COMMON_DATA_STATE, ...ALDOT_DATA_STATE };
    default:
      return COMMON_DATA_STATE;
  }
};

export const getInitialOptions = () => {
  switch (true) {
    case isCurrentClient([Client.UDOT]):
      return { ...COMMON_OPTIONS_STATE, ...UDOT_OPTIONS_STATE };
    case isCurrentClient([Client.ALDOT]):
      return { ...COMMON_OPTIONS_STATE, ...ALDOT_OPTIONS_STATE };
    default:
      return COMMON_OPTIONS_STATE;
  }
};

const getInitialStateFromLocationALDOT = (
  initialState: LocationDataState,
  location: TaskLocation,
): LocationDataState => {
  return location?.data?.reduce((acc, dataItem) => {
    const spreadData =
      dataItem.title === LocationDataTitle.MANAGEMENT_UNIT ||
      dataItem.title === LocationDataTitle.COUNTY ||
      dataItem.title === LocationDataTitle.ROAD_CLASS;

    return spreadData ? { ...acc, [dataItem.title]: dataItem.value } : acc;
  }, initialState);
};

const getInitialStateFromAssetALDOT = (
  initialState: LocationDataState,
  asset: any,
): LocationDataState => {
  return R.values(asset?.attributes).reduce((acc, attribute) => {
    const spreadData =
      attribute.name === LocationDataTitle.MANAGEMENT_UNIT ||
      attribute.name === LocationDataTitle.COUNTY ||
      attribute.name === LocationDataTitle.ROAD_CLASS;
    return spreadData ? { ...acc, [attribute.name]: attribute.value } : acc;
  }, initialState);
};

export const mapLocationDataToState = (
  location: TaskLocation,
  locations: TaskLocation[],
  assets: any,
): LocationDataState => {
  let initialState = getInitialState();

  const isOnlyLocationEdit =
    locations.length === 1 && locations[0]?.id === location?.id;

  // FOR ALDOT ONLY:
  // - If locations already exist on the task that are different than the current location,
  //   or assets,then Management Unit, County, and Road Class fields must match.
  const fillInitialInputs =
    isCurrentClient([Client.ALDOT]) &&
    ((!isNilOrEmpty(locations) && !isOnlyLocationEdit) ||
      !isNilOrEmpty(assets));

  if (fillInitialInputs) {
    const otherLocation = locations[0];
    const otherAsset = assets[0];

    initialState = !isNilOrEmpty(otherLocation)
      ? getInitialStateFromLocationALDOT(initialState, otherLocation)
      : getInitialStateFromAssetALDOT(initialState, otherAsset);
  }

  if (!location) {
    return initialState;
  }

  return location?.data?.reduce((acc, dataItem) => {
    return { ...acc, [dataItem.title]: dataItem.value };
  }, initialState);
};

export const mapAutocompleteToOptionsState = (
  autocomplete: TaskLocationAutocomplete,
): LocationOptionsState => {
  if (!autocomplete) {
    return getInitialOptions();
  }

  const mappedFields = autocomplete.components.reduce((acc, component) => {
    return { ...acc, [component.title]: component.enumeration };
  }, getInitialOptions());

  return {
    ...mappedFields,
    [LocationDataTitle.START_MILEPOST]: autocomplete?.minMilePost,
    [LocationDataTitle.END_MILEPOST]: autocomplete?.maxMilePost,
  };
};

export const mapDataToSimpleInput = (
  data: LocationDataState,
  omitMileposts: boolean,
): TaskLocationSimpleInput => {
  return {
    data: R.keys(data).reduce((acc, title) => {
      if (
        omitMileposts &&
        (title === LocationDataTitle.START_MILEPOST ||
          title === LocationDataTitle.END_MILEPOST)
      ) {
        return acc;
      }

      const value =
        title === LocationDataTitle.START_MILEPOST ||
        title === LocationDataTitle.END_MILEPOST
          ? Number(data[title])
          : data[title];

      return isNilOrEmpty(value) ? acc : [...acc, { title, value }];
    }, []),
  };
};

export const isMilepostsValid = (
  options: LocationOptionsState,
  values: LocationDataState,
): boolean => {
  const startMilepostLimit = options[LocationDataTitle.START_MILEPOST];
  const endMilepostLimit = options[LocationDataTitle.END_MILEPOST];
  const startMilepostValue = values[LocationDataTitle.START_MILEPOST];
  const endMilepostValue = values[LocationDataTitle.END_MILEPOST];

  if (!isNilOrEmpty(startMilepostValue) && !isNilOrEmpty(endMilepostValue)) {
    if (
      startMilepostValue < startMilepostLimit ||
      startMilepostValue > endMilepostLimit
    ) {
      return false;
    }

    if (
      endMilepostValue < startMilepostLimit ||
      endMilepostValue > endMilepostLimit
    ) {
      return false;
    }
  }

  return true;
};

export const mapStateToData = (
  options: LocationOptionsState,
  values: LocationDataState,
): LocationDataItem[] => {
  return R.keys(values).map(title => {
    if (
      title === LocationDataTitle.START_MILEPOST ||
      title === LocationDataTitle.END_MILEPOST
    ) {
      return {
        title,
        dataType: DataType.NUMBER,
        value: Number(values[title]),
      };
    }

    return {
      title,
      dataType: DataType.ENUM_SINGLE,
      enumeration: options[title],
      value: values[title],
    };
  });
};

const ALDOT_INITIAL_CROSS_ATTRIBUTES = {
  [LocationDataTitle.MANAGEMENT_UNIT]: null,
  [LocationDataTitle.COUNTY]: null,
  [LocationDataTitle.ROAD_CLASS]: null,
};

// gets ALDOT cross validation attributes that are set on the task via existing locations or assets.
export const getCrossValidationAttributes = (
  assets: any[],
  locations: TaskLocation[],
) => {
  const firstLocation = locations[0];
  const firstAsset = assets[0];

  return !isNilOrEmpty(firstLocation)
    ? getInitialStateFromLocationALDOT(
        ALDOT_INITIAL_CROSS_ATTRIBUTES,
        firstLocation,
      )
    : getInitialStateFromAssetALDOT(ALDOT_INITIAL_CROSS_ATTRIBUTES, firstAsset);
};

// Tests whether all ALDOT cross attributes match across assets
export const getAllAttributesEqualALDOT = (assets: any[]): boolean => {
  const mappedAttributes = assets.map(asset => {
    return getInitialStateFromAssetALDOT(ALDOT_INITIAL_CROSS_ATTRIBUTES, asset);
  });

  const firstMappedAttributes = mappedAttributes[0];

  return R.all(R.equals(firstMappedAttributes), mappedAttributes);
};

// Returns first set of cross validation attributes from the given assets
// ALDOT will use the first existing asset to pre-fill new location inputs
export const getCartCrossAttributesALDOT = (assets: any[]) => {
  return assets.map(asset => {
    return getInitialStateFromAssetALDOT(ALDOT_INITIAL_CROSS_ATTRIBUTES, asset);
  })[0];
};

import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
// @ts-ignore
import elementIcon from '@atom/components/common/svgIcons/elementIcon.svg';
import TaskItemTile from '@atom/components/common/workOrderDetail/taskItemTile/TaskItemTile';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Icon, IconButton, Snackbar } from '@atom/mui';
import {
  InheritedComponentType,
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateSchema,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './schemaSection.css';

interface Props {
  schema: WorkTemplateSchema;
}

const styles = {
  icon: {
    height: '24px',
    width: '24px',
  },
};

const SchemaTile = ({ schema }: Props) => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [open, setOpen] = useState<boolean>(false);

  const [taskTemplateUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  const isSubItem = R.length(schema.ancestors) > 0;

  const removeSchema = async () => {
    setOpen(false);

    const schemaIds = R.pathOr([], ['schemaIds'], task).filter(
      schemaId => schemaId !== schema.id,
    );

    Snackbar.info({
      message: 'Deleting...',
    });

    await taskTemplateUpdate({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          schemaIds,
        },
      },
    });

    Snackbar.info({
      message: isSubItem ? 'Removed Sub Item.' : 'Removed Asset Type.',
    });

    refetch();
  };

  const isItemInherited = isComponentInherited(
    InheritedComponentType.TASK_SCHEMA,
    task?.inheritedComponents || [],
    schema?.id,
  );

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const deleteModalData = {
    title: isSubItem
      ? 'Remove Sub Item from Task?'
      : 'Remove Asset Type from Task?',
    content: isSubItem
      ? 'Are you sure you want to remove this sub item from the task?'
      : 'Are you sure you want to remove this asset type from the task?',
  };

  const icon = isSubItem ? (
    <img style={styles.icon} src={elementIcon} />
  ) : (
    schemaUtilities.getSchemaIconFromSchemaOrAsset(schema, {
      imgOffset: '0',
      iconSize: '34px',
      marginTop: '0',
    })
  );

  const actionButton = isItemInherited ? (
    <div styleName="lock-container">
      <InheritanceLockIcon
        tooltip="Inherited Inventory cannot be removed or edited."
        placement="bottom-start"
      />
    </div>
  ) : isDisabled ? (
    <div />
  ) : (
    <IconButton onClick={() => setOpen(true)} tooltip="Remove" size="small">
      <Icon>close</Icon>
    </IconButton>
  );

  return (
    <>
      <TaskItemTile
        content={R.pathOr('', ['name'], schema)}
        icon={icon}
        actionButton={actionButton}
      />
      <DeleteModal
        open={open}
        onConfirm={removeSchema}
        onCancel={() => setOpen(false)}
        title={deleteModalData.title}
        content={deleteModalData.content}
        cancelText="Cancel"
        confirmText="Remove"
      />
    </>
  );
};

export default SchemaTile;

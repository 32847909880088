import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { ATTRIBUTE_UPDATE } from '@atom/graph/schema';
import { Checkbox, Snackbar } from '@atom/mui';
import { AttributeUpdateInput, SchemaTree } from '@atom/types/schema';

import SchemaDetailContext from '../../SchemaDetailContext';
import { updatePendingCreation } from '../subItemDetail/subItemDetailUtilities';

import {
  mergePendingChanges,
  updatePendingUpdates,
} from './attributeDetailUtilities';

const ThousandSeparator = () => {
  const {
    schemaTree,
    selectedAttribute,
    setSelectedAttribute,
    selectedSubItem,
    selectedAttributeRoute,
    refetchSchemaTree,
    pendingUpdates,
    setPendingUpdates,
    setPendingCreations,
  } = useContext(SchemaDetailContext);

  const mergedAttribute = mergePendingChanges(
    selectedSubItem.id,
    selectedAttribute,
    pendingUpdates,
  );

  const isChecked = mergedAttribute.thousandsSeparator;

  const [updateAttribute, { loading }] = useMutation<
    { attributeUpdate: SchemaTree },
    { input: AttributeUpdateInput }
  >(ATTRIBUTE_UPDATE);

  const handlePendingAttributeUpdate = (value: boolean) => {
    setPendingUpdates(prev =>
      updatePendingUpdates(
        selectedSubItem.id,
        prev,
        selectedAttribute,
        'thousandsSeparator',
        value,
      ),
    );

    setSelectedAttribute({
      ...selectedAttribute,
      thousandsSeparator: value,
    });
  };

  const handlePendingCreationUpdate = (value: boolean) => {
    const updatedAttribute = {
      ...selectedAttribute,
      thousandsSeparator: value,
    };

    setPendingCreations(prev =>
      updatePendingCreation(
        selectedSubItem.id,
        selectedAttributeRoute,
        prev,
        updatedAttribute,
      ),
    );

    setSelectedAttribute(updatedAttribute);
  };

  const handleAttributeUpdate = async (value: boolean) => {
    try {
      await updateAttribute({
        variables: {
          input: {
            schemaId: selectedSubItem.id,
            attributeGroupId: selectedAttributeRoute.attributeGroupId,
            attributeId: selectedAttribute.id,
            thousandsSeparator: value,
          },
        },
      });

      setSelectedAttribute(prev => ({ ...prev, thousandsSeparator: value }));
      refetchSchemaTree();
    } catch (error) {
      Snackbar.error({ message: 'Something went wrong' });
    }
  };

  const onChange = () => {
    if (mergedAttribute.isTempAttribute) {
      handlePendingCreationUpdate(!isChecked);
    } else if (schemaTree.isPublished) {
      handlePendingAttributeUpdate(!isChecked);
    } else {
      handleAttributeUpdate(!isChecked);
    }
  };

  return (
    <Checkbox
      disabled={loading}
      onChange={onChange}
      checked={isChecked}
      label="Add thousand separator (eg: 123,456.789)"
    />
  );
};

export default ThousandSeparator;

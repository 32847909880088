import actionTypes from '@atom/actions/actionTypes';

import initialState from './initialState';

export default function teamNavigation(
  state = initialState.teamNavigation,
  action,
) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.UPDATE_TEAM_NAVIGATION: {
      return data;
    }
    default: {
      return state;
    }
  }
}

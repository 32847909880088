import React, { useContext } from 'react';

import { useUserProfile } from '@atom/hooks/useUserProfile';
import { Button } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetRequestStatus } from '@atom/types/assetRequest';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import ViewRequestContext from '../ViewRequestContext';

import ApprovalForm from './ApprovalForm';

import './sddotView.css';

const styles = {
  cancelButton: {
    width: '100%',
    color: colors.brand.red,
    border: `1px solid ${colors.neutral.ash}`,
  },
  rejectButton: {
    width: '48%',
    border: `1px solid ${colors.neutral.ash}`,
  },
  approveButton: {
    width: '48%',
  },
};

const ApprovalControls = () => {
  const userProfile = useUserProfile();

  const { status, setStatus, activeRequest } = useContext(ViewRequestContext);

  const showCancel =
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) ||
    (hasRolePermissions(ROLE_SETS.INSPECTOR) &&
      activeRequest?.createdBy?.id === userProfile?.userId);

  const showApproveReject =
    hasRolePermissions(ROLE_SETS.ORG_ADMIN) ||
    (hasRolePermissions(ROLE_SETS.INSPECTOR) &&
      activeRequest?.assignedTo
        ?.map(user => user?.id)
        .includes(userProfile?.userId));

  return isNilOrEmpty(status) ? (
    <div styleName="approval-controls">
      {showCancel && (
        <Button
          style={styles.cancelButton}
          onClick={() => setStatus(AssetRequestStatus.CANCELED)}
        >
          Cancel Request
        </Button>
      )}
      {showApproveReject && (
        <div styleName="sub-buttons">
          <Button
            style={styles.rejectButton}
            onClick={() => setStatus(AssetRequestStatus.REJECTED)}
          >
            Reject
          </Button>
          <Button
            style={styles.approveButton}
            color="primary"
            variant="contained"
            onClick={() => setStatus(AssetRequestStatus.FULL_APPROVAL)}
          >
            Approve
          </Button>
        </div>
      )}
    </div>
  ) : (
    <ApprovalForm />
  );
};

export default ApprovalControls;

import React, { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';

import TextOverflowTooltip from '@atom/components/common/tooltip/TextOverflowTooltip';
import { Checkbox, Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetUnitTree } from '@atom/types/budget';
import { POLICY_GRANT_IDS_ALL } from '@atom/types/policy';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import PolicyModalContext from '../PolicyModalContext';

import BudgetingApprovalSelectionContext from './BudgetingApprovalSelectionContext';

const TITLE_WIDTH_REMS = 25;
const TITLE_TOOLTIP_LEAF_REMS = 21;
const TITLE_TOOLTIP_REMS = 14;

const styles = {
  arrowIcon: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0',
  },
  folderIcon: {
    fontSize: '1.75rem',
    marginRight: '0.25rem',
    padding: '0.25rem 0',
    cursor: 'pointer',
  },
  blankIcon: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0.75rem',
  },
  unitNameContainer: {
    display: 'flex',
    flexFlow: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  unitIconContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  hoverLink: {
    position: 'absolute',
    right: '0.25rem',
    top: '0.7rem',
    paddingLeft: '0.25rem',
    color: colors.brand.blue,
    cursor: 'pointer',
    textAlign: 'right',
    background: colors.neutral.mist,
    fontWeight: 500,
  },
};

export interface Props {
  tree: BudgetUnitTree;
  indentation?: number;
}

const BudgetingApprovalSelectionTree = ({ tree, indentation = 0 }: Props) => {
  const {
    collapsed,
    setCollapsed,
    selectedUnitIds,
    handleChange,
    rowHoverId,
    setRowHoverId,
    handleToggleDescendants,
  } = useContext(BudgetingApprovalSelectionContext);

  const { state } = useContext(PolicyModalContext);
  const { grants } = state;

  const selected =
    selectedUnitIds.has(tree.id) ||
    grants.some(({ id }) => id === POLICY_GRANT_IDS_ALL);

  const [selectDescendantsMode, setSelectDescendantsMode] = useState<boolean>(
    true,
  );

  const hovering: boolean = useMemo(() => rowHoverId === tree.id, [rowHoverId]);

  const leftIcons = (
    <>
      {!R.isEmpty(tree.children) ? (
        <IconButton
          style={styles.arrowIcon}
          onClick={() => setCollapsed(toggleFromSet(collapsed, tree.id))}
        >
          <Icon color={colors.neutral.dim}>
            {collapsed.has(tree.id) ? 'arrow_right' : 'arrow_drop_down'}
          </Icon>
        </IconButton>
      ) : (
        <div style={styles.blankIcon} />
      )}
      <Checkbox checked={selected} onChange={() => handleChange(tree)} />
      <Icon
        onClick={() => handleChange(tree)}
        style={styles.folderIcon}
        color={
          isNilOrEmpty(tree.children)
            ? colors.utility.activeBlue
            : colors.neutral.gray
        }
      >
        folder
      </Icon>
    </>
  );

  const containerStyle = useMemo(() => {
    // account for 2 rem overflow on first two levels causing offset
    const maxWidth =
      indentation < 2 ? TITLE_WIDTH_REMS + (2 - indentation) : TITLE_WIDTH_REMS;
    return {
      paddingLeft: `${indentation}rem`,
      position: 'relative',
      maxWidth: `${maxWidth}rem`,
      backgroundColor: hovering ? colors.neutral.mist : 'inherit',
    };
  }, [indentation, hovering]);

  const handleToggleSetDescendent = () => {
    handleToggleDescendants(tree, selectDescendantsMode);
    setSelectDescendantsMode(!selectDescendantsMode);
  };

  return (
    <>
      <div
        onMouseEnter={() => setRowHoverId(tree?.id)}
        onMouseLeave={() => setRowHoverId(null)}
        style={containerStyle}
      >
        <div style={styles.unitIconContainer}>
          {leftIcons}
          <div
            style={styles.unitNameContainer}
            onClick={() => handleChange(tree)}
          >
            <TextOverflowTooltip
              width={`${
                (!isNilOrEmpty(tree.children)
                  ? TITLE_TOOLTIP_REMS
                  : TITLE_TOOLTIP_LEAF_REMS) - indentation
              }rem`}
              text={tree?.name}
              placementBottom
            />
          </div>
        </div>
        {hovering && !isNilOrEmpty(tree.children) && (
          <div
            onMouseEnter={() => setRowHoverId(id => id)}
            onClick={() => handleToggleSetDescendent()}
            style={styles.hoverLink}
          >
            {selectDescendantsMode ? 'Select' : 'Unselect'} Sub Units
          </div>
        )}
      </div>
      {!collapsed.has(tree?.id) &&
        tree.children.map((unit: BudgetUnitTree) => {
          return (
            <BudgetingApprovalSelectionTree
              key={unit.id}
              tree={unit}
              indentation={indentation + 1}
            />
          );
        })}
    </>
  );
};

export default BudgetingApprovalSelectionTree;

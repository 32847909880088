import React from 'react';

import { Icon, List } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

const { ListItem, ListItemText } = List;

interface Props {
  asset?: InventoryAssetDetailType;
  onClick?: () => void;
  onRemove?: () => void;
  requireAsset?: boolean;
}

const styles = {
  choose: {
    boxSizing: 'border-box',
    minHeight: '4.125rem',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    color: colors.brand.blue,
    cursor: 'pointer',
  },
  listItem: {
    minHeight: '4.125rem',
    marginLeft: '-6px',
    padding: 0,
  },
  icon: {
    cursor: 'pointer',
  },
  iconContainer: {
    marginRight: '0.5rem',
  },
};

const InventoryAssetTile = ({
  asset,
  onClick,
  onRemove,
  requireAsset,
}: Props) => {
  const displayText = requireAsset ? 'Add asset *' : 'Add asset';
  const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(asset, {
    imgOffset: '0',
    iconSize: '34px',
    marginTop: '0',
  });

  return asset ? (
    <ListItem disableGutters style={styles.listItem}>
      <div style={styles.iconContainer}>{icon}</div>
      <ListItemText primary={asset.name} secondary={asset.assetType} />
      {onRemove && (
        <Icon style={styles.icon} onClick={onRemove}>
          close
        </Icon>
      )}
    </ListItem>
  ) : (
    <div onClick={onClick} style={styles.choose}>
      {displayText}
    </div>
  );
};

export default InventoryAssetTile;

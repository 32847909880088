import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { getConfirmModalContent } from '@atom/components/common/requests/customTenantUtilities';
import { CREATE_ASSET_REQUEST } from '@atom/graph/assetRequest';
import { Icon, IconButton, Modal, Snackbar } from '@atom/mui';
import {
  AssetRequest,
  AssetRequestCreateInput,
  AssetRequestType,
} from '@atom/types/assetRequest';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';

import RequestsContext from '../RequestsContext';

import CreateRequestModal from './CreateRequestModal';

const CreateRequest = () => {
  const { filters, asset, changeType, setActiveRequestId } = useContext(
    RequestsContext,
  );

  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [createInputs, setCreateInputs] = useState<AssetRequestCreateInput>({
    type: filters.type,
    assetId: asset.id,
  });

  const [createRequest] = useMutation<
    { assetRequestCreate: AssetRequest },
    { input: AssetRequestCreateInput }
  >(CREATE_ASSET_REQUEST);

  const updateCreateInput = (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => {
    setCreateInputs(prev => ({ ...prev, [property]: value }));
  };

  const resetState = () => {
    setCreateInputs({ type: filters.type, assetId: asset.id });
  };

  useEffect(() => {
    resetState();
  }, [filters]);

  const updateType = (type: AssetRequestType) => {
    setCreateInputs({ type, assetId: asset.id });
  };

  const handleCreateClick = () => {
    setOpenCreate(false);
    setOpenConfirm(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
    resetState();
  };

  const handleConfirmClick = async () => {
    const { data } = await createRequest({
      variables: {
        input: {
          ...createInputs,
        },
      },
    });

    Snackbar.info({
      message: 'Request created.',
    });

    setActiveRequestId(data?.assetRequestCreate?.id);
    changeType(createInputs.type);
    setOpenConfirm(false);
    resetState();
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setOpenCreate(true);
  };

  const confirmModalContent = getConfirmModalContent(createInputs.type);
  const showCreateButton = hasRolePermissions(ROLE_SETS.INSPECTOR);

  return (
    <>
      {showCreateButton && (
        <IconButton
          edge="end"
          tooltip="Add Request"
          onClick={() => setOpenCreate(true)}
        >
          <Icon>add</Icon>
        </IconButton>
      )}
      <CreateRequestModal
        open={openCreate}
        onClose={handleCreateClose}
        createInputs={createInputs}
        updateCreateInput={updateCreateInput}
        onConfirm={handleCreateClick}
        updateType={updateType}
      />
      <Modal
        open={openConfirm}
        confirmButtonText="Create"
        onCancel={handleConfirmClose}
        onConfirm={handleConfirmClick}
        title={confirmModalContent.title}
      >
        {confirmModalContent.content}
      </Modal>
    </>
  );
};

export default CreateRequest;

import React from 'react';
import * as R from 'ramda';

import {
  AssetDetailView,
  InventoryAssetDetailType,
} from '@atom/types/inventory';
// TODO: AM-16021 Release requests feature
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';

import HeaderControls from './HeaderControls';
import HeaderFields from './HeaderFields';
import HeaderNavigationIcon from './HeaderNavigationIcon';
import NavigationTitle from './NavigationTitle';

import './header.css';

// TODO: AM-7339 Remove hard coded analytics tab
const UAT_HENNEPIN_ANALYTICS_ASSET_ID = '605199f5608e6111ab5a0856';

const getViews = (inventoryAssetDetail: InventoryAssetDetailType) => {
  const isMaterial = !!R.pathOr(false, ['isMaterial'], inventoryAssetDetail);
  const isStockBased = !!R.pathOr(
    false,
    ['budget', 'isStockBased'],
    inventoryAssetDetail,
  );
  const showAnalytics =
    UAT_HENNEPIN_ANALYTICS_ASSET_ID === inventoryAssetDetail.id;

  return [
    {
      value: AssetDetailView.ASSET_INFO,
      label: 'info',
      icon: 'info',
      dataCy: 'assetInfoTab',
    },
    ...(!isMaterial && [
      {
        value: AssetDetailView.SUB_ITEMS,
        label: 'sub items',
        icon: 'list',
        dataCy: 'assetSubItemsTab',
      },
    ]),
    {
      value: AssetDetailView.WORK,
      label: 'work',
      icon: 'work',
      dataCy: 'assetWorkTab',
    },
    ...(isStockBased &&
      isCurrentEnvironment([Environment.DEV, Environment.QA]) && [
        {
          value: AssetDetailView.REQUESTS,
          label: 'requests',
          icon: 'description',
          dataCy: 'assetRequestsTab',
        },
      ]),
    {
      value: AssetDetailView.FILES,
      label: 'files',
      icon: 'folder',
      dataCy: 'assetFilesTab',
    },
    {
      value: AssetDetailView.PHOTOS,
      label: 'photos',
      icon: 'insert_photo',
      dataCy: 'assetPhotosTab',
    },
    ...(isMaterial && [
      {
        value: AssetDetailView.COST,
        label: 'cost',
        icon: 'monetization_on',
        dataCy: 'assetCostTab',
      },
    ]),
    ...(showAnalytics && [
      {
        value: AssetDetailView.ANALYTICS,
        label: 'analytics',
        icon: 'bar_chart',
        dataCy: 'assetAnalyticsTab',
      },
    ]),
  ];
};

interface Props {
  inventoryAssetDetail: InventoryAssetDetailType;
  activeView: AssetDetailView;
  setActiveView: (activeView: AssetDetailView) => void;
  navigateBack: () => void;
  loadingApproveAll: boolean;
  onAssetEdit: (
    id: string,
    name: string,
    rate?: number,
    unit?: string,
    isStockBased?: boolean,
    isStartEndReading?: boolean,
    isStartEndCalculated?: boolean,
    quantityRemaining?: number,
  ) => void;
  onApproveAllPendingChanges: () => void;
  inventoryMoveDisabled: boolean;
}

const InventoryDetailHeader = ({
  inventoryAssetDetail,
  activeView,
  setActiveView,
  navigateBack,
  loadingApproveAll,
  onAssetEdit,
  onApproveAllPendingChanges,
  inventoryMoveDisabled,
}: Props) => {
  const views = getViews(inventoryAssetDetail);

  return (
    <div styleName="header-container">
      <NavigationTitle
        title={inventoryAssetDetail.name}
        navigateBack={navigateBack}
      />
      <HeaderControls
        loadingApproveAll={loadingApproveAll}
        inventoryAssetDetail={inventoryAssetDetail}
        onAssetEdit={onAssetEdit}
        onApproveAllPendingChanges={onApproveAllPendingChanges}
        inventoryMoveDisabled={inventoryMoveDisabled}
      />
      <HeaderFields inventoryAssetDetail={inventoryAssetDetail} />
      <div styleName="icon-row">
        {views.map(view => {
          return (
            <HeaderNavigationIcon
              key={view.value}
              view={view}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InventoryDetailHeader;

import React, { useState } from 'react';

import { Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AttributesType } from '@atom/types/inventory';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import './attributeDetailResponsive.css';

const styles = {
  icon: {
    padding: '0px',
    marginLeft: '0.5rem',
    height: '35px',
    width: '35px',
    bottom: '10px',
  },
};

interface Props {
  attribute: AttributesType;
  attributeGroupName?: string;
  onPendingApproval?: (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  canManageChanges?: boolean;
  schemaPreview?: boolean;
  hasAttributeError?: boolean;
}

const AttributeRowResponsive = ({
  attribute,
  attributeGroupName,
  onPendingApproval,
  canManageChanges = false,
  schemaPreview = false,
  hasAttributeError = false,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const disableControls = !!attribute.changeId && !attribute.isEditable;

  const onRejectClick = () => {
    if (!disableControls) {
      setOpen(true);
    }
  };

  const onApproveCLick = () => {
    if (!disableControls) {
      onPendingApproval('accept', attributeGroupName, attribute);
    }
  };

  const onRejectConfirm = () => {
    onPendingApproval('reject', attributeGroupName, attribute);
    setOpen(false);
  };

  const showControls = !!attribute.changeId && canManageChanges;
  const unitSuffix = ` (${attribute.unit})`;
  const controlButtonsColor = disableControls
    ? colors.neutral.gray
    : colors.brand.blue;
  const disabledTooltip =
    'The pending change cannot be approved or rejected because the attribute is not editable. Please set the attribute to editable in the asset settings to approve or reject changes.';
  const rejectTooltip = disableControls ? disabledTooltip : 'Reject';
  const approveTooltip = disableControls ? disabledTooltip : 'Approve';
  const attributeName = attribute.isRequired
    ? `* ${attribute.name}`
    : attribute.name;
  const value = schemaPreview ? attribute.defaultValue : attribute.value;
  const labelStyle = hasAttributeError
    ? 'attribute-label error'
    : 'attribute-label';

  return (
    <>
      <div styleName="attribute-list-row">
        <div styleName={labelStyle}>
          {attributeName}
          {attribute.unit && !attribute.name.endsWith(unitSuffix) && unitSuffix}
        </div>
        <div styleName="attribute-value">
          {attributeDisplayUtility.display(
            value,
            attribute.dataType,
            attribute.unit,
            !!attribute.changeId,
            attribute.newValue,
            attribute.thousandsSeparator,
          )}
        </div>
        {showControls && (
          <div styleName="attribute-approval-container">
            <div styleName="pending-attribute-label">Edited</div>
            <div styleName="pending-attribute-control">
              <IconButton
                style={styles.icon}
                tooltip={rejectTooltip}
                onClick={onRejectClick}
              >
                <Icon color={controlButtonsColor}>clear</Icon>
              </IconButton>
            </div>
            <div styleName="pending-attribute-control">
              <IconButton
                tooltip={approveTooltip}
                style={styles.icon}
                onClick={onApproveCLick}
              >
                <Icon color={controlButtonsColor}>check</Icon>
              </IconButton>
            </div>
          </div>
        )}
      </div>
      <Modal
        open={open}
        cancelButtonText="Cancel"
        confirmButtonText="Reject"
        onCancel={() => setOpen(false)}
        onConfirm={onRejectConfirm}
        title="Reject Changes"
      >
        Are you sure you want to reject the changes on this attribute?
      </Modal>
    </>
  );
};

export default AttributeRowResponsive;

import React from 'react';

// @ts-ignore
import elementIcon from '@atom/components/common/svgIcons/elementIcon.svg';
import { InventorySchemaDetail } from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

interface Props {
  isElementGroup: boolean;
  isRoot: boolean;
  schema?: InventorySchemaDetail;
}

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

const TreeRowIcon = ({ isRoot, isElementGroup, schema }: Props) => {
  // schema icon
  if (isRoot) {
    return schemaUtilities.getSchemaIconFromSchemaOrAsset(schema, {
      imgOffset: '0',
      iconSize: '34px',
      marginTop: '0',
    });
  }

  // subitem icon
  if (!isElementGroup) {
    return <img style={styles.icon} src={elementIcon} />;
  }

  // no icon (element group)
  return <div />;
};

export default TreeRowIcon;
